export default class ApiManager {
  /**
   * apiClientsを介してAPIリクエストをまとめて行う
   * @param {Array} types APIのタイプの配列
   * @param {Array} methods APIのメソッド名の配列
   * @param {Array} parameters APIのパスパラメータ・クエリパラメータの配列
   */
  static request (types, methods, parameters) {
    let promises = []
    let apiClients = {
      auth: window._API_.Authorization(),
      tenant: window._API_.Tenants(),
      user: window._API_.Users(),
      event: window._API_.Events(),
      project: window._API_.Projects(),
      asset: window._API_.Assets(),
      notice: window._API_.Notices(),
      aws: window._API_.Aws(),
      util: window._API_.Utils()
    }
    for (let i = 0; i < methods.length; i++) {
      let method = window._API_.mode === 'stub' ? 'stub' + methods[i] : methods[i]
      // console.log(method)

      // APIが存在している時
      if (apiClients[types[i]] && apiClients[types[i]][method]) {
        promises.push(apiClients[types[i]][method](parameters[i]))

      // APIが存在しないのでモックデータを利用する時
      } else {
        // stubv1AuthLogin
        if (method === 'stubv1AuthLogin') {
          if (parameters[i].Id === 'm') {
            promises.push(this.requestMock('stubv1AuthLoginModerator'))
          } else if (parameters[i].Id === 'i') {
            promises.push(this.requestMock('stubv1AuthLoginInspector'))
          } else if (parameters[i].Id === 'o') {
            promises.push(this.requestMock('stubv1AuthLoginObserver'))
          } else {
            promises.push(this.requestMock('stubv1AuthLoginModerator'))
          }

        // stubv1UserListGet
        } else if (method === 'stubv1UserListGet') {
          if (parameters[i].Role === 'moderator') {
            promises.push(this.requestMock('stubv1ModeratorListGet'))
          } else if (parameters[i].Role === 'inspector') {
            promises.push(this.requestMock('stubv1InspectorListGet'))
          } else if (parameters[i].roles === 'observer') {
            promises.push(this.requestMock('stubv1ObserverListGet'))
          } else if (parameters[i].roles === 'moderator_observer') {
            promises.push(this.requestMock('stubv1ModeratorObserverListGet'))
          } else {
            promises.push(this.requestMock(method))
          }

        // stubv1UserDetailGet
        } else if (method === 'stubv1UserDetailGet') {
          if (parameters[i].userIdentityIds) {
            promises.push(this.requestMock('stubv1UserDetailGet_' + parameters[i].userIdentityIds[0]))
          } else {
            promises.push(this.requestMock(method))
          }

        // stubv1EventDetailGet
        } else if (method === 'stubv1EventDetailGet') {
          if (parameters[i].eventIds) {
            promises.push(this.requestMock('stubv1EventDetailGet_' + parameters[i].eventIds[0]))
          } else {
            promises.push(this.requestMock('stubv1EventDetailGet'))
          }

        // stubv1ProjectDetailGet
        } else if (method === 'stubv1ProjectDetailGet') {
          // console.log(parameters[i].projectIds)
          if (parameters[i].projectIds) {
            promises.push(this.requestMock('stubv1ProjectDetailGet_' + parameters[i].projectIds[0]))
          } else {
            promises.push(this.requestMock('stubv1ProjectDetailGet'))
          }

        // v1ProjectPhotoListGet
        } else if (method === 'stubv1ProjectPhotoListGet') {
          if (parameters[i].ProjectId) {
            promises.push(this.requestMock('stubv1ProjectPhotoListGet_' + parameters[i].ProjectId))
          } else {
            promises.push(this.requestMock('stubv1ProjectPhotoListGet'))
          }

        // v1ProjectCommentListGet
        } else if (method === 'stubv1ProjectCommentListGet') {
          if (parameters[i].ProjectId) {
            promises.push(this.requestMock('stubv1ProjectCommentListGet_' + parameters[i].ProjectId))
          } else {
            promises.push(this.requestMock('stubv1ProjectCommentListGet'))
          }

        // v1ProjectPromptReportListGet
        } else if (method === 'stubv1ProjectPromptReportListGet') {
          if (parameters[i].ProjectId) {
            promises.push(this.requestMock('stubv1ProjectPromptReportListGet_' + parameters[i].ProjectId))
          } else {
            promises.push(this.requestMock('stubv1ProjectPromptReportListGet'))
          }

        // v1ProjectCheckReportListGet
        } else if (method === 'stubv1ProjectCheckReportListGet') {
          if (parameters[i].ProjectId) {
            promises.push(this.requestMock('stubv1ProjectCheckReportListGet_' + parameters[i].ProjectId))
          } else {
            promises.push(this.requestMock('stubv1ProjectCheckReportListGet'))
          }

        // stubv1AssetDetailGet
        } else if (method === 'stubv1AssetDetailGet') {
            // console.log(parameters[i])
          if (parameters[i].assetIds) {
            promises.push(this.requestMock('stubv1AssetDetailGet_' + parameters[i].assetIds[0]))
          } else {
            promises.push(this.requestMock(method))
          }

        } else {
          promises.push(this.requestMock(method))
        }
      }
    }
    // return Promise.all(promises.map(p => p.catch(() => undefined)))
    return Promise.all(promises.map(p => p.catch((error) => error.response)))
    // return Promise.all(promises.map(p => p.catch((error) => {
    //   // console.log('response', error.response)
    //   // console.log('request', error.request)
    //   // console.log('message', error.message)
    //   // console.log('config', error.config)
    //   return error.response
    // })))
  }

  /**
   * モックデータを取得する
   * @param {String} fileName モックデータを取得するファイル名
   */
  static requestMock (fileName) {
    let data = require('../../test/mock/' + fileName).default.mock
    return new Promise(function (resolve) {
      setTimeout(resolve(data), 2000)
    })
  }
}
