import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import Loading from '@/components/Loading'
import Constants from '@/utils/Constants'
import Utility from '@/utils/Utility'

export default {
  name: 'ProjectDetail',
  components: {
    Loading
  },
  metaInfo () {
    this.setMeta({
      title: 'プロジェクト詳細 | ' + Constants.SiteInfo.title
    })
    return this.getMeta
  },
  props: {
    projectId: String
  },
  data () {
    return {
      showLoading: true
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapState({
      model: state => state.projectDetail.projectModel,
      taskId: state => state.projectDetail.taskId
    }),
    ...mapGetters({
      getMeta: 'meta/get',
      isModerator: 'auth/isModerator',
      isInspector: 'auth/isInspector',
      isObserver: 'auth/isObserver',
      projectCompleted: 'projectDetail/projectCompleted'
    }),
    pageTitle () {
      return this.model.BridgeName
    },
    taskList () {
      return Constants.Tasks
    },

    // detail
    startDate () {
      return Utility.getJSTDate(this.model.StartDatePlan, 'YYYY/MM/DD')
    },
    endDate () {
      return Utility.getJSTDate(this.model.EndDatePlan, 'YYYY/MM/DD')
    },

    // modify
    showModifyButton () {
      return this.isModerator || this.isInspector
    },
    disableModifyButton () {
      return Utility.projectCompleted(this.model.ProjectStatus)
    }
  },
  watch: {
    taskId () {
      const routeName = 'ProjectTask' + this.taskId
      if (this.$route.name === routeName) {
        return;
      }

      this.$router.push({
        name: routeName
      }).catch(failure => {
        // console.log('#####', failure)
      })
    }
  },
  methods: {
    ...mapActions({
      setMeta: 'meta/set',
      showError: 'error/show',
      initLoad: 'projectDetail/initLoad',
      showDetailModal: 'projectDetailModal/show',
      showModifyModal: 'projectModifyModal/show'
    }),
    ...mapMutations({
      setTaskId: 'projectDetail/setTaskId'
    }),
    init () {
      this.showLoading = true

      // データ読出し
      this.initLoad({
        projectIds: [Number(this.projectId)]
      })
        .then(() => {
          this.showLoading = false

          const routeName = 'ProjectTask' + this.taskId
          if (this.$route.name === routeName) {
            return;
          }

          this.$router.push({
            name: routeName
          }).catch(failure => {
            // console.log('@@@@@', failure)
          })
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
        })
    },
    // タスクのクラス名を取得
    getTaskClass (id) {
      let bit = Utility.projectStatusToBit(this.model.ProjectStatus)
      // console.log(bit)
      let classes = []
      if (this.projectCompleted) {
        classes.push('complete')

      } else {
        if (id === 6) {
          classes.push('disabled')
        } else {
          let index = Constants.Tasks.length - id
          bit.charAt(index) === '1' ? classes.push('complete') : null
        }
      }
      if (id === this.taskId) {
        classes.push('active')
      }
      return classes.join(' ')
    },
    // タスクをクリック
    clickTask (id) {
      if (id === 6 && !this.projectCompleted) return
      this.setTaskId(id)
    },
    clickModify () {
      this.showModifyModal({
        id: 'project-modify-modal'
        // id: 'project-modify-modal-' + this.model.projectId
      })
    },
    // 詳細表示
    clickDetail () {
      this.showDetailModal({
        id: 'asset-detail-modal'
        // id: 'asset-detail-modal-' + this.model.projectId
      })
    }
  }
}
