import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Vue from 'vue'

const state = {
  targetId: Constants.Params.RegisterHash,
  // イベント一覧で選択された地震ID
  initialEqId: null,
  // 選択済みの地震ID
  selectedQuakeModel: [],
  // 候補の地震ID
  candidateQuakeModel: [],
  values: {}
}

const getters = {
  get: state => state,
  isRegister: state => state.targetId === Constants.Params.RegisterHash,
  isModify: state => state.targetId !== Constants.Params.RegisterHash
}

const actions = {
  /**
   * 初期読出し
   */
  initLoad ({commit}, {targetId, quakeParameters}) {
    commit('setTargetId', targetId)

    return new Promise((resolve, reject) => {
      if (getters.isRegister(state)) {
        // 地震速報一覧を読出し
        ApiManager.request(
          ['util'],
          ['v1HypocentersListGet'],
          [quakeParameters])
          .then(res => {
            console.log(res)
            if (res[0].data.Result === Constants.ApiResult.Success) {
              commit('setCandidateQuakeModel', res[0].data.Hypocenters)

              console.log('@candidateQuakeModel', state.candidateQuakeModel)
              resolve()
              
            } else {
              reject(res[0].data)
            }
          })
          .catch(error => {
            reject(error)
          })

      } else {
        let eventParameters = {
          eventIds: [state.targetId]
        }

        // イベント詳細・地震速報一覧を読出し
        ApiManager.request(
          ['event', 'util'],
          ['v1EventDetailGet', 'v1HypocentersListGet'],
          [eventParameters, quakeParameters])
          .then(res => {
            console.log(res)
            if (res[0].data.Result === Constants.ApiResult.Success) {
              commit('initLoaded', res[0].data.Events[0])

              // すでに関連付けされているeqIdをキーに地震情報を取得
              commit('setSelectedQuakeModel', res[0].data.Events[0].EarthquakesInfo)

              // すでに関連付けされているeqIdを削除して登録
              let list = res[1].data.Hypocenters.filter(hypo => res[0].data.Events[0].EarthquakesInfo.findIndex(item => item.EqId === hypo.EqId) === -1)
              commit('setCandidateQuakeModel', list)

              console.log('@eventValue', state.values)
              console.log('@selectedQuakeModel', state.selectedQuakeModel)
              console.log('@candidateQuakeModel', state.candidateQuakeModel)
              resolve()
              
            } else {
              reject(res[0].data)
            }
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  },
  /**
   * イベント一覧の地震情報から新規作成時に地震IDを登録
   */
  initQuake ({commit}, eqId) {
    commit('setInitialEqId', eqId)
  },
  /**
   * 任意の地震IDを候補から削除して、選択済みに移行
   */
  addQuakes ({commit}, quakeIds) {
    if (!quakeIds.length) return

    let selected = $.extend(true, [], state.selectedQuakeModel)
    let candidate = $.extend(true, [], state.candidateQuakeModel)

    quakeIds.forEach(id => {
      let index = candidate.findIndex(item => item.EqId === id)
      if (index !== -1) {
        selected = selected.concat(candidate.filter(item => item.EqId === id))
        candidate.splice(index, 1)
      }
    })

    commit('setSelectedQuakeModel', selected)
    commit('setCandidateQuakeModel', candidate)
  },
  /**
   * 任意の地震IDを選択済みから削除して、候補に移行
   */
  removeQuakes ({commit}, quakeIds) {
    if (!quakeIds.length) return
    
    let selected = $.extend(true, [], state.selectedQuakeModel)
    let candidate = $.extend(true, [], state.candidateQuakeModel)

    quakeIds.forEach(id => {
      let index = selected.findIndex(item => item.EqId === id)
      if (index !== -1) {
        candidate = candidate.concat(selected.filter(item => item.EqId === id))
        selected.splice(index, 1)
      }
    })

    commit('setSelectedQuakeModel', selected)
    commit('setCandidateQuakeModel', candidate)
  }
}

const mutations = {
  initLoaded (state, data) {
    Vue.set(state, 'values', {
      eventName: data.EventName,
      moderatorName: data.ModeratorName,
      eventStatus: data.EventStatus,
      eventStatusUpdated: data.EventStatusUpdated,
      createdAt: data.CreatedAt,
      updatedAt: data.UpdatedAt,
      projectCount: data.ProjectCount,
      invalidFlag: !data.LiveFlg,
      note: data.Note,
      earthquakesInfo: data.EarthquakesInfo
    })
  },
  setTargetId (state, targetId) {
    state.targetId = targetId
  },
  setInitialEqId (state, eqId) {
    state.initialEqId = eqId
  },
  setSelectedQuakeModel (state, data) {
    // 発生時刻でソート
    Vue.set(state, 'selectedQuakeModel', data)
  },
  setCandidateQuakeModel (state, data) {
    // 発生時刻でソート
    Vue.set(state, 'candidateQuakeModel', data)
  },
  setValues (state, values) {
    Vue.set(state, 'values', values)
  },
  reset (state) {
    state.initialEqId = null
    Vue.set(state, 'selectedQuakeModel', [])
    Vue.set(state, 'candidateQuakeModel', [])
    Vue.set(state, 'values', {})
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
