import { mapState, mapActions } from 'vuex'

export default {
  name: 'ProjectPhotoModal',
  mounted () {
    // // モーダル表示時の処理
    // $('#' + this.id).on('show.bs.modal', () => {

    // })

    // モーダル非表示時の処理
    $('#' + this.id).on('hide.bs.modal', () => {
      this.hide()
    })
    
    $('#' + this.id).modal('show')
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapState({
      active: state => state.projectPhotoModal.active,
      id: state => state.projectPhotoModal.id,
      model: state => state.projectPhotoModal.model
    }),
    getTitle () {
      if (this.model === null) return ''
      return this.model.FileName
    },
    fileUrl () {
      if (this.model === null) return ''
      return this.model.ThumbUrl
    }
  },
  watch: {
    '$route' () {
      // console.log('close modal')
      $('#' + this.id).modal('hide')
    },
    active (to) {
      if (to) {
        // console.log('open project photo modal')
        $('#' + this.id).modal('show')
      } else {
        // console.log('close project photo modal')
        $('#' + this.id).modal('hide')
      }
    }
  },
  methods: {
    ...mapActions({
      hide: 'projectPhotoModal/hide'
    }),
    clickAccept () {
      // this.$emit('childs-event', {
      //   accept: true
      // })
      $('#' + this.id).modal('hide')
    }
  }
}