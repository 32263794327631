import { mapState, mapMutations } from 'vuex'
import Validator from '@/utils/Validator'
export default {
  name: 'ReminderPasswordInput',
  data () {
    return {
      // 入力値
      // email: '',
      password: '',
      passwordConfirm: '',
      isClickedConfirm: false
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapState({
      values: state => state.reminderPassword.values,
      getEmail: state => state.reminderPassword.email
    }),
    email: {
      get () {
        return this.getEmail
      },
      set (value) {
        this.setEmail(value)
      }
    },
    // validate
    emailIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.email)
    },
    passwordIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.password)
    },
    passwordConfirmIsValid () {
      if (!this.isClickedConfirm) return true
      return this.password === this.passwordConfirm
    },
    formIsValid () {
      // return true
      return this.emailIsValid &&
        this.passwordIsValid &&
        this.passwordConfirmIsValid
    },
    disableConfirmButton () {
      return !Validator.validText(this.email) ||
        !Validator.validText(this.password) ||
        this.password !== this.passwordConfirm
    }
  },
  methods: {
    ...mapMutations({
      setValues: 'reminderPassword/setValues',
      setEmail: 'reminderPassword/setEmail'
    }),
    init () {
      // 確認画面から戻った時、編集に宣した時に値を挿入
      // if (this.values) this.isClickedConfirm = true
      if (this.values.email) this.email = this.values.email
      if (this.values.password) this.password = this.values.password
      if (this.values.password) this.passwordConfirm = this.values.password
    },
    clickConfirm () {
      this.isClickedConfirm = true
      // console.log(this.invalidFlag)

      // validate
      if (this.formIsValid) {
        let data = {
          email: this.email,
          password: this.password
        }
        this.setValues(data)
        this.$router.push({name: 'ReminderPasswordConfirm'})
      }
    }
  }
}
