export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      TakenPhotos: [
        {
          ProjectId: 1,
          TakenPhotosId: 1,
          ProjectPhotoNumber: 1,
          TargetView: '撮影対象箇所',
          DamageDescription: '損傷・変状の説明',
          BridgeProfileFlg: true,
          FileName: 'オリジナル写真ファイル名001',
          FileSize: 120304,
          FileObjectKey: 'オリジナル写真ObjectKey',
          ThumbUrl: '/images/1.png',
          CreatedAt: '2020-03-20 19:20:29',
          UpdatedAt: '2020-03-20 19:20:29'
        }
      ]
    }
  }
}