import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

// views
import Signin from '@/views/Signin'
import NotFound from '@/views/NotFound'
import Home from '@/views/Home'

// view : Register
import Register from '@/views/Register'
import RegisterInput from '@/views/Register/Input'
import RegisterConfirm from '@/views/Register/Confirm'
import RegisterComplete from '@/views/Register/Complete'

// view : Password
import Password from '@/views/Password'
import PasswordInput from '@/views/Password/Input'
import PasswordConfirm from '@/views/Password/Confirm'
import PasswordComplete from '@/views/Password/Complete'

// views : Reminder
import Reminder from '@/views/Reminder/Email'
import ReminderInput from '@/views/Reminder/Email/Input'
import ReminderConfirm from '@/views/Reminder/Email/Confirm'
import ReminderComplete from '@/views/Reminder/Email/Complete'
import ReminderPassword from '@/views/Reminder/Password'
import ReminderPasswordInput from '@/views/Reminder/Password/Input'
import ReminderPasswordConfirm from '@/views/Reminder/Password/Confirm'
import ReminderPasswordComplete from '@/views/Reminder/Password/Complete'
import ReminderInvalid from '@/views/Reminder/Invalid'

// views : notice
import Notice from '@/views/Notice'
import NoticeDetail from '@/views/Notice/Detail'

// views : event
import Event from '@/views/Event'
import EventForm from '@/views/Event/Form'
import EventInput from '@/views/Event/Form/Input'
import EventConfirm from '@/views/Event/Form/Confirm'
import EventComplete from '@/views/Event/Form/Complete'
import EventDetail from '@/views/Event/Detail'

// views : project
import Project from '@/views/Project'
import ProjectForm from '@/views/Project/Form'
import ProjectInput from '@/views/Project/Form/Input'
import ProjectAttribute from '@/views/Project/Form/Attribute'
import ProjectConfirm from '@/views/Project/Form/Confirm'
import ProjectComplete from '@/views/Project/Form/Complete'
import ProjectDetail from '@/views/Project/Detail'
import ProjectTask1 from '@/views/Project/Detail/Task1'
import ProjectTask2 from '@/views/Project/Detail/Task2'
import ProjectTask3 from '@/views/Project/Detail/Task3'
import ProjectTask4 from '@/views/Project/Detail/Task4'
import ProjectTask5 from '@/views/Project/Detail/Task5'
import ProjectTask6 from '@/views/Project/Detail/Task6'
// import ProjectPhotos from '@/views/Project/Photos'

// views : account
import Account from '@/views/Account'
import AccountInput from '@/views/Account/Input'
import AccountConfirm from '@/views/Account/Confirm'
import AccountComplete from '@/views/Account/Complete'

// utils
import Constants from '@/utils/Constants'

Vue.use(Router)
Vue.use(Meta)

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // スラ止めの場合にリダイレクト
    { path: '/event/form', redirect: '/event/form/input'},
    { path: '/project/form', redirect: '/project/form/input'},
    { path: '/account', redirect: '/account/form'},
    { path: '/register', redirect: '/register/form'},
    { path: '/password', redirect: '/password/form'},
    { path: '/reminder', redirect: '/reminder/form'},
    // ダッシュボード
    {
      path: '/home',
      // redirect: { name: 'Notice' }
      name: 'Home',
      component: Home,
      meta: { requiresAuth: true }
    },

    // お知らせ
    {
      path: '/notice',
      name: 'Notice',
      component: Notice,
      meta: { requiresAuth: true }
    },
    // お知らせ詳細
    {
      path: '/notice/:noticeId',
      name: 'NoticeDetail',
      component: NoticeDetail,
      props: route => ({ noticeId: route.params.noticeId }),
      meta: { requiresAuth: true }
    },

    // イベント一覧
    {
      path: '/event',
      name: 'Event',
      component: Event,
      meta: { requiresAuth: true }
    },
    // イベント登録
    {
      path: '/event/:eventId/form',
      component: EventForm,
      meta: { requiresAuth: true },
      props: route => ({ eventId: route.params.eventId }),
      children: [
        // 入力
        {
          path: 'input',
          name: 'EventRegister',
          component: EventInput,
          props: { modifyMode: false }
        },
        // 確認
        {
          path: 'confirm',
          name: 'EventRegisterConfirm',
          component: EventConfirm
        },
        // 完了
        {
          path: 'complete',
          name: 'EventRegisterComplete',
          component: EventComplete
        }
      ]
    },
    // イベント詳細
    {
      path: '/event/:eventId',
      name: 'EventDetail',
      component: EventDetail,
      props: route => ({ eventId: route.params.eventId }),
      meta: { requiresAuth: true }
    },

    // プロジェクト一覧
    {
      path: '/project',
      name: 'Project',
      component: Project,
      props: { projectInCharge: true },
      meta: { requiresAuth: true }
    },
    // プロジェクト登録
    {
      path: '/project/form',
      component: ProjectForm,
      meta: { requiresAuth: true },
      children: [
        // 入力
        {
          path: 'input',
          name: 'ProjectRegister',
          component: ProjectInput,
          props: { modifyMode: false }
        },
        // 入力
        {
          path: 'attribute',
          name: 'ProjectRegisterAttribute',
          component: ProjectAttribute,
          props: { modifyMode: false }
        },
        // 確認
        {
          path: 'confirm',
          name: 'ProjectRegisterConfirm',
          component: ProjectConfirm
        },
        // 完了
        {
          path: 'complete',
          name: 'ProjectRegisterComplete',
          component: ProjectComplete
        }
      ]
    },
    // // プロジェクトの撮影写真一覧
    // {
    //   path: '/project/photos/:projectId',
    //   name: 'ProjectPhotos',
    //   component: ProjectPhotos,
    //   props: route => ({ projectId: route.params.projectId }),
    //   meta: { requiresAuth: true }
    // },
    // プロジェクト詳細
    // {
    //   path: '/project/:projectId',
    //   name: 'ProjectDetail',
    //   component: ProjectDetail,
    //   props: route => ({ projectId: route.params.projectId }),
    //   meta: { requiresAuth: true }
    // },
    {
      path: '/project/:projectId',
      name: 'ProjectDetail',
      component: ProjectDetail,
      props: route => ({ projectId: route.params.projectId }),
      meta: { requiresAuth: true },
      children: [
        // 各タスク
        {
          path: 'task1',
          name: 'ProjectTask1',
          component: ProjectTask1
        },
        {
          path: 'task2',
          name: 'ProjectTask2',
          component: ProjectTask2
        },
        {
          path: 'task3',
          name: 'ProjectTask3',
          component: ProjectTask3
        },
        {
          path: 'task4',
          name: 'ProjectTask4',
          component: ProjectTask4
        },
        {
          path: 'task5',
          name: 'ProjectTask5',
          component: ProjectTask5
        },
        {
          path: 'task6',
          name: 'ProjectTask6',
          component: ProjectTask6
        }
      ]
    },

    // プロフィール編集
    {
      path: '/account',
      component: Account,
      meta: { requiresAuth: true },
      children: [
        // 入力
        {
          path: 'form',
          name: 'Account',
          component: AccountInput
        },
        // 確認
        {
          path: 'confirm',
          name: 'AccountConfirm',
          component: AccountConfirm
        },
        // 完了
        {
          path: 'complete',
          name: 'AccountComplete',
          component: AccountComplete
        }
      ]
    },

    // ログイン
    {
      path: '/signin',
      name: 'Signin',
      component: Signin
    },

    // パスワード登録（新規ユーザー）
    {
      path: '/register',
      component: Register,
      children: [
        // 入力
        {
          path: 'form',
          name: 'Register',
          component: RegisterInput
        },
        // 確認
        {
          path: 'confirm',
          name: 'RegisterConfirm',
          component: RegisterConfirm
        },
        // 完了
        {
          path: 'complete',
          name: 'RegisterComplete',
          component: RegisterComplete
        }
      ]
    },
    // パスワード変更（既存ユーザー）
    {
      path: '/password',
      component: Password,
      children: [
        // 入力
        {
          path: 'form',
          name: 'Password',
          component: PasswordInput
        },
        // 確認
        {
          path: 'confirm',
          name: 'PasswordConfirm',
          component: PasswordConfirm
        },
        // 完了
        {
          path: 'complete',
          name: 'PasswordComplete',
          component: PasswordComplete
        }
      ]
    },

    // リマインダ（メールアドレス送信）
    {
      path: '/reminder',
      component: Reminder,
      children: [
        // 入力
        {
          path: 'form',
          name: 'Reminder',
          component: ReminderInput
        },
        // 確認
        {
          path: 'confirm',
          name: 'ReminderConfirm',
          component: ReminderConfirm
        },
        // 完了
        {
          path: 'complete',
          name: 'ReminderComplete',
          component: ReminderComplete
        }
      ]
    },
    // リマインダ（新パスワード登録）
    {
      path: '/reminder/password',
      component: ReminderPassword,
      children: [
        // 入力
        {
          path: '',
          name: 'ReminderPassword',
          component: ReminderPasswordInput
        },
        // 確認
        {
          path: 'confirm',
          name: 'ReminderPasswordConfirm',
          component: ReminderPasswordConfirm
        },
        // 完了
        {
          path: 'complete',
          name: 'ReminderPasswordComplete',
          component: ReminderPasswordComplete
        }
      ]
    },
    // リマインダ（パラメータ不正）
    {
      path: '/reminder/invalid',
      name: 'ReminderInvalid',
      component: ReminderInvalid
    },
    
    // 404
    {
      path: '/404',
      name: 'NotFound',
      component: NotFound
    },
    {
      path: '*',
      redirect: 'Signin'
    }
  ]
})

/* 認証していない場合にリダイレクト */
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (requiresAuth) {
    // ローカルストレージにユーザー情報が保持されているかチェック
    // 保持されていなければログインページにリダイレクト
    Constants.checkAuth()
      .then(routeName => {
        if (routeName) {
          next({name: routeName})
        } else {
          next()
        }
      })
      .catch(() => {
        next({name: 'Signin'})
      })
  } else {
    next() // next() を常に呼び出すようにしてください!
  }
})

export default router
