import { mapState, mapActions, mapMutations } from 'vuex'
import Loading from '@/components/Loading'
import DeleteSelector from '@/components/DeleteSelector'
import Constants from '@/utils/Constants'

export default {
  name: 'ProjectForm',
  components: {
    Loading,
    DeleteSelector
  },
  data () {
    return {
      // チェック
      checkList: []
    }
  },
  mounted () {
    // resize
    $(window).on({ resize: this.onResize })
    this.$nextTick(() => {
      this.onResize()
    })
  },
  beforeDestroy () {
    $(window).off({ resize: this.onResize })
  },
  computed: {
    ...mapState({
      eventId: state => state.projectForm.eventId,
      eqId: state => state.projectForm.eqId,
      assetValue: state => state.projectFormMap.value,
      selectedMarkerId: state => state.projectFormMap.selectedMarkerId
    }),
    disabledRegisterButton () {
      return !this.assetValue.length || 
        this.checkList.length > 0
    }
  },
  watch: {
    assetValue () {
      this.$nextTick(() => {
        this.onResize()
      })
    },
    checkList () {
      let selectedAll = true
      if (this.assetValue.length) {
        console.log(this.assetValue)
        this.assetValue.forEach(item => {
          if (this.checkList.indexOf(item.AssetId) === -1) {
            selectedAll = false
          }
        })
      } else {
        selectedAll = false
      }
      this.$refs.deleteSelector.value = selectedAll
    }
  },
  methods: {
    ...mapActions({
      showError: 'error/show',
      initLoad: 'projectForm/initLoad',
      removeValue: 'projectFormMap/removeValue'
    }),
    ...mapMutations({
      setAssetIds: 'projectForm/setAssetIds',
      resetForm: 'projectForm/reset'
    }),
    onResize () {
      let windowHeight = window.innerHeight ?
        window.innerHeight : $(window).height()
      let container = $('#projectFormInput');
      let bodyH = windowHeight - 80
      let h1 = container.children('.header').length ?
        container.children('.header').outerHeight() : 0
      let h2 = container.children('.body').find('.control').length ?
        container.children('.body').find('.control').outerHeight() : 0
      let h3 = container.children('.footer').length ?
        container.children('.footer').outerHeight() : 0
        // console.log(h1 + ' - ' + h2 + ' - ' + h3)
      let h = bodyH - h1 - h2 - h3

      container.children('.body').find('.list').height(h)
    },

    /**
     * マーカー画像を取得
     */
    getMarker (model) {
      // サイズ
      let size = Constants.GoogleMapMarkerSize.md

      // 色
      let roadAdminKbn = model.RoadAdminKbn ? String(model.RoadAdminKbn) : '4'
      let color = Constants.GoogleMapMarkerColor.roadAdminKbn[roadAdminKbn][1]

      // let color = this.selectedMarkerId === model.assetId ?
      //   Constants.GoogleMapMarkerColor.active :
      //   Constants.GoogleMapMarkerColor.selected

      // マーカーを生成
      let template = Constants.GoogleMapMarker.Pin()
      let svg = template.replace('{size}', size + 'px')
        .replace('{color}', color)
        .replace('{center}', Constants.GoogleMapMarkerColor.center)
        .replace('{stroke}', Constants.GoogleMapMarkerColor.stroke)
      return 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(svg)
    },

    deleteSeclectorChange (value) {
      this.clickFunction(value ? 'select' : 'clear')
    },
    clickFunction (func) {
      // すべて選択
      if (func === 'select') {
        this.checkList = []
        this.assetValue.forEach(item => {
          this.checkList.push(item.AssetId)
        })
      }

      // 選択解除
      if (func === 'clear') {
        this.checkList = []
      }

      // 選択したファイルを削除
      if (func === 'remove') {
        // $('#modal-delete').modal('show')
        this.removeValue(this.checkList)
        this.checkList = []
      }
    },

    clickRegister () {
      this.setAssetIds(this.assetValue)

      this.$router.push({
        name: 'ProjectRegisterAttribute'
      })
    }
  }
}
