import { mapState, mapGetters,  mapActions } from 'vuex'
import Constants from '@/utils/Constants'
import Utility from '@/utils/Utility'
import StatusFormPhoto from './Photo'

export default {
  name: 'StatusForm',
  components: {
    StatusFormPhoto
  },
  props: {
    sectionKey: String
  },
  data () {
    return {

    }
  },

  mounted() {
    if (this.isModify) return;

    // 新規登録の場合、プリセットデータを反映する
    // ラベルを取得
    const label = Constants.task4StatusList
      .find(item => item.id === this.sectionKey)
      .label;

    // プリセットデータを参照
    const model = this.presetData(label);
    // console.log(this.sectionKey, label, model)

    if (model.DamageDescription) {
      this.section = 'true';
      this.comment = model.DamageDescription;
    }

    if (model.ProjectPhotoNumber) {
      this.addStatusPhotoId({
        sectionKey: this.sectionKey,
        takenPhotoId: model.TakenPhotoId
      })
    }
  },

  computed: {
    ...mapState({
      photoModel: state => state.projectTask4.photoModel
    }),
    ...mapGetters({
      isModerator: 'auth/isModerator',
      isInspector: 'auth/isInspector',
      isObserver: 'auth/isObserver',
      projectCompleted: 'projectDetail/projectCompleted',
      getStatusValue: 'projectTask4/getStatusValue',
      isModify: 'projectTask4/isModify',
      presetData: 'projectTask4/presetData',
    }),

    // result
    damageResult () {
      if (this.damage === undefined || this.damage === null) return '-'
      return this.damage || '-'
    },
    commentResult () {
      if (this.comment === undefined || this.comment === null) return '-'
      return this.comment  ? Utility.decodeBraak(this.comment) : '-'
    },

    // 入力値のリスト
    damageList () {
      return Constants.ProjectConst.Damage
    },
    // 添付写真のリスト
    photoList () {
      let list = $.extend(true, [], this.getStatusValue(this.sectionKey).photoIds)
      if (this.enableForm) list.push(null)
      return list
    },

    // input
    damage: {
      get () { return this.getStatusValue(this.sectionKey).damage },
      set (value) {
        this.setStatusParam({
          sectionKey: this.sectionKey,
          key: 'damage',
          value: value
        })
      }
    },
    comment: {
      get () { return this.getStatusValue(this.sectionKey).comment },
      set (value) {
        this.setStatusParam({
          sectionKey: this.sectionKey,
          key: 'comment',
          value: value
        })
      }
    },

    // form
    enableForm () {
      return (this.isModerator || this.isInspector) &&
        !this.projectCompleted
    },
    showRequire () {
      return this.enableForm
    },
    showDamage () {
      return this.sectionKey !== 'Opinion'
    },

    // validate
    damageIsValid () {
      return true
    },
    commentIsValid () {
      return true
    },
    photoIsValid () {
      return true
    }
  },
  methods: {
    ...mapActions({
      setStatusParam: 'projectTask4/setStatusParam',
      addStatusPhotoId: 'projectTask4/addStatusPhotoId',
      // showAlert: 'alert/show'
    })
  }
}