import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import Loading from '@/components/Loading'
import Constants from '@/utils/Constants'
import Utility from '@/utils/Utility'

export default {
  name: 'NoticeForm',
  components: {
    Loading
  },
  metaInfo () {
    this.setMeta({
      title: 'お知らせ詳細 | ' + Constants.SiteInfo.title
    })
    return this.getMeta
  },
  props: {
    noticeId: String
  },
  data () {
    return {
      showLoading: false
    }
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    this.resetForm()
  },
  computed: {
    ...mapState({
      values: state => state.noticeDetail.values
    }),
    ...mapGetters({
      getMeta: 'meta/get'
    }),
    getDate () {
      if (this.values.startDate) {
        let v = this.values.startDate.split('T').join(' ').split('+09:00').join('')
        return Utility.getDate(v, 'YYYY/MM/DD HH:mm')
      }

      // if (this.values.updatedDt) {
      //   return Utility.getJSTDate(this.values.updatedDt, 'YYYY/MM/DD HH:mm')
      // } else if (this.values.createdDt) {
      //   return Utility.getJSTDate(this.values.createdDt, 'YYYY/MM/DD HH:mm')
      // } else {
      //   return ''
      // }
    },
    body () {
      return this.values.body ? this.values.body.split('\n').join('<br>') : ''
    }
  },
  methods: {
    ...mapActions({
      setMeta: 'meta/set',
      initLoad: 'noticeDetail/initLoad',
      showError: 'error/show'
    }),
    ...mapMutations({
      resetForm: 'noticeDetail/reset'
    }),
    init () {
      // データ読出し
      this.showLoading = true
      this.initLoad({
        targetId: this.noticeId
      })
        .then(() => {
          this.showLoading = false
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
        })
    },
    clickBack () {
      this.$router.go(-1)
    }
  }
}
