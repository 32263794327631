import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Vue from 'vue'

const state = {
  active: true,
  map: null,
  // MapVIewのモデル
  model: [],
  // 生成されたMarkerのリスト
  markerList: [],
  // 選択されたマーカーのAssetID
  selectedMarkerId: null,
  // マーカーの表示ZINDEX
  markerZIndex: 1,

  // 震源地のデータ
  hypocenter: null,

  // 各種クエリ
  keywordQuery: '',
  locationIdQuery: '',
  roadAdminQuery: '',
  cityQuery: '',
  completeYearQuery: '',
  constructorNameQuery: '',
  bridgeAdminQuery: '',
  formQuery: '',
  eqQuery: '',

  // 検索クエリ
  serachedType: null,
  searchedQuery: '',

  // 検索中
  searching: false
}

const getters = {
  get: state => state,
  // 選択されたアセット情報を返却
  getSelectedModel: state => state.model.filter(item => item.AssetId === state.selectedMarkerId),
}

const actions = {
  /**
   * 検索
   */
  search ({commit}, {type}) {
    commit('selectedMarker', null)
    commit('clearModel')
    // console.log('search.type : ' + type)

    // 検索中フラグ
    commit('setSearching', true)
    
    // フィルターを閉じる
    this.commit('config/setShowFilters', false)

    /*
     * 検索クエリ以外の入力をリセット
     */
    let clearQueries = type => {
      let queries = [
        'keywordQuery',
        'locationIdQuery',
        'roadAdminQuery',
        'cityQuery',
        'completeYearQuery',
        'constructorNameQuery',
        'bridgeAdminQuery',
        'formQuery',
        'eqQuery'
      ]
      queries.forEach((item, index) => {
        if (type !== index) Vue.set(state, item, '')
      })
    }

    // 検索条件
    let value = {
      type: type,
      query: ''
    }
    if (type === 0) value.query = state.keywordQuery
    if (type === 1) value.query = state.locationIdQuery
    if (type === 2) value.query = state.roadAdminQuery
    if (type === 3) value.query = state.cityQuery
    if (type === 4) value.query = state.completeYearQuery
    if (type === 5) value.query = state.constructorNameQuery
    if (type === 6) value.query = state.bridgeAdminQuery
    if (type === 7) value.query = state.formQuery
    if (type === 8) value.query = state.eqQuery
    commit('setSearchedValue', value)

    // 検索
    return new Promise((resolve, reject) => {
      let parameters = {
        showAll: false,
        attachPhotos: true
      }
      if (type === 0) parameters.keyword = state.searchedQuery
      if (type === 1) parameters.locationId = state.searchedQuery
      if (type === 2) parameters.roadAdmin = state.searchedQuery
      if (type === 3) parameters.city = state.searchedQuery
      if (type === 4) parameters.completeYear = state.searchedQuery
      if (type === 5) parameters.constructorName = state.searchedQuery
      if (type === 6) parameters.bridgeAdmin = state.searchedQuery
      if (type === 7) parameters.bridgeForm = state.searchedQuery
      if (type === 8) parameters.eqId = state.eqQuery
      console.log('parameters : ', parameters)

      // 検索クエリ以外の入力をリセット
      clearQueries(type)

      // アセット一覧を読出し
      ApiManager.request(
        ['asset'],
        ['v1AssetListGet'],
        [parameters])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            commit('setModel', res[0].data.Assets)
            if (res[0].data.Hypocenter) {
              commit('setHypocenter', res[0].data.Hypocenter)
            }
            console.log('GoogleMap.model', state.model)
            console.log('GoogleMap.hypocenter', state.hypocenter)
            
            resolve(res[0].data)
            commit('setSearching', false)

          } else {
            reject(res[0].data)
            commit('setSearching', false)
          }
        })
        .catch(error => {
          reject(error)
          commit('setSearching', false)
        })
    })
  },
  // mapにプロットするマーカーを追加
  addMarker ({commit}, marker) {
    commit('addMarker', marker)
    commit('setMarkerZIndex', state.markerZIndex + 1)
  },
  // mapにプロットされたマーカーを全て削除
  clearMarkers ({commit}) {
    state.markerList.forEach(marker => {
      marker.setMap(null)
    })
    commit('clearMarkers')
    commit('setHypocenter', null)
  },
  // マーカーを選択
  selectMarker ({commit}, id) {
    commit('selectedMarker', id === undefined ? null : id)
  },
  // // フィルター
  // toggleFilters ({commit}) {
  //   this.commit('config/setShowFilters', !state.showFilters)
  // },
  // 初期化
  reset ({commit}) {
    // マーカー
    state.markerList.forEach(marker => {
      marker.setMap(null)
    })
    commit('clearMarkers')
    // 震源地
    commit('setHypocenter', null)
    // データ
    commit('clearModel')
    commit('selectedMarker', null)
    commit('setMarkerZIndex', 1)
    commit('setSearchedValue', {
      type: null,
      query: ''
    })
    // フィルター
    commit('setKeywordQuery', '')
    commit('setLocationIdQuery', '')
    commit('setRoadAdminQuery', '')
    commit('setCityQuery', '')
    commit('setCompleteYearQuery', '')
    commit('setConstructorNameQuery', '')
    commit('setBridgeAdminQuery', '')
    commit('setFormQuery', '')
    commit('setEqQuery', '')
    // 表示
    commit('setActive', false)
  }
}

const mutations = {
  setActive (state, flag) {
    state.active = flag
    if (flag) {
      this.commit('projectMap/setActive', false)
      this.commit('projectFormMap/setActive', false)
    }
  },
  setGoogleMap (state, map) {
    state.map = map
  },
  setModel (state, data) {
    Vue.set(state, 'model', data)
  },
  clearModel (state) {
    Vue.set(state, 'model', [])
  },
  // マーカー
  addMarker (state, marker) {
    state.markerList.push(marker)
  },
  clearMarkers (state) {
    Vue.set(state, 'markerList', [])
  },
  selectedMarker (state, id) {
    state.selectedMarkerId = id
  },
  setMarkerZIndex (state, zindex) {
    state.markerZIndex = zindex
  },
  // 震源地情報
  setHypocenter (state, data) {
    Vue.set(state, 'hypocenter', data)
  },
  // 各種クエリ
  setKeywordQuery (state, str) {
    state.keywordQuery = str
  },
  setLocationIdQuery (state, str) {
    state.locationIdQuery = str
  },
  setRoadAdminQuery (state, str) {
    state.roadAdminQuery = str
  },
  setCityQuery (state, str) {
    state.cityQuery = str
  },
  setCompleteYearQuery (state, str) {
    state.completeYearQuery = str
  },
  setConstructorNameQuery (state, str) {
    state.constructorNameQuery = str
  },
  setBridgeAdminQuery (state, str) {
    state.bridgeAdminQuery = str
  },
  setFormQuery (state, str) {
    state.formQuery = str
  },
  setEqQuery (state, str) {
    state.eqQuery = str
  },
  // 検索クエリ
  setSearchedValue (state, {type, query}) {
    state.serachedType = type
    state.searchedQuery = query
  },
  setSearching (state, flag) {
    state.searching = flag
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
