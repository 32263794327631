export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      ProjectId: 1,
      PhotoCount: 3,
      PhotoReportName: 'xxx',
      PhotoReportObjectKey: 'xxx',
      PhotoReportCreatedAt: 'xxx',
      TakenPhotos: [
        {
          ProjectId: 1,
          TakenPhotosId: 1,
          ProjectPhotoNumber: 1,
          TargetView: '撮影対象箇所',
          DamageDescription: '損傷・変状の説明',
          BridgeProfileFlg: false,
          FileName: 'オリジナル写真ファイル名001',
          FileSize: 120304,
          FileObjectKey: 'オリジナル写真ObjectKey',
          ThumbUrl: '/images/1.png',
          CreatedAt: '2020-03-20 19:20:29',
          UpdatedAt: '2020-03-20 19:20:29'
        },
        {
          ProjectId: 1,
          TakenPhotosId: 2,
          ProjectPhotoNumber: 2,
          TargetView: '撮影対象箇所',
          DamageDescription: '損傷・変状の説明',
          BridgeProfileFlg: false,
          FileName: 'オリジナル写真ファイル名002',
          FileSize: 120304,
          FileObjectKey: 'オリジナル写真ObjectKey',
          ThumbUrl: '/images/2.png',
          CreatedAt: '2020-03-20 19:20:29',
          UpdatedAt: '2020-03-20 19:20:29'
        },
        {
          ProjectId: 1,
          TakenPhotosId: 3,
          ProjectPhotoNumber: 3,
          TargetView: '撮影対象箇所',
          DamageDescription: '損傷・変状の説明',
          BridgeProfileFlg: true,
          FileName: 'オリジナル写真ファイル名003',
          FileSize: 120304,
          FileObjectKey: 'オリジナル写真ObjectKey',
          ThumbUrl: '/images/3.png',
          CreatedAt: '2020-03-20 19:20:29',
          UpdatedAt: '2020-03-20 19:20:29'
        }
      ]
    }
  }
}