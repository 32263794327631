export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      Assets: [
        {
          AssetId: 102,
          TenantIdentity: '36ab6155cb5623d4565ec58f48464c62',
          Name: '合ヶ瀬大橋',
          Prefecture: '44',
          City: '竹田市',
          Address: '大分県直入郡荻町大字柏原（竹田市）',
          Latitude: 32.89499999,
          Longitude: 131.30138888,
          Latitude_60_degree: 32,
          Latitude_60_minutes: 53,
          Latitude_60_seconds: 42,
          Longitude_60_degree: 131,
          Longitude_60_minutes: 18,
          Longitude_60_seconds: 5,
          LocationId: '13',
          OwnerName: '大分県竹田土木',
          LineName: '県道695号',
          CompletionYear: '1981',
          CompletionMonth: '',
          Constructor: '施工会社名',
          BridgeAdmin: '橋梁管理者',
          RoadAdmin: '道路管理者',
          OfficeContact: '連絡先',
          OfficeName: '事業所名',
          BridgeForm: '3径間連続上路トラス橋',
          Form1: '形式1',
          Form2: 'トラス橋',
          Form3: '形式3',
          Length: '213.65',
          Spanner: '62.5+87.5+62.5',
          Width: '7.5',
          FallPreventionEq: 1,
          Figure1: '橋梁一般図 S3 Object Key',
          Figure2: '図面2  S3 Object Key',
          Note: '備考テキスト\n備考テキスト',
          LiveFlg: true,
          CreatedAt: '2019-09-02',
          UpdatedAt: '2019-10-01',
          LastProjectId: 0,
          LatestProjectPhotoCount: 0,
          ProfilePhotoThumbUrl: '',
          ProfilePhotoFileObjectKey: ''
        }
      ]
    }
  }
}
