import { DoubleBounce } from 'vue-loading-spinner'

export default {
  name: 'Loading',
  components: {
    DoubleBounce
  },
  props: {
    color: String,
    size: String,
    customClass: String
  },
  computed: {
    iconColor () {
      return this.color || '#f00'
    },
    iconSize () {
      return this.size || '40px'
    }
  }
}