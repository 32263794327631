export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      ProjectId: 1,
      PhotoCount: 3,
      PromptReports: [
        {
          ProjectId: 1,
          PromptReportId: 1,
          EnterDate: '2020-04-01',
          LoadCapacity: 'A',
          Runnability: 'a',
          Aging: 'S',
          Opinion: '所見',
          PromptReportName: '速報レポート001',
          PromptReportObjectKey: 'Report S3 Object Key',
          PromptReportCreatedAt: '2020-03-20 19:20:29',
          UpdatedAt: '2020-03-20 19:20:29',
          CreatedAt: '2020-03-20 19:20:29'
        }
      ]
    }
  }
}