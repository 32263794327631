import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import Constants from '@/utils/Constants'

export default {
  name: 'EventDetail',
  metaInfo () {
    this.setMeta({
      title: 'イベント詳細 | ' + Constants.SiteInfo.title
    })
    return this.getMeta
  },
  props: {
    eventId: String
  },
  data () {
    return {
      showLoading: false
    }
  },
  mounted () {
    this.init()
    // console.log('EVENT', this.eventId)

    // GoogleMapを初期化
    this.resetGooglemap()
    this.activateProjectMap(true)

    this.setProjectLoaded(false)

    // プロジェクトを読み出し
    let parameters = {
      eventId: this.eventId,
      showAll: true
    }
    if (this.statusQuery !== -1) {
      parameters.status = this.statusQuery
    }
    this.searchGooglemap(parameters)
  },
  computed: {
    ...mapState({
      eventModel: state => state.eventDetail.values,
      statusQuery: state => state.projectMap.statusQuery
    }),
    ...mapGetters({
      getMeta: 'meta/get',
      isModerator: 'auth/isModerator'
    })
  },
  methods: {
    ...mapActions({
      setMeta: 'meta/set',
      showError: 'error/show',
      showAlert: 'alert/show',
      initLoad: 'eventDetail/initLoad',
      searchGooglemap: 'projectMap/search',
      resetGooglemap: 'projectMap/reset'
    }),
    ...mapMutations({
      setProjectLoaded: 'eventDetail/setLoaded',
      activateProjectMap: 'projectMap/setActive'
    }),
    init () {
      this.showLoading = true

      // イベントデータ読出し
      this.initLoad({
        targetId: Number(this.eventId)
      })
        .then(() => {
          this.showLoading = false

          // // 橋梁データがないとき
          // if (!this.eventModel.ProjectCount) {
          //   this.showAlert({
          //     body: '登録されているプロジェクトがありません'
          //   })
          // }
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
        })
    }
  }
}