import { mapState, mapActions } from 'vuex'
import Constants from '@/utils/Constants'
import AssetDetailMap from '@/components/GoogleMap/AssetDetailMap'

export default {
  name: 'ProjectDetailModal',
  components: {
    AssetDetailMap
  },
  mounted () {
    // // モーダル表示時の処理
    // $('#' + this.id).on('show.bs.modal', () => {

    // })

    // モーダル非表示時の処理
    $('#' + this.id).on('hide.bs.modal', () => {
      this.hide()
    })
    
    $('#' + this.id).modal('show')
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapState({
      active: state => state.projectDetailModal.active,
      id: state => state.projectDetailModal.id,
      projectModel: state => state.projectDetail.projectModel,
      assetModel: state => state.projectDetail.assetModel
    }),
    getTitle () {
      console.log('ProjectDetailModal', this.projectModel)
      if (this.projectModel === null) return ''
      return this.projectModel.BridgeName + '（' + this.projectModel.AssetId + '）'
    },
    // 左列
    locationId () {
      return this.assetModel.LocationId || ''
    },
    completeDate () {
      if (this.assetModel.CompletionYear && this.assetModel.CompletionMonth) {
        return this.assetModel.CompletionYear + ' / ' + this.assetModel.CompletionMonth
      } else {
        return this.assetModel.CompletionYear || ''
      }
    },
    roadAdmin () {
      return this.assetModel.RoadAdmin || ''
    },
    officeName () {
      return this.assetModel.OfficeName || ''
    },
    officeContact () {
      return this.assetModel.OfficeContact || ''
    },
    lineName () {
      return this.assetModel.LineName || ''
    },

    // 中列
    prefecture () {
      return this.assetModel.Prefecture ? Constants.Prefecture[this.assetModel.Prefecture] : ''
    },
    city () {
      return this.assetModel.City || ''
    },
    address () {
      return this.assetModel.Address || ''
    },
    latitude () {
      if (this.assetModel.Latitude_60_degree === undefined ||
        this.assetModel.Latitude_60_minutes === undefined ||
        this.assetModel.Latitude_60_seconds === undefined) return ''
      return this.assetModel.Latitude_60_minutes + '° ' +
        this.assetModel.Latitude_60_minutes + '&#39;' +
        this.assetModel.Latitude_60_seconds + '&quot;'
    },
    longitude () {
      if (this.assetModel.Longitude_60_degree === undefined ||
        this.assetModel.Longitude_60_minutes === undefined ||
        this.assetModel.Longitude_60_seconds === undefined) return ''
      return this.assetModel.Longitude_60_minutes + '° ' +
        this.assetModel.Longitude_60_minutes + '&#39;' +
        this.assetModel.Longitude_60_seconds + '&quot;'
    },
    constructorName () {
      return this.assetModel.Constructor || ''
    },

    // 右列
    bridgeAdmin () {
      return this.assetModel.BridgeAdmin || ''
    },
    bridgeForm () {
      return this.assetModel.BridgeForm || ''
    },
    bridgeLength () {
      return this.assetModel.Length || ''
    },
    bridgeSpanner () {
      return this.assetModel.Spanner || ''
    },
    bridgeWidth () {
      return this.assetModel.Width || ''
    },
    fallPrevention () {
      if (this.assetModel.FallPreventionEq === undefined) return ''
      return Constants.AssetConst.FallPrevention[this.assetModel.FallPreventionEq]
    },

    // map
    showMap () {
      return this.assetModel.Latitude && this.assetModel.Longitude
    },
    lat () {
      return this.assetModel.Latitude
    },
    lng () {
      return this.assetModel.Longitude
    }
  },
  methods: {
    ...mapActions({
      hide: 'projectDetailModal/hide'
    }),
    clickClose () {
      // this.$emit('childs-event', {
      //   accept: true
      // })
      $('#' + this.id).modal('hide')
    }
  }
}