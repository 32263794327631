import { mapState, mapActions, mapMutations } from 'vuex'
import GoogleMapsApiLoader from 'google-maps-api-loader'
import MapProvider from '@/components/GoogleMap/MapProvider'

export default {
  name: 'MapLoader',
  components: {
    MapProvider
  },
  props: {
    id: String,
    mapConfig: Object,
    apiKey: String
  },
  data () {
    return {
      timer: null
    }
  },
  mounted () { // point 3
    this.initGoogle()
  },
  beforeDestroy () {
    this.resetMap()
  },
  computed: {
    ...mapState({
      startedGoogle: state => state.google.started,
      initedGoogle: state => state.google.inited,
      storeGoogle: state => state.google.google,
      storeMap: state => state.projectMap.map,
      model: state => state.projectMap.model,
      hypocenter: state => state.projectMap.hypocenter,
      searching: state => state.projectMap.searching
    }),
    google: {
      get: function () {
        return this.storeGoogle
      },
      set: function (value) {
        this.setGoogle(value)
      }
    },
    map: {
      get: function () {
        return this.storeMap
      },
      set: function (value) {
        this.setGoogleMap(value)
      }
    }
  },
  watch: {
    model: function (to) {
      if (!to.length && this.hypocenter === null) return

      // this.clearMarkers()
      // if (!to.length) return

      // this.adjustMap()
      console.log('ProjectMap.check:', this.map)
      this.adjustMapExtreem()
    },
    searching: function (to) {
      // console.log('watch:searching', to)
      if (to) {
        this.clearMarkers()
      }
    }
  },
  methods: {
    ...mapActions({
      clearMarkers: 'projectMap/clearMarkers',
      resetMap: 'projectMap/reset'
    }),
    ...mapMutations({
      setStartedGoogle: 'google/setStarted',
      setInitedGoogle: 'google/setInited',
      setGoogle: 'google/setGoogle',
      setGoogleMap: 'projectMap/setGoogleMap'
    }),
    initGoogle () {
      // GoogleAPIの読込中の場合
      if (this.startedGoogle) {
        setTimeout(this.initGoogle, 100)

      // GoogleAPIの初期化が完了している場合
      } else if (this.initedGoogle) {
        this.initGoogleMap()

      // GoogleMapを初期化
      } else {
        this.setStartedGoogle(true)
        GoogleMapsApiLoader({
          apiKey: this.apiKey
        })
          .then(google => {
            this.google = google
            this.setInitedGoogle(true)
            this.setStartedGoogle(false)
            this.initGoogleMap()
          })
      }
    },
    initGoogleMap () {
      // console.log('ProjectMap.id:', this.id)
      const mapContainer = this.$el.querySelector('#' + this.id) // point 1
      const { Map } = this.google.maps
      this.map = new Map(mapContainer, this.mapConfig)
    },
    // プロットするマーカーが全て表示されるようにマップの表示領域を自動調整
    // adjustMap () {
    //   // 表示領域のSW/NEを取得
    //   let x1, x2, y1, y2
    //   this.model.forEach(item => {
    //     x1 = !x1 ?
    //       item.Latitude : Math.min(x1, item.Latitude)
    //     y1 = !y1 ?
    //       item.Longitude : Math.min(y1, item.Longitude)
    //     x2 = !x2 ?
    //       item.Latitude : Math.max(x2, item.Latitude)
    //     y2 = !y2 ?
    //       item.Longitude : Math.max(y2, item.Longitude)
    //   })

    //   // 領域の中心を地図の中心に設定
    //   const { LatLng } = this.google.maps
    //   let center = new LatLng((x1 + x2) / 2, (y1 + y2) / 2)
    //   this.map.setCenter(center)

    //   // 表示領域をフィット
    //   const { LatLngBounds } = this.google.maps
    //   let bounds = new LatLngBounds(
    //     new LatLng(x1, y1),
    //     new LatLng(x2, y2)
    //   )
    //   let padding = 60
    //   this.map.fitBounds(bounds, padding)
    // },
    adjustMapExtreem () {
      // 震源地がある場合は橋梁一覧にデータを追加
      let list = $.extend(true, [], this.model)
      if (this.hypocenter !== null) {
        list.push({
          Latitude: this.hypocenter.Latitude,
          Longitude: this.hypocenter.Longitude
        })
      }

      // 表示領域のSW/NEを取得
      let x1, x2, y1, y2
      list.forEach(item => {
        if (item.Latitude && item.Longitude) {
          x1 = !x1 ?
            item.Latitude : Math.min(x1, item.Latitude)
          y1 = !y1 ?
            item.Longitude : Math.min(y1, item.Longitude)
          x2 = !x2 ?
            item.Latitude : Math.max(x2, item.Latitude)
          y2 = !y2 ?
            item.Longitude : Math.max(y2, item.Longitude)
        }
      })

      const { LatLng } = this.google.maps
      const { LatLngBounds } = this.google.maps
      const { Point } = this.google.maps

      // 領域の中心を地図の中心に設定
      let center = new LatLng((x1 + x2) / 2, (y1 + y2) / 2)
      this.map.setCenter(center)

      // 表示領域
      let bounds = new LatLngBounds(
        new LatLng(x1, y1),
        new LatLng(x2, y2)
      )

      var projection = this.map.getProjection()
      if (projection) {
        // padding
        var paddings = {
          top: 70,
          right: 10,
          bottom: 10,
          left: 270
        };

        // copying the bounds object, since we will extend it
        bounds = new LatLngBounds(bounds.getSouthWest(), bounds.getNorthEast())

        // SW
        var point1 = projection.fromLatLngToPoint(bounds.getSouthWest())

        // we must call fitBounds 2 times - first is necessary to set up a projection with initial (actual) bounds
        // and then calculate new bounds by adding our pixel-sized paddings to the resulting viewport
        this.map.fitBounds(bounds)

        var point2 = new Point(
          paddings.left / Math.pow(2, this.map.getZoom()),
          paddings.bottom / Math.pow(2, this.map.getZoom())
        )

        var newPoint = projection.fromPointToLatLng(new Point(
          point1.x - point2.x,
          point1.y + point2.y
        ))

        bounds.extend(newPoint)

        // NE
        point1 = projection.fromLatLngToPoint(bounds.getNorthEast())
        point2 = new Point(
          paddings.right / Math.pow(2, this.map.getZoom()),
          paddings.top / Math.pow(2, this.map.getZoom())
        )
        newPoint = projection.fromPointToLatLng(new Point(
          point1.x + point2.x,
          point1.y - point2.y
        ))

        bounds.extend(newPoint)
        // console.log(bounds)
        this.map.fitBounds(bounds)

        // 領域フィット後にzoom値の変更
        // const { GEvent } = this.google.maps
        // console.log(GEvent)
        // let zoomChangeBoundsListener = GEvent.addListenerOnce(
        //   map,
        //   'bounds_changed',
        //   (event) => {
        //     if (this.getZoom()) {   // or set a minimum
        //       this.setZoom(16)  // set zoom here
        //     }
        //   })

        // setTimeout(() => {
        //   GEvent.removeListener(zoomChangeBoundsListener)
        // }, 2000)
      }
    }
  }
}