export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      User: {
        UserIdentity: '1b2c6a69f00ab0ea7583ce9b3c58b4a7',
        Role: 4,
        TenantIdentity: '36ab6155cb5623d4565ec58f48464c62',
        TenantName: '株式会社XXXXXXXX'
      },
      Projects: [
        {
          ProjectId: 1,
          AssetType: 'アセットタイプA',
          ModeratorIdentity: '1b2c6a69f00ab0ea7583ce9b3c58b4a7',
          Status: '200',
          ProjectName: 'プロジェクト002',
          CustomerName: 'カスタマーA',
          OrderDate: '2019-08-02',
          UpdatedDate: '2019-09-20'
        },
        {
          ProjectId: 2,
          AssetType: 'アセットタイプE',
          ModeratorIdentity: '1b2c6a69f00ab0ea7583ce9b3c58b4a7',
          Status: '300',
          ProjectName: 'プロジェクト003',
          CustomerName: 'カスタマーB',
          OrderDate: '2019-08-12',
          UpdatedDate: '2019-10-20'
        },
        {
          ProjectId: 3,
          AssetType: 'アセットタイプG',
          ModeratorIdentity: '1b2c6a69f00ab0ea7583ce9b3c58b4a7',
          Status: '400',
          ProjectName: 'プロジェクト004',
          CustomerName: 'カスタマーR',
          OrderDate: '2019-09-02',
          UpdatedDate: '2019-11-03'
        },
        {
          ProjectId: 4,
          AssetType: 'アセットタイプB',
          ModeratorIdentity: '1b2c6a69f00ab0ea7583ce9b3c58b4a7',
          Status: '410',
          ProjectName: 'プロジェクト005',
          CustomerName: 'カスタマーK',
          OrderDate: '2019-08-02',
          UpdatedDate: '2019-09-20'
        },
        {
          ProjectId: 5,
          AssetType: 'アセットタイプA',
          ModeratorIdentity: '1b2c6a69f00ab0ea7583ce9b3c58b4a7',
          Status: '420',
          ProjectName: 'プロジェクト006',
          CustomerName: 'カスタマーA',
          OrderDate: '2019-08-02',
          UpdatedDate: '2019-09-20'
        },
        {
          ProjectId: 6,
          AssetType: 'アセットタイプA',
          ModeratorIdentity: '1b2c6a69f00ab0ea7583ce9b3c58b4a7',
          Status: '500',
          ProjectName: 'プロジェクト007',
          CustomerName: 'カスタマーA',
          OrderDate: '2019-08-02',
          UpdatedDate: '2019-09-20'
        },
        {
          ProjectId: 7,
          AssetType: 'アセットタイプA',
          ModeratorIdentity: '1b2c6a69f00ab0ea7583ce9b3c58b4a7',
          Status: '600',
          ProjectName: 'プロジェクト008',
          CustomerName: 'カスタマーA',
          OrderDate: '2019-08-02',
          UpdatedDate: '2019-09-20'
        },
        {
          ProjectId: 8,
          AssetType: 'アセットタイプA',
          ModeratorIdentity: '1b2c6a69f00ab0ea7583ce9b3c58b4a7',
          Status: '610',
          ProjectName: 'プロジェクト009',
          CustomerName: 'カスタマーA',
          OrderDate: '2019-08-02',
          UpdatedDate: '2019-09-20'
        },
        {
          ProjectId: 9,
          AssetType: 'アセットタイプA',
          ModeratorIdentity: '1b2c6a69f00ab0ea7583ce9b3c58b4a7',
          Status: '700',
          ProjectName: 'プロジェクト010',
          CustomerName: 'カスタマーA',
          OrderDate: '2019-08-02',
          UpdatedDate: '2019-09-20'
        },
        {
          ProjectId: 10,
          AssetType: 'アセットタイプA',
          ModeratorIdentity: '1b2c6a69f00ab0ea7583ce9b3c58b4a7',
          Status: '800',
          ProjectName: 'プロジェクト011',
          CustomerName: 'カスタマーA',
          OrderDate: '2019-08-02',
          UpdatedDate: '2019-09-20'
        },
        {
          ProjectId: 11,
          AssetType: 'アセットタイプA',
          ModeratorIdentity: '1b2c6a69f00ab0ea7583ce9b3c58b4a7',
          Status: '500',
          ProjectName: 'プロジェクト012',
          CustomerName: 'カスタマーA',
          OrderDate: '2019-08-02',
          UpdatedDate: '2019-09-20'
        },
        {
          ProjectId: 12,
          AssetType: 'アセットタイプE',
          ModeratorIdentity: '1b2c6a69f00ab0ea7583ce9b3c58b4a7',
          Status: '300',
          ProjectName: 'プロジェクト013',
          CustomerName: 'カスタマーB',
          OrderDate: '2019-08-12',
          UpdatedDate: '2019-10-20'
        }
      ]
    }
  }
}