import StatusForm from '../StatusForm'

export default {
  name: 'Damper',
  components: {
    StatusForm
  },
  data () {
    return {
      sectionKey: 'Damper'
    }
  }
}