import { mapState, mapActions } from 'vuex'
import Loading from '@/components/Loading'
import Constants from '@/utils/Constants'

export default {
  name: 'ProjectPhoto',
  components: {
    Loading
  },
  data () {
    return {
      showLoading: false
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapState({
      projectId: state => state.home.selectedProjectId,
      photoModel: state => state.projectPhoto.photoModel
    }),
    showList () {
      return this.itemList.length > 0
    },
    itemList () {
      return this.photoModel
    },
    modalId () {
      return 'modal-project-photo-' + this.projectId
    }
  },
  methods: {
    ...mapActions({
      hidePhotoList: 'home/hidePhotoList',
      loadPhoto: 'projectPhoto/loadPhoto',
      showError: 'error/show',
      showModal: 'projectPhotoModal/show'
    }),
    init () {
      // ProjectIdの撮影写真を取得
      this.showLoading = true
      this.loadPhoto({
        projectId: this.projectId
      })
        .then(() => {
          this.showLoading = false
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
        })
    },
    getThumbImageStyle (item) {
      return 'backgroundImage: url("' + item.ThumbUrl + '");'
    },
    clickClose () {
      this.hidePhotoList()
    },
    clickThumb (item) {
      this.showModal({
        id: this.modalId,
        model: item
      })
    }
  }
}