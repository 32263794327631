import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Vue from 'vue'

const state = {
  targetId: null,
  values: {}
}

const getters = {
  get: state => state
}

const actions = {
  /**
   * 初期読出し
   */
  initLoad ({commit}, {targetId}) {
    commit('setTargetId', targetId)

    return new Promise((resolve, reject) => {
      // ユーザー詳細を読出し
      ApiManager.request(
        ['user'],
        ['v1UserDetailGet'],
        [{ userIdentityIds: [state.targetId] }])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            console.log('v1UserDetailGet', res)
            commit('initLoaded', res[0].data.Users[0])
            resolve()
            
          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {
  initLoaded (state, data) {
    Vue.set(state, 'values', {
      tenantIdentity: data.TenantIdentity,
      name: data.Name,
      kana: data.NameKana,
      division: data.Division,
      loginId: data.LoginId,
      userRole: data.UserRole,
      email: data.Email,
      phone1: data.Phone1,
      phone2: data.Phone2,
      phone3: data.Phone3,
      address: data.Address,
      invalidFlag: !data.LiveFlg,
      note: data.Note
    })
  },
  setTargetId (state, targetId) {
    state.targetId = targetId
  },
  setValues (state, values) {
    Vue.set(state, 'values', values)
  },
  reset (state) {
    // state.targetId = Constants.Params.RegisterHash
    Vue.set(state, 'values', {})
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
