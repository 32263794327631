export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      Hypocenters: [
        {
          EqId: '20201022120332',
          OriginTime: '2019-08-02 12:29:02',
          HypoAreaName: '東海沖',
          Latitude: 38.741065,
          Longitude: 141.9641172,
          Depth: 10000,
          Magnitude: '8.1',
          MagnitudeDesc: '巨大地震',
          MaxInt: '6+'
        },
        {
          EqId: '20201022120331',
          OriginTime: '2019-08-02 12:29:02',
          HypoAreaName: '南海トラフ',
          Latitude: 38.741065,
          Longitude: 141.9641172,
          Depth: 10000,
          Magnitude: '8.1',
          MagnitudeDesc: '巨大地震',
          MaxInt: '6+'
        },
        {
          EqId: '20201022120330',
          OriginTime: '2019-08-02 12:29:02',
          HypoAreaName: '三陸沖',
          Latitude: 38.741065,
          Longitude: 141.9641172,
          Depth: 10000,
          Magnitude: '8.1',
          MagnitudeDesc: '巨大地震',
          MaxInt: '6+'
        }
      ]
    }
  }
}