import { mapState, mapActions } from 'vuex'
import Loading from '@/components/Loading'
import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'

export default {
  name: 'ReminderPasswordConfirm',
  components: {
    Loading
  },
  data () {
    return {
      connecting: false
    }
  },
  computed: {
    ...mapState({
      values: state => state.reminderPassword.values,
      token: state => state.reminderPassword.token,
      email: state => state.reminderPassword.email
    }),
    maskedPassword () {
      return String('*').repeat(this.values.password.length)
    }
  },
  methods: {
    ...mapActions({
      updatePassword: 'auth/updatePassword',
      showError: 'error/show'
    }),
    clickBack () {
      this.$router.go(-1)
    },
    clickRegister () {
      this.connecting = true

      let parameters = {
        PasswordRestUrlToken: this.token,
        Email: this.email,
        NewPassword: this.values.password
      }
      console.log('register data', parameters)

      // 登録してログイン
      ApiManager.request(['auth'], ['v1AuthReminderUpdate'], [parameters])
        .then(res => {
          console.log('@ApiManager.signin.success : ', res)
          if (res[0].data.Result === 'success') {
            // 自動ログインの場合
            this.$router.push({name: 'ReminderPasswordComplete'})
            // 再度ログインさせる場合
            // this.signOut()
            // this.$router.push({name: 'Signin'})

          // API エラー
          } else if (res[0].data.Result === Constants.ApiResult.Failure) {
            this.showError(res[0].data)

          // システムエラー
          } else {
            this.showError(null)
          }
          this.connecting = false
          
        })
        .catch(() => {
          this.showError(null)
          this.connecting = false
        })
    }
  }
}
