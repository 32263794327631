import { mapState, mapMutations } from 'vuex'
import Validator from '@/utils/Validator'
export default {
  name: 'ReminderInput',
  data () {
    return {
      // 入力値
      email: '',
      isClickedConfirm: false
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapState({
      values: state => state.reminder.values
    }),
    // validate
    emailIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.email)
    },
    formIsValid () {
      // return true
      return this.emailIsValid
    },
    disableConfirmButton () {
      return !Validator.validText(this.email)
    }
  },
  methods: {
    ...mapMutations({
      setValues: 'reminder/setValues'
    }),
    init () {
      // 確認画面から戻った時、編集に宣した時に値を挿入
      // if (this.values) this.isClickedConfirm = true
      if (this.values.email) this.email = this.values.email
    },
    clickConfirm () {
      this.isClickedConfirm = true
      console.log(this.invalidFlag)

      // validate
      if (this.formIsValid) {
        let data = {
          email: this.email
        }
        this.setValues(data)
        this.$router.push({name: 'ReminderConfirm'})
      }
    }
  }
}
