import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import Loading from '@/components/Loading'
import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Utility from '@/utils/Utility'

export default {
  name: 'ProjectList',
  components: {
    Loading
  },

  data () {
    return {
      showLoading: false,
      registerHash: Constants.Params.RegisterHash,
      // 道路管理者区分のドロップダウン表示
      showRoadAdmin: false
    }
  },

  mounted () {
    $(window).on({resize: this.onResize })
    
    this.$nextTick(() => {
      this.onResize()
    })
  },
  beforeDestroy () {
    $(window).off({resize: this.onResize })
  },

  computed: {
    ...mapState({
      eventId: state => state.eventDetail.targetId,
      eventModel: state => state.eventDetail.values,
      projectLoaded: state => state.eventDetail.loaded,
      projectModel: state => state.projectMap.model,
      statusQuery: state => state.projectMap.statusQuery,
      roadAdminQuery: state => state.projectMap.roadAdminQuery,
      deletedProject: state => state.projectDeleteModal.deleted
    }),
    ...mapGetters({
      isModerator: 'auth/isModerator',
      isInspector: 'auth/isInspector',
      isObserver: 'auth/isObserver'
    }),

    pageTitle () {
      if (this.isInspector) return 'MYプロジェクト'

      return '「' + this.eventModel.EventName + '」のプロジェクト'
    },

    // 点検会社の配下にプロジェクトを移したプロジェクトリスト
    adjustedProjectModel() {
      const list = [];
      if (!this.projectModel.length) return list;

      // 点検会社の配列を作成する
      this.projectModel
        .forEach(model => {
          if (!list.length ||
            list.findIndex(item => item.name === model.InspectorCompanyName) === -1) {
            list.push({
              name: model.InspectorCompanyName,
              projects: [model],
            });
          } else {
            list.find(item => item.name === model.InspectorCompanyName)
              .projects.push(model);
          }
        });

      return list;
    },

    // filter
    statusFilter: {
      get () { return this.statusQuery },
      set (value) { this.setStatusQuery(value) }
    },
    roadAdminFilter: {
      get () { return this.roadAdminQuery },
      set (value) { this.setRoadAdminQuery(value) }
    },

    roadAdminPeriodList () {
      let list = []
      // let list = [{
      //   value: '',
      //   label: '全ての道路管理区分'
      // }]
      Object.keys(Constants.AssetConst.RoadAdminPeriod)
        .forEach(item => list.push({
          value: item,
          label: Constants.AssetConst.RoadAdminPeriod[item]
        }))
      return list
    },
    roadAdminValue () {
      if (!this.roadAdminQuery.length)
        return '全ての道路管理区分'

      let list = this.roadAdminPeriodList.filter(item => this.roadAdminQuery.indexOf(item.value) !== -1)
      let str = ''
      list.forEach(item => {
        str += str === '' ? '' : '、'
        str += item.label
      })
      return str
    },

    showList () {
      return this.projectLoaded && this.projectModel.length > 0
    },
    showNone () {
      return this.projectLoaded && this.projectModel.length === 0
    },

    // button
    disabledDownloadButton () {
      return this.projectModel.length === 0
    },

    // プロジェウクと削除ボタンを有効にする場合、trueを返す
    showDeleteButton() {
      return projectStatus => {
        // 管理者以外は表示しない
        if (!this.isModerator) return false;

        // プロジェクトが完了の場合、表示しない
        const completed = Utility.getEnableToCompleteProject(projectStatus);
        return !completed;
      }
    },
  },

  watch: {
    statusFilter () {
      this.search()
    },
    roadAdminFilter () {
      this.search()
    },
    // プロジェクトの削除が実行されたら検知してプロジェクト一覧を読み出し
    deletedProject (to) {
      if (to) {
        this.search()
      }
    }
  },

  methods: {
    ...mapActions({
      showError: 'error/show',
      showAlert: 'alert/show',
      selectEventId: 'projectForm/selectEventId',
      selectMarker: 'projectMap/selectMarker',
      searchGooglemap: 'projectMap/search',
      showProjectDeleteModal: 'projectDeleteModal/show',
    }),
    ...mapMutations({
      setProjectLoaded: 'eventDetail/setLoaded',
      setStatusQuery: 'projectMap/setStatusQuery',
      setRoadAdminQuery: 'projectMap/setRoadAdminQuery'
    }),

    init () {

    },
    onResize () {
      let windowHeight = window.innerHeight ?
        window.innerHeight : $(window).height()
      let container = $('#asideProjectList').children('.wrap');
      let bodyH = windowHeight - 120
      let h1 = container.children('.header').length ?
        container.children('.header').outerHeight() : 0
      let h2 = container.find('.filters').length ?
        container.find('.filters').outerHeight() : 0
      let h3 = container.find('.footer').length ?
        container.find('.footer').outerHeight() : 0
      let h = bodyH - h1 - h2 - h3

      container.find('.list').children('.wrap').height(h)
    },
    search () {
      console.log(this.roadAdminQuery)
      this.setProjectLoaded(false)

      // プロジェクトを読み出し
      let parameters = {
        showAll: this.isModerator
      }
      // イベント詳細表示時はイベントIDを追加
      if (this.isModerator || this.isObserver) {
        parameters.eventId = this.eventId
      }
      if (this.statusQuery !== -1) {
        parameters.status = this.statusQuery
      }
      if (this.roadAdminQuery.length) {
        parameters.roadAdminKbns = this.roadAdminQuery
      }
      this.searchGooglemap(parameters)
        .then(() => {
          this.setProjectLoaded(true)
        })
    },
    /**
     * プロジェクト作成
     */
    clickRegisterProject () {
      this.selectEventId({
        eventId: Number(this.eventId)
      })
      this.$router.push({
        name: 'ProjectRegister'
      })
    },
    /**
     * 道路管理者区分
     */
    toggleRoadAdmin () {
      // console.log('roadAdminQuery :', this.roadAdminQuery)
      this.showRoadAdmin = !this.showRoadAdmin
    },
    /**
     * マーカー画像を取得
     */
    getMarker (model) {
      // サイズ
      let size = Constants.GoogleMapMarkerSize.md

      // 色
      let roadAdminKbn = model.RoadAdminKbn ? String(model.RoadAdminKbn) : '4'
      let statusLabel = Utility.currentProjectTaskLabel(model.ProjectStatus)
      let currentStatusId = Constants.Tasks.filter(item => item.label === statusLabel)[0].id

      let color = currentStatusId === 1 ?
        Constants.GoogleMapMarkerColor.roadAdminKbn[roadAdminKbn][0] :
        currentStatusId === 6 ?
          Constants.GoogleMapMarkerColor.roadAdminKbn[roadAdminKbn][2] :
          Constants.GoogleMapMarkerColor.roadAdminKbn[roadAdminKbn][1]

      // マーカーを生成
      let template = Constants.GoogleMapMarker.Pin()
      let svg = template.replace('{size}', size + 'px')
        .replace('{color}', color)
        .replace('{center}', Constants.GoogleMapMarkerColor.center)
        .replace('{stroke}', Constants.GoogleMapMarkerColor.stroke)
      return 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(svg)
    },
    /**
     * プロジェクトを選択
     */
    clickProjectItem (projectId) {
      this.selectMarker(projectId)
    },
    /**
     * 調査・点検橋梁一覧表をダウンロード
     */
    clickDownloadList () {
      // 調査・点検橋梁一覧表を取得
      ApiManager.request(
        ['event'],
        ['v1EventReportListGet'],
        [{ EventId: this.eventId }])
        .then(res => {
          console.log('v1EventReportListGet', res)
          if (res[0].data.Result === Constants.ApiResult.Success) {

            if (res[0].data.EventReportObjectKey === null) {
              console.log(99999)
              this.showAlert({
                body: '生成された調査・点検橋梁一覧表がありません'
              })

            } else {
              // 調査・点検橋梁一覧表をダウンロード
              ApiManager.request(
                ['aws'],
                ['v1AwsPresignedUrlGet'],
                [{
                  docType: 11,
                  s3ObjectKey: res[0].data.EventReportObjectKey,
                  use: 0
                }])
                .then(res => {
                  if (res[0].data.Result === Constants.ApiResult.Success) {
                    let url = res[0].data.S3ObjectInfo.PreSignedUrl
                    window.open(url)

                  } else if (res[0].data.Result === Constants.ApiResult.Failure) {
                    this.showError(res[0].data.Errors)

                  } else {
                    this.showError(null)
                  }
                })
                .catch(() => {
                  this.showError(null)
                })
            }

          // API エラー
          } else if (res[0].data.Result === Constants.ApiResult.Failure) {
            this.showError(res[0].data)

          // システムエラー
          } else {
            this.showError(null)
          }
        })
        .catch(() => {
          this.showError(null)
        })
    },

    deleteProject(projectId) {
      const model = this.projectModel
        .find(item => item.ProjectId === projectId);
      this.showProjectDeleteModal({
        id: 'projectDeleteModal',
        model: model
      })
    }
  }
}