import { mapState, mapGetters, mapActions } from 'vuex'
import Loading from '@/components/Loading'
import { ja } from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Validator from '@/utils/Validator'
import CommentHistory from '@/components/Project/CommentHistory'
// 被災度
import LoadCapacity from '@/components/Project/Task4/LoadCapacity'
import Runnability from '@/components/Project/Task4/Runnability'
// 落橋防止システムの状態
import SideBlock from '@/components/Project/Task4/SideBlock'
import Restricting from '@/components/Project/Task4/Restricting'
import Damper from '@/components/Project/Task4/Damper'
import Prevention from '@/components/Project/Task4/Prevention'
import Others from '@/components/Project/Task4/Others'
import Opinion from '@/components/Project/Task4/Opinion'

export default {
  name: 'ProjectTask4',
  components: {
    Loading,
    Datepicker,
    CommentHistory,
    LoadCapacity,
    Runnability,
    SideBlock,
    Restricting,
    Damper,
    Prevention,
    Others,
    Opinion
  },
  data () {
    return {
      showLoading: true,
      // flag
      isClickedConfirm: false,
      connectingForm: false,
      connectingComment: false,
      // date picker option
      checkDateOptions: {
        ja: ja,
        typeable: true,
        // initialView: '201911',
        disabledDates: {
          to: null,
          from: new Date()
        }
      },
      // 被災度
      damageTabList: [
        { id: 'loadCapacity', label: '耐荷力に関する調査' },
        { id: 'runnability', label: '走行性に関する調査' }
      ],
      // 落橋防止システムの状態
      statusTabList: Constants.task4StatusList,
      // statusTabList: [
      //   { id: 'Sideblock', label: '支承サイドブロック' },
      //   { id: 'Restricting', label: '変位制限構造' },
      //   { id: 'Damper', label: 'ダンパー' },
      //   { id: 'Prevention', label: '落橋防止装置' },
      //   { id: 'Others', label: 'その他' },
      //   { id: 'Opinion', label: '下部工' }
      // ]
    }
  },
  mounted () {
    console.log('--- Task4 ---')
    this.init()
  },
  computed: {
    ...mapState({
      // model
      projectModel: state => state.projectDetail.projectModel,
      commentModel: state => state.projectDetail.commentModel,
      checkReportModel: state => state.projectTask4.checkReportModel,
      photoModel: state => state.projectTask4.photoModel,
      // value
      checkDateValue: state => state.projectTask4.checkDateValue,
      checkDateWeatherValue: state => state.projectTask4.checkDateWeatherValue,
      // 判定結果
      loadCapacityValue: state => state.projectTask4.loadCapacityValue,
      runnabilityValue: state => state.projectTask4.runnabilityValue,
      agingValue: state => state.projectTask4.agingValue,
      // 被災度
      damageTabId: state => state.projectTask4.damageTabId,
      damageValue: state => state.projectTask4.damageValue,
      // 落橋防止システムの状態
      statusTabId: state => state.projectTask4.statusTabId,
      statusValue: state => state.projectTask4.statusValue,
      // confirm
      accepted: state => state.confirm.accept,
      // 管理者へのコメント
      moderatorCommentValue: state => state.projectTask4.moderatorCommentValue,
      inspectorCommentValue: state => state.projectTask4.inspectorCommentValue
    }),
    ...mapGetters({
      isModerator: 'auth/isModerator',
      isInspector: 'auth/isInspector',
      isObserver: 'auth/isObserver',
      projectCompleted: 'projectDetail/projectCompleted',
      isModify: 'projectTask4/isModify'
    }),
    commentLabel () {
      return this.isModerator ?
        '点検者へのコメント' : '管理者へのコメント'
    },

    // result
    checkDateResult () {
      return this.checkDate ?
        moment(this.checkDate).format('YYYY/MM/DD') : '-'
    },
    checkDateWeatherResult () {
      return this.checkDateWeather || '-'
    },
    loadCapacityResult () {
      return this.loadCapacity || '-'
    },
    runnabilityResult () {
      return this.runnability || '-'
    },
    agingResult () {
      return this.aging || '-'
    },

    // 実施者
    showPractitioner () {
      try {
        return !this.disabledDownloadButton
        // return this.projectModel.ProjectJishiInfo.filter(item => item.ProjectStatus === 8).length > 0
      } catch (e) {
        return false
      }
    },
    practiceModel () {
      return this.projectModel.ProjectJishiInfo.filter(item => item.ProjectStatus === 8)[0]
    },
    practiceCompany () {
      return this.practiceModel.InspectorCompanyName || ''
    },
    practiceInspector () {
      return this.practiceModel.InspectorName || ''
    },
    practiceLeader () {
      return this.practiceModel.InspectorLeaderName || ''
    },
    practiceDivision () {
      return this.practiceModel.InspectorDivision || ''
    },
    practicePhone () {
      if (!this.practiceModel.InspectorPhone1 &&
        !this.practiceModel.InspectorPhone2 &&
        !this.practiceModel.InspectorPhone3)
        return ''
      return this.practiceModel.InspectorPhone1 + '-'
        + this.practiceModel.InspectorPhone2 + '-'
        + this.practiceModel.InspectorPhone3
    },

    // 入力値のリスト
    loadCapacityList () {
      return Constants.ProjectConst.LoadCapacity
    },
    runnabilityList () {
      return Constants.ProjectConst.Runnability
    },
    agingList () {
      return Constants.ProjectConst.Aging
    },
    weatherList () {
      return Constants.ProjectConst.Weather
    },

    // input
    checkDate: {
      get () { return this.checkDateValue },
      set (value) { this.setCheckDate(value) }
    },
    checkDateWeather: {
      get () { return this.checkDateWeatherValue },
      set (value) { this.setCheckDateWeather(value) }
    },

    // input : 判定結果
    loadCapacity: {
      get () { return this.loadCapacityValue },
      set (value) { this.setLoadCapacity(value) }
    },
    runnability: {
      get () { return this.runnabilityValue },
      set (value) { this.setRunnability(value) }
    },
    aging: {
      get () { return this.agingValue },
      set (value) { this.setAging(value) }
    },

    // input : コメント
    comment: {
      get () {
        return this.isModerator ?
          this.moderatorCommentValue : this.inspectorCommentValue
      },
      set (value) {
        this.isModerator ?
          this.setModeratorComment(value) : this.setInspectorComment(value)
      }
    },

    // form
    enableForm () {
      return (this.isModerator || this.isInspector) &&
        !this.projectCompleted
    },
    enableCommentForm () {
      return (this.isModerator || this.isInspector) &&
        !this.projectCompleted
    },
    showRequire () {
      return this.enableForm
    },

    // validate
    checkDateIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validDate(this.checkDate)
    },
    checkDateWeatherIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validSelect(this.checkDateWeather)
    },
    loadCapacityIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validSelect(this.loadCapacity)
    },
    runnabilityIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validSelect(this.runnability)
    },
    agingIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validSelect(this.aging)
    },

    // button
    disabledDownloadButton () {
      return this.checkReportModel.CheckReportObjectKey === undefined ||
        this.checkReportModel.CheckReportObjectKey === ''
    },
    showSaveButton () {
      return this.enableForm
    },
    disabledSaveButton () {
      if (this.connectingForm) return true

      // 点検・調査レポートが生成されている場合、登録値から変更されていれば登録可能に
      if (this.checkReportModel.CheckReportObjectKey !== undefined) {
        let fBase = this.checkReportModel.CheckDate === moment(this.checkDate).format('YYYYMMDD') &&
          this.checkReportModel.CheckDayWeather === this.checkDateWeather &&
          this.checkReportModel.LoadCapacity === this.loadCapacity &&
          this.checkReportModel.Runnability === this.runnability &&
          this.checkReportModel.Aging === this.aging

        // 被災度に変更があるか確認
        // console.log('MODEL:', this.checkReportModel)
        // console.log('VALUE:', this.damageValue)
        let fDamage = true
        Object.keys(this.damageValue).forEach(key => {
          let obj = this.damageValue[key]

          // 点検対象が変更されているか確認
          if (obj.exists !== this.checkReportModel[key + 'ExistFlg'])
            fDamage = false

          let checkDamage = obj => {
            // 被災区分が選択されているか確認
            if ((obj.loadCapacity !== undefined && obj.loadCapacity !== '') ||
              (obj.runnability !== undefined && obj.runnability !== '') ||
              obj.aging !== '') {

              // 被災区分が変更されているか確認
              if (obj.loadCapacity &&
                obj.loadCapacity !== this.checkReportModel[key + 'DamageLoad'])
                fDamage = false
              if (obj.runnability &&
                obj.runnability !== this.checkReportModel[key + 'DamageRun'])
                fDamage = false
              if (obj.aging &&
                obj.aging !== this.checkReportModel[key + 'DamageAging'])
                fDamage = false

              // コメント
              if (obj.comment !== this.checkReportModel[key + 'Comment'])
                fDamage = false

              // 添付写真
              // console.log(key + ' : ' + obj.photoIds + ' / ' + this.checkReportModel[key + 'PhotoIds'])
              if (obj.photoIds.join(',') !== this.checkReportModel[key + 'PhotoIds'].join(','))
                fDamage = false
            }
          }

          // 被災区分が変更されている場合
          if (obj.section !== this.checkReportModel[key + 'CheckFlg']) {
            // 確認がチェックされている場合
            if (obj.section) {
              checkDamage(obj)

            // 未確認がチェックされている場合
            } else {
              fDamage = false
            }

          // 被災区分が変更されていない場合
          } else {
            // 確認がチェックされている場合
            if (obj.section) {
              checkDamage(obj)
            }
          }
        })

        // 状態に変更があるか確認
        // console.log('MODEL:', this.checkReportModel)
        // console.log('VALUE:', this.statusValue)
        let fStatus = true
        Object.keys(this.statusValue).forEach(key => {
          let obj = this.statusValue[key]

          // 状態が変更されているか確認
          if (key !== 'Opinion' &&
            obj.damage !== this.checkReportModel[key + 'Damage'])
            fStatus = false

          // コメント
          if (key === 'Opinion') {
            if (obj.comment !== this.checkReportModel[key])
              fStatus = false
          } else {
            if (obj.comment !== this.checkReportModel[key + 'Comment'])
              fStatus = false
          }

          // 添付写真
          if (obj.photoIds.join(',') !== this.checkReportModel[key + 'PhotoIds'].join(','))
            fStatus = false
        })

        // console.log('CHECK : ' + fBase + ' | ' + fDamage + ' | ' + fStatus)
        return fBase && fDamage && fStatus


      // --------------------
      // 点検・調査レポート未生成の場合は通常のバリデート
      } else {
        let fBase = !Validator.validDate(this.checkDate) ||
          !Validator.validSelect(this.checkDateWeather) ||
          !Validator.validSelect(this.loadCapacity) ||
          !Validator.validSelect(this.runnability) ||
          !Validator.validSelect(this.aging)

        // 被災度のバリデート
        // console.log('damageValue : ', this.damageValue)
        let fDamage = Object.keys(this.damageValue).filter(item => {
          let obj = this.damageValue[item]
          if (obj.exists) {
            if (obj.section) {
              return (obj.loadCapacity === undefined || obj.loadCapacity === '') &&
                (obj.runnability === undefined || obj.runnability === '') &&
                obj.aging === ''
            }
            return false
          }
          return false
        }).length > 0

        // 状態のバリデート
        let fStatus = Object.keys(this.statusValue).filter(item => item !== 'Opinion' && this.statusValue[item].damage === '').length > 0

        return fBase || fDamage || fStatus
      }
    },
    disabledCommentButton () {
      if (this.connectingComment) return true
      return this.isModerator ?
        this.moderatorCommentValue === '' :
        this.inspectorCommentValue === ''
    }
  },
  watch: {
    accepted: function (to) {
      if (to) {
        this.saveTask()
      }
    }
  },
  methods: {
    ...mapActions({
      showAlert: 'alert/show',
      showError: 'error/show',
      showConfirm: 'confirm/show',
      // プロジェクトステータス
      changeProjectStatus: 'projectDetail/changeProjectStatus',
      // 判定区分例の説明
      showExamplesModal: 'examplesModal/show',
      // Task4
      initLoad: 'projectTask4/initLoad',
      setCheckDate: 'projectTask4/setCheckDate',
      setCheckDateWeather: 'projectTask4/setCheckDateWeather',
      // 判定結果
      setLoadCapacity: 'projectTask4/setLoadCapacity',
      setRunnability: 'projectTask4/setRunnability',
      setAging: 'projectTask4/setAging',
      // 被災度
      setDamageTab: 'projectTask4/setDamageTab',
      // 落橋防止システムの状態
      setStatusTab: 'projectTask4/setStatusTab',
      // 登録
      loadCheckReport: 'projectTask4/loadCheckReport',
      saveCheckReport: 'projectTask4/saveCheckReport',
      // コメント
      loadComments: 'projectDetail/loadComments',
      saveComment: 'projectDetail/saveComment',
      setModeratorComment: 'projectTask4/setModeratorComment',
      setInspectorComment: 'projectTask4/setInspectorComment',
      // 初期化
      reset: 'projectTask4/reset'
    }),
    init () {
      // 初期化
      this.reset()
      this.showLoading = true

      // データ読出し
      this.initLoad({
        ProjectId: this.projectModel.ProjectId
      })
        .then(() => {
          console.log('@checkReportModel', this.checkReportModel)
          console.log('@photoModel', this.photoModel)
          console.log('@commentModel', this.commentModel)
          this.showLoading = false
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
        })
    },
    /**
     * 調査・点検結果報告書のダウンロード
     */
    clickDownload () {
      ApiManager.request(
        ['aws'],
        ['v1AwsPresignedUrlGet'],
        [{
          docType: 8,
          s3ObjectKey: this.checkReportModel.CheckReportObjectKey,
          use: 1
        }])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            let url = res[0].data.S3ObjectInfo.PreSignedUrl
            window.open(url)

          } else if (res[0].data.Result === Constants.ApiResult.Failure) {
            this.showError(res[0].data.Errors)

          } else {
            this.showError(null)
          }
        })
        .catch(() => {
          this.showError(null)
        })
    },
    /**
     * 判定区分例を表示
     */
    clickExamples () {
      this.showExamplesModal({
        id: 'examples-modal'
        // id: 'examples-modal-' + (new Date()).getTime()
      })
    },
    changeCheckDate (value) {
      console.log('changeCheckDate', value)
      this.checkDate = value
    },
    /**
     * プロジェクト一覧に戻る
     */
    clickBack () {
      // 点検者はプロジェクト一覧へ
      if (this.isInspector) {
        this.$router.push({ name: 'Project' })

      // 管理者はイベント詳細へ
      } else {
        this.$router.push({
          name: 'EventDetail',
          params: {
            eventId: String(this.projectModel.EventId)
          }
        })
      }
    },
    /**
     * 被災橋梁検結果を登録
     */
    clickSave () {
      this.isClickedConfirm = true

      this.showConfirm({
        key: 'save',
        body: 'Task4を登録しますか？',
        acceptLabel: '登録',
        denyLabel: 'キャンセル'
      })
    },
    saveTask () {
      this.connectingForm = true

      // パラメータの整形
      let parameters = {
        ProjectId: this.projectModel.ProjectId,
        CheckReportData: {
          CreateReportFlg: true,
          CheckDate: moment(this.checkDate).format('YYYYMMDD'),
          CheckDayWeather: this.checkDateWeather,
          LoadCapacity: this.loadCapacity,
          Runnability: this.runnability,
          Aging: this.aging
        }
      }

      // damageValue
      Object.keys(this.damageValue).forEach(item => {
        let obj = this.damageValue[item]
        parameters.CheckReportData[item + 'ExistFlg'] = obj.exists

        // checkFlg
        parameters.CheckReportData[item + 'CheckFlg'] = obj.exists ?
          obj.section : false

        // damage
        if (obj.loadCapacity !== undefined)
          parameters.CheckReportData[item + 'DamageLoad'] = obj.exists && obj.section ?
            obj.loadCapacity : ''
        if (obj.runnability !== undefined)
          parameters.CheckReportData[item + 'DamageRun'] = obj.exists && obj.section ?
            obj.runnability : ''
        if (obj.aging !== undefined)
          parameters.CheckReportData[item + 'DamageAging'] = obj.exists && obj.section ?
            obj.aging : ''

        // comment
        parameters.CheckReportData[item + 'Comment'] = obj.exists && obj.section ?
          obj.comment : ''

        // photoIds
        parameters.CheckReportData[item + 'PhotoIds'] = obj.exists && obj.section ?
          obj.photoIds : []
      })

      // statusValue
      Object.keys(this.statusValue).forEach(item => {
        let obj = this.statusValue[item]
        // damage
        if (obj.damage !== '')
          parameters.CheckReportData[item + 'Damage'] = obj.damage

        // comment
        if (item === 'Opinion') {
          parameters.CheckReportData[item] = obj.comment
        } else {
          parameters.CheckReportData[item + 'Comment'] = obj.comment
        }

        // photoIds
        parameters.CheckReportData[item + 'PhotoIds'] = obj.photoIds
      })


      // 編集時はCheckReportIdを追加
      if (this.isModify) {
        parameters.CheckReportData.CheckReportId = this.checkReportModel.CheckReportId
      }
      console.log('clickSave : ', parameters)

      // 登録
      this.saveCheckReport(parameters)
        .then(this.reload)
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }

          this.connectingForm = false
        })
    },
    /**
     * 登録処理後の点検。調査レポートの再読み出し
     */
    reload () {
      // データ読出し
      this.loadCheckReport({
        ProjectId: this.projectModel.ProjectId
      })
        .then(() => {
          // プロジェクトステータスを変更
          this.changeProjectStatus({
            code: 'task4'
          })
            .then(() => {
              // アラート
              let message = this.isModify ?
                '調査・点検レポートを変更しました' : '調査・点検レポートを登録しました'
              this.showAlert({
                key: 'check-report',
                body: message
              })
              this.connectingForm = false
              
            })
            .catch(error => {
              // API エラー
              if (error.Result === Constants.ApiResult.Failure) {
                this.showError(error)
                
              // システムエラー
              } else {
                this.showError(null)
              }
              this.connectingForm = false
            })

        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
          this.connectingForm = false
        })
    },

    /**
     * 管理者へのコメントを登録
     */
    clickComment () {
      this.connectingComment = true

      // パラメータ
      let parameters = {
        ProjectId: this.projectModel.ProjectId,
        CommentData: {
          CommentType: this.isModerator ? 2 : 1,
          Comment: this.isModerator ?
            this.moderatorCommentValue : this.inspectorCommentValue
        }
      }

      // コメントを登録
      this.saveComment(parameters)
        .then(() => {
          // this.showAlert({
          //   key: 'add-comment',
          //   body: 'コメントを登録しました'
          // })
          this.comment = ''

          // コメント一覧を読出し
          this.loadComments({
            ProjectId: this.projectModel.ProjectId
          })
            .catch(error => {
              // API エラー
              if (error.Result === Constants.ApiResult.Failure) {
                this.showError(error)
                
              // システムエラー
              } else {
                this.showError(null)
              }
            })

          this.connectingComment = false
          
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
          this.connectingComment = false
        })
    }
  }
}
