export default {
  // rev
  // 0 : 3月リリース
  // 1 : 本公開
  Revision: 1,
  
  // META
  Meta: [
    {'http-equiv': 'X-UA-Compatible', content: 'IE=edge'},
    {name: 'viewport', content: 'width=device-width,initial-scale=1.0'}
  ],
  // サイト情報
  SiteInfo: {
    title: '橋梁点検データベースシステム'
  },
  // パラメータ
  Params: {
    RegisterHash: '-',
    CompletedRedirectDelay: 1500
  },

  // GoogleMap API Key for Prd
  GoogleMapApiKey: process.env.VUE_APP_GOOGLEMAP_API_KEY,
  GoogleMapCenter: {
    lat: 35.6432027,
    lng: 139.6729435
  },
  GoogleMapMarker: {
    Pin: () => {
      return [
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" width="{size}" height="{size}">',
        '<path d="M37,19c0,11-15,25-15,25S7,30,7,19c0-8.3,6.7-15,15-15S37,10.7,37,19z" fill="{color}" stroke-width="1" stroke="{stroke}" />',
        '<circle cx="22" cy="19" r="6" fill="{center}"/>',
        '</svg>'
      ].join('\n')
    },
    Hypo: () => {
      return [
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" width="40px" height="40px">',
        '<polygon points="28.1,18 25.9,15.9 22,19.8 18.1,15.9 15.9,18 19.9,21.9 15.9,25.9 18.1,28 22,24.1 25.9,28 28.1,25.9 24.1,21.9" fill="{color}" />',
      '</svg>'].join('\n')
    }
  },
  GoogleMapMarkerColor: {
    // 橋梁一覧: マーカーの色
    assetsColor: {
      // プロジェクトが割り当たっていない橋梁のマーカー色
      // LatestProjectStatus: 0
      default: '#000',
      // 選択した橋梁のマーカー色
      active: '#00f',
      // タスクの進捗
      // Task 1 ( LatestProjectStatus: 1 )
      task1: '#444444',
      // Task 2 ( LatestProjectStatus: 2 )
      task2: '#666666',
      // Task 3 ( LatestProjectStatus: 4 )
      task3: '#888888',
      // Task 4 ( LatestProjectStatus: 8 )
      task4: '#aaaaaa',
      // Task 5 or 6 ( LatestProjectStatus: 16 or 32 )
      complete: '#cccccc',
    },

    // プロジェクト一覧: マーカーの色
    default: '#000',
    active: '#f00',
    selected: '#00f',

    // プロジェクト一覧: マーカーの色: 道路管理者区分
    roadAdminKbn: {
      // 国
      '1': [
        '#ff7084',
        '#ff0024',
        '#950015'
      ],
      // 都道府県
      '2': [
        '#8dc4ff',
        '#007aff',
        '#004795'
      ],
      // 市町村
      '3': [
        '#ffdc70',
        '#ffc000',
        '#ad8200'
      ],
      // その他
      '4': [
        '#7af970',
        '#19ef08',
        '#0cad00'
      ]
    },

    // 中心の丸
    center: '#fff',
    // 枠線
    stroke: '#fff',
    // 震源地の＋
    hypo: '#f00'
  },
  GoogleMapMarkerSize: {
    sm: 30,
    md: 40,
    lg: 60
  },

  // テーブルオプション
  TableOptions: {
    perPage: 20,
    perPageValues: [],
    // perPageValues: [20, 50],
    pagination: {
      edge: false,
      chunk: 10
    },
    getTexts (label) {
      return {
        count: '{count}件中{from}～{to}件を表示|{count}件|1件',
        noResults: label + 'はありません。',
        limit: '表示件数',
        page: 'ページ'
      }
    }
  },

  // ユーザー権限
  UserRole: [
    {
      code: 'systemAdmin',
      abbrevation: 'S',
      label: 'システム管理者'
    },
    {
      code: 'tenantAdmin',
      abbrevation: 'T',
      label: 'テナント管理者'
    },
    {
      code: 'moderator',
      abbrevation: 'M',
      label: '管理者'
    },
    {
      code: 'pilot',
      abbrevation: 'P',
      label: 'パイロット'
    },
    {
      code: 'inspector',
      abbrevation: 'I',
      label: '点検者'
    },
    {
      code: 'customer',
      abbrevation: 'C',
      label: 'カスタマー'
    },
    {
      code: 'observer',
      abbrevation: 'O',
      label: '管理者（閲覧のみ）'
    }
  ],

  // プロジェクトステータス
  Tasks: [
    {
      code: 'task1',
      id: 1,
      bit: 1,
      label: '点検者情報'
    },
    {
      code: 'task2',
      id: 2,
      bit: 2,
      label: '速報'
    },
    {
      code: 'task3',
      id: 3,
      bit: 4,
      label: '写真報告書'
    },
    {
      code: 'task4',
      id: 4,
      bit: 8,
      label: '調査・点検結果'
    },
    {
      code: 'task5',
      id: 5,
      bit: 16,
      label: '報告書確認'
    },
    {
      code: 'task6',
      id: 6,
      bit: 32,
      label: '完了'
    }
  ],
  // プロジェクトの入力項目
  ProjectConst: {
    // 耐荷力
    LoadCapacity: ['As', 'A', 'B', 'C', 'D'],
    // 走行性
    Runnability: ['a', 'b', 'c'],
    // 経年劣化
    Aging: ['M', 'S', 'N'],
    // 調査日天候
    Weather: ['晴れ', '曇り', '雨', '雪'],
    // 落橋防止システムの状態
    Damage: [
      '対象外',
      '健全：作用痕なし',
      '健全：作用痕あり',
      '損傷'
    ]
  },
  // Task3 「損傷・変状の説明」のサジェスト
  Task3Suggest: [
    '損傷・変状の説明のサジェスト01',
    '損傷・変状の説明のサジェスト02',
    '損傷・変状の説明のサジェスト03'
  ],
  // Task4 添付写真の登録上限枚数
  Task4DamageMaxPhoto: 8,
  Task4StatusMaxPhoto: 8,
  // Task4 落橋防止システムの状態
  task4StatusList: [
    { id: 'Sideblock', label: '支承サイドブロック' },
    { id: 'Restricting', label: '変位制限構造' },
    { id: 'Damper', label: 'ダンパー' },
    { id: 'Prevention', label: '落橋防止装置' },
    { id: 'Others', label: 'その他' },
    { id: 'Opinion', label: '下部工' }
  ],

  // 橋梁のステータス
  AssetConst: {
    FallPrevention: {
      '0': 'なし',
      '1': '旧',
      '2': '新'
    },
    RoadAdminPeriod: {
      '1': '国',
      '2': '都道府県',
      '3': '市町村',
      '4': 'その他'
    }
  },

  // 総務省制定の都道府県番号
  Prefecture: {
    '01': '北海道',
    '02': '青森県',
    '03': '秋田県',
    '04': '宮城県',
    '05': '秋田県',
    '06': '山形県',
    '07': '福島県',
    '08': '茨城県',
    '09': '栃木県',
    '10': '群馬県',
    '11': '埼玉県',
    '12': '千葉県',
    '13': '東京都',
    '14': '神奈川県',
    '15': '新潟県',
    '16': '富山県',
    '17': '石川県',
    '18': '福井県',
    '19': '山梨県',
    '20': '長野県',
    '21': '岐阜県',
    '22': '静岡県',
    '23': '愛知県',
    '24': '三重県',
    '25': '滋賀県',
    '26': '京都府',
    '27': '大阪府',
    '28': '兵庫県',
    '29': '奈良県',
    '30': '和歌山県',
    '31': '鳥取県',
    '32': '島根県',
    '33': '岡山県',
    '34': '広島県',
    '35': '山口県',
    '36': '徳島県',
    '37': '香川県',
    '38': '愛媛県',
    '39': '高知県',
    '40': '福岡県',
    '41': '佐賀県',
    '42': '長崎県',
    '43': '熊本県',
    '44': '大分県',
    '45': '宮崎県',
    '46': '鹿児島県',
    '47': '沖縄県'
  },

  // ファイルアップロードの許可拡張子
  Extensions: {
    image: ['gif', 'jpg', 'jpeg', 'png', 'tif'],
    document: ['ppt', 'pptx', 'xls', 'xlsx', 'doc', 'docx', 'pdf']
  },

  // APIレスポンス結果
  ApiResult: {
    Success: 'success',
    Failure: 'failure'
  },
  // エラーメッセージ
  Errors: {
    SystemError: {
      title: 'システムエラー',
      body: 'システム管理者にお問い合わせください。'
    }
  },
  // アラートメッセージ
  Alerts: {
    NotHitAsset: {
      title: '橋梁の検索結果',
      body: '検索条件に該当する橋梁がありません。'
    },
    BlankLatLng: {
      title: '',
      body: 'この橋梁には有効な緯度経度が設定されていません。'
    }
  }
}
