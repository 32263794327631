import { mapState, mapMutations } from 'vuex'
import Validator from '@/utils/Validator'
// import Utility from '@/utils/Utility'

export default {
  name: 'AccountInput',
  data () {
    return {
      // 入力値
      name: '',
      kana: '',
      division: '',
      loginId: '',
      userRole: '',
      email: '',
      phone1: '',
      phone2: '',
      phone3: '',
      address: '',
      note: '',
      // flag
      invalidFlag: false,
      isClickedConfirm: false
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapState({
      label: state => state.label.model,
      userIdentity: state => state.account.targetId,
      values: state => state.account.values
    }),
    // validate
    nameIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.name)
    },
    kanaIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.kana)
    },
    emailIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.email)
    },
    phoneIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.phone1) &&
        Validator.validText(this.phone2) &&
        Validator.validText(this.phone3)
    },
    addressIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.address)
    },
    formIsValid () {
      // return true
      return this.nameIsValid &&
        this.kanaIsValid &&
        this.emailIsValid &&
        this.phoneIsValid
    },
    disableConfirmButton () {
      return !Validator.validText(this.name) ||
        !Validator.validText(this.kana) ||
        !Validator.validText(this.email) ||
        !Validator.validText(this.phone1) ||
        !Validator.validText(this.phone2) ||
        !Validator.validText(this.phone3)
    }
  },
  methods: {
    ...mapMutations({
      setValues: 'account/setValues'
    }),
    init () {
      // 確認画面から戻った時、編集に宣した時に値を挿入
      // if (this.values) this.isClickedConfirm = true
      if (this.values.name) this.name = this.values.name
      if (this.values.kana) this.kana = this.values.kana
      if (this.values.division) this.division = this.values.division
      if (this.values.loginId) this.loginId = this.values.loginId
      if (this.values.userRole) this.userRole = this.values.userRole
      if (this.values.email) this.email = this.values.email
      if (this.values.phone1) this.phone1 = this.values.phone1
      if (this.values.phone2) this.phone2 = this.values.phone2
      if (this.values.phone3) this.phone3 = this.values.phone3
      if (this.values.address) this.address = this.values.address
      if (this.values.note) this.note = this.values.note
      if (this.values.invalidFlag) this.invalidFlag = this.values.invalidFlag
    },
    clickConfirm () {
      this.isClickedConfirm = true
      console.log(this.invalidFlag)

      // validate
      if (this.formIsValid) {
        let data = $.extend(true, {}, this.values)
        data.name = this.name
        data.kana = this.kana
        data.division = this.division
        data.loginId = this.loginId
        data.userRole = this.userRole
        data.email = this.email
        data.phone1 = this.phone1
        data.phone2 = this.phone2
        data.phone3 = this.phone3
        data.address = this.address
        data.note = this.note
        data.invalidFlag = this.invalidFlag
        this.setValues(data)

        this.$router.push({name: 'AccountConfirm'})
      }
    },
    clickResetPassword () {
      this.$router.push({name: 'Password'})
    }
  }
}
