import Vue from 'vue'

const state = {
  map: null,
  // MapVIewのモデル
  model: [],
  // 生成されたMarkerのリスト
  markerList: [],
  // 選択されたマーカーのAssetID
  selectedMarkerId: null,
  // マーカーの表示ZINDEX
  markerZIndex: 1,

  // 震源地のデータ
  hypocenter: null
}

const getters = {
  get: (state) => {
    return state
  },
  getSelectedModel: state => state.model.filter(item => item.AssetId === state.selectedMarkerId)
}

const actions = {
  /**
   * 
   */
  init ({commit}, model) {
    commit('selectedMarker', null)
    commit('clearModel')

    commit('setModel', model)
    // commit('setModel', res[0].data.Assets)
  },
  // mapにプロットするマーカーを追加
  addMarker ({commit}, marker) {
    commit('addMarker', marker)
    commit('setMarkerZIndex', state.markerZIndex + 1)
  },
  // mapにプロットされたマーカーを全て削除
  clearMarkers ({commit}) {
    // console.log('clearMarkers', $.extend(true, [], state.markerList))
    state.markerList.forEach(marker => {
      marker.setMap(null)
    })
    commit('clearMarkers')
    commit('setHypocenter', null)
  },
  // マーカーを選択
  selectMarker ({commit}, id) {
    commit('selectedMarker', id === undefined ? null : id)
  },
  // 初期化
  reset ({commit}) {
    state.markerList.forEach(marker => {
      marker.setMap(null)
    })
    commit('clearMarkers')
    commit('setHypocenter', null)

    commit('clearModel')
    commit('selectedMarker', null)
    commit('setMarkerZIndex', 1)
    commit('setGoogleMap', null)
  }
}

const mutations = {
  setGoogleMap (state, map) {
    state.map = map
  },
  setModel (state, data) {
    Vue.set(state, 'model', data)
  },
  clearModel (state) {
    Vue.set(state, 'model', [])
  },
  // マーカー
  addMarker (state, marker) {
    state.markerList.push(marker)
  },
  clearMarkers (state) {
    Vue.set(state, 'markerList', [])
  },
  selectedMarker (state, id) {
    state.selectedMarkerId = id
  },
  setMarkerZIndex (state, zindex) {
    state.markerZIndex = zindex
  },
  // 震源地情報
  setHypocenter (state, data) {
    Vue.set(state, 'hypocenter', data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
