import Vue from 'vue'

const state = {
  map: null,
  // 生成されたMarkerのリスト
  markerList: []
}

const getters = {
  get: state => state
}

const actions = {
  // mapにプロットするマーカーを追加
  addMarker ({commit}, marker) {
    commit('addMarker', marker)
  },
  // mapにプロットされたマーカーを全て削除
  clearMarkers ({commit}) {
    state.markerList.forEach(marker => {
      marker.setMap(null)
    })
    commit('clearMarkers')
  },
  // 初期化
  reset ({commit}) {
    state.markerList.forEach(marker => {
      marker.setMap(null)
    })
    commit('clearMarkers')
    commit('setGoogleMap', null)
  }
}

const mutations = {
  setGoogleMap (state, map) {
    state.map = map
  },
  // マーカー
  addMarker (state, marker) {
    state.markerList.push(marker)
  },
  clearMarkers (state) {
    Vue.set(state, 'markerList', [])
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
