// import ApiManager from '@/utils/ApiManager'
// import Constants from '@/utils/Constants'
import Vue from 'vue'

const state = {
  values: {}
}

const getters = {
  get: (state) => {
    return state
  }
}

const actions = {
  
}

const mutations = {
  setValues (state, values) {
    Vue.set(state, 'values', values)
  },
  reset (state) {
    Vue.set(state, 'values', {})
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
