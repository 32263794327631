import { mapState, mapGetters, mapActions} from 'vuex'

export default {
  name: 'AccountBox',
  computed: {
    ...mapState({
      userRole: state => state.auth.userRole,
      active: state => state.accountBox.active
    }),
    ...mapGetters({
      userName: 'auth/userName',
      loginId: 'auth/loginId',
      userRoleList: 'auth/userRoleList'
    }),
    userRoles () {
      return this.userRoleList.join('、')
    },
    id () {
      return this.loginId || '--'
    }
  },
  watch: {
    '$route' () {
      this.hide()
    }
  },
  methods: {
    ...mapActions({
      signOut: 'auth/signOut',
      hide: 'accountBox/hide'
    }),
    clickSignOut () {
      this.signOut()
        .then(() => {
          this.$router.push({
            name: 'Signin'
          })
        })
    }
  }
}