export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      Token: 'xxxxxrefreshedaccesstokenxxxxx',
      ExpireIn: 3600
    }
  }
}