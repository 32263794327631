import { mapState, mapActions } from 'vuex'
// import moment from 'moment'
import Utility from '@/utils/Utility'

export default {
  name: 'EqModal',
  mounted () {
    // // モーダル表示時の処理
    // $('#' + this.id).on('show.bs.modal', () => {

    // })

    // モーダル非表示時の処理
    $('#' + this.id).on('hide.bs.modal', () => {
      this.hide()
    })
    
    $('#' + this.id).modal('show')
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapState({
      active: state => state.eqModal.active,
      id: state => state.eqModal.id,
      model: state => state.eqModal.model
    }),
    getTitle () {
      console.log(this.model)
      if (this.projectModel === null) return ''
      return '地震ID : ' + this.model.EqId
    },

    originTime () {
      // return moment(new Date(this.model.OriginTime)).format('YYYY/MM/DD HH:mmごろ')
      return Utility.getJSTDate(this.model.OriginTime, 'YYYY/MM/DD HH:mmごろ')
    },
    hypoAreaName () {
      return this.model.HypoAreaName || '-'
    },
    level () {
      return this.model.MaxInt || '-'
    },
    magnitude () {
      return this.model.Magnitude || '-'
    },
    depth () {
      if (this.model.Depth === undefined) return '-'
      return -1 * (this.model.Depth / 1000) + 'km'
    },
    latlng () {
      if (this.model.Latitude === undefined ||
        this.model.Longitude === undefined) return ''
      return '北緯' + this.model.Latitude + '度／東経' +
        this.model.Longitude + '度'
    }
  },
  methods: {
    ...mapActions({
      hide: 'eqModal/hide'
    }),
    clickClose () {
      $('#' + this.id).modal('hide')
    }
  }
}