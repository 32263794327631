import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import Loading from '@/components/Loading'
import Constants from '@/utils/Constants'

export default {
  name: 'EventForm',
  components: {
    Loading
  },
  metaInfo () {
    this.setMeta({
      title: this.pageTitle + ' | ' + Constants.SiteInfo.title
    })
    return this.getMeta
  },
  props: {
    eventId: String
  },
  data () {
    return {
      showLoading: false
    }
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    this.resetForm()
  },
  computed: {
    ...mapState({
      initialEqId: state => state.eventForm.initialEqId,
    }),
    ...mapGetters({
      getMeta: 'meta/get',
      isRegister: 'eventForm/isRegister',
      isModify: 'eventForm/isModify'
    }),
    pageTitle () {
      if (this.isRegister) return 'イベント新規作成'
      return 'イベント編集'
    }
  },
  methods: {
    ...mapActions({
      setMeta: 'meta/set',
      showError: 'error/show',
      initLoad: 'eventForm/initLoad',
      addQuakes: 'eventForm/addQuakes',
    }),
    ...mapMutations({
      resetForm: 'eventForm/reset'
    }),
    init () {
      console.log('eqId from event list : ', this.initialEqId)

      // 地震速報の絞込み条件
      let quakeParameters = {}
      let date = moment()
      quakeParameters.originDtTo = date.format()
      quakeParameters.originDtFrom = date.add(-10, 'days').format()
      quakeParameters.maxIntLower = 2

      // データ読出し
      this.showLoading = true
      this.initLoad({
        targetId: this.eventId,
        quakeParameters: quakeParameters
      })
        .then(() => {
          // イベント一覧で選択された地震IDがある場合
          if (this.initialEqId !== null) {
            this.addQuakes([this.initialEqId])
          }

          this.showLoading = false

        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
        })
    }
  }
}
