import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import Loading from '@/components/Loading'
import Constants from '@/utils/Constants'

export default {
  name: 'ProjectForm',
  components: {
    Loading
  },
  metaInfo () {
    this.setMeta({
      title: 'プロジェクト新規作成 | ' + Constants.SiteInfo.title
    })
    return this.getMeta
  },
  data () {
    return {
      showLoading: true,
      // チェック
      checkList: []
    }
  },
  mounted () {
    this.showLoading = true
    this.init()

    // GoogleMapを初期化
    this.resetGooglemap()
    this.activateProjectMap(true)
  },
  beforeDestroy () {
    this.resetForm()
  },
  computed: {
    ...mapState({
      eventId: state => state.projectForm.eventId,
      // eqId: state => state.projectForm.eqId,
      assetModel: state => state.projectFormMap.value
    }),
    ...mapGetters({
      getMeta: 'meta/get'
    }),
    showPageTitle () {
      return this.$route.name !== 'ProjectRegister'
    },
    pageTitle () {
      if (this.$route.name === 'ProjectRegisterAttribute')
        return '点検者登録'
      if (this.$route.name === 'ProjectRegisterConfirm')
        return 'プロジェクト作成の確認'
      return 'プロジェクト作成完了'
    },
    isInputPage () {
      return this.$route.name === 'ProjectRegister'
    }
  },
  methods: {
    ...mapActions({
      setMeta: 'meta/set',
      showError: 'error/show',
      initLoad: 'projectForm/initLoad',
      resetGooglemap: 'projectFormMap/reset'
    }),
    ...mapMutations({
      resetForm: 'projectForm/reset',
      activateProjectMap: 'projectFormMap/setActive'
    }),
    init () {
      // データ読出し
      this.showLoading = true
      this.initLoad()
        .then(() => {
          this.showLoading = false
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
        })
    }
  }
}
