import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Utility from '@/utils/Utility'
import Vue from 'vue'

const state = {
  projectId: null,
  // flags for loading
  isInitLoaded: false,
  // model
  projectModel: {},
  assetModel: {},
  commentModel: [],
  reportModel: [],
  // 表示しているタスクのID
  taskId: 1
}

const getters = {
  get: state => state,
  // プロジェクトが完了しているか判別
  projectCompleted: state => Utility.projectCompleted(state.projectModel.ProjectStatus)
}

const actions = {
  /**
   * 初期読出し
   */
  initLoad ({commit, getters}, {projectIds}) {
    commit('setProjectId', projectIds[0])
    
    return new Promise((resolve, reject) => {
      // プロジェクト詳細を読出し
      ApiManager.request(
        ['project'],
        ['v1ProjectDetailGet'],
        [{ projectIds: projectIds }])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            commit('setProjectModel', res[0].data.Projects[0])
            console.log('@projectModel', state.projectModel)

            // アセット詳細を読出し
            let assetId = res[0].data.Projects[0].AssetId
            ApiManager.request(
              ['asset'],
              ['v1AssetDetailGet'],
              [{ assetIds: [assetId] }])
              .then(res => {
                if (res[0].data.Result === Constants.ApiResult.Success) {
                  commit('setAssetModel', res[0].data.Assets[0])

                  // 初期表示のタスクをbit値から導く
                  let bit = Utility.projectStatusToBit(state.projectModel.ProjectStatus)
                  let bitArray = bit.split('').reverse()
                  let task_id = 1
                  if (getters.projectCompleted) {
                    task_id = 6
                  } else {
                    bitArray.forEach((item, index) => {
                      if (item === '1')
                        task_id = index + 1
                    })
                  }
                  commit('setTaskId', task_id)

                  // flag
                  commit('initLoaded')
                  console.log('@assetModel', state.assetModel)

                  resolve()
                  
                } else {
                  reject(res[0].data)
                }

              })
              .catch(error => {
                reject(error)
              })
            
          } else {
            reject(res[0].data)
          }

        })
        .catch(error => {
          reject(error)
        })
    })
  },

  /**
   * 初期読出し
   */
  loadProjectModel ({commit}) {
    return new Promise((resolve, reject) => {
      // プロジェクト詳細を読出し
      ApiManager.request(
        ['project'],
        ['v1ProjectDetailGet'],
        [{ projectIds: [state.projectId] }])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            commit('setProjectModel', res[0].data.Projects[0])
            console.log('@projectModel', state.projectModel)
            resolve()
            
          } else {
            reject(res[0].data)
          }

        })
        .catch(error => {
          reject(error)
        })
    })
  },

  /**
   * プロジェクトステータスを変更
   * ※完了済にしたtaskを未完了に戻すことは考慮していない
   * @param  code  {string}  Constants.Tasksのcodeを完了済に変更
   */
  changeProjectStatus ({commit}, {code}) {
    let bit = Utility.projectStatusToBit(state.projectModel.ProjectStatus)
    let bitArray = bit.split('')
    let targetId = Constants.Tasks.filter(item => item.code === code)[0].id
    let bitIndex = Constants.Tasks.length - targetId
    // codeに対応するbitArrayの値を「1」に変更
    bitArray[bitIndex] = '1'
    // index:0以外が全て完了していれば、index:0も「1」に変更
    if (!bitArray.filter((item, index) => index && item ==='0').length) {
      bitArray[0] = '1'
    }
    // bitArrayをビット値に変換
    let projectStatus = Utility.projectStatusToInt(bitArray.join(''))

    return new Promise((resolve, reject) => {
      ApiManager.request(
        ['project'],
        ['v1ProjectUpdate'],
        [{
          ProjectData: {
            ProjectId: state.projectId,
            Status: projectStatus
          }
        }])
        .then(res => {
          console.log(res)
          if (res[0].data.Result === Constants.ApiResult.Success) {
            // projectModelを更新
            commit('setProjectModel', res[0].data.Project)

            // task id
            // let taskId = Number(status.charAt(0))
            // commit('setTaskId', taskId)
            
            resolve(res)

          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  /**
   * コメント一覧を読出し
   */
  loadComments ({commit}, parameters) {
    return new Promise((resolve, reject) => {
      // コメント一覧を読出し
      ApiManager.request(
        ['project'],
        ['v1ProjectCommentListGet'],
        [parameters])
        .then(res => {
          console.log('v1ProjectCommentListGet', res)
          if (res[0].data.Result === Constants.ApiResult.Success) {
            // model
            commit('setCommentModel', res[0].data.Comments)
            resolve()

          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  /**
   * コメントの登録
   */
  // eslint-disable-next-line
  saveComment ({commit}, parameters) {
    console.log('saveComment')
    return new Promise((resolve, reject) => {
      ApiManager.request(
        ['project'],
        ['v1ProjectCommentPost'],
        [parameters])
        .then(res => {
          console.log('v1ProjectCommentPost', res)
          if (res[0].data.Result === Constants.ApiResult.Success) {
            resolve()

          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  /**
   * レポート覧を読出し
   */
  // loadReports ({commit}, parameters) {
  //   return new Promise((resolve, reject) => {
  //     // コメント一覧を読出し
  //     ApiManager.request(
  //       ['project'],
  //       ['v1ProjectReportListGet'],
  //       [{ ProjectId: state.projectId }])
  //       .then(res => {
  //         console.log('v1ProjectReportListGet', res)
  //         if (res[0].data.Result === Constants.ApiResult.Success) {
  //           // model
  //           commit('setReportModel', res[0].data.ProjectReports)
  //           resolve()

  //         } else {
  //           reject(res[0].data)
  //         }
  //       })
  //       .catch(error => {
  //         reject(error)
  //       })
  //   })
  // }
}

const mutations = {
  setProjectId (state, projectId) {
    state.projectId = projectId
  },
  // 初期読出し
  initLoaded (state) {
    state.isInitLoaded = true
  },
  // model
  setProjectModel (state, data) {
    Vue.set(state, 'projectModel', data)
  },
  setAssetModel (state, data) {
    Vue.set(state, 'assetModel', data)
  },
  setCommentModel (state, data) {
    Vue.set(state, 'commentModel', data)
  },
  setReportModel (state, data) {
    Vue.set(state, 'reportModel', data)
  },
  // task
  setTaskId (state, id) {
    state.taskId = id
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
