import { mapState, mapActions } from 'vuex'
import Loading from '@/components/Loading'
import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'

export default {
  name: 'PasswordConfirm',
  components: {
    Loading
  },
  data () {
    return {
      connecting: false
    }
  },
  computed: {
    ...mapState({
      values: state => state.register.values
    }),
    maskedCurrentPassword () {
      return String('*').repeat(this.values.currentPassword.length)
    },
    maskedPassword () {
      return String('*').repeat(this.values.password.length)
    }
  },
  methods: {
    ...mapActions({
      signOut: 'auth/signOut',
      showError: 'error/show'
    }),
    clickBack () {
      this.$router.go(-1)
    },
    clickRegister () {
      this.connecting = true

      let parameters = {
        PasswordData: {
          CurPassword: this.values.currentPassword,
          NewPassword: this.values.password
        }
      }
      console.log('register data', parameters)

      // 登録
      ApiManager.request(['user'], ['v1UserPasswordUpdate'], [parameters])
        .then(res => {
          console.log('v1UserPasswordUpdate', res)
          if (res[0].data.Result === Constants.ApiResult.Success) {
            // 自動ログインの場合
            // this.$router.push({name: 'Notice'})
            // 再度ログインさせる場合
            this.signOut()
            this.$router.push({name: 'Signin'})
            // 完了画面
            // this.$router.push({name: 'RegisterComplete'})

          // API エラー
          } else if (res[0].data.Result === Constants.ApiResult.Failure) {
            this.showError(res[0].data)
            
          // システムエラー
          } else {
            this.showError(null)
          }
          this.connecting = false
          
        })
        .catch(() => {
          this.showError(null)
          this.connecting = false
        })
    }
  }
}
