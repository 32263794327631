
const state = {
  active: false
}

const getters = {
  get: state => state
}

const actions = {
  show ({commit}) {
    commit('setActive', true)
  },
  hide ({commit}) {
    commit('setActive', false)
  }
}

const mutations = {
  setActive (state, flag) {
    state.active = flag
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
