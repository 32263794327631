import { mapState, mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'

export default {
  name: 'ProjectTask6',
  data () {
    return {

    }
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapState({
      // model
      projectModel: state => state.projectDetail.projectModel,
    }),
    ...mapGetters({
      isModerator: 'auth/isModerator',
      isInspector: 'auth/isInspector',
      isObserver: 'auth/isObserver'
    }),

    showDownloadButton() {
      return !this.isInspector;
    },
    completeDate () {
      return moment(new Date(this.projectModel.ProjectUpdatedAt)).format('YYYY/MM/DD')
    }
  },
  methods: {
    ...mapActions({
      showError: 'error/show',
      showAlert: 'alert/show',
    }),
    init () {

    },
    /**
     * プロジェクト一覧に戻る
     */
    clickBack () {
      // 点検者はプロジェクト一覧へ
      if (this.isInspector) {
        this.$router.push({ name: 'Project' })

      // 管理者はイベント詳細へ
      } else {
        this.$router.push({
          name: 'EventDetail',
          params: {
            eventId: String(this.projectModel.EventId)
          }
        })
      }
    },
    /**
     * 調査・点検橋梁一覧表をダウンロード
     */
    clickDownloadList () {
      // 調査・点検橋梁一覧表を取得
      ApiManager.request(
        ['event'],
        ['v1EventReportListGet'],
        [{ EventId: this.projectModel.EventId }])
        .then(res => {
          console.log('v1EventReportListGet', res)
          if (res[0].data.Result === Constants.ApiResult.Success) {

            if (res[0].data.EventReportObjectKey === null) {
              console.log(99999)
              this.showAlert({
                body: '生成された調査・点検橋梁一覧表がありません'
              })

            } else {
              // 調査・点検橋梁一覧表をダウンロード
              ApiManager.request(
                ['aws'],
                ['v1AwsPresignedUrlGet'],
                [{
                  docType: 11,
                  s3ObjectKey: res[0].data.EventReportObjectKey,
                  use: 0
                }])
                .then(res => {
                  if (res[0].data.Result === Constants.ApiResult.Success) {
                    let url = res[0].data.S3ObjectInfo.PreSignedUrl
                    window.open(url)

                  } else if (res[0].data.Result === Constants.ApiResult.Failure) {
                    this.showError(res[0].data.Errors)

                  } else {
                    this.showError(null)
                  }
                })
                .catch(() => {
                  this.showError(null)
                })
            }

          // API エラー
          } else if (res[0].data.Result === Constants.ApiResult.Failure) {
            this.showError(res[0].data)

          // システムエラー
          } else {
            this.showError(null)
          }
        })
        .catch(() => {
          this.showError(null)
        })
    }
  }
}
