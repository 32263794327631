// import Constants from '@/utils/Constants'

const state = {
	active: false,
	id: null,
	originName: '',
	extension: 'pdf',
	reportType: null,
	modified: false
}

const getters = {
  get: state => state
}

const actions = {
	show ({commit}, {id, fileName, reportType}) {
		let array = fileName.split('.')
		let extension = array[array.length - 1]
		array.pop()
		let name = array.join('.')

		commit('showed', {
			id: id,
			extension: extension,
			name: name,
			reportType: reportType
		})
	},
	modified ({commit}) {
		commit('modified')
	},
	hide ({commit}) {
		commit('hided')
	}
}

const mutations = {
	showed (state, {id, extension, name, reportType}) {
		state.id = id
		state.extension = extension
		state.originName = name
		state.reportType = reportType
		state.active = true
	},
	modified (state) {
		state.modified = true
	},
	hided (state) {
		state.active = false
		state.id = null
		state.extension = 'pdf'
		state.originName = ''
		state.reportType = null
		state.modified = false
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
