import Vue from 'vue'

const state = {
	active: false,
	id: null,
	eventName: null,
	model: []
}

const getters = {
  get: state => state
}

const actions = {
	show ({commit}, {id, eventName, model}) {
		console.log(id)
		console.log(model)
		commit('showed', {
			id: id,
			eventName: eventName,
			model: model
		})
	},
	hide ({commit}) {
		commit('hided')
	}
}

const mutations = {
	showed (state, {id, eventName, model}) {
		state.id = id
		state.eventName = eventName
		state.active = true
		Vue.set(state, 'model', model)
	},
	hided (state) {
		state.active = false
		state.id = null
		state.eventName = null
		Vue.set(state, 'model', [])
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
