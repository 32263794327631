import { mapState, mapActions } from 'vuex'
import Loading from '@/components/Loading'
import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'

export default {
  name: 'ExamplesModal',
  components: {
    Loading
  },
  mounted () {
    this.name = this.originName

    // // モーダル表示時の処理
    // $('#' + this.id).on('show.bs.modal', () => {

    // })

    // モーダル非表示時の処理
    $('#' + this.id).on('hide.bs.modal', () => {
      this.hide()
    })
    
    $('#' + this.id).modal('show')
  },
  data () {
    return {
      name: '',
      // flag
      connecting: false
    }
  },
  computed: {
    ...mapState({
      // model
      projectModel: state => state.projectDetail.projectModel,
      // modal
      active: state => state.renameModal.active,
      id: state => state.renameModal.id,
      originName: state => state.renameModal.originName,
      extension: state => state.renameModal.extension,
      reportType: state => state.renameModal.reportType
    }),
    disabledSaveButton () {
      if (this.connecting) return true
      return this.name === this.originName
    }
  },
  methods: {
    ...mapActions({
      showError: 'error/show',
      modified: 'renameModal/modified',
      hide: 'renameModal/hide',
      loadProjectReport: 'projectTask5/loadProjectReport'
    }),
    clickClose () {
      // this.$emit('childs-event', {
      //   accept: true
      // })
      $('#' + this.id).modal('hide')
    },
    clickSave () {
      this.connecting = true

      // console.log(this.reportType)
      // console.log(this.name)
      ApiManager.request(
        ['project'],
        ['v1ProjectReportUpdate'],
        [{
          ProjectId: this.projectModel.ProjectId,
          ReportFileNameInfo: {
            ReportType: this.reportType,
            FileName: this.name
          }
        }])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            // レポート一覧の再読み出し
            this.loadProjectReport({
              ProjectId: this.projectModel.ProjectId
            })
              .then(() => {
                this.modified()
                this.$nextTick(() => {
                  this.clickClose()
                })
              })
              .catch(error => {
                // API エラー
                if (error.Result === Constants.ApiResult.Failure) {
                  this.clickClose()
                  this.showError(error)
                  
                // システムエラー
                } else {
                  this.clickClose()
                  this.showError(null)
                }
              })

          } else if (res[0].data.Result === Constants.ApiResult.Failure) {
            this.clickClose()
            this.showError(res[0].data.Errors)

          } else {
            this.clickClose()
            this.showError(null)
          }
          this.connecting = false

        })
        .catch(() => {
          this.clickClose()
          this.showError(null)
          this.connecting = false
        })
    }
  }
}