export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      ProjectId: 1,
      PhotoCount: 0,
      TakenPhotos: []
    }
  }
}