import { mapState, mapActions, mapMutations } from 'vuex'
import Constants from '@/utils/Constants'

export default {
  name: 'ChildMarker',
  inject: [
    'google',
    'map'
  ],
  props: {
    model: Object
  },
  mounted () {
    this.setMarker()
  },
  data () {
    return {
      marker: null,
      infoWindow: null
    }
  },
  computed: {
    ...mapState({
      selectedMarkerId: state => state.projectRegisterMap.selectedMarkerId,
      markerZIndex: state => state.projectRegisterMap.markerZIndex
    })
  },
  watch: {
    selectedMarkerId: function (to, from) {
      // アイコンの変更
      this.changeMarker()

      // 選択されたマーカーが自身の場合
      if (to === this.model.AssetId &&
        this.model.Latitude &&
        this.model.Longitude) {
        // 地図の中心を移動
        const { LatLng } = this.google.maps
        this.map.panTo(new LatLng(this.model.Latitude, this.model.Longitude))
        this.map.panBy(0, -100)

        // zIndexを上に
        this.setMarkerZIndex(this.markerZIndex + 1)
        this.marker.setZIndex(this.markerZIndex)

        // InfoWindowを表示
        this.showInfoWindow()
      }

      // 選択されたマーカーが自身から変更された場合
      if (from === this.model.AssetId &&
        this.model.Latitude &&
        this.model.Longitude) {
        // InfoWindowを削除
        this.hideInfoWindow()
      }
    }
  },
  methods: {
    ...mapActions({
      addMarker: 'projectRegisterMap/addMarker',
      selectMarker: 'projectRegisterMap/selectMarker'
    }),
    ...mapMutations({
      setMarkerZIndex: 'projectRegisterMap/setMarkerZIndex'
    }),
    /**
     * マーカーを配置する
     */
    setMarker () {
      const { Marker } = this.google.maps
      
      // マーカーを生成
      this.marker = new Marker({
        position: {
          lat: this.model.Latitude,
          lng: this.model.Longitude
        },
        map: this.map,
        icon: this.getMarker('default'),
        zIndex: this.markerZIndex
      })
      
      // マーカーのクリックイベント
      this.marker.addListener('click', this.click)

      this.addMarker(this.marker)
    },
    /**
     * マーカーのSVGを取得する
     * default / active
     */
    getMarker (type) {
      // サイズ
      let size = type === 'active' ?
        Constants.GoogleMapMarkerSize.lg :
        Constants.GoogleMapMarkerSize.md

      // 色
      let color = type === 'default' ?
        Constants.GoogleMapMarkerColor.default :
        Constants.GoogleMapMarkerColor.active

      // マーカーを生成
      let template = Constants.GoogleMapMarker.Pin()
      let svg = template.replace('{size}', size + 'px')
        .replace('{color}', color)
        .replace('{center}', Constants.GoogleMapMarkerColor.center)
        .replace('{stroke}', Constants.GoogleMapMarkerColor.stroke)
      
      const { Size } = this.google.maps
      const { Point } = this.google.maps

      let icon = {
        url: 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(svg),
        size: new Size(size, size),
        origin: new Point(0, 0),
        anchor: new Point(size / 2, size)
      }
      return icon
    },
    /**
     * マーカーを変更する
     */
    changeMarker () {
      let type = 'default'
      if (this.selectedMarkerId === this.model.AssetId) {
        type = 'active'
      }
      this.marker.setIcon(this.getMarker(type))
    },

    /**
     * クリック時の処理
     */
    click () {
      // console.log('click marker', this.model)

      // 選択されたAssetIdをstoreに登録
      this.selectMarker(this.model.AssetId)
    },
    /**
     * InfoWindowを表示
     */
    showInfoWindow () {
      // console.log('showInfoWindow : ', this.model.AssetId)
      const { InfoWindow } = this.google.maps

      this.infoWindow = new InfoWindow({
        content: this.$refs.if
      })

      this.infoWindow.open(this.map, this.marker)
    },
    /**
     * InfoWindowを削除
     */
    hideInfoWindow () {
      // console.log('hideInfoWindow : ', this.model.AssetId)
      if (this.infoWindow !== null) {
        this.infoWindow.close()
        this.infoWindow = null
      }
    }
  }
}