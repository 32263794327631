import { mapState, mapGetters, mapActions } from 'vuex'
import Constants from '@/utils/Constants'
import DamageForm from '../DamageForm'

export default {
  name: 'LoadCapacity',
  components: {
    DamageForm
  },
  data () {
    return {
      categoryList: [
        {
          sectionKey: 'Foundation',
          title: '基礎',
          loadCapacity: {
            enable: true,
            items: ['A', 'B', 'C', 'D']
          },
          aging: {
            enable: true,
            items: Constants.ProjectConst.Aging
          }
        },
        {
          sectionKey: 'Pier',
          title: '橋台',
          loadCapacity: {
            enable: true,
            items: Constants.ProjectConst.LoadCapacity
          },
          aging: {
            enable: true,
            items: Constants.ProjectConst.Aging
          }
        },
        {
          sectionKey: 'Bent',
          title: '橋脚（RC・鉄鋼）',
          loadCapacity: {
            enable: true,
            items: Constants.ProjectConst.LoadCapacity
          },
          aging: {
            enable: true,
            items: Constants.ProjectConst.Aging
          }
        },
        {
          sectionKey: 'Main',
          title: '主構造（上部工）',
          loadCapacity: {
            enable: true,
            items: Constants.ProjectConst.LoadCapacity
          },
          aging: {
            enable: true,
            items: Constants.ProjectConst.Aging
          }
        },
        {
          sectionKey: 'Support',
          title: '支承部',
          loadCapacity: {
            enable: true,
            items: ['A', 'B', 'C', 'D']
          },
          aging: {
            enable: true,
            items: Constants.ProjectConst.Aging
          }
        },
        {
          sectionKey: 'Floor',
          title: '床版（下面）',
          loadCapacity: {
            enable: true,
            items: ['A', 'B', 'C', 'D']
          },
          aging: {
            enable: true,
            items: Constants.ProjectConst.Aging
          }
        }
      ]
    }
  },

  beforeMount() {
    if (this.isModify) return;

    this.photoModel.forEach(item => console.log('@', item.TargetView, item));
    // 新規登録の場合、プリセットデータを参照する
    this.categoryList
      .map(item => {
        const model = this.presetData(item.title);
        // console.log(item.title, model);
        return Object.assign(item, {
          preset: model,
        });
      });
  },

  computed: {
    ...mapState({
      photoModel: state => state.projectTask4.photoModel
    }),
    ...mapGetters({
      isModify: 'projectTask4/isModify',
      presetData: 'projectTask4/presetData',
      // showAlert: 'alert/show'
    }),

    activePreset() {
      return !this.isModify;
    }
  },

  methods: {
    ...mapActions({
      // setPhotoValueParam: 'projectTask3/setPhotoValueParam',
      // showAlert: 'alert/show'
    })
  }
}