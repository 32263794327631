// import Constants from '@/utils/Constants'

const state = {
  active: false,
  key: null,
  title: '',
  body: '',
  acceptLabel: '',
  denyLabel: '',
  accept: false
}

const getters = {
  get: (state) => {
    return state
  }
}

const actions = {
  show ({commit}, {key, title, body, acceptLabel, denyLabel}) {
    let parameters = {}
    if (key !== undefined) parameters.key = key
    if (title !== undefined) parameters.title = title
    if (body !== undefined) parameters.body = body
    if (acceptLabel !== undefined) parameters.acceptLabel = acceptLabel
    if (denyLabel !== undefined) parameters.denyLabel = denyLabel
    commit('showed', parameters)
  },
  hide ({commit}) {
    commit('hided')
  },
  accept ({commit}) {
    commit('accepted')
  }
}

const mutations = {
  showed (state, {key, title, body, acceptLabel, denyLabel}) {
    if (key !== undefined) state.key = key
    if (title !== undefined) state.title = title
    if (body !== undefined) state.body = body
    if (acceptLabel !== undefined) state.acceptLabel = acceptLabel
    if (denyLabel !== undefined) state.denyLabel = denyLabel
    state.active = true
  },
  hided (state) {
    state.active = false
    state.key = null
    state.title = ''
    state.body = ''
    state.acceptLabel = ''
    state.denyLabel = ''
    state.accept = false
  },
  accepted (state) {
    state.accept = true
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
