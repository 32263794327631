// import { mapState, mapGetters, mapActions } from 'vuex'
// import GoogleMapsApiLoader from 'google-maps-api-loader'
// import MapProvider from '@/components/GoogleMap/MapProvider'

export default {
  name: 'MapProvider',
  props: {
    google: Object,
    map: Object,
  },
  provide () {
    return {
      google: this.google,
      map: this.map
    }
  },
}