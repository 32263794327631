import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Vue from 'vue'

const state = {
  // イベントID
  targetId: null,
  // イベント詳細情報
  values: {},
  loaded: false
}

const getters = {
  get: state => state
}

const actions = {
  /**
   * 初期読出し
   */
  initLoad ({commit}, {targetId}) {
    commit('setTargetId', targetId)

    return new Promise((resolve, reject) => {
      // イベント詳細を読出し
      ApiManager.request(
        ['event'],
        ['v1EventDetailGet'],
        [{ eventIds: [state.targetId] }])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            commit('initLoaded', {
              event: res[0].data.Events[0]
            })
            console.log('@eventData', state.values)
            resolve()
            
          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {
  initLoaded (state, {event}) {
    Vue.set(state, 'values', event)
    state.loaded = true
  },
  setTargetId (state, targetId) {
    state.targetId = targetId
  },
  setLoaded (state, flag) {
    state.loaded = flag
  },
  reset (state) {
    state.targetId = null
    Vue.set(state, 'values', {})
    state.loaded = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
