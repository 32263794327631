import { mapState, mapActions } from 'vuex'
import Constants from '@/utils/Constants'
import ApiManager from '@/utils/ApiManager'

export default {
  name: 'PhotoUploaderItem',
  props: {
    index: Number,
    // 編集可能な場合、true
    // 写真のダウンロードのみの場合、false
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },

    type: {
      type: Number,
      required: false,
      default: 0,
    }
  },
  data () {
    return {
      showSuggest: false,
      focusSuggest: false,
      uploadImage: null
    }
  },

  computed: {
    ...mapState({
      projectId: state => state.projectDetail.projectId,
      alertKey: state => state.alert.key,
      alertAccept: state => state.alert.accept,
      photoValue: state => state.projectTask3.photoValue
    }),
    model () {
      return this.photoValue[this.index]
    },
    isModify () {
      return this.model.TakenPhotoId !== undefined
    },

    // detail
    photoNo () {
      return this.model.ProjectPhotoNumber || '-'
      // return this.index + 1
    },

    // input
    targetView: {
      get () { return this.model.TargetView },
      set (value) {
        this.setPhotoValueParam({
          index: this.index,
          key: 'TargetView',
          value: value
        })
      }
    },
    description: {
      get () { return this.model.DamageDescription },
      set (value) {
        this.setPhotoValueParam({
          index: this.index,
          key: 'DamageDescription',
          value: value
        })
      }
    },
    file: {
      get () { return this.model.File },
      set (value) {
        this.setPhotoValueParam({
          index: this.index,
          key: 'File',
          value: value
        })
      }
    },
    profileFlag: {
      get () { return this.model.BridgeProfileFlg },
      set (value) {
        this.setPhotoValueParam({
          index: this.index,
          key: 'BridgeProfileFlg',
          value: value
        })
      }
    },

    // form
    disabledTarget () {
      if (this.type === 1) return true;

      return this.file === null
    },
    disabledDescription () {
      if (this.type === 1) return false;

      return this.file === null
    },

    // suggest
    suggestList () {
      return Constants.Task3Suggest
    },

    // preview
    previewImage () {
      // 登録済みのアイテムはサムネイルを表示
      if (this.model.ThumbUrl) {
        return this.model.ThumbUrl
      }

      // 新規アイテムは選択されたファイルオブジェクトを表示
      return this.uploadImage
    },

    // profile
    disabledProfile () {
      if (!this.editable) return true;

      return this.file === null
    }
  },
  watch: {
    file: function (to) {
      console.log(to)
      if (to === undefined || to === null) {
        this.uploadImage = null
        return
      }

      const reader = new FileReader()
      reader.onload = e => {
        this.uploadImage = e.target.result
      }
      reader.readAsDataURL(to)
    },
    alertAccept: function (to) {
      if (to && this.alertKey === this.index) {
        console.log('remove')
        
        // 削除した写真がプロフィールに選択されている場合
        if (this.profileFlag) {
          this.setProfileIndex(null)
        }

        // プリセット項目の場合、データから削除するだけ
        if (this.type === 1) {

          // ローディング表示
          this.$emit('start-delete');

          // ファイルの削除を行う
          this.deletePresetPhoto({
            ProjectId: this.projectId,
            TakenPhotoId: this.model.TakenPhotoId,
            TakenPhotoData: {
              TargetView: this.model.TargetView,
              DamageDescription: this.model.DamageDescription,
              BridgeProfileFlg: false,
              Type: this.model.Type,
              CreateReportFlg: true,
              DeletePhotoFlg: true,
            },
          })
            .then(() => {
              // 写真一覧の再読み込み
              this.loadPhoto({
                ProjectId: this.projectId
              })
                .then(() => {
                  this.copySinglePhotoModel(this.model)

                  this.$emit('finish-delete');
                })
                .catch(error => {
                  // API エラー
                  if (error.Result === Constants.ApiResult.Failure) {
                    this.showError(error)
                    
                  // システムエラー
                  } else {
                    this.showError(null)
                  }

                  this.$emit('finish-delete');
                })

            })
            .catch(error => {
              // API エラー
              if (error.Result === Constants.ApiResult.Failure) {
                this.showError(error)
                
              // システムエラー
              } else {
                this.showError(null)
              }

              this.$emit('finish-delete');
            })

        } else {
          // ローディング表示
          this.$emit('start-delete');

          // ファイルの削除を行う
          this.deletePhoto({
            ProjectId: this.projectId,
            TakenPhotoIds: [this.model.TakenPhotoId],
            CreateReportFlg: true
          })
            .then(() => {
              // 写真一覧の再読み込み
              this.loadPhoto({
                ProjectId: this.projectId
              })
                .then(() => {
                  this.copySinglePhotoModel(this.model)

                  this.$emit('finish-delete');
                })
                .catch(error => {
                  // API エラー
                  if (error.Result === Constants.ApiResult.Failure) {
                    this.showError(error)
                    
                  // システムエラー
                  } else {
                    this.showError(null)
                  }

                  this.$emit('finish-delete');
                })

            })
            .catch(error => {
              // API エラー
              if (error.Result === Constants.ApiResult.Failure) {
                this.showError(error)
                
              // システムエラー
              } else {
                this.showError(null)
              }

              this.$emit('finish-delete');
            })
        }
      }
    },
  },
  methods: {
    ...mapActions({
      showError: 'error/show',
      showAlert: 'alert/show',
      setPhotoValueParam: 'projectTask3/setPhotoValueParam',
      setProfileIndex: 'projectTask3/setProfileIndex',
      loadPhoto: 'projectTask3/loadPhoto',
      deletePhoto: 'projectTask3/deletePhoto',
      deletePresetPhoto: 'projectTask3/deletePresetPhoto',
      copySinglePhotoModel: 'projectTask3/copySinglePhotoModel'
    }),
    /**
     * 損傷・変状の説明
     */
    focusDescription () {
      console.log('desc : ' + this.description)
      if (this.description === '' || this.description === null) {
        this.showSuggest = true
      }
    },
    blurDescription () {
      if (!this.focusSuggest) {
        this.showSuggest = false
      }
    },
    mouseoverSuggest () {
      this.focusSuggest = true
    },
    mouseoutSuggest () {
      this.focusSuggest = false
    },
    clickSuggest (str) {
      // console.log(str)
      this.description = str
      this.showSuggest = false
    },
    /**
     * 写真登録
     */
    changeFile (event) {
      // console.log('File', event.target.files[0])
      let file = event.target.files[0]
      
      // 拡張子チェック
      let tmp = file.name.split('.')
      let ext = tmp[tmp.length - 1].toLowerCase()
      // 拡張子エラーが有った場合
      if (Constants.Extensions.image.indexOf(ext) === -1) {
        this.$refs['file'].value = '';
        this.showAlert({
          body: '許可されていない拡張子のファイルはアップロードできません。<br>「' +
            file.name +
            '」'
        })
        return
      }

      this.file = file
    },
    clickRemove () {
      // 登録済みのアイテムを削除
      if (this.isModify) {
        this.showAlert({
          key: this.index,
          body: 'この写真を削除しますか？',
          label: '削除する'
        })

      // 未登録のアイテムを削除
      } else {
        // 削除した写真がプロフィールに選択されている場合
        if (this.profileFlag) {
          this.setProfileIndex(null)
        }
        this.file = null
      }
    },
    /**
     * プロフィール画像登録
     */
    // eslint-disable-next-line
    changeProfile (event) {
      // console.log('changeProfile : ' + this.index)
      this.setProfileIndex(this.index)
    },

    /**
     * 写真をダウンロードする
     */
    downloadPhoto() {
      ApiManager.request(
        ['aws'],
        ['v1AwsPresignedUrlGet'],
        [{
          docType: 6,
          s3ObjectKey: this.model.FileObjectKey,
          use: 0
        }])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            let url = res[0].data.S3ObjectInfo.PreSignedUrl
            window.open(url)

          } else if (res[0].data.Result === Constants.ApiResult.Failure) {
            this.showError(res[0].data.Errors)

          } else {
            this.showError(null)
          }
        })
        .catch(() => {
          this.showError(null)
        })
    },
  }
}