import { mapState, mapGetters, mapActions } from 'vuex'
import Loading from '@/components/Loading'
import Constants from '@/utils/Constants'

export default {
  name: 'SelectPhotoModal',
  components: {
    Loading,
  },
  mounted () {
    // // モーダル表示時の処理
    // $('#' + this.id).on('show.bs.modal', () => {

    // })

    // モーダル非表示時の処理
    $('#' + this.id).on('hide.bs.modal', () => {
      this.takenPhotoIds = []
      this.hide()
    })
    
    $('#' + this.id).modal('show')
  },
  data () {
    return {
      connecting: false,
      takenPhotoIds: [],

      // 写真アップロード画面にする場合、true
      isUploadMode: false,
      // アップロードするFileオブジェクト
      file: null,
      // アップロードするFileオブジェクトのプレビュー用URL
      uploadImage: null,
    }
  },
  computed: {
    ...mapState({
      active: state => state.selectPhotoModal.active,
      sectionKey: state => state.selectPhotoModal.sectionKey,
      id: state => state.selectPhotoModal.modalId,
      // model
      projectModel: state => state.projectDetail.projectModel,
      photoModel: state => state.projectTask4.photoModel,
      photoCount: state => state.projectTask4.photoCount
    }),
    ...mapGetters({
      isDamageValue: 'projectTask4/isDamageValue',
      getDamageValue: 'projectTask4/getDamageValue',
      getStatusValue: 'projectTask4/getStatusValue',
      enablePhotoModel: 'projectTask4/enablePhotoModel',
    }),
    getTitle () {
      return '点検写真一覧（' + this.photoCount + '）'
    },
    isDamageForm () {
      return this.isDamageValue(this.sectionKey)
    },

    // 表示する撮影写真の一覧を取得
    itemList () {
      return this.enablePhotoModel
    },
    // 選択されている写真IDのリストを取得
    selectedTakenPhotoIds () {
      if (this.isDamageForm) {
        return this.getDamageValue(this.sectionKey).photoIds
      } else {
        return this.getStatusValue(this.sectionKey).photoIds
      }
    },

    // 選択済みの写真が上限になったらtrue
    maxSelected () {
      let count = this.selectedTakenPhotoIds.length + this.takenPhotoIds.length
      if (this.isDamageForm) {
        return count >= Constants.Task4DamageMaxPhoto
      } else {
        return count >= Constants.Task4StatusMaxPhoto
      }
    },

    // 表示モード変更ボタンのラベルを返す
    toggleButtonLabel() {
      return this.isUploadMode
        ? '写真選択に戻る'
        : '写真アップロード';
    },

    // form
    enableForm () {
      return this.itemList.length > 0
    },

    // button
    disabledSelectButton () {
      return !this.takenPhotoIds.length
    },
    disabledUploadButton() {
      if (this.connecting) return true

      return this.uploadImage === null
    },

    // preview
    previewImage () {
      // 新規アイテムは選択されたファイルオブジェクトを表示
      return this.uploadImage
    },
  },
  watch: {
    file: function (to) {
      if (to === undefined || to === null) {
        this.uploadImage = null
        return
      }

      const reader = new FileReader()
      reader.onload = e => {
        this.uploadImage = e.target.result
      }
      reader.readAsDataURL(to)
    },
  },
  methods: {
    ...mapActions({
      showError: 'error/show',
      showAlert: 'alert/show',
      hide: 'selectPhotoModal/hide',
      addDamagePhotoId: 'projectTask4/addDamagePhotoId',
      addStatusPhotoId: 'projectTask4/addStatusPhotoId',
      loadPhoto: 'projectTask4/loadPhoto',
      savePhoto: 'projectTask4/savePhoto'
    }),
    clickClose () {
      // this.$emit('childs-event', {
      //   accept: true
      // })
      $('#' + this.id).modal('hide')
    },
    getThumbImageStyle (item) {
      return 'backgroundImage: url("' + item.ThumbUrl + '");'
    },

    // サムネイルステータス
    selectedItem (item) {
      return this.selectedTakenPhotoIds.indexOf(item.TakenPhotoId) !== -1
    },
    activeItem (item) {
      return this.takenPhotoIds.indexOf(item.TakenPhotoId) !== -1
    },
    disabledItem (item) {
      return this.takenPhotoIds.indexOf(item.TakenPhotoId) === -1 && this.maxSelected
    },
    // サムネイルクリック
    clickThumb (item) {
      console.log(item)
      if (this.selectedItem(item)) return

      let index = this.takenPhotoIds.indexOf(item.TakenPhotoId)
      // 写真を追加選択
      if (index === -1) {
        if (this.maxSelected) return
        this.takenPhotoIds.push(item.TakenPhotoId)

      // 選択を解除
      } else {
        this.takenPhotoIds.splice(index, 1)
      }
      console.log(this.takenPhotoIds)
    },

    // 選択ボタン
    clickSelect () {
      if (this.isDamageForm) {
        this.takenPhotoIds.forEach(item => {
          console.log(item)
          this.addDamagePhotoId({
            sectionKey: this.sectionKey,
            takenPhotoId: item
          })
        })
        
      } else {
        this.takenPhotoIds.forEach(item => {
          this.addStatusPhotoId({
            sectionKey: this.sectionKey,
            takenPhotoId: item
          })
        })
      }
      this.clickClose()
    },

    // 写真アップロード画面に変更
    toggleMode() {
      this.isUploadMode = !this.isUploadMode;
      this.clickRemove()
    },
    /**
     * 写真登録
     */
    changeFile (event) {
      // console.log('File', event.target.files[0])
      let file = event.target.files[0]
      
      // 拡張子チェック
      let tmp = file.name.split('.')
      let ext = tmp[tmp.length - 1].toLowerCase()
      // 拡張子エラーが有った場合
      if (Constants.Extensions.image.indexOf(ext) === -1) {
        this.$refs['file'].value = '';
        this.showAlert({
          body: '許可されていない拡張子のファイルはアップロードできません。<br>「' +
            file.name +
            '」'
        })
        return
      }

      this.file = file
    },
    clickRemove () {
      this.file = null
      this.uploadImage = null
    },

    uploadPhoto() {
      this.connecting = true

      const parameters = [];
      parameters.push({
        File: this.file,
      });

      // ファイルの追加を行う
      this.savePhoto(parameters)
        .then(() => {

          // デ写真一覧読出し
          this.loadPhoto({
            ProjectId: this.projectModel.ProjectId
          })
            .then(() => {
              this.toggleMode();
              this.connecting = false
            })
            .catch(error => {
              // API エラー
              if (error.Result === Constants.ApiResult.Failure) {
                this.showError(error)
                
              // システムエラー
              } else {
                this.showError(null)
              }
              this.connecting = false
            })

        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
          this.connecting = false
        })
    },
  }
}