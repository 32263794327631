import { mapGetters, mapActions } from 'vuex'
import Constants from '@/utils/Constants'
export default {
  name: 'ReminderInvalid',
  metaInfo () {
    this.setMeta({
      title: Constants.SiteInfo.title
    })
    return this.getMeta
  },
  computed: {
    ...mapGetters({
      getMeta: 'meta/get'
    })
  },
  methods: {
    ...mapActions({
      setMeta: 'meta/set'
    })
  }
}
