import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'DamageFormPhoto',
  props: {
    sectionKey: String,
    takenPhotoId: Number
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapState({
      projectId: state => state.projectDetail.projectId
    }),
    ...mapGetters({
      isModerator: 'auth/isModerator',
      isInspector: 'auth/isInspector',
      isObserver: 'auth/isObserver',
      projectCompleted: 'projectDetail/projectCompleted',
      getPhotoModel: 'projectTask4/getPhotoModel'
    }),
    showPreview () {
      return this.takenPhotoId !== null
    },
    getThumbImageStyle () {
      if (!this.getPhotoModel(this.takenPhotoId).length) return ''
      return 'backgroundImage: url("' +
        this.getPhotoModel(this.takenPhotoId)[0].ThumbUrl + '");'
    },
    photoNo () {
      return this.getPhotoModel(this.takenPhotoId)[0].ProjectPhotoNumber
    },

    // form
    enableForm () {
      return (this.isModerator || this.isInspector) &&
        !this.projectCompleted
    },
  },
  watch: {

  },
  methods: {
    ...mapActions({
      showAlert: 'alert/show',
      showModal: 'selectPhotoModal/show',
      removeStatusPhotoId: 'projectTask4/removeStatusPhotoId'
    }),
    /**
     * 写真選択
     */
    clickSelect () {
      this.showModal({
        projectId: this.projectId,
        sectionKey: this.sectionKey
      })
    },
    /**
     * 写真削除
     */
    clickRemove () {
      this.removeStatusPhotoId({
        sectionKey: this.sectionKey,
        takenPhotoId: this.takenPhotoId
      })
    }
  }
}