import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import Constants from '@/utils/Constants'

export default {
  name: 'Filters',
  computed: {
    ...mapState({
      showFilters: state => state.config.showFilters,
      locationIdQuery: state => state.backgroundMap.locationIdQuery,
      roadAdminQuery: state => state.backgroundMap.roadAdminQuery,
      cityQuery: state => state.backgroundMap.cityQuery,
      completeYearQuery: state => state.backgroundMap.completeYearQuery,
      constructorNameQuery: state => state.backgroundMap.constructorNameQuery,
      bridgeAdminQuery: state => state.backgroundMap.bridgeAdminQuery,
      formQuery: state => state.backgroundMap.formQuery,
      eqQuery: state => state.backgroundMap.eqQuery
    }),
    ...mapGetters({
      isModerator: 'auth/isModerator',
      isInspector: 'auth/isInspector'
    }),
    locationId: {
      get () { return this.locationIdQuery },
      set (value) { this.setLocationIdQuery(value) }
    },
    roadAdmin: {
      get () { return this.roadAdminQuery },
      set (value) { this.setRoadAdminQuery(value) }
    },
    city: {
      get () { return this.cityQuery },
      set (value) { this.setCityQuery(value) }
    },
    completeYear: {
      get () { return this.completeYearQuery },
      set (value) { this.setCompleteYearQuery(value) }
    },
    constructorName: {
      get () { return this.constructorNameQuery },
      set (value) { this.setConstructorNameQuery(value) }
    },
    bridgeAdmin: {
      get () { return this.bridgeAdminQuery },
      set (value) { this.setBridgeAdminQuery(value) }
    },
    form: {
      get () { return this.formQuery },
      set (value) { this.setFormQuery(value) }
    },
    eq: {
      get () { return this.eqQuery },
      set (value) { this.setEqQuery(value) }
    }
  },
  methods: {
    ...mapActions({
      toggleFilters: 'config/toggleFilters',
      search: 'backgroundMap/search',
      hidePhotoList: 'home/hidePhotoList',
      showAlert: 'alert/show',
      showError: 'error/show'
    }),
    ...mapMutations({
      setLocationIdQuery: 'backgroundMap/setLocationIdQuery',
      setRoadAdminQuery: 'backgroundMap/setRoadAdminQuery',
      setCityQuery: 'backgroundMap/setCityQuery',
      setCompleteYearQuery: 'backgroundMap/setCompleteYearQuery',
      setConstructorNameQuery: 'backgroundMap/setConstructorNameQuery',
      setBridgeAdminQuery: 'backgroundMap/setBridgeAdminQuery',
      setFormQuery: 'backgroundMap/setFormQuery',
      setEqQuery: 'backgroundMap/setEqQuery'
    }),
    clickToggle () {
      this.toggleFilters()
    },
    clickSearch (type) {
      if (type === 1 && !this.locationId) return
      if (type === 2 && !this.roadAdmin) return
      if (type === 3 && !this.city) return
      if (type === 4 && !this.completeYear) return
      if (type === 5 && !this.constructorName) return
      if (type === 6 && !this.bridgeAdmin) return
      if (type === 7 && !this.form) return
      if (type === 8 && !this.eq) return

      // 写真リストを非表示
      this.hidePhotoList()

      // 検索
      this.search({
        type: type
      })
        .then(res => {
          if (!res.Assets.length) {
            this.showAlert(Constants.Alerts.NotHitAsset)
          }
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
        })
    }
  }
}