import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import Constants from '@/utils/Constants'
import GlobalNavi from '@/components/Aside/GlobalNavi'
// Home
import Filters from '@/components/Aside/Home/Filters'
import Results from '@/components/Aside/Home/Results'
import Detail from '@/components/Aside/Home/Detail'
// Project
import ProjectList from '@/components/Aside/ProjectList'
import ProjectDetail from '@/components/Aside/ProjectDetail'
import ProjectForm from '@/components/Aside/ProjectForm'

export default {
  name: 'Aside',
  components: {
    GlobalNavi,
    Filters,
    Results,
    Detail,
    ProjectList,
    ProjectDetail,
    ProjectForm
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapState({
      authorized: state => state.auth.authorized,
      requireChangePassword: state => state.auth.requireChangePassword,
      showGnav: state => state.config.showGnav,
      // Home
      showFilters: state => state.config.showFilters,
      model: state => state.backgroundMap.model,
      selectedAssetId: state => state.backgroundMap.selectedMarkerId,
      keywordQuery: state => state.backgroundMap.keywordQuery,
      activeList: state => state.home.activeList,
      // Project
      selectedProjectId: state => state.projectMap.selectedMarkerId,
    }),
    ...mapGetters({
      isModerator: 'auth/isModerator',
      isInspector: 'auth/isInspector',
      homeIsActive: 'home/active'
    }),
    showMenu: {
      get () { return this.showGnav },
      set (value) { this.setShowGnav(value) }
    },
    query: {
      get () { return this.keywordQuery },
      set (value) { this.setKeywordQuery(value) }
    },
    isHomePage () {
      return this.$route.name === 'Home'
    },
    isProjectPage () {
      return this.$route.name === 'Project'
    },
    isProjectDetailPage () {
      return this.$route.name === 'ProjectTask1' ||
        this.$route.name === 'ProjectTask2' ||
        this.$route.name === 'ProjectTask3' ||
        this.$route.name === 'ProjectTask4' ||
        this.$route.name === 'ProjectTask5' ||
        this.$route.name === 'ProjectTask6'
    },
    isProjectForm () {
      return this.$route.name === 'ProjectRegister'
    },
    isEventDetailPage () {
      return this.$route.name === 'EventDetail'
    },

    // header
    showHeader () {
      return !this.activeList
    },
    showHomeHeader () {
      return this.isHomePage &&
        !this.showMenu
    },
    showHomeButton () {
      if (this.isHomePage) return false
      return !this.showBackButton
    },
    showBackButton () {
      console.log('isEventDetailPage : ', this.isEventDetailPage)
      console.log('showProjectList : ', this.showProjectList)
      if (this.isHomePage && this.showMenu)
        return true
      if (this.isEventDetailPage)
      // if (this.isEventDetailPage && !this.showProjectList)
        return true
      if (this.isProjectPage && !this.showProjectList)
        return true
      return false
    },

    // modules : 詳細検索
    showScoop () {
      return this.isHomePage &&
        !this.activeList &&
        !this.showMenu &&
        this.selectedAssetId === null
    },
    // module : 橋梁一覧
    showResults () {
      return this.model.length &&
        !this.showFilters
    },
    // module : 橋梁詳細
    showDetail () {
      return !this.showMenu &&
        !this.activeList &&
        this.selectedAssetId !== null
    },
    // module : プロジェクト
    showProjectList () {
      return (this.isEventDetailPage ||
        this.isProjectPage) &&
        this.selectedProjectId === null
    },
    showProjectDetail () {
      return (this.isEventDetailPage ||
        this.isProjectPage) &&
        this.selectedProjectId !== null
    },
    showProjectForm () {
      return this.isProjectForm
    }
  },
  watch: {
    // eslint-disable-next-line
    '$route' (to, from) {
      // マーカーの選択を解除
      this.selectMarker(null)
      // ヘッダーの表示設定
      this.showMenu = to.name !== 'Home' &&
        to.name !== 'EventDetail' &&
        to.name !== 'Project' &&
        to.name !== 'ProjectRegister'
    },
    // homeIsActive (to) {
    //   if (to) this.showMenu = false
    // }
  },
  methods: {
    ...mapActions({
      // Home
      search: 'backgroundMap/search',
      selectMarker: 'backgroundMap/selectMarker',
      hidePhotoList: 'home/hidePhotoList',
      // EventDetail
      selectProjectMarker: 'projectMap/selectMarker',
      // Dialog
      showAlert: 'alert/show',
      showError: 'error/show'
    }),
    ...mapMutations({
      setShowGnav: 'config/setShowGnav',
      setKeywordQuery: 'backgroundMap/setKeywordQuery'
    }),
    clickMenu () {
      this.showMenu = true
    },
    clickHome () {
      this.$router.push({name: 'Home'})
    },
    clickBack () {
      // ホーム画面
      if (this.isHomePage) {
        this.showMenu = false

      // イベント詳細（管理者）
      } else if (this.isEventDetailPage) {
        if (this.showProjectList) {
          this.$router.push({name: 'Event'})
        } else {
          this.selectProjectMarker()
        }

      // プロジェクト一覧（点検者）
      } else if (this.isProjectPage) {
        this.selectProjectMarker()
      }

      // // ホーム画面
      // if (this.isHomePage) {
      //   this.showMenu = false

      // // イベント詳細
      // } else if (this.isEventDetailPage) {
      //   if (this.showProjectList) {
      //     this.$router.push({name: 'Event'})
          
      //   } else {
      //     this.selectProjectMarker()
      //   }

      // // プロジェクト
      // } else if (this.isProjectPage) {
      //   if (this.showProjectList) {
      //     this.$router.push({name: 'Home'})
          
      //   } else {
      //     this.selectProjectMarker()
      //   }

      // // // プロジェクト詳細
      // // } else if (this.isProjectDetailPage) {
      // //   this.$router.go(-2)

      // // その他
      // } else {
      //   this.$router.push({name: 'Home'})
      //   // this.$router.go(-1)
      // }
    },
    clickSearch () {
      if (!this.query) return

      // 写真リストを非表示
      this.hidePhotoList()
      
      // 検索
      this.search({
        type: 0
      })
        .then(res => {
          if (!res.Assets.length) {
            this.showAlert(Constants.Alerts.NotHitAsset)
          }
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
        })
    }
  }
}