export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'failure',
      Errors: {
        Message: 'エラーメッセージ',
        Code: '101'
      }
    }
  }
}