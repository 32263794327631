// import Constants from '@/utils/Constants'
import Vue from 'vue'

const state = {
	active: false,
	id: null,
	model: {}
}

const getters = {
  get: state => state
}

const actions = {
	show ({commit}, {id, model}) {
		commit('showed', {
			id: id,
			model: model
		})
	},
	hide ({commit}) {
		commit('hided')
	}
}

const mutations = {
	showed (state, {id, model}) {
		state.id = id
		Vue.set(state, 'model', model)
		state.active = true
	},
	hided (state) {
		state.active = false
		Vue.set(state, 'model', {})
		state.id = null
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
