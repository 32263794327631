export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      Events: [
        {
          EventId: 1,
          EventName: 'イベント001',
          ModeratorName: 'モデレータ 太郎',
          LiveFlg: true,
          EventStatus: '100',
          EventStatusUpdated: '2019-09-20 13:00:02',
          CreatedAt: '2019-08-02 12:29:02',
          UpdatedAt: '2019-09-20 13:00:02',
          ProjectCount: '4',
          EarthquakesInfo: [
            {
              EqId: '20201022120330',
              OriginTime: '2019-08-02 12:29:02',
              HypoAreaName: '三陸沖',
              Latitude: 38.741065,
              Longitude: 141.9641172,
              Depth: 10000,
              Magnitude: '8.1',
              MagnitudeDesc: '巨大地震',
              MaxInt: '6+'
            }
          ]
        }
      ]
    }
  }
}