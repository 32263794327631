
const state = {

}

const getters = {
  get: state => state
}

const actions = {

}

const mutations = {
  
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
