export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      Users: [
        {
          InspectorCompanyName: '点検業者会社名01',
          InspectorCompanyNameKana: '点検業者会社名01（カナ）',
          InspectorTeam: [
            {
              InspectorLeaderName: '点検者班長名',
              InspectorLeaderNameKana: '点検者氏名（カナ）',
              InspectorTeamNumber: '77',
              InspectorName: '点検者氏名',
              InspectorNameKana: '点検者氏名（カナ）'
            },
            {
              InspectorLeaderName: '点検者班長名',
              InspectorLeaderNameKana: '点検者氏名（カナ）',
              InspectorTeamNumber: '77',
              InspectorName: '点検者氏名',
              InspectorNameKana: '点検者氏名（カナ）'
            }
          ]
        },
        {
          InspectorCompanyName: '点検業者会社名02',
          InspectorCompanyNameKana: '点検業者会社名02（カナ）',
          InspectorTeam: [
            {
              InspectorLeaderName: '点検者班長名',
              InspectorLeaderNameKana: '点検者氏名（カナ）',
              InspectorTeamNumber: '77',
              InspectorName: '点検者氏名',
              InspectorNameKana: '点検者氏名（カナ）'
            },
            {
              InspectorLeaderName: '点検者班長名',
              InspectorLeaderNameKana: '点検者氏名（カナ）',
              InspectorTeamNumber: '77',
              InspectorName: '点検者氏名',
              InspectorNameKana: '点検者氏名（カナ）'
            },
            {
              InspectorLeaderName: '点検者班長名',
              InspectorLeaderNameKana: '点検者氏名（カナ）',
              InspectorTeamNumber: '77',
              InspectorName: '点検者氏名',
              InspectorNameKana: '点検者氏名（カナ）'
            },
            {
              InspectorLeaderName: '点検者班長名',
              InspectorLeaderNameKana: '点検者氏名（カナ）',
              InspectorTeamNumber: '77',
              InspectorName: '点検者氏名',
              InspectorNameKana: '点検者氏名（カナ）'
            }
          ]
        }
      ]
    }
  }
}