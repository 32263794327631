import { mapState, mapGetters,  mapActions} from 'vuex'

export default {
  name: 'Header',
  computed: {
    ...mapState({
      authorized: state => state.auth.authorized,
      requireChangePassword: state => state.auth.requireChangePassword,
      userRole: state => state.auth.userRole,
      activeAccountBox: state => state.accountBox.active
    }),
    ...mapGetters({
      userName: 'auth/userName',
      userRoleList: 'auth/userRoleList',
      isModerator: 'auth/isModerator',
      isInspector: 'auth/isInspector',
      isObserver: 'auth/isObserver'
    }),
    userRoles () {
      return this.userRoleList.join('、')
    },
    showNavi () {
      return this.authorized && !this.requireChangePassword
    },
    showDebug () {
      return window._API_.mode === 'stub'
    }
  },
  methods: {
    ...mapActions({
      signOut: 'auth/signOut',
      signIn: 'auth/signIn',
      showAccountBox: 'accountBox/show',
      hideAccountBox: 'accountBox/hide'
    }),
    clickSignOut () {
      this.signOut()
        .then(() => {
          // window.refresh()
          this.$router.push({name: 'Signin'})
          // this.$router.go({path: this.$router.currentRoute.path, force: true})
        })
    },
    toggleAccountBox () {
      this.activeAccountBox ?
        this.hideAccountBox() : this.showAccountBox()
    },
    clickDebug (role) {
      this.signOut()
        .then(() => {
          this.signIn({
            loginId: role,
            password: 'password'
          })
            .then(res => {
              console.log('change role', res)
            })
        })
    }
  }
}