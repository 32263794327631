import { mapState, mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import Loading from '@/components/Loading'
import Constants from '@/utils/Constants'
import Utility from '@/utils/Utility'

export default {
  name: 'Event',
  components: {
    Loading
  },
  metaInfo () {
    this.setMeta({
      title: 'イベント一覧 | ' + Constants.SiteInfo.title
    })
    return this.getMeta
  },
  data () {
    return {
      initLoaded: false,
      showLoading: false,
      // テーブル絞り込み
      // filterAllEvents: 'false',
      filterMyEvents: 'true',
      registerHash: Constants.Params.RegisterHash,
      // イベント一覧テーブル
      tableColumnsModerator: [
        'EventId',
        'CreatedAt',
        'EventName',
        'EarthquakesInfo',
        'ModeratorName',
        'modifyButton'
      ],
      tableColumnsObserver: [
        'EventId',
        'CreatedAt',
        'EventName',
        'EarthquakesInfo',
        'ModeratorName'
      ],
      tableData: [],
      tableOptions: {
        headings: {
          EventId: 'ID',
          CreatedAt: '作成日',
          EventName: 'イベント名称',
          EarthquakesInfo: '地震情報',
          ModeratorName: '担当管理者',
          modifyButton: ''
        },
        columnsClasses: {
          EventId: 'wp-10',
          CreatedAt: 'wp-20',
          EventName: '',
          EarthquakesInfo: 'wp-8 text-center',
          ModeratorName: 'wp-20',
          modifyButton: 'wp-8 text-center'
        },
        sortable: [],
        filterable: false,
        texts: Constants.TableOptions.getTexts('イベント'),
        perPage: Constants.TableOptions.perPage,
        perPageValues: Constants.TableOptions.perPageValues,
        pagination: Constants.TableOptions.pagination,
        resizableColumns: false,
      },
      // 地震速報テーブル
      quakeTableColumns: [
        'EqId',
        'OriginTime',
        'HypoAreaName',
        'MaxInt',
        'projectButton'
      ],
      quakeTableData: [],
      quakeTableOptions: {
        headings: {
          EqId: '地震ID',
          OriginTime: '発生時刻',
          HypoAreaName: '震源地',
          MaxInt: '震度',
          projectButton: ''
        },
        columnsClasses: {
          EqId: 'wp-10',
          OriginTime: 'wp-25',
          HypoAreaName: '',
          MaxInt: 'wp-10',
          projectButton: 'wp-10 text-center'
        },
        sortable: [],
        filterable: false,
        texts: Constants.TableOptions.getTexts('地震情報'),
        perPage: Constants.TableOptions.perPage,
        perPageValues: Constants.TableOptions.perPageValues,
        pagination: {
          show: false
        },
        resizableColumns: false,
      },
      // 絞込み
      showAll: 'true'
    }
  },
  mounted () {
    if (!this.isModerator) {
      this.filterMyEvents = 'false'
    }
    this.init()
  },
  computed: {
    ...mapState({
      eventModel: state => state.event.eventModel,
      quakeModel: state => state.event.quakeModel
    }),
    ...mapGetters({
      getMeta: 'meta/get',
      isModerator: 'auth/isModerator',
      userIdentity: 'auth/userIdentity'
    }),
    showResult () {
      return !this.showLoading && this.tableData.length > 0
    },
    tableColumns () {
      return this.isModerator ? this.tableColumnsModerator : this.tableColumnsObserver
    },
    // 地震速報
    quakeItem () {
      return this.quakeModel
    },
    showQuakes () {
      return this.initLoaded && this.quakeItem.length > 0
    }
  },
  watch: {
    filterMyEvents () {
      this.loadEvents()
    }
  },
  methods: {
    ...mapActions({
      setMeta: 'meta/set',
      initLoad: 'event/initLoad',
      loadEventList: 'event/loadEventList',
      showError: 'error/show',
      showEqModal: 'eqModal/show',
      showEqListModal: 'eqListModal/show',
      initQuake: 'eventForm/initQuake'
    }),
    init () {
      // イベント一覧の絞込み条件
      let eventParameters = {}
      if (this.filterMyEvents === 'true') eventParameters.moderator = this.userIdentity

      // 地震速報の絞込み条件
      let quakeParameters = {}
      let date = moment()
      quakeParameters.originDtTo = date.format()
      // quakeParameters.originDtFrom = date.add(-3, 'days').format()
      quakeParameters.originDtFrom = date.add(-7, 'days').format()
      quakeParameters.maxIntLower = 3

      // データ読出し
      this.showLoading = true
      this.initLoad({
        eventParameters: eventParameters,
        quakeParameters: quakeParameters
      })
        .then(() => {
          this.tableData = $.extend(true, [], this.eventModel)
          this.quakeTableData = $.extend(true, [], this.quakeItem)
          this.showLoading = false
          this.initLoaded = true
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
        })
    },
    loadEvents () {
      let parameters = {}
      if (this.filterMyEvents === 'true') parameters.moderator = this.userIdentity

      // データ読出し
      this.showLoading = true
      this.loadEventList(parameters)
        .then(() => {
          this.tableData = $.extend(true, [], this.eventModel)
          this.quakeTableData = $.extend(true, [], this.quakeItem)
          this.showLoading = false
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
        })
    },
    getViewData (props) {
      let key = props.column
      let value = props.row[key]
      
      if (key === 'EventId') return Utility.getEventIdZeroPadding(value)
      if (key === 'CreatedAt') return Utility.getJSTDate(value, 'YYYY/MM/DD')
      // 地震速報
      // if (key === 'OriginTime') return moment(value).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mmごろ')
      if (key === 'EarthquakesInfo') {
        if (value.length) {
          return 'あり'
        } else {
          return '--'
        }
      }
      if (key === 'OriginTime') return Utility.getJSTDate(value, 'YYYY/MM/DD HH:mmごろ')
      return value
    },
    changePage () {
      // console.log(this.$refs.table.page)
    },
    clickEq (props) {
      this.showEqModal({
        id: props.row.EqId,
        model: $.extend(true, {}, props.row)
      })
    },
    disabledEqList (props) {
      return !props.row.EarthquakesInfo.length
    },
    clickEqList (props) {
      this.showEqListModal({
        id: 'event' + props.row.EventId,
        eventName: props.row.EventName,
        model: $.extend(true, [], props.row.EarthquakesInfo)
      })
    },
    createEventProjects (eqId) {
      this.initQuake(eqId)
      this.$router.push({
        name: 'EventRegister',
        params: {
          eventId: this.registerHash
        }
      })
    }
  }
}