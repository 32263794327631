export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      User: {
        UserIdentity: 'identity id',
        Role: 8,
        TenantIdentity: 'xxxxx',
        TenantName: 'tenant name'
      },
      Assets: [
        {
          AssetId: 101,
          TenantIdentity: '36ab6155cb5623d4565ec58f48464c62',
          Name: '岩戸大橋',
          Prefecture: '44',
          City: '竹田市',
          Address: '大分県直入郡荻町大字柏原（竹田市）',
          Latitude: 32.91944444,
          Longitude: 131.29527777,
          Latitude_60_degree: 32,
          Latitude_60_minutes: 55,
          Latitude_60_seconds: 10,
          Longitude_60_degree: 131,
          Longitude_60_minutes: 17,
          Longitude_60_seconds: 43,
          LocationId: '13',
          OwnerName: '大分県竹田土木',
          LineName: '九重野荻線',
          CompletionYear: '2002',
          CompletionMonth: '10',
          Constructor: '施工会社名',
          BridgeAdmin: '橋梁管理者',
          RoadAdmin: '道路管理者',
          OfficeContact: '連絡先',
          OfficeName: '事業所名',
          BridgeForm: '単純鈑桁橋 単純下路式ﾄﾗｽﾄﾞﾗﾝｶﾞｰ橋 単純鈑桁橋',
          Form1: '形式1',
          Form2: 'アーチ橋',
          Form3: '形式3',
          Length: '42/98/32',
          Spanner: '40.8/96.3/30.9',
          Width: '11',
          FallPreventionEq: 2,
          Figure1: '橋梁一般図 S3 Object Key',
          Figure2: '図面2  S3 Object Key',
          Note: '単純鈑桁橋×２連',
          LiveFlg: true,
          CreatedAt: '2019-09-02',
          UpdatedAt: '2019-10-01',
          LastProjectId: 1,
          LatestProjectPhotoCount: 10,
          ProfilePhotoThumbUrl: 'プロファイル写真THUMB URL',
          ProfilePhotoFileObjectKey: 'プロファイル写真S３ OBJECT KEY'
        },
        {
          AssetId: 102,
          TenantIdentity: '36ab6155cb5623d4565ec58f48464c62',
          Name: '合ヶ瀬大橋',
          Prefecture: '44',
          City: '竹田市',
          Address: '大分県直入郡荻町大字柏原（竹田市）',
          Latitude: 32.89499999,
          Longitude: 131.30138888,
          Latitude_60_degree: 32,
          Latitude_60_minutes: 53,
          Latitude_60_seconds: 42,
          Longitude_60_degree: 131,
          Longitude_60_minutes: 18,
          Longitude_60_seconds: 5,
          LocationId: '13',
          OwnerName: '大分県竹田土木',
          LineName: '県道695号',
          CompletionYear: '1981',
          CompletionMonth: '',
          Constructor: '施工会社名',
          BridgeAdmin: '橋梁管理者',
          RoadAdmin: '道路管理者',
          OfficeContact: '連絡先',
          OfficeName: '事業所名',
          BridgeForm: '3径間連続上路トラス橋',
          Form1: '形式1',
          Form2: 'トラス橋',
          Form3: '形式3',
          Length: '213.65',
          Spanner: '62.5+87.5+62.5',
          Width: '7.5',
          FallPreventionEq: 0,
          Figure1: '橋梁一般図 S3 Object Key',
          Figure2: '図面2  S3 Object Key',
          Note: '備考テキスト\n備考テキスト',
          LiveFlg: true,
          CreatedAt: '2019-09-02',
          UpdatedAt: '2019-10-01',
          LastProjectId: 0,
          LatestProjectPhotoCount: 0,
          ProfilePhotoThumbUrl: '',
          ProfilePhotoFileObjectKey: ''
        },
        {
          AssetId: 103,
          TenantIdentity: '36ab6155cb5623d4565ec58f48464c62',
          Name: '岸野下橋',
          Prefecture: '43',
          City: '南阿蘇村',
          Address: '熊本県阿蘇郡南阿蘇村河陰',
          Latitude: 32.84194444,
          Longitude: 131.00916666,
          Latitude_60_degree: 32,
          Latitude_60_minutes: 50,
          Latitude_60_seconds: 31,
          Longitude_60_degree: 131,
          Longitude_60_minutes: 0,
          Longitude_60_seconds: 33,
          LocationId: '13',
          OwnerName: '熊本県南阿蘇村',
          LineName: '-',
          CompletionYear: '2014',
          CompletionMonth: '',
          Constructor: 'エム・エムブリッジ㈱',
          BridgeAdmin: '森林農地センター',
          RoadAdmin: '道路管理者',
          OfficeContact: '連絡先',
          OfficeName: '事業所名',
          BridgeForm: '2径間連続鋼床版鈑桁橋',
          Form1: '鋼床版',
          Form2: '連続鈑桁',
          Form3: '形式3',
          Length: '63.6',
          Spanner: '31.3+31.3',
          Width: '4.1',
          FallPreventionEq: 2,
          Figure1: '橋梁一般図 S3 Object Key',
          Figure2: '図面2  S3 Object Key',
          Note: '備考テキスト\n備考テキスト',
          LiveFlg: true,
          CreatedAt: '2019-09-02',
          UpdatedAt: '2019-10-01',
          LastProjectId: 2,
          LatestProjectPhotoCount: 8,
          ProfilePhotoThumbUrl: 'プロファイル写真THUMB URL',
          ProfilePhotoFileObjectKey: 'プロファイル写真S３ OBJECT KEY'
        }
      ]
    }
  }
}
