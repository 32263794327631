const state = {
  started: false,
  inited: false,
  google: null
}

const getters = {
  get: (state) => {
    return state
  }
}

const actions = {
  // set ({commit}, google) {
  //   commit('inited', google)
  // },
}

const mutations = {
  setStarted (state, flag) {
    state.started = flag
  },
  setInited (state, flag) {
    state.inited = flag
  },
  setGoogle (state, google) {
    state.google = google
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
