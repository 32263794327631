export default {
  name: 'DeleteSelector',
  props: {
    tableData: Array,
    checkList: Array
  },
  data () {
    return {
      value: false,
      active: false,
      over: false
    }
  },
  computed: {
    show () {
      return this.tableData.length > 0
    },
    disabledRemove () {
      return !this.checkList.length
    }
  },
  methods: {
    change () {
      this.$emit('changeCheck', this.value)
    },
    toggleMenu () {
      this.active = !this.active

      if (this.active) {
        this.$nextTick(() => {
          $(window).off().on({
            mousedown: this.clickWindow
          })
        })

      } else {
        this.over = false
      }
    },
    clickMenu (func) {
      if (func === 'remove' && this.disabledRemove) return
      
      this.$emit('clickFunc', func)
      this.active = false
      this.over = false
    },
    overMenu () {
      this.over = true
    },
    outMenu () {
      this.over = false
    },
    clickWindow () {
      if (!this.over) {
        $(window).off({
          mousedown: this.clickWindow
        })
        this.active = false
        this.over = false
      }
    }
  }
}