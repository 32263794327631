import { mapState, mapGetters, mapActions } from 'vuex'
import { ja } from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import Loading from '@/components/Loading'
import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Validator from '@/utils/Validator'
import Utility from '@/utils/Utility'

export default {
  name: 'ProjectModifyModal',
  components: {
    Datepicker,
    Loading
  },
  mounted () {
    console.log(this.id)
    // // モーダル表示時の処理
    // $('#' + this.id).on('show.bs.modal', () => {

    // })

    // モーダル非表示時の処理
    $('#' + this.id).on('hide.bs.modal', () => {
      this.hide()
    })
    
    $('#' + this.id).modal('show')

    this.init()
  },
  data () {
    return {
      showLoading: true,
      connecting: false,
      // 入力値
      startDate: null,
      endDate: null,
      company: null,
      inspectorIdentity: null,
      // note: '',
      // date picker option
      startDateOptions: {
        ja: ja,
        typeable: true,
        disabledDates: {
          to: null,
          from: null
        }
      },
      endDateOptions: {
        ja: ja,
        typeable: true,
        disabledDates: {
          to: null,
          from: null
        }
      },
      isClickedConfirm: false,
      isInput: true
    }
  },
  computed: {
    ...mapState({
      active: state => state.projectModifyModal.active,
      id: state => state.projectModifyModal.id,
      projectId: state => state.projectDetail.projectId,
      projectModel: state => state.projectDetail.projectModel,
      companyModel: state => state.projectModifyModal.companyModel,
      inspectorModel: state => state.projectModifyModal.inspectorModel
    }),
    ...mapGetters({
      isModerator: 'auth/isModerator',
      isInspector: 'auth/isInspector'
    }),
    // disable
    disabledInspector () {
      return this.company === null
    },

    // validate
    startDateIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validDate(this.startDate)
    },
    endDateIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validDate(this.endDate)
    },
    companyIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.company)
    },
    inspectorIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.inspectorIdentity)
    },
    formIsValid () {
      if (this.isModerator) {
        return this.startDateIsValid &&
          this.endDateIsValid &&
          this.companyIsValid &&
          this.inspectorIsValid
      } else {
        return this.startDateIsValid &&
          this.endDateIsValid
      }
    },
    disableConfirmButton () {
      if (this.isModerator) {
        return !Validator.validDate(this.startDate) ||
          !Validator.validDate(this.endDate) ||
          !Validator.validText(this.company) ||
          !Validator.validText(this.inspectorIdentity)
      } else {
        return !Validator.validDate(this.startDate) ||
          !Validator.validDate(this.endDate)
      }
    },

    //result
    resultStartDate () {
      return moment(this.startDate).format('YYYY年MM月DD日')
    },
    resultEndDate () {
      return moment(this.endDate).format('YYYY年MM月DD日')
    },
    resultInspector () {
      try {
        return this.inspectorModel.filter(item => item.UserIdentity === this.inspectorIdentity)[0].Name
      } catch (e) {
        return ''
      }
    }
  },
  watch: {
    company: function (to) {
      if (to) {
        // 点検者をリセット
        this.inspectorIdentity = null

        // 点検者一覧を取得
        this.loadInspectorList({
          company: to
        })
          .catch(error => {
            // API エラー
            if (error.Result === Constants.ApiResult.Failure) {
              this.showError(error)
              
            // システムエラー
            } else {
              this.showError(null)
            }
          })
      }
    }
  },
  methods: {
    ...mapActions({
      hide: 'projectModifyModal/hide',
      showAlert: 'alert/show',
      initLoad: 'projectModifyModal/initLoad',
      loadInspectorList: 'projectModifyModal/loadInspectorList',
      loadProjectModel: 'projectDetail/loadProjectModel'
    }),
    clickClose () {
      // this.$emit('childs-event', {
      //   accept: true
      // })
      $('#' + this.id).modal('hide')
    },
    init () {
      console.log('INIT projectModel', this.projectModel)
      // 確認画面から戻ったときに入力値を挿入
      if (this.projectModel.StartDatePlan) this.startDate = Utility.getDateObjectByYYYYMMDD(this.projectModel.StartDatePlan)
      if (this.projectModel.EndDatePlan) this.endDate = Utility.getDateObjectByYYYYMMDD(this.projectModel.EndDatePlan)
      if (this.projectModel.InspectorCompanyName) this.company = this.projectModel.InspectorCompanyName
      if (this.projectModel.InspectorIdentity) this.inspectorIdentity = this.projectModel.InspectorIdentity
      // if (this.projectModel.note) this.note = this.projectModel.note

      // データ読出し
      this.showLoading = true
      this.initLoad()
        .then(() => {
          this.showLoading = false

          if (this.projectModel.InspectorIdentity)
            this.inspectorIdentity = this.projectModel.InspectorIdentity
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
        })
    },
    changeStartDate (value) {
      // console.log('changeStartDate', value)
      let dateObj = new Date(value)
      this.startDate = new Date(dateObj.setHours(12,0,0,0))
      this.endDateOptions.disabledDates.to = moment(this.startDate).toDate()
    },
    changeEndDate (value) {
      // console.log('changeEndDate', value)
      let dateObj = new Date(value)
      this.endDate = new Date(dateObj.setHours(12,0,0,0))
      this.startDateOptions.disabledDates.from = moment(this.endDate).toDate()
    },
    clickConfirm () {
      this.isClickedConfirm = true

      // validate
      if (this.formIsValid) {
        // 確認画面表示
        if (this.isInput) {
          this.isInput = false

        } else {
          this.connecting = true
          
          let parameters = {
            ProjectData: {
              ProjectId: this.projectId,
              StartDatePlan: moment(this.startDate).format('YYYY/MM/DD'),
              EndDatePlan: moment(this.endDate).format('YYYY/MM/DD')
            }
          }
          if (this.isModerator &&
            this.projectModel.InspectorCompanyName !== this.company) {
            parameters.ProjectData.InspectorCompanyName = this.company
          }
          if (this.isModerator &&
            this.projectModel.InspectorIdentity !== this.inspectorIdentity) {
            parameters.ProjectData.Inspector = this.inspectorIdentity
            parameters.ProjectData.TaskResetFlg = 1
          }
          console.log('register data', parameters)

          // プロジェクト登録
          ApiManager.request(
            ['project'],
            ['v1ProjectUpdate'],
            [parameters])
            .then(res => {
              // console.log('v1ProjectPost', res)
              if (res[0].data.Result === Constants.ApiResult.Success) {
                console.log(99999)
                this.loadProjectModel()
                  .then(() => {
                    this.connecting = false
                    this.clickClose()
                  })

              // API エラー
              } else if (res[0].data.Result === Constants.ApiResult.Failure) {
                this.connecting = false
                this.showError(res[0].data)

              // システムエラー
              } else {
                this.connecting = false
                this.showError(null)
              }
            })
            .catch(() => {
              this.showError(null)
              this.connecting = false
            })
        }
      }
    },
    clickBack () {
      this.isInput = true
    }
  }
}