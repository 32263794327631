import { mapState, mapActions } from 'vuex'

export default {
  name: 'ExamplesModal',
  mounted () {
    // // モーダル表示時の処理
    // $('#' + this.id).on('show.bs.modal', () => {

    // })

    // モーダル非表示時の処理
    $('#' + this.id).on('hide.bs.modal', () => {
      this.hide()
    })
    
    $('#' + this.id).modal('show')
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapState({
      active: state => state.examplesModal.active,
      id: state => state.examplesModal.id
    })
  },
  methods: {
    ...mapActions({
      hide: 'examplesModal/hide'
    }),
    clickClose () {
      // this.$emit('childs-event', {
      //   accept: true
      // })
      $('#' + this.id).modal('hide')
    }
  }
}