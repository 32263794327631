export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      Assets: [
        {
          AssetId: 103,
          TenantIdentity: '36ab6155cb5623d4565ec58f48464c62',
          Name: '岸野下橋',
          Prefecture: '43',
          City: '南阿蘇村',
          Address: '熊本県阿蘇郡南阿蘇村河陰',
          Latitude: 32.84194444,
          Longitude: 131.00916666,
          Latitude_60_degree: 32,
          Latitude_60_minutes: 50,
          Latitude_60_seconds: 31,
          Longitude_60_degree: 131,
          Longitude_60_minutes: 0,
          Longitude_60_seconds: 33,
          LocationId: '13',
          OwnerName: '熊本県南阿蘇村',
          LineName: '-',
          CompletionYear: '2014',
          CompletionMonth: '',
          Constructor: 'エム・エムブリッジ㈱',
          BridgeAdmin: '森林農地センター',
          RoadAdmin: '道路管理者',
          OfficeContact: '連絡先',
          OfficeName: '事業所名',
          BridgeForm: '2径間連続鋼床版鈑桁橋',
          Form1: '鋼床版',
          Form2: '連続鈑桁',
          Form3: '形式3',
          Length: '63.6',
          Spanner: '31.3+31.3',
          Width: '4.1',
          FallPreventionEq: 2,
          Figure1: '橋梁一般図 S3 Object Key',
          Figure2: '図面2  S3 Object Key',
          Note: '備考テキスト\n備考テキスト',
          LiveFlg: true,
          CreatedAt: '2019-09-02',
          UpdatedAt: '2019-10-01',
          LastProjectId: 2,
          LatestProjectPhotoCount: 8,
          ProfilePhotoThumbUrl: 'プロファイル写真THUMB URL',
          ProfilePhotoFileObjectKey: 'プロファイル写真S３ OBJECT KEY'
        }
      ]
    }
  }
}
