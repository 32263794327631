import Vue from 'vue'
import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Utility from '@/utils/Utility'

const state = {
  // model
  checkReportModel: {},
  photoModel: [],
  photoCount: 0,

  // value
  checkDateValue: '',
  checkDateWeatherValue: '',
  // 判定結果
  loadCapacityValue: '',
  runnabilityValue: '',
  agingValue: '',

  // 被災度
  // views/Project/Detail/Task4」で定義
  damageTabId: 'loadCapacity',
  // keyは「components/Project/Task4/LoadCapacity」で定義
  damageValue: {
    // 耐荷力に関する調査 -----
    // 基礎
    Foundation: {
      exists: true,
      section: false,
      loadCapacity: '',
      aging: '',
      comment: '',
      photoIds: []
    },
    // 橋台
    Pier: {
      exists: true,
      section: false,
      loadCapacity: '',
      aging: '',
      comment: '',
      photoIds: []
    },
    // 橋脚（RC・鉄鋼）
    Bent: {
      exists: true,
      section: false,
      loadCapacity: '',
      aging: '',
      comment: '',
      photoIds: []
    },
    // 主構造（上部工）
    Main: {
      exists: true,
      section: false,
      loadCapacity: '',
      aging: '',
      comment: '',
      photoIds: []
    },
    // 支承部
    Support: {
      exists: true,
      section: false,
      loadCapacity: '',
      aging: '',
      comment: '',
      photoIds: []
    },
    // 床版（下面）
    Floor: {
      exists: true,
      section: false,
      loadCapacity: '',
      aging: '',
      comment: '',
      photoIds: []
    },

    // 走行性に関する調査 -----
    // 伸縮装置
    Expantion: {
      exists: true,
      section: false,
      runnability: '',
      aging: '',
      comment: '',
      photoIds: []
    },
    // 取付盛土(沈下)
    Banking: {
      exists: true,
      section: false,
      runnability: '',
      aging: '',
      comment: '',
      photoIds: []
    },
    // 車両用防護柵
    Fence: {
      exists: true,
      section: false,
      runnability: '',
      aging: '',
      comment: '',
      photoIds: []
    },
    // 路面
    Road: {
      exists: true,
      section: false,
      runnability: '',
      aging: '',
      comment: '',
      photoIds: []
    },
    // 標識付属物
    Sign: {
      exists: true,
      section: false,
      runnability: '',
      aging: '',
      comment: '',
      photoIds: []
    },
  },

  // 落橋防止システムの状態
  // views/Project/Detail/Task4」で定義
  statusTabId: 'Sideblock',
  // keyは「components/Project/Task4/LoadCapacity」で定義
  statusValue: {
    // 支承サイドブロック
    Sideblock: {
      damage: '',
      comment: '',
      photoIds: []
    },
    // 変位制限構造
    Restricting: {
      damage: '',
      comment: '',
      photoIds: []
    },
    // ダンパー
    Damper: {
      damage: '',
      comment: '',
      photoIds: []
    },
    // 落橋防止装置
    Prevention: {
      damage: '',
      comment: '',
      photoIds: []
    },
    // その他
    Others: {
      damage: '',
      comment: '',
      photoIds: []
    },
    // 下部工
    Opinion: {
      damage: '',
      comment: '',
      photoIds: []
    },
  },

  // 管理者へのコメント
  moderatorCommentValue: '',
  inspectorCommentValue: ''
}

const getters = {
  get: state => state,
  getPhotoModel: state => takenPhotoId => state.photoModel.filter(item => {
    return item.TakenPhotoId === Number(takenPhotoId)
  }),
  isDamageValue: state => sectionKey => state.damageValue[sectionKey] !== undefined,
  isStatusValue: state => sectionKey => state.statusValue[sectionKey] !== undefined,
  getDamageValue: state => sectionKey => state.damageValue[sectionKey],
  getStatusValue: state => sectionKey => state.statusValue[sectionKey],
  isModify: state => state.checkReportModel.CheckReportId !== undefined,

  // 写真が登録されている写真データを返す
  enablePhotoModel: state => 
    state.photoModel.filter(item => item.ProjectPhotoNumber !== null &&
      item.FileObjectKey !== null),
    // state.photoModel.filter(item => item.ProjectPhotoNumber !== null),
  // プリセット項目のデータを返す
  presetData: state => targetView => {
    if (state.photoModel.length === 0) return {};

    const index = state.photoModel
      .findIndex(item => item.TargetView === targetView &&
        item.Type === 1)
    return index === -1
      ? {}
      : state.photoModel[index];
  },
}

const actions = {
  /**
   * 初期読み出し
   */
  initLoad ({commit}, parameters) {
    return new Promise((resolve, reject) => {
      // 点検・調査レポート／撮影写真一覧を読出し
      ApiManager.request(
        ['project'],
        ['v1ProjectCheckReportListGet'],
        [parameters])
        .then(res => {
          // チェックレポート
          if (res[0].data.Result === Constants.ApiResult.Success &&
            res[0].data.CheckReports.length) {
            // model
            commit('setCheckReportModel', res[0].data.CheckReports[0])
            // value
            this.dispatch('projectTask4/copyCheckReportModel')
          }

          // // 撮影写真一覧
          // if (res[1].data.Result === Constants.ApiResult.Success) {
          //   // model
          //   commit('setPhotoModel', res[1].data.TakenPhotos)
          //   commit('setValue', {
          //     key: 'photoCount',
          //     value: res[1].data.PhotoCount || 0
          //   })
          // }

          // 結果
          if (res[0].data.Result === Constants.ApiResult.Success) {

            // 写真一覧を取得
            this.dispatch('projectTask4/loadPhoto', {
              ProjectId: parameters.ProjectId
            })
              .then(res => {

                // コメント一覧を取得
                this.dispatch('projectDetail/loadComments', {
                  ProjectId: parameters.ProjectId
                })
                  .then(res => {
                    resolve(res)
                  })
                  .catch(error => {
                    reject(error)
                  })

              })
              .catch(error => {
                reject(error)
              })

          } else {
            reject(res[0].data)
            // let index = res[0].data.Result === Constants.ApiResult.Failure ?
            //   0 : 1
            // reject(res[index].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  /**
   * 調査・点検レポートを読出し
   */
  loadCheckReport ({commit}, parameters) {
    return new Promise((resolve, reject) => {
      // プロジェクト一覧を読出し
      ApiManager.request(
        ['project'],
        ['v1ProjectCheckReportListGet'],
        [parameters])
        .then(res => {
          console.log('v1ProjectCheckReportListGet', res)
          if (res[0].data.Result === Constants.ApiResult.Success) {
            if (res[0].data.CheckReports.length) {
              // model
              commit('setCheckReportModel', res[0].data.CheckReports[0])
              // value
              this.dispatch('projectTask4/copyCheckReportModel')
            }
            resolve()

          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          console.log('v1ProjectCheckReportListGet', error)
          reject(error)
        })
    })
  },
  /**
   * 調査・点検レポートのmodelをvalueに上書きコピー
   * 初期読出し、削除時に実行
   */
  copyCheckReportModel ({commit}) {
    // value
    // let getDateString = value => {
    //   return value.substr(0, 4) + '-' +
    //     value.substr(4, 2) + '-' +
    //     value.substr(6, 2)
    // }
    commit('setValue', {
      key: 'checkDateValue',
      value: state.checkReportModel.CheckDate ?
        Utility.getDateObjectByYYYYMMDD(state.checkReportModel.CheckDate) : ''
        // new Date(getDateString(state.checkReportModel.CheckDate)) : ''
    })
    commit('setValue', {
      key: 'checkDateWeatherValue',
      value: state.checkReportModel.CheckDayWeather || ''
    })
    commit('setValue', {
      key: 'loadCapacityValue',
      value: state.checkReportModel.LoadCapacity || ''
    })
    commit('setValue', {
      key: 'runnabilityValue',
      value: state.checkReportModel.Runnability || ''
    })
    commit('setValue', {
      key: 'agingValue',
      value: state.checkReportModel.Aging || ''
    })

    // 耐荷力に関する調査
    commit('initDamageValue', 'Foundation')
    commit('initDamageValue', 'Pier')
    commit('initDamageValue', 'Bent')
    commit('initDamageValue', 'Main')
    commit('initDamageValue', 'Support')
    commit('initDamageValue', 'Floor')

    // 走行性に関する調査
    commit('initDamageValue', 'Expantion')
    commit('initDamageValue', 'Banking')
    commit('initDamageValue', 'Fence')
    commit('initDamageValue', 'Road')
    commit('initDamageValue', 'Sign')

    // 落橋防止システムの状態
    commit('initStatusValue', 'Sideblock')
    commit('initStatusValue', 'Restricting')
    commit('initStatusValue', 'Damper')
    commit('initStatusValue', 'Prevention')
    commit('initStatusValue', 'Others')
    commit('initStatusValue', 'Opinion')
  },
  /**
   * 調査・点検レポートを作成する
   */
  // eslint-disable-next-line
  saveCheckReport ({commit, getters}, parameters) {
    return new Promise((resolve, reject) => {
      let methods = getters.isModify ?
        'v1ProjectCheckReportUpdate' : 'v1ProjectCheckReportPost'
      ApiManager.request(
        ['project'],
        [methods],
        [parameters])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            resolve()

          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  /**
   * 調査日を設定
   */
  setCheckDate ({commit}, value) {
    commit('setValue', {
      key: 'checkDateValue',
      value: value
    })
  },
  /**
   * 調査日天候を設定
   */
  setCheckDateWeather ({commit}, value) {
    commit('setValue', {
      key: 'checkDateWeatherValue',
      value: value
    })
  },
  /**
   * 耐荷力を設定
   */
  setLoadCapacity ({commit}, value) {
    commit('setValue', {
      key: 'loadCapacityValue',
      value: value
    })
  },
  /**
   * 走行力を設定
   */
  setRunnability ({commit}, value) {
    commit('setValue', {
      key: 'runnabilityValue',
      value: value
    })
  },
  /**
   * 経年劣化を設定
   */
  setAging ({commit}, value) {
    commit('setValue', {
      key: 'agingValue',
      value: value
    })
  },

  /**
   * -----------------------------------------
   * 被災度タブ表示設定
   */
  setDamageTab ({commit}, tabId) {
    commit('setValue', {
      key: 'damageTabId',
      value: tabId
    })
  },
  /**
   * 項目値を設定
   */
  setDamageParam ({commit}, {sectionKey, key, value}) {
    commit('setDamageValueParam', {
      sectionKey: sectionKey,
      key: key,
      value: value
    })

    // 耐荷力・走行性・経年劣化は履いた処理を行う
    let keyList = ['loadCapacity', 'runnability', 'aging']
    if (keyList.indexOf(key) !== -1) {
      keyList.splice(keyList.indexOf(key), 1)
      keyList.forEach(item => {
        commit('setDamageValueParam', {
          sectionKey: sectionKey,
          key: item,
          value: ''
        })
      })
    }
  },
  /**
   * 添付写真を追加
   */
  // eslint-disable-next-line
  addDamagePhotoId ({commit}, {sectionKey, takenPhotoId}) {
    if (state.damageValue[sectionKey].photoIds
      .indexOf(takenPhotoId) !== -1) return

    state.damageValue[sectionKey].photoIds.push(takenPhotoId)
  },
  /**
   * 添付写真を削除
   */
  // eslint-disable-next-line
  removeDamagePhotoId ({commit}, {sectionKey, takenPhotoId}) {
    let index = state.damageValue[sectionKey].photoIds.findIndex(item => item === takenPhotoId)
    if (index !== -1) state.damageValue[sectionKey].photoIds.splice(index, 1)
  },

  /**
   * -----------------------------------------
   * 落橋防止システムの状態タブ表示設定
   */
  setStatusTab ({commit}, tabId) {
    commit('setValue', {
      key: 'statusTabId',
      value: tabId
    })
  },
  /**
   * 項目値を設定
   */
  setStatusParam ({commit}, {sectionKey, key, value}) {
    commit('setStatusValueParam', {
      sectionKey: sectionKey,
      key: key,
      value: value
    })
  },
  /**
   * 添付写真を追加
   */
  // eslint-disable-next-line
  addStatusPhotoId ({commit}, {sectionKey, takenPhotoId}) {
    if (state.statusValue[sectionKey].photoIds
      .indexOf(takenPhotoId) !== -1) return

    state.statusValue[sectionKey].photoIds.push(takenPhotoId)
  },
  /**
   * 添付写真を削除
   */
  // eslint-disable-next-line
  removeStatusPhotoId ({commit}, {sectionKey, takenPhotoId}) {
    let index = state.statusValue[sectionKey].photoIds.findIndex(item => item === takenPhotoId)
    if (index !== -1) state.statusValue[sectionKey].photoIds.splice(index, 1)
  },

  /**
   * -----------------------------------------
   * 管理者からのコメントを設定する
   */
  setModeratorComment ({commit}, comment) {
    commit('setValue', {
      key: 'moderatorCommentValue',
      value: comment
    })
  },
  /**
   * 点検者からのコメントを設定する
   */
  setInspectorComment ({commit}, comment) {
    commit('setValue', {
      key: 'inspectorCommentValue',
      value: comment
    })
  },

  // 写真のアップロード
  // eslint-disable-next-line
  savePhoto ({commit}, parameterList) {
    // console.log('parameterList', parameterList)
    return new Promise((resolve, reject) => {
      // 10ファイル単位で呼び出し
      let limit = 10
      let count = 0
      let results = []

      // 再帰アップロード
      let upload = () => {
        // console.log('upload ----------- ' + count)
        let types = []
        let methods = []
        let parameters = []

        // パラメータを生成
        parameterList.forEach((item, index) => {
          if (limit * count <= index &&
            index < limit * (count + 1)) {
            types.push('project')
            methods.push('v1ProjectTask4PhotoPost')
            parameters.push({
              ProjectId: this.state.projectDetail.projectModel.ProjectId,
              TakenPhotoData: item
            })
          }
        })

        // API
        ApiManager.request(
          types,
          methods,
          parameters)
          .then(res => {
            results = results.concat(res)
            // console.log(results)
            if (parameterList.length <= ++count * limit) {
              if (results[0].data.Result === Constants.ApiResult.Success) {
                resolve(results)

              } else {
                reject(results[0].data)
              }

            } else {
              upload()
            }
            
          })
          .catch(error => {
            reject(error)
          })
      }
      upload()
    })
  },

  /**
   * Task3/Task4で登録した利用可能な画像を取得する
   */
  loadPhoto ({commit}, parameters) {
    return new Promise((resolve, reject) => {
      const param01 = $.extend(true, {}, parameters)
      const param02 = $.extend(true, {}, parameters)
      // 点検・調査レポート／撮影写真一覧を読出し
      ApiManager.request(
        [
          'project',
          'project',
        ],
        [
          'v1ProjectPhotoListGet',
          'v1ProjectPhotoListGet',
        ],
        [
          $.extend(true, param01, { UseType: 3 }),
          $.extend(true, param02, { UseType: 4 }),
        ])
        .then(res => {
          let data = [];
          let count = 0;

          // Task3 撮影写真一覧を取得
          if (res[0].data.Result === Constants.ApiResult.Success) {
            count += res[0].data.PhotoCount;
            if (res[0].data.PhotoCount > 0) {
              res[0].data.TakenPhotos
                .forEach(item => data.push(item));
            }
          }
          // Task4 撮影写真一覧を取得
          if (res[1].data.Result === Constants.ApiResult.Success) {
            count += res[1].data.PhotoCount;
            if (res[1].data.PhotoCount > 0) {
              res[1].data.TakenPhotos
                .forEach(item => data.push(item));
            }
          }

          // model
          commit('setPhotoModel', data)
          commit('setValue', {
            key: 'photoCount',
            value: count
          })

          resolve();
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  /**
   * 初期化
   */
  reset ({commit}) {
    commit('reset')
  }
}

const mutations = {
  setCheckReportModel (state, data) {
    Vue.set(state, 'checkReportModel', data)
  },
  setPhotoModel (state, data) {
    Vue.set(state, 'photoModel', data)
  },
  setValue (state, {key, value}) {
    Vue.set(state, key, value)
  },

  // 被災度
  initDamageValue (state, id) {
    Vue.set(state.damageValue[id], 'exists', state.checkReportModel[id + 'ExistFlg'] || false)
    Vue.set(state.damageValue[id], 'section', state.checkReportModel[id + 'CheckFlg'] || false)
    if (state.checkReportModel[id + 'DamageLoad'])
      Vue.set(state.damageValue[id], 'loadCapacity', state.checkReportModel[id + 'DamageLoad'])
    if (state.checkReportModel[id + 'DamageRun'])
      Vue.set(state.damageValue[id], 'runnability', state.checkReportModel[id + 'DamageRun'])
    Vue.set(state.damageValue[id], 'aging', state.checkReportModel[id + 'DamageAging'] || '')
    Vue.set(state.damageValue[id], 'comment', state.checkReportModel[id + 'Comment'] || '')
    // 現場写真はObjectをTakenPhotoIdの配列に変更してvalueで保持
    let list = state.checkReportModel[id + 'PhotoIds'] ?
      state.checkReportModel[id + 'PhotoIds'].map(item => item.TakenPhotoId) : []
    Vue.set(state.damageValue[id], 'photoIds', list)
  },
  setDamageValueParam (state, {sectionKey, key, value}) {
    Vue.set(state.damageValue[sectionKey], key, value)
  },

  // 落橋防止システムの状態
  initStatusValue (state, id) {
    let damage = state.checkReportModel[id + 'Damage'] === undefined ?
      '' : state.checkReportModel[id + 'Damage']
    Vue.set(state.statusValue[id], 'damage', damage)
    let commentKey = id === 'Opinion' ? 'Opinion' : id + 'Comment'
    Vue.set(state.statusValue[id], 'comment', state.checkReportModel[commentKey] || '')
    // 現場写真はObjectをTakenPhotoIdの配列に変更してvalueで保持
    let list = state.checkReportModel[id + 'PhotoIds'] ?
      state.checkReportModel[id + 'PhotoIds'].map(item => item.TakenPhotoId) : []
    Vue.set(state.statusValue[id], 'photoIds', list)
  },
  setStatusValueParam (state, {sectionKey, key, value}) {
    Vue.set(state.statusValue[sectionKey], key, value)
  },

  // 初期化
  reset (state) {
    // model
    Vue.set(state, 'checkReportModel', {})
    Vue.set(state, 'photoModel', [])
    state.photoCount = 0

    // value
    state.checkDateValue = ''
    state.checkDateWeatherValue = ''
    // 判定結果
    state.loadCapacityValue = ''
    state.runnabilityValue = ''
    state.agingValue = ''

    // damageValue
    Object.keys(state.damageValue).forEach(item => {
      if (item === 'Foundation' ||
        item === 'Pier' ||
        item === 'Bent' ||
        item === 'Main' ||
        item === 'Support' ||
        item === 'Floor') {

        Vue.set(state.damageValue, item, {
          exists: true,
          section: false,
          loadCapacity: '',
          aging: '',
          comment: '',
          photoIds: []
        })

      } else {
        Vue.set(state.damageValue, item, {
          exists: true,
          section: false,
          runnability: '',
          aging: '',
          comment: '',
          photoIds: []
        })
      }
    })

    // statusValue
    Object.keys(state.statusValue).forEach(item => {
      Vue.set(state.statusValue, item, {
        damage: '',
        comment: '',
        photoIds: []
      })
    })

    // コメント
    state.moderatorCommentValue = ''
    state.inspectorCommentValue = ''
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
