import { mapState, mapActions } from 'vuex'
import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Utility from '@/utils/Utility'
import Loading from '@/components/Loading'

export default {
  name: 'ProjectDeleteModal',
  components: {
    Loading
  },

  data () {
    return {
      showLoading: false,

      // プロジェクト削除が実行された場合、true
      deleted: false,
    }
  },

  mounted () {
    // // モーダル表示時の処理
    // $('#' + this.id).on('show.bs.modal', () => {

    // })

    // モーダル非表示時の処理
    $('#' + this.id).on('hide.bs.modal', () => {
      this.hide(this.deleted)
    })
    
    $('#' + this.id).modal('show')

    console.log(this.model);
  },

  computed: {
    ...mapState({
      active: state => state.projectDeleteModal.active,
      id: state => state.projectDeleteModal.id,
      model: state => state.projectDeleteModal.model
    }),

    identifier () {
      return this.model.EventId + '-' + this.model.ProjectId
    },
    locationId () {
      return this.model.LocationId
    },
    createdAt () {
      return this.model.ProjectCreatedAt
    },
    roadAdmin () {
      return this.model.RoadAdmin
    },
    address () {
      return this.model.Address
    },
    inspectorCompany () {
      return this.model.InspectorCompanyName
    },
    inspector () {
      return this.model.InspectorName
    },
    projectStatus () {
      // ビット演算で一番進んでいるタスクを表示
      return Utility.currentProjectTaskLabel(this.model.ProjectStatus)
    },

    disabledDeleteButton () {
      return false
    }
  },

  methods: {
    ...mapActions({
      showError: 'error/show',
      showAlert: 'alert/show',
      hide: 'projectDeleteModal/hide'
    }),

    clickClose () {
      $('#' + this.id).modal('hide')
    },
    clickAccept () {
      this.showLoading = true

      // 指定プロジェクトを削除
      ApiManager.request(
        ['project'],
        ['v1ProjectDelete'],
        [{
          ProjectIds: [
            this.model.ProjectId
          ]
        }])
        .then(res => {
          if (res[0].data.Results[0].Result === Constants.ApiResult.Success) {
            this.deleted = true;

            // アラート
            this.showAlert({
              key: 'project-delete',
              body: 'プロジェクトを削除しました'
            })

          // API エラー
          } else if (res[0].data.Result === Constants.ApiResult.Failure) {
            this.showError(res[0].data)

          // システムエラー
          } else {
            this.showError(null)
          }
          this.showLoading = false
          this.clickClose();

        })
        .catch(() => {
          this.showError(null)
          this.showLoading = false
          this.clickClose();
        })
    }
  }
}