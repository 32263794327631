import StatusForm from '../StatusForm'

export default {
  name: 'SideBlock',
  components: {
    StatusForm
  },
  data () {
    return {
      sectionKey: 'Sideblock'
    }
  }
}