import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { ja } from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import Constants from '@/utils/Constants'
import Validator from '@/utils/Validator'

export default {
  name: 'ProjectAttribute',
  components: {
    Datepicker
  },
  props: {
    modifyMode: Boolean
  },
  data () {
    return {
      inited: false,
      // 入力値
      startDate: null,
      endDate: null,
      company: null,
      inspectorIdentity: null,
      note: '',
      // date picker option
      startDateOptions: {
        ja: ja,
        typeable: true,
        disabledDates: {
          to: null,
          from: null
        }
      },
      endDateOptions: {
        ja: ja,
        typeable: true,
        disabledDates: {
          to: null,
          from: null
        }
      },
      isClickedConfirm: false
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapState({
      eventId: state => state.projectForm.eventId,
      companyModel: state => state.projectForm.companyModel,
      inspectorModel: state => state.projectForm.inspectorModel,
      values: state => state.projectForm.values,
      // asset
      // selectedAssetId: state => state.projectForm.assetId,
      selectedAssetIds: state => state.projectForm.assetIds,
      // model
      mapModel: state => state.projectRegisterMap.model,
    }),
    ...mapGetters({
      isModerator: 'auth/isModerator',
      isObserver: 'auth/isObserver'
    }),
    googleMapId () {
      return 'projectRegisterMap-' + (new Date()).getTime()
    },
    // asset
    // assetId: {
    //   get () { return this.selectedAssetId },
    //   set (value) { this.setAsset({ id: value }) }
    // },
    assetIds: {
      get () { return this.selectedAssetIds },
      set (value) { this.setAssetIds(value) }
    },
    // disable
    disabledInspector () {
      return this.company === null
    },
    // validate
    startDateIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validDate(this.startDate)
    },
    endDateIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validDate(this.endDate)
    },
    // assetIsValid () {
    //   if (!this.isClickedConfirm) return true
    //   return Validator.validId(this.assetId)
    // },
    assetIdsIsValid () {
      if (!this.isClickedConfirm) return true
      return this.assetIds.length > 0
    },
    companyIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.company)
    },
    inspectorIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.inspectorIdentity)
    },
    noteIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.note)
    },
    formIsValid () {
      // return true
      return this.startDateIsValid &&
        this.endDateIsValid &&
        this.companyIsValid &&
        this.inspectorIsValid
    },
    disableConfirmButton () {
      return !Validator.validDate(this.startDate) ||
        !Validator.validDate(this.endDate) ||
        !Validator.validText(this.company) ||
        !Validator.validText(this.inspectorIdentity)
    }
  },
  watch: {
    company: function (to) {
      if (to) {
        // 点検者をリセット
        this.inspectorIdentity = null

        // 点検者一覧を取得
        this.loadInspectorList({
          company: to
        })
          .then(() => {
            if (this.values.inspector) this.inspectorIdentity = this.values.inspector
          })
          .catch(error => {
            // API エラー
            if (error.Result === Constants.ApiResult.Failure) {
              this.showError(error)
              
            // システムエラー
            } else {
              this.showError(null)
            }
          })
      }
    }
  },
  methods: {
    ...mapActions({
      showAlert: 'alert/show',
      loadInspectorList: 'projectForm/loadInspectorList'
    }),
    ...mapMutations({
      setValues: 'projectForm/setValues',
      // setAsset: 'projectForm/setAsset',
      setAssetIds: 'projectForm/setAssetIds',
    }),
    init () {
      console.log('values', this.values)
      // 確認画面から戻ったときに入力値を挿入
      // if (this.values) this.isClickedConfirm = true
      // if (this.values.projectName) this.projectName = this.values.projectName
      if (this.values.startDate) this.startDate = this.values.startDate
      if (this.values.endDate) this.endDate = this.values.endDate
      // if (this.values.assetId) this.assetId = this.values.assetId
      if (this.values.company) this.company = this.values.company
      if (this.values.inspector) this.inspectorIdentity = this.values.inspector
      if (this.values.note) this.note = this.values.note

      this.inited = true
    },
    changeStartDate (value) {
      // console.log('changeStartDate', value)
      let dateObj = new Date(value)
      this.startDate = new Date(dateObj.setHours(12,0,0,0))
      this.endDateOptions.disabledDates.to = moment(this.startDate).toDate()
    },
    changeEndDate (value) {
      // console.log('changeEndDate', value)
      let dateObj = new Date(value)
      this.endDate = new Date(dateObj.setHours(12,0,0,0))
      this.startDateOptions.disabledDates.from = moment(this.endDate).toDate()
    },
    changeNote (event) {
      // this.input.note = event.target.value.replace(/\n/, '<br>')
      this.note = event.target.value
    },
    clickBack () {
      this.$router.push({
        name: 'ProjectRegister'
      })
    },
    clickConfirm () {
      this.isClickedConfirm = true

      // validate
      if (this.formIsValid) {
        this.setValues({
          startDate: this.startDate,
          endDate: this.endDate,
          // assetId: this.assetId,
          assetIds: this.assetIds,
          company: this.company,
          inspector: this.inspectorIdentity,
          note: this.note,
        })
        console.log(this.values)

        this.$router.push({
          name: 'ProjectRegisterConfirm'
        })
      }
    }
  }
}
