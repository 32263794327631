import { mapState, mapGetters, mapActions } from 'vuex'
import Loading from '@/components/Loading'
import moment from 'moment'
import Constants from '@/utils/Constants'
import Validator from '@/utils/Validator'
import Utility from '@/utils/Utility'
import ApiManager from '@/utils/ApiManager'

export default {
  name: 'ProjectTask2',
  components: {
    Loading
  },
  data () {
    return {
      showLoading: true,
      // flag
      isClickedConfirm: false,
      connecting: false
    }
  },
  mounted () {
    console.log('--- Task2 ---')
    this.init()
  },
  computed: {
    ...mapState({
      // model
      projectModel: state => state.projectDetail.projectModel,
      promptReportModel: state => state.projectTask2.promptReportModel,
      // value
      loadCapacityValue: state => state.projectTask2.loadCapacityValue,
      runnabilityValue: state => state.projectTask2.runnabilityValue,
      agingValue: state => state.projectTask2.agingValue,
      noteValue: state => state.projectTask2.noteValue,
      // confirm
      confirmKey: state => state.confirm.key,
      accepted: state => state.confirm.accept
    }),
    ...mapGetters({
      isModerator: 'auth/isModerator',
      isInspector: 'auth/isInspector',
      isObserver: 'auth/isObserver',
      projectCompleted: 'projectDetail/projectCompleted',
      isModify: 'projectTask2/isModify'
    }),
    
    // detail
    enterDate () {
      return this.promptReportModel.EnterDate ?
        Utility.getJSTDate(this.promptReportModel.EnterDate, 'YYYY/MM/DD') : '-'
    },
    lineName () {
      return this.projectModel.LineName || '-'
    },
    bridgeForm () {
      return this.projectModel.BridgeForm || '-'
    },
    bridgeSize () {
      let length = this.projectModel.BridgeLength ?
        this.projectModel.BridgeLength + 'm' : '-'
      let width = this.projectModel.BridgeWidth ?
        this.projectModel.BridgeWidth + 'm' : '-'
      return length + ' / ' + width
    },
    roadAdmin () {
      return this.projectModel.RoadAdmin || '-'
    },
    roadAdminContact () {
      if (!this.projectModel.OfficeName &&
        !this.projectModel.OfficeContact) return '-'
      if (!this.projectModel.OfficeName) return this.projectModel.OfficeContact
      if (!this.projectModel.OfficeContact) return this.projectModel.OfficeName
      return this.projectModel.OfficeName + '<br>' + this.projectModel.OfficeContact
    },
    moderatorName () {
      return this.projectModel.ModeratorName || '-'
    },
    moderatorContact () {
      let name = this.projectModel.ModeratorDivision || ''
      let phone = this.projectModel.ModeratorPhone1 + '-' +
        this.projectModel.ModeratorPhone2 + '-' +
        this.projectModel.ModeratorPhone3

      if (!name && phone === '--') return '-'
      if (!name) return phone
      if (!phone) return name
      return name + '<br>' + phone
    },

    // result
    loadCapacityResult () {
      return this.loadCapacity || '-'
    },
    runnabilityResult () {
      return this.runnability || '-'
    },
    agingResult () {
      return this.aging || '-'
    },
    noteResult () {
      return this.note ? Utility.decodeBraak(this.note) : '-'
    },

    // 実施者
    showPractitioner () {
      try {
        return !this.disabledDownloadButton
        // return this.projectModel.ProjectJishiInfo.filter(item => item.ProjectStatus === 2).length > 0
      } catch (e) {
        return false
      }
    },
    practiceModel () {
      return this.projectModel.ProjectJishiInfo.filter(item => item.ProjectStatus === 2)[0]
    },
    practiceCompany () {
      return this.practiceModel.InspectorCompanyName || ''
    },
    practiceInspector () {
      return this.practiceModel.InspectorName || ''
    },
    practiceLeader () {
      return this.practiceModel.InspectorLeaderName || ''
    },
    practiceDivision () {
      return this.practiceModel.InspectorDivision || ''
    },
    practicePhone () {
      if (!this.practiceModel.InspectorPhone1 &&
        !this.practiceModel.InspectorPhone2 &&
        !this.practiceModel.InspectorPhone3)
        return ''
      return this.practiceModel.InspectorPhone1 + '-'
        + this.practiceModel.InspectorPhone2 + '-'
        + this.practiceModel.InspectorPhone3
    },

    // 入力値のリスト
    loadCapacityList () {
      return Constants.ProjectConst.LoadCapacity
    },
    runnabilityList () {
      return Constants.ProjectConst.Runnability
    },
    agingList () {
      return Constants.ProjectConst.Aging
    },

    // form
    enableForm () {
      return this.isInspector &&
        !this.projectCompleted
    },
    showRequire () {
      return this.enableForm
    },

    // input
    loadCapacity: {
      get () { return this.loadCapacityValue },
      set (value) {
        this.setValue({
          key: 'loadCapacityValue',
          value: value
        })
      }
    },
    runnability: {
      get () { return this.runnabilityValue },
      set (value) {
        this.setValue({
          key: 'runnabilityValue',
          value: value
        })
      }
    },
    aging: {
      get () { return this.agingValue },
      set (value) {
        this.setValue({
          key: 'agingValue',
          value: value
        })
      }
    },
    note: {
      get () { return this.noteValue },
      set (value) {
        this.setValue({
          key: 'noteValue',
          value: value
        })
      }
    },

    // validate
    loadCapacityIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validSelect(this.loadCapacity)
    },
    runnabilityIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validSelect(this.runnability)
    },
    agingIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validSelect(this.aging)
    },

    // button
    disabledDownloadButton () {
      return this.promptReportModel.PromptReportObjectKey === undefined ||
        this.promptReportModel.PromptReportObjectKey === null
    },
    showSkipButton () {
      if (!this.isInspector) return false
      return this.promptReportModel.PromptReportObjectKey === undefined
    },
    showSaveButton () {
      return this.enableForm
    },
    disabledSaveButton () {
      if (this.connecting) return true

      // 未生成の場合は通常のバリデート
      if (this.promptReportModel.ExistFlg === undefined) {
        return !Validator.validSelect(this.loadCapacity) ||
          !Validator.validSelect(this.runnability) ||
          !Validator.validSelect(this.aging)

      // スキップされている場合、または速報レポートが生成されている場合、登録値から変更されていれば登録可能に
      } else {
        return this.promptReportModel.LoadCapacity === this.loadCapacity &&
          this.promptReportModel.Runnability === this.runnability &&
          this.promptReportModel.Aging === this.aging &&
          this.promptReportModel.Opinion === this.note
      }
    
      // // スキップされている場合、または速報レポートが生成されている場合、登録値から変更されていれば登録可能に
      // if (!this.promptReportModel.ExistFlg ||
      //   this.promptReportModel.PromptReportObjectKey !== undefined) {
      //   console.log(this.promptReportModel.PromptReportObjectKey)
      //   return this.promptReportModel.LoadCapacity === this.loadCapacity &&
      //     this.promptReportModel.Runnability === this.runnability &&
      //     this.promptReportModel.Aging === this.aging &&
      //     this.promptReportModel.Opinion === this.note

      // // 未生成の場合は通常のバリデート
      // } else {
      //   return !Validator.validSelect(this.loadCapacity) ||
      //     !Validator.validSelect(this.runnability) ||
      //     !Validator.validSelect(this.aging)
      // }
    },
  },
  watch: {
    accepted: function (to) {
      if (to) {
        // console.log(this.confirmKey)
        if (this.confirmKey === 'save')
          this.saveTask()
        if (this.confirmKey === 'skip')
          this.skipTask()
      }
    }
  },
  methods: {
    ...mapActions({
      showError: 'error/show',
      showAlert: 'alert/show',
      showConfirm: 'confirm/show',
      // プロジェクトステータス
      changeProjectStatus: 'projectDetail/changeProjectStatus',
      // 判定区分例の説明
      showExamplesModal: 'examplesModal/show',
      // Task2
      loadPromptReport: 'projectTask2/loadPromptReport',
      savePromptReport: 'projectTask2/savePromptReport',
      setValue: 'projectTask2/setValue',
      // 初期化
      reset: 'projectTask2/reset'
    }),
    init () {
      // 初期化
      this.reset()
      this.showLoading = true

      // データ読出し
      this.loadPromptReport({
        ProjectId: this.projectModel.ProjectId
      })
        .then(() => {
          console.log('@promptReportModel', this.promptReportModel)
          this.showLoading = false
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
        })
    },
    /**
     * 被災橋梁の速報ダウンロード
     */
    clickDownload () {
      ApiManager.request(
        ['aws'],
        ['v1AwsPresignedUrlGet'],
        [{
          docType: 7,
          s3ObjectKey: this.promptReportModel.PromptReportObjectKey,
          use: 1
        }])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            let url = res[0].data.S3ObjectInfo.PreSignedUrl
            window.open(url)

          } else if (res[0].data.Result === Constants.ApiResult.Failure) {
            this.showError(res[0].data.Errors)

          } else {
            this.showError(null)
          }
        })
        .catch(() => {
          this.showError(null)
        })
    },
    /**
     * 判定区分例を表示
     */
    clickExamples () {
      this.showExamplesModal({
        id: 'examples-modal'
        // id: 'examples-modal-' + (new Date()).getTime()
      })
    },
    /**
     * プロジェクト一覧に戻る
     */
    clickBack () {
      // 点検者はプロジェクト一覧へ
      if (this.isInspector) {
        this.$router.push({ name: 'Project' })

      // 管理者はイベント詳細へ
      } else {
        this.$router.push({
          name: 'EventDetail',
          params: {
            eventId: String(this.projectModel.EventId)
          }
        })
      }
    },
    /**
     * 被災橋梁検結果を登録
     */
    clickSave () {
      this.isClickedConfirm = true

      this.showConfirm({
        key: 'save',
        body: 'Task2を登録しますか？',
        acceptLabel: '登録',
        denyLabel: 'キャンセル'
      })
    },
    saveTask () {
      this.connecting = true

      // パラメータの整形
      let parameters = {
        ProjectId: this.projectModel.ProjectId,
        PromptReportData: {
          ExistFlg: true,
          CreateReportFlg: true,
          EnterDate: moment().format('YYYYMMDD'),
          LoadCapacity: this.loadCapacity,
          Runnability: this.runnability,
          Aging: this.aging,
        }
      }
      if (this.note) parameters.PromptReportData.Opinion = this.note
      // console.log('isModify', this.isModify)
      console.log('clickSave : ', parameters)

      // 登録
      this.savePromptReport(parameters)
        .then(this.reload)
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }

          this.connecting = false
        })
    },
    /**
     * 速報をスキップ
     */
    clickSkip () {
      this.showConfirm({
        key: 'skip',
        body: 'Task2をスキップしますか？',
        acceptLabel: 'スキップ',
        denyLabel: 'キャンセル'
      })
    },
    skipTask () {
      this.connecting = true
      
      // パラメータの整形
      let parameters = {
        ProjectId: this.projectModel.ProjectId,
        PromptReportData: {
          ExistFlg: false,
          CreateReportFlg: false
        }
      }

      // 登録
      this.savePromptReport(parameters)
        .then(this.reload)
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }

          this.connecting = false
        })
    },
    /**
     * 登録処理後の速報情報の再読み出し
     */
    reload () {
      // データ読出し
      this.loadPromptReport({
        ProjectId: this.projectModel.ProjectId
      })
        .then(() => {
          // プロジェクトステータスを変更
          this.changeProjectStatus({
            code: 'task2'
          })
            .then(() => {
              // アラート
              let message = this.isModify ?
                '速報を変更しました' : '速報を登録しました'
              this.showAlert({
                key: 'prompt-report',
                body: message
              })
              this.connecting = false
              
            })
            .catch(error => {
              // API エラー
              if (error.Result === Constants.ApiResult.Failure) {
                this.showError(error)
                
              // システムエラー
              } else {
                this.showError(null)
              }
              this.connecting = false
            })

        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
          this.connecting = false
        })
    }
  }
} 
