export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      User: {
        UserId: 26,
        UserIdentity: 'ib2c6a69f00ab0e34583ce9b3c58b4a1',
        Token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9kZXYuaW5zcGVjdGlvbi5qaXcuY28uanBcL2FwaVwvdjFcL2xvZ2luXC91c2VyIiwiaWF0IjoxNTgzNzMzNTU1LCJleHAiOjE1ODM4MTk5NTUsIm5iZiI6MTU4MzczMzU1NSwianRpIjoib1B3QVM3Tzd2VUQ2VUpGRSIsInN1YiI6MjYsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.DSktq7i4gWc7DCdop9iGkaz-UP5hlQdIk6OGGbeyXH8',
        TenantId: 10,
        TenantIdentity: '36ab6155cb5623d4565ec58f48464c62',
        TenantName: '株式会社XXXXXXXX',
        Role: 16,
        // 4 : moderator
        // 16 : inspector
        // 64 : observer
        UserName: '点検者 太郎',
        ChangePassword: false,
        TimeStamp: '1583733555',
        CheckStr: '4853f1d764486a1e74d7ed59b1fb0b7c'
      }
    }
  }
}