import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import Constants from '@/utils/Constants'
export default {
  name: 'EventComplete',
  data () {
    return {
      redirectTimer: null
    }
  },
  mounted () {
    this.redirectTimer = setTimeout(() => {
      // 登録時
      if (this.isRegister) {
        // this.$router.push({
        //   name: 'Event'
        // })
        
        // 登録したイベントID、地震IDを取得してプロジェクト作成に遷移
        // this.selectEventId({
        //   eventId: Number(this.eventId)
        // })
        if (this.initialEqId) {
          this.selectEqId({
            eqId: Number(this.initialEqId)
          })
        }
        this.$router.push({
          name: 'ProjectRegister'
        })

      // 変更時
      } else {
        this.$router.push({
          name: 'Event'
        })
      }
      
      this.resetForm()
    }, Constants.Params.CompletedRedirectDelay)
  },
  beforeDestroy () {
    if (this.redirectTimer) {
      clearTimeout(this.redirectTimer)
      this.redirectTimer = null
    }
  },
  computed: {
    ...mapState({
      // eventId: state => state.eventForm.targetId,
      // values: state => state.eventForm.values,
      initialEqId: state => state.eventForm.initialEqId,
    }),
    ...mapGetters({
      isRegister: 'eventForm/isRegister'
    }),
    description () {
      return 'イベントを登録しました。'
    }
  },
  methods: {
    ...mapActions({
      // selectEventId: 'projectForm/selectEventId',
      selectEqId: 'projectForm/selectEqId'
    }),
    ...mapMutations({
      resetForm: 'register/reset'
    })
  }
}
