import { mapState, mapGetters, mapActions } from 'vuex'
import Loading from '@/components/Loading'
import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Utility from '@/utils/Utility'
import CommentHistory from '@/components/Project/CommentHistory'

export default {
  name: 'ProjectTask5',
  components: {
    Loading,
    CommentHistory
  },
  data () {
    return {
      showLoading: true,
      // flag
      isClickedConfirm: false,
      connectingForm: false,
      connectingComment: false,
      // テーブルオプション
      defaultTableColumns: [
        'Name',
        'CreatorName',
        'CreatedAt',
        'downloadButton'
      ],
      moderatorTableColumns: [
        'Name',
        'CreatorName',
        'CreatedAt',
        'renameButton',
        'downloadButton'
      ],
      tableData: [],
      defaultTableOptions: {
        headings: {
          Name: '点検報告書',
          CreatorName: '最終更新者',
          // CreatorName: '報告実施者',
          CreatedAt: '最終更新日',
          renameButton: '',
          downloadButton: ''
        },
        columnsClasses: {
          CreatorName: 'wp-18',
          CreatedAt: 'wp-17',
          renameButton: 'wp-18 text-center',
          downloadButton: 'wp-16 text-center'
        },
        sortable: [
          'Name',
          'CreatedAt'
        ],
        filterable: false,
        texts: Constants.TableOptions.getTexts('報告書'),
        perPage: Constants.TableOptions.perPage,
        perPageValues: Constants.TableOptions.perPageValues,
        pagination: Constants.TableOptions.pagination,
        resizableColumns: false,
      }
    }
  },
  mounted () {
    console.log('--- Task5 ---')
    this.init()
  },
  computed: {
    ...mapState({
      // model
      projectModel: state => state.projectDetail.projectModel,
      commentModel: state => state.projectDetail.commentModel,
      reportModel: state => state.projectTask5.reportModel,
      // rename
      modified: state => state.renameModal.modified,
      // confirm
      accepted: state => state.confirm.accept,
      // 管理者へのコメント
      moderatorCommentValue: state => state.projectTask5.moderatorCommentValue,
      inspectorCommentValue: state => state.projectTask5.inspectorCommentValue
    }),
    ...mapGetters({
      isModerator: 'auth/isModerator',
      isInspector: 'auth/isInspector',
      isObserver: 'auth/isObserver',
      projectCompleted: 'projectDetail/projectCompleted'
    }),
    commentLabel () {
      return this.isModerator ?
        '点検者へのコメント' : '管理者へのコメント'
    },
    tableColumns () {
      return this.enableRenameForm ? this.moderatorTableColumns : this.defaultTableColumns
    },
    tableOptions () {
      return this.defaultTableOptions
    },
    reportList () {
      let list = []
      // 速報
      if (this.reportModel.PromptReportObjectKey) {
        list.push({
          Name: this.reportModel.PromptReportName,
          CompanyName: this.reportModel.PromptReportCreatorCompanyName,
          CreatorName: this.reportModel.PromptReportCreatorName,
          ObjectKey: this.reportModel.PromptReportObjectKey,
          CreatedAt: this.reportModel.PromptReportCreatedAt,
          DocType: 7,
          ReportType: 2
        })
      }
      // 写真報告書
      if (this.reportModel.PhotosReportObjectKey) {
        list.push({
          Name: this.reportModel.PhotosReportName,
          CompanyName: this.reportModel.PhotosReportCreatorCompanyName,
          CreatorName: this.reportModel.PhotosReportCreatorName,
          ObjectKey: this.reportModel.PhotosReportObjectKey,
          CreatedAt: this.reportModel.PhotosReportCreatedAt,
          DocType: 10,
          ReportType: 3
        })
      }
      // 地図報告書
      if (this.reportModel.MapReportObjectKey) {
        list.push({
          Name: this.reportModel.MapReportName,
          CompanyName: this.reportModel.MapReportCreatorCompanyName,
          CreatorName: this.reportModel.MapReportCreatorName,
          ObjectKey: this.reportModel.MapReportObjectKey,
          CreatedAt: this.reportModel.MapReportCreatedAt,
          DocType: 9,
          ReportType: 1
        })
      }
      // 点検報告書
      if (this.reportModel.CheckReportObjectKey) {
        list.push({
          Name: this.reportModel.CheckReportName,
          CompanyName: this.reportModel.CheckReportCreatorCompanyName,
          CreatorName: this.reportModel.CheckReportCreatorName,
          ObjectKey: this.reportModel.CheckReportObjectKey,
          CreatedAt: this.reportModel.CheckReportCreatedAt,
          DocType: 8,
          ReportType: 4
        })
      }
      return list
    },

    // input : 管理者へのコメント
    comment: {
      get () {
        return this.isModerator ?
          this.moderatorCommentValue : this.inspectorCommentValue
      },
      set (value) {
        this.isModerator ?
          this.setModeratorComment(value) : this.setInspectorComment(value)
      }
    },

    // form
    enableRenameForm () {
      return this.isModerator &&
        !this.projectCompleted
    },
    enableCommentForm () {
      return (this.isModerator || this.isInspector) &&
        !this.projectCompleted
    },

    // button
    disabledCommentButton () {
      if (this.connectingComment) return true
      return this.isModerator ?
        this.moderatorCommentValue === '' :
        this.inspectorCommentValue === ''
    },
    showCompleteButton () {
      return this.isModerator && !this.projectCompleted
    },
    disabledCompleteButton () {
      return !Utility.getEnableToCompleteProject(this.projectModel.ProjectStatus)
    }
  },
  watch: {
    modified: function (to) {
      if (!to) return
      this.tableData = $.extend(true, [], this.reportList)
    },
    accepted: function (to) {
      if (to) {
        this.completeTask()
      }
    }
  },
  methods: {
    ...mapActions({
      showAlert: 'alert/show',
      showError: 'error/show',
      showConfirm: 'confirm/show',
      // プロジェクトステータス
      changeProjectStatus: 'projectDetail/changeProjectStatus',
      // Task5
      initLoad: 'projectTask5/initLoad',
      // コメント
      loadComments: 'projectDetail/loadComments',
      saveComment: 'projectDetail/saveComment',
      setModeratorComment: 'projectTask5/setModeratorComment',
      setInspectorComment: 'projectTask5/setInspectorComment',
      // 初期化
      reset: 'projectTask5/reset',
      // リネーム
      showRenameModal: 'renameModal/show'
    }),
    init () {
      // 初期化
      this.reset()
      this.showLoading = true

      // データ読出し
      this.initLoad({
        ProjectId: this.projectModel.ProjectId
      })
        .then(() => {
          console.log('@reportModel', this.reportModel)
          console.log('@commentModel', this.commentModel)
          this.tableData = $.extend(true, [], this.reportList)
          this.showLoading = false
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
        })
    },
    getViewData (props) {
      let key = props.column
      let value = props.row[key]
      
      if (key === 'CreatedAt') return Utility.getJSTDate(value, 'YYYY/MM/DD HH:mm')
      // if (key === 'renameButton') return props.row.ObjectKey
      // if (key === 'downloadButton') return props.row.ObjectKey
      return value
    },
    changePage () {

    },
    /**
     * リネームモーダルを起動
     */
    clickRename (props) {
      // console.log('clickRename : ', props)
      this.showRenameModal({
        id: 'rename-modal',
        fileName: props.row.Name,
        reportType: props.row.ReportType
      })
    },
    /**
     * レポートを表示
     */
    clickDownload (props) {
      // console.log('clickDownload : ', props)
      ApiManager.request(
        ['aws'],
        ['v1AwsPresignedUrlGet'],
        [{
          docType: props.row.DocType,
          s3ObjectKey: props.row.ObjectKey,
          use: 0
        }])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            let url = res[0].data.S3ObjectInfo.PreSignedUrl
            window.open(url)

          } else if (res[0].data.Result === Constants.ApiResult.Failure) {
            this.showError(res[0].data.Errors)

          } else {
            this.showError(null)
          }
        })
        .catch(() => {
          this.showError(null)
        })
    },

    /**
     * 統合ファイルをダウンロードする
     */
    downloadIntegratedReport () {
      console.log('downloadIntegratedReport : ', this.projectModel.ProjectId)
      ApiManager.request(
        ['project'],
        ['v1ProjectIntegratedReportGet'],
        [{
          ProjectId: this.projectModel.ProjectId,
        }])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            console.log(res[0].data)
            let url = res[0].data.IntegratedReportDownloadUrl
            window.open(url)

          } else if (res[0].data.Result === Constants.ApiResult.Failure) {
            this.showError(res[0].data.Errors)

          } else {
            this.showError(null)
          }
        })
        .catch(() => {
          this.showError(null)
        })
    },

    /**
     * 管理者へのコメントを登録
     */
    clickComment () {
      this.connectingComment = true

      // パラメータ
      let parameters = {
        ProjectId: this.projectModel.ProjectId,
        CommentData: {
          CommentType: this.isModerator ? 2 : 1,
          Comment: this.isModerator ?
            this.moderatorCommentValue : this.inspectorCommentValue
        }
      }

      // コメントを登録
      this.saveComment(parameters)
        .then(() => {
          // this.showAlert({
          //   key: 'add-comment',
          //   body: 'コメントを登録しました'
          // })
          this.comment = ''

          // コメント一覧を読出し
          this.loadComments({
            ProjectId: this.projectModel.ProjectId
          })
            .catch(error => {
              // API エラー
              if (error.Result === Constants.ApiResult.Failure) {
                this.showError(error)
                
              // システムエラー
              } else {
                this.showError(null)
              }
            })

          this.connectingComment = false
          
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
          this.connectingComment = false
        })
    },

    /**
     * プロジェクトを完了する
     */
    clickComplete () {
      this.showConfirm({
        key: 'complete',
        body: 'プロジェクトを完了しますか？',
        acceptLabel: '完了',
        denyLabel: 'キャンセル'
      })
    },
    completeTask () {
      this.connectingForm = true

      // プロジェクトステータスを変更
      this.changeProjectStatus({
        code: 'task5'
      })
        .then(() => {
          // アラート
          this.showAlert({
            key: 'prompt-report',
            body: 'プロジェクトを完了しました'
          })
          this.connectingForm = false
          
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
          this.connectingForm = false
        })
    },

    /**
     * プロジェクト一覧に戻る
     */
    clickBack () {
      // 点検者はプロジェクト一覧へ
      if (this.isInspector) {
        this.$router.push({ name: 'Project' })

      // 管理者はイベント詳細へ
      } else {
        this.$router.push({
          name: 'EventDetail',
          params: {
            eventId: String(this.projectModel.EventId)
          }
        })
      }
    }
  }
}
