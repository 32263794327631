export default {
  /**
   * ID（int型）データのチェック
   */
  validId (value) {
    return typeof value === 'number' && value !== null
  },
  /**
   * IdentityId（String型）データのチェック
   */
  validIdentityId (value) {
    return typeof value === 'string' && value !== ''
  },
  /**
   * 文字列型データのチェック
   */
  validText (value) {
    return value !== ''
  },
  /**
   * セレクトボックスの入力値チェック
   */
  validSelect (value) {
    return value !== '' && value !== null
  },
  /**
   * 配列型データのチェック
   */
  validArray (value) {
    return typeof value === 'object' && value.length > 0
  },
  /**
   * 日付型データのチェック
   */
  validDate (value) {
    return typeof value === 'object' && value !== null
  }
}
