import { mapState, mapGetters, mapActions } from 'vuex'
import Loading from '@/components/Loading'
import ProjectRegisterMap from '@/components/GoogleMap/ProjectRegisterMap'
import moment from 'moment'
import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'

export default {
  name: 'EventConfirm',
  components: {
    Loading,
    ProjectRegisterMap
  },
  data () {
    return {
      connecting: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initMap(this.assetModel)
    })
  },
  computed: {
    ...mapState({
      eventId: state => state.projectForm.eventId,
      values: state => state.projectForm.values
    }),
    ...mapGetters({
      userIdentity: 'auth/userIdentity',
      getInspectorName: 'projectForm/getInspectorName'
    }),
    assetModel () {
      return this.values.assetIds
    },
    startDate () {
      return moment(this.values.startDate).format('YYYY年MM月DD日')
    },
    endDate () {
      return moment(this.values.endDate).format('YYYY年MM月DD日')
    },
    company () {
      return this.values.company || ''
    },
    inspector () {
      return this.values.inspector ? this.getInspectorName(this.values.inspector) : ''
    },
    note () {
      return this.values.note ? this.values.note.replace(/\n/, '<br>') : ''
    }
  },
  methods: {
    ...mapActions({
      showError: 'error/show',
      initMap: 'projectRegisterMap/init'
    }),
    clickBack () {
      this.$router.push({
        name: 'ProjectRegisterAttribute'
      })
    },
    clickRegister () {
      this.connecting = true

      // // parameters
      // let types = []
      // let methods = []
      // let parameters = []
      // this.values.assetIds.forEach(item => {
      //   types.push('project')
      //   methods.push('v1ProjectPost')
      //   parameters.push({
      //     ProjectData: {
      //       EventId: this.eventId,
      //       Moderator: this.userIdentity,
      //       // ProjectName: this.values.projectName,
      //       StartDatePlan: moment(this.values.startDate).format('YYYY/MM/DD'),
      //       EndDatePlan: moment(this.values.endDate).format('YYYY/MM/DD'),
      //       Inspector: this.values.inspector,
      //       AssetId: item.AssetId,
      //       Note: this.values.note
      //     }
      //   })
      // })

      // console.log('register data', parameters)
      // return
      
      // // プロジェクト登録
      // ApiManager.request(
      //   types,
      //   methods,
      //   parameters)
      //   .then(res => {
      //     console.log('v1ProjectPost', res)
      //     if (res[0].data.Result === Constants.ApiResult.Success) {
      //       this.$router.push({name: 'ProjectRegisterComplete'})

      //     // API エラー
      //     } else if (res[0].data.Result === Constants.ApiResult.Failure) {
      //       this.showError(res[0].data)

      //     // システムエラー
      //     } else {
      //       this.showError(null)
      //     }
      //     this.connecting = false
          
      //   })
      //   .catch(() => {
      //     this.showError(null)
      //     this.connecting = false
      //   })

      let parameters = {
        ProjectData: {
          EventId: this.eventId,
          Moderator: this.userIdentity,
          // ProjectName: this.values.projectName,
          StartDatePlan: moment(this.values.startDate).format('YYYY/MM/DD'),
          EndDatePlan: moment(this.values.endDate).format('YYYY/MM/DD'),
          Inspector: this.values.inspector,
          AssetIds: this.values.assetIds.map(item => item.AssetId),
          Note: this.values.note
        }
      }

      console.log('register data', parameters)

      // プロジェクト登録
      ApiManager.request(
        ['project'],
        ['v1ProjectPost'],
        [parameters])
        .then(res => {
          // console.log('v1ProjectPost', res)
          if (res[0].data.Result === Constants.ApiResult.Success) {
            this.$router.push({name: 'ProjectRegisterComplete'})

          // API エラー
          } else if (res[0].data.Result === Constants.ApiResult.Failure) {
            this.showError(res[0].data)

          // システムエラー
          } else {
            this.showError(null)
          }
          this.connecting = false
        })
        .catch(() => {
          this.showError(null)
          this.connecting = false
        })
    }
  }
}
