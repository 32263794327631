import { mapState } from 'vuex'
import Constants from '@/utils/Constants'
import MapLoader from '@/components/GoogleMap/AssetDetailMap/MapLoader'
import ChildMarker from '@/components/GoogleMap/AssetDetailMap/ChildMarker'

export default {
  name: 'AssetDetailMap',
  components: {
    MapLoader,
    ChildMarker
  },
  props: {
    id: String,
    lat: Number,
    lng: Number
  },
  data () {
    return {
      mapConfig: {
        zoom: 13,
        maxZoom: 18,
        center: {
          lat: this.lat,
          lng: this.lng
        },
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: true,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
        clickableIcons: false,
        // styles
        // ref. https://lab.syncer.jp/Web/API/Google_Maps/JavaScript/MapTypeStyle/
        styles: [
          {
            featureType: 'poi', // 全てのスポット
            // featureType: 'poi.attraction',       // 観光名所
            // featureType: 'poi.business',         // 企業、店舗
            // featureType: 'poi.government',       // 政府機関
            // featureType: 'poi.medical',          // 病院、薬局、警察、医師などの緊急サービス
            // featureType: 'poi.park',             // 公園
            // featureType: 'poi.place_of_worship', // 宗教施設
            // featureType: 'poi.school',           // 学校
            // featureType: 'poi.sports_complex',   // スポーツ施設
            stylers: [
              {
                visibility: 'off'
              }
            ]
          }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      storeMap: state => state.assetDetailMap.map
    }),
    apiKey () {
      return Constants.GoogleMapApiKey
    },
    markerList () {
      return [
        {
          Latitude: this.lat,
          Longitude: this.lng
        }
      ]
    }
  },
  methods: {

  }
}