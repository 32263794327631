export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      CheckReports: [
        {
          ProjectId: 1,
          CheckReportId: 1,
          CheckDate: '2020-04-03',
          CheckDayWeather: '晴れ',
          LoadCapacity: 'A',
          Runnability: 'a',
          Aging: 'M',
          DamageCheckResults: '点検結果（損傷状況） ',
          FoundationExistFlg: true,
          FoundationCheckFlg: true,
          FoundationDamageLoad: 'B',
          FoundationDamageAging: 'S',
          FoundationComment: '基礎コメント',
          FoundationPhotoIds: [1, 2],
          PierExistFlg: true,
          PierCheckFlg: true,
          PierDamageLoad: 'A',
          PierDamageAging: 'N',
          PierComment: '橋台コメント',
          PierPhotoIds: [1],
          BentExistFlg: true,
          BentCheckFlg: true,
          BentDamageLoad: 'A',
          BentDamageAging: 'N',
          BentComment: '橋脚コメント',
          BentPhotoIds: [1],
          MainExistFlg: true,
          MainCheckFlg: true,
          MainDamageLoad: 'A',
          MainDamageAging: 'N',
          MainComment: '主構造コメント',
          MainPhotoIds: [1],
          SupportExistFlg: true,
          SupportCheckFlg: true,
          SupportDamageLoad: 'A',
          SupportDamageAging: 'N',
          SupportComment: '支承部コメント',
          SupportPhotoIds: [1],
          FloorExistFlg: true,
          FloorCheckFlg: true,
          FloorDamageLoad: 'A',
          FloorDamageAging: 'N',
          FloorComment: '床版コメント',
          FloorPhotoIds: [1],
          ExpantionExistFlg: true,
          ExpantionCheckFlg: true,
          ExpantionDamageRun: 'a',
          ExpantionDamageAging: 'N',
          ExpantionComment: '伸縮装置コメント',
          ExpantionPhotoIds: [1],
          BankingExistFlg: true,
          BankingCheckFlg: true,
          BankingDamageRun: 'a',
          BankingDamageAging: 'N',
          BankingComment: '取付盛土コメント',
          BankingPhotoIds: [1],
          FenceExistFlg: true,
          FenceCheckFlg: true,
          FenceDamageRun: 'a',
          FenceDamageAging: 'N',
          FenceComment: '車両用防護柵コメント',
          FencePhotoIds: [1],
          RoadExistFlg: true,
          RoadCheckFlg: true,
          RoadDamageRun: 'a',
          RoadDamageAging: 'N',
          RoadComment: '路面コメント',
          RoadPhotoIds: [1],
          SignExistFlg: true,
          SignCheckFlg: true,
          SignDamageRun: 'a',
          SignDamageAging: 'N',
          SignComment: '標識付属物コメント',
          SignPhotoIds: [1],
          DamageNotes: '損傷発生部位備考',
          SidelbockDamage: 1,
          SideblockComment: '支承サイドブロックコメント',
          SideblockPhotoIds: [1],
          RestrictingDamage: 1,
          RestrictingComment: '変位制限構造コメント',
          RestrictingPhotoIds: [1],
          DamperDamage: 1,
          DamperComment: 'ダンパーコメント',
          DamperPhotoIds: [1],
          PreventionDamage: 1,
          PreventionComment: '落橋防止装置コメント',
          PreventionPhotoIds: [1],
          OthersDamage: 1,
          OthersComment: 'その他コメント',
          OthersPhotoIds: [1],
          Opinion: '下部工（コメント）',
          OpinionPhotoIds: [1],
          Note: '備考',
          CheckReportName: '点検調査レポートファイル名',
          CheckReportObjectKey: '点検調査レポートファイルS３ObjectKey',
          CheckReportCreatedAt: '2020-03-20 19:20:29',
          UpdatedAt: '2020-03-20 19:20:29',
          CreatedAt: '2020-03-20 19:20:29'
        }
      ]
    }
  }
}  
 
