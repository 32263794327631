import { mapState, mapGetters, mapActions } from 'vuex'
import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'

export default {
  name: 'Detail',
  mounted () {
    $(window).on({resize: this.onResize })
    
    this.$nextTick(() => {
      this.onResize()
    })
  },
  beforeDestroy () {
    $(window).off({resize: this.onResize })
  },
  computed: {
    ...mapState({
      // authorized: state => state.auth.authorized
    }),
    ...mapGetters({
      model: 'backgroundMap/getSelectedModel',
    }),
    active () {
      return this.model && this.model.length
    },
    assetName () {
      return this.model[0].Name
    },
    year () {
      if (!this.model[0].CompletionYear) return ''
      return this.model[0].CompletionYear + '年'
      // return this.model[0].CreatedAt.substr(0, 4)
    },
    roadAdmin () {
      return this.model[0].RoadAdmin
    },
    prefecture () {
      return Constants.Prefecture[this.model[0].Prefecture]
    },
    city () {
      return this.model[0].City
    },
    address () {
      return this.model[0].Address
    },
    latitude () {
      return this.model[0].Latitude
    },
    longitude () {
      return this.model[0].Longitude
    },
    bridgeFormat () {
      return this.model[0].BridgeForm
    },
    bridgeLength () {
      if (!this.model[0].Length) return ''
      return this.model[0].Length + ' m'
    },
    bridgeSpanner () {
      if (!this.model[0].Spanner) return ''
      return this.model[0].Spanner + ' m'
    },
    bridgeWidth () {
      if (!this.model[0].Width) return ''
      return this.model[0].Width + ' m'
    },
    profilePhoto () {
      // return '/images/1.png'
      return this.model[0].ProfilePhotoThumbUrl
    },

    // flag
    disabledPhotoButton () {
      return this.model[0].LatestProjectId === null
    },
    disabledPdf () {
      return !this.model[0].Figure1
    }
  },
  methods: {
    ...mapActions({
      selectMarker: 'backgroundMap/selectMarker',
      showPhotoList: 'home/showPhotoList',
      hidePhotoList: 'home/hidePhotoList',
      showError: 'error/show'
    }),
    // init () {
    //   // プロフィール画像を取得
    //   ApiManager.request(
    //     ['aws'],
    //     ['v1AwsPresignedUrlGet'],
    //     [{
    //       docType: 2,
    //       s3ObjectKey: this.model.ProfilePhotoFileOjectKey,
    //       use: 1
    //     }])
    //     .then(res => {
    //       if (res[0].data.Result === Constants.ApiResult.Success) {
    //         let url = res[0].data.S3ObjectInfo.PreSignedUrl
    //         console.log(url)

    //       // API エラー
    //       } else if (res[0].data.Result === Constants.ApiResult.Failure) {
    //         this.showError(res[0].data)
            
    //       // システムエラー
    //       } else {
    //         this.showError(null)
    //       }
    //     })
    //     .catch(() => {
    //       this.showError(null)
    //     })
    // },
    onResize () {
      let windowHeight = window.innerHeight ?
        window.innerHeight : $(window).height()
      let header = $('#asideHomeDetail').children('.header');
      let container = $('#asideHomeDetail').children('.wrap');
      // let h = windowHeight - header.outerHeight() - 185
      // container.height(h)
      let h = windowHeight - header.outerHeight() - 165
      container.css({maxHeight: h})
    },
    clickBack () {
      this.hidePhotoList()
      this.selectMarker()
    },
    clickPhoto () {
      if (this.disabledPhotoButton) return

      // console.log('photo')
      this.showPhotoList({
        projectId: this.model[0].LatestProjectId
      })
    },
    clickPdf () {
      if (!this.model[0].Figure1) return
      console.log(this.model[0].Figure1)
      // window.open('http://achrome.jp/test.pdf')

      // ダウンロード
      ApiManager.request(
        ['aws'],
        ['v1AwsPresignedUrlGet'],
        [{
          docType: 5,
          s3ObjectKey: this.model[0].Figure1,
          use: 1
        }])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            let url = res[0].data.S3ObjectInfo.PreSignedUrl
            window.open(url)

          // API エラー
          } else if (res[0].data.Result === Constants.ApiResult.Failure) {
            this.showError(res[0].data)
            
          // システムエラー
          } else {
            this.showError(null)
          }
        })
        .catch(() => {
          this.showError(null)
        })
    },
    clickGmap() {
      const lat = this.model[0].Latitude;
      const lng = this.model[0].Longitude;
      // const url = 'https://www.google.co.jp/maps/@' + lat + ',' + lng + ',18z';
      const url = 'https://maps.google.com/maps?q=' + lat + ',' + lng + '&z=18';
      window.open(url);
    },
  }
}