import { mapState, mapGetters } from 'vuex'

export default {
  name: 'GlobalNavi',
  computed: {
    ...mapState({
      authorized: state => state.auth.authorized,
      requireChangePassword: state => state.auth.requireChangePassword
    }),
    ...mapGetters({
      privateRelease: 'config/privateRelease',
      publicRelease: 'config/publicRelease',
      isModerator: 'auth/isModerator',
      isInspector: 'auth/isInspector',
      isObserver: 'auth/isObserver'
    }),
    hideAside () {
      return !this.authorized || this.requireChangePassword
    },
    showProject () {
      return this.isModerator
    },
    showProjectRegister () {
      return this.isModerator || this.isObserver
    },
    projectInChargeLabel () {
      return this.isObserver ? 'プロジェクト' : '担当プロジェクト'
    }
  },
  methods: {
    isActive (category) {
      if (!this.$route.name) return false

      let pathArray = this.$route.path.split('/')
      if(pathArray[0] === '') pathArray.shift()
      if(pathArray[pathArray.length - 1] === '') pathArray.pop()
      
      // お知らせ
      if (category === 'Notice') {
        return pathArray[0] === 'notice'
      }
      // イベント一覧
      if (category === 'Event') {
        return this.isModerator ?
          pathArray[0] === 'event' || pathArray[0] === 'project' :
          pathArray[0] === 'event'
      }
      // プロジェクト一覧
      if (category === 'Project') {
        return pathArray[0] === 'project'
      }
      // 橋梁一覧
      if (category === 'Bridge') {
        return pathArray[0] === 'bridge'
      }
      // プロフィール編集
      if (category === 'Account') {
        return pathArray[0] === 'account' || pathArray[0] === 'password'
      }
    }
  }
}