import { mapState, mapActions } from 'vuex'
import Loading from '@/components/Loading'
import Constants from '@/utils/Constants'
import ApiManager from '@/utils/ApiManager'

export default {
  name: 'ReminderConfirm',
  components: {
    Loading
  },
  data () {
    return {
      connecting: false
    }
  },
  computed: {
    ...mapState({
      values: state => state.reminder.values
    })
  },
  methods: {
    ...mapActions({
      showError: 'error/show'
    }),
    clickBack () {
      this.$router.go(-1)
    },
    clickRegister () {
      this.connecting = true

      let parameters = {
        Email: this.values.email
      }
      console.log('register data', parameters)

      // 登録
      ApiManager.request(['auth'], ['v1AuthReminderPost'], [parameters])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            this.$router.push({name: 'ReminderComplete'})

          // API エラー
          } else if (res[0].data.Result === Constants.ApiResult.Failure) {
            // ここはセキュリティー上、失敗しても完了画面を表示
            this.$router.push({name: 'ReminderComplete'})

          // システムエラー
          } else {
            this.showError(null)
          }
          this.connecting = false
          
        })
        .catch(() => {
          this.showError(null)
          this.connecting = false
        })
    }
  }
}
