import { mapMutations } from 'vuex'
import Constants from '@/utils/Constants'
export default {
  name: 'PasswordComplete',
  data () {
    return {
      redirectTimer: null
    }
  },
  mounted () {
    this.resetForm()
    this.redirectTimer = setTimeout(() => {
      this.$router.push({name: 'Signin'})
    }, Constants.Params.CompletedRedirectDelay)
  },
  beforeDestroy () {
    if (this.redirectTimer) {
      clearTimeout(this.redirectTimer)
      this.redirectTimer = null
    }
  },
  computed: {
    description () {
      return 'パスワードを登録できませんでした。'
    }
  },
  methods: {
    ...mapMutations({
      resetForm: 'register/reset'
    })
  }
}
