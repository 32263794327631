import Vue from 'vue'
import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'

const state = {
  // model
  promptReportModel: {},
  // value
  loadCapacityValue: '',
  runnabilityValue: '',
  agingValue: '',
  noteValue: ''
}

const getters = {
  get: state => state,
  isModify: state => state.promptReportModel.PromptReportId !== undefined
}

const actions = {
  /**
   * プロジェクト速報を読出し
   */
  loadPromptReport ({commit}, parameters) {
    return new Promise((resolve, reject) => {
      // プロジェクト一覧を読出し
      ApiManager.request(
        ['project'],
        ['v1ProjectPromptReportListGet'],
        [parameters])
        .then(res => {
          console.log('v1ProjectPromptReportListGet', res)
          if (res[0].data.Result === Constants.ApiResult.Success) {
            if (res[0].data.PromptReports.length) {
              // model
              commit('setPromptReportModel', res[0].data.PromptReports[0])
              // value
              commit('setValue', {
                key: 'loadCapacityValue',
                value: state.promptReportModel.LoadCapacity || ''
              })
              commit('setValue', {
                key: 'runnabilityValue',
                value: state.promptReportModel.Runnability || ''
              })
              commit('setValue', {
                key: 'agingValue',
                value: state.promptReportModel.Aging || ''
              })
              commit('setValue', {
                key: 'noteValue',
                value: state.promptReportModel.Opinion || ''
              })
            }
            resolve()

          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          console.log('v1ProjectPromptReportListGet', error)
          reject(error)
        })
    })
  },
  /**
   * stateのvalueを変更
   */
  setValue ({commit}, {key, value}) {
    commit('setValue', {
      key: key,
      value: value
    })
  },

  /**
   * 速報を作成する
   */
  // eslint-disable-next-line
  savePromptReport ({commit, getters}, parameters) {
    return new Promise((resolve, reject) => {
      let methods = getters.isModify ?
        'v1ProjectPromptReportUpdate' : 'v1ProjectPromptReportPost'
      ApiManager.request(
        ['project'],
        [methods],
        [parameters])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            resolve()

          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  /**
   * 初期化
   */
  reset ({commit}) {
    commit('reset')
  }
}

const mutations = {
  setPromptReportModel (state, data) {
    Vue.set(state, 'promptReportModel', data)
  },
  setValue (state, {key, value}) {
    Vue.set(state, key, value)
  },
  reset (state) {
    Vue.set(state, 'promptReportModel', {})
    state.loadCapacityValue = ''
    state.runnabilityValue = ''
    state.agingValue = ''
    state.noteValue = ''
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
