import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
// import moment from 'moment'
import Constants from '@/utils/Constants'
import Validator from '@/utils/Validator'
import Utility from '@/utils/Utility'

export default {
  name: 'EventInput',
  data () {
    return {
      // 入力値
      eventName: '',
      note: '',
      // invalidFlag: false,
      isClickedConfirm: false,

      // 地震速報テーブル
      quakeTableColumns: [
        'checkbox',
        'EqId',
        'OriginTime',
        'HypoAreaName',
        'MaxInt'
      ],
      candidateTableData: [],
      selectedTableData: [],
      quakeTableOptions: {
        headings: {
          checkbox: '',
          EqId: '地震ID',
          OriginTime: '発生時刻',
          HypoAreaName: '震源地',
          MaxInt: '震度'
        },
        columnsClasses: {
          checkbox: 'wp-4 text-center',
          EqId: 'wp-10',
          OriginTime: 'wp-25',
          HypoAreaName: '',
          MaxInt: 'wp-10'
        },
        sortable: [],
        filterable: false,
        texts: Constants.TableOptions.getTexts('地震情報'),
        // perPage: Constants.TableOptions.perPage,
        perPage: 10,
        perPageValues: Constants.TableOptions.perPageValues,
        pagination: {
          show: false
        },
        resizableColumns: false,
      },
      // チェック
      selectedCheckList: [],
      candidateCheckList: []
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapState({
      values: state => state.eventForm.values,
      selectedQuakeModel: state => state.eventForm.selectedQuakeModel,
      candidateQuakeModel: state => state.eventForm.candidateQuakeModel
    }),
    ...mapGetters({
      isRegister: 'eventForm/isRegister',
      isModify: 'eventForm/isModify'
    }),
    // validate
    eventNameIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.eventName)
    },
    formIsValid () {
      // return true
      return this.eventNameIsValid
    },
    disableConfirmButton () {
      return !Validator.validText(this.eventName)
    },
    // // 地震速報
    // quakeItem () {
    //   return this.candidateQuakeModel
    // },
  },
  watch: {
    selectedQuakeModel: function (to) {
      this.selectedTableData = $.extend(true, [], to)
    },
    candidateQuakeModel: function (to) {
      this.candidateTableData = $.extend(true, [], to)
    }
  },
  methods: {
    ...mapActions({
      addQuakes: 'eventForm/addQuakes',
      removeQuakes: 'eventForm/removeQuakes',
      showEqModal: 'eqModal/show'
    }),
    ...mapMutations({
      setValues: 'eventForm/setValues'
    }),
    init () {
      // 確認画面から戻った時、編集に宣した時に値を挿入
      // if (this.values) this.isClickedConfirm = true
      if (this.values.eventName) this.eventName = this.values.eventName
      if (this.values.note) this.note = this.values.note
      // if (this.values.invalidFlag) this.invalidFlag = this.values.invalidFlag

      // テーブルデータを初期化
      this.selectedTableData = $.extend(true, [], this.selectedQuakeModel)
      this.candidateTableData = $.extend(true, [], this.candidateQuakeModel)
    },
    clickEq (props) {
      this.showEqModal({
        id: props.row.EqId,
        model: $.extend(true, {}, props.row)
      })
    },
    /**
     * 地震情報の関連付け
     */
    getViewData (props) {
      let key = props.column
      let value = props.row[key]
      
      // 地震速報
      if (key === 'checkbox') return props.row.EqId
      // if (key === 'OriginTime') return moment(value).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mmごろ')
      if (key === 'OriginTime') return Utility.getJSTDate(value, 'YYYY/MM/DD HH:mmごろ')
      return value
    },
    selectQuakeItems () {
      // console.log(this.candidateCheckList)
      this.addQuakes(this.candidateCheckList)
      this.selectedCheckList = []
      this.candidateCheckList = []
    },
    clearQuakeItems () {
      // console.log(this.selectedCheckList)
      this.removeQuakes(this.selectedCheckList)
      this.selectedCheckList = []
      this.candidateCheckList = []
    },
    /**
     * 登録内容を確認
     */
    clickConfirm () {
      this.isClickedConfirm = true
      // console.log(this.invalidFlag)
      console.log(this.selectedQuakeModel)

      // validate
      if (this.formIsValid) {
        let data = $.extend(true, {}, this.values)
        data.eventName = this.eventName
        data.note = this.note
        // if (this.isModify) data.invalidFlag = this.invalidFlag
        this.setValues(data)
        this.$router.push({name: 'EventRegisterConfirm'})
      }
    }
  }
}
