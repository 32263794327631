export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      User: {
        IdentityId: 'identity id',
        Role: 8,
        TenantId: 1,
        TenantName: 'tenant name'
      },
      Projects: [
        {
          ProjectId: 1,
          AssetId: 1,
          AssetName: 'アセットA',
          Status: '300',
          ProjectName: 'プロジェクト001',
          CustomerIdentity: 'xxxxx',
          CustomerName: 'カスタマー 進',
          ModeratorIdentity: 'xxxxx',
          ModeratorName: 'モデレータ　一郎',
          PilotIdentity: 'xxxxx',
          PilotName: 'パイロット　次郎',
          InspectorIdentity: 'xxxxx',
          InspectorName: 'インスペクター 三郎',
          FlightPlan: ['プランA', 'プランB'],
          StartDate: '2019-08-02',
          EndDate: '2019-09-20',
          Note: 'notenote note\nnote',
          OrderDate: '2019-08-02',
          UpdatedDate: '2019-09-20',
        }
      ]
    }
  }
}
