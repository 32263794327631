import { mapState, mapGetters, mapActions } from 'vuex'
import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Utility from '@/utils/Utility'
import Loading from '@/components/Loading'

export default {
  name: 'ProjectDetail',
  components: {
    Loading
  },
  data () {
    return {
      showLoading: false,
      reportModel: {},
      // プロジェクト状態変更のドロップダウン表示
      showModifyProject: false
    }
  },
  mounted () {
    this.init()

    $(window).on({resize: this.onResize })
  },
  beforeDestroy () {
    $(window).off({resize: this.onResize })
  },
  computed: {
    ...mapState({
      eventId: state => state.eventDetail.targetId
    }),
    ...mapGetters({
      isModerator: 'auth/isModerator',
      isInspector: 'auth/isInspector',
      isObserver: 'auth/isObserver',
      projectModel: 'projectMap/getSelectedModel'
    }),
    pageTitle () {
      return this.projectModel.AssetName
    },

    // プロジェクト詳細
    projectId () {
      return this.projectModel.ProjectId || null
    },
    identifier () {
      return this.projectModel.EventId + '-' + this.projectId
    },
    locationId () {
      return this.projectModel.LocationId
    },
    originDate () {
      return this.projectModel.ProjectCreatedAt
    },
    roadAdmin () {
      return this.projectModel.RoadAdmin
    },
    address () {
      return this.projectModel.Address
    },
    inspectorCompany () {
      return this.projectModel.InspectorCompanyName
    },
    inspector () {
      return this.projectModel.InspectorName
    },
    projectStatus () {
      // ビット演算で一番進んでいるタスクを表示
      return Utility.currentProjectTaskLabel(this.projectModel.ProjectStatus)
      // try {
      //   return Constants.Tasks.filter(item => item.)
        
      // } catch (e) {
      //   return ''
      // }
    },

    // レポート情報
    reportList () {
      let list = []
      list.push({
        label: '速報報告書',
        ObjectKey: this.reportModel.PromptReportObjectKey,
        DocType: 7,
      })
      list.push({
        label: '写真報告書',
        ObjectKey: this.reportModel.PhotosReportObjectKey,
        DocType: 10,
      })
      list.push({
        label: '地図報告書',
        ObjectKey: this.reportModel.MapReportObjectKey,
        DocType: 9,
      })
      list.push({
        label: '調査・点検報告書',
        ObjectKey: this.reportModel.CheckReportObjectKey,
        DocType: 8,
      })
      return list
    }
  },
  watch: {
    projectId (to) {
      if (to !== null) {
        this.init()
      }
    }
  },
  methods: {
    ...mapActions({
      showError: 'error/show',
      showAlert: 'alert/show',
      selectMarker: 'projectMap/selectMarker',
      // showProjectStopModal: 'projectStopModal/show',
      // showProjectDeleteModal: 'projectDeleteModal/show'
    }),
    init () {
      this.showLoading = true

      // レポート一覧を読出し
      ApiManager.request(
        ['project'],
        ['v1ProjectReportListGet'],
        [{
          ProjectId: this.projectId
        }])
        .then(res => {
          // レポート一覧
          if (res[0].data.Result === Constants.ApiResult.Success) {
            // model
            this.$set(this, 'reportModel', res[0].data.ProjectReports[0])
            console.log('@reportModel', this.reportModel)

          // API エラー
          } else if (res[0].data.Result === Constants.ApiResult.Failure) {
            this.showError(res[0].data)

          // システムエラー
          } else {
            this.showError(null)
          }
          this.showLoading = false

          this.$nextTick(() => {
            this.onResize()
          })

        })
        .catch(() => {
          this.showError(null)
          this.showLoading = false
        })
    },
    onResize () {
      let windowHeight = window.innerHeight ?
        window.innerHeight : $(window).height()
      let container = $('#asideProjectDetail').children('.wrap');
      let bodyH = windowHeight - 120
      let h1 = container.children('.header').length ?
        container.children('.header').outerHeight() : 0
      let h = bodyH - h1

      container.children('.projects').height(h)
    },

    /**
     * プロジェクト状態変更
     */
    // toggleModifyProject () {
    //   // console.log('roadAdminQuery :', this.roadAdminQuery)
    //   this.showModifyProject = !this.showModifyProject
    // },
    // stopProject () {
    //   this.showModifyProject = false
    //   this.showProjectStopModal({
    //     id: 'projectStopModal',
    //     model: this.projectModel
    //   })
    // },
    // deleteProject () {
    //   this.showModifyProject = false
    //   this.showProjectDeleteModal({
    //     id: 'projectDeleteModal',
    //     model: this.projectModel
    //   })
    // },

    /**
     * レポートを表示
     */
    clickDownload (props) {
      if (props.ObjectKey === null) return

      // console.log('clickDownload : ', props)
      ApiManager.request(
        ['aws'],
        ['v1AwsPresignedUrlGet'],
        [{
          docType: props.DocType,
          s3ObjectKey: props.ObjectKey,
          use: 0
        }])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            let url = res[0].data.S3ObjectInfo.PreSignedUrl
            window.open(url)

          } else if (res[0].data.Result === Constants.ApiResult.Failure) {
            this.showError(res[0].data.Errors)

          } else {
            this.showError(null)
          }
        })
        .catch(() => {
          this.showError(null)
        })
    }
  }
}