import 'babel-polyfill'
import Vue from 'vue'
import Vuex from 'vuex'
// common
import config from '@/store/common/config'
import auth from '@/store/common/auth'
import meta from '@/store/common/meta'
// view
import home from '@/store/views/home'
import register from '@/store/views/register'
import reminder from '@/store/views/reminder'
import reminderPassword from '@/store/views/reminderPassword'
import notice from '@/store/views/notice'
import noticeDetail from '@/store/views/noticeDetail'
import event from '@/store/views/event'
import eventForm from '@/store/views/eventForm'
import eventDetail from '@/store/views/eventDetail'
import project from '@/store/views/project'
import projectForm from '@/store/views/projectForm'
import projectDetail from '@/store/views/projectDetail'
import projectTask1 from '@/store/views/projectTask1'
import projectTask2 from '@/store/views/projectTask2'
import projectTask3 from '@/store/views/projectTask3'
import projectTask4 from '@/store/views/projectTask4'
import projectTask5 from '@/store/views/projectTask5'
import projectTask6 from '@/store/views/projectTask6'
import account from '@/store/views/account'
// components
import alert from '@/store/components/alert'
import error from '@/store/components/error'
import confirm from '@/store/components/confirm'
import accountBox from '@/store/components/accountBox'
// components : googlemap
import google from '@/store/components/googlemap/google'
import backgroundMap from '@/store/components/googlemap/backgroundMap'
import projectMap from '@/store/components/googlemap/projectMap'
import projectFormMap from '@/store/components/googlemap/projectFormMap'
import projectRegisterMap from '@/store/components/googlemap/projectRegisterMap'
import assetDetailMap from '@/store/components/googlemap/assetDetailMap'
// components : home
import projectPhoto from '@/store/components/home/projectPhoto'
import projectPhotoModal from '@/store/components/home/projectPhotoModal'
// components : event
import eqModal from '@/store/components/event/eqModal'
import eqListModal from '@/store/components/event/eqListModal'
// components : project
import projectDetailModal from '@/store/components/project/projectDetailModal'
import selectPhotoModal from '@/store/components/project/selectPhotoModal'
import examplesModal from '@/store/components/project/examplesModal'
import renameModal from '@/store/components/project/renameModal'
import projectStopModal from '@/store/components/project/projectStopModal'
import projectDeleteModal from '@/store/components/project/projectDeleteModal'
import projectModifyModal from '@/store/components/project/projectModifyModal'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    // common
    config: config,
    auth: auth,
    meta: meta,
    // view
    home: home,
    register: register,
    reminder: reminder,
    reminderPassword: reminderPassword,
    notice: notice,
    noticeDetail: noticeDetail,
    event: event,
    eventForm: eventForm,
    eventDetail: eventDetail,
    project: project,
    projectForm: projectForm,
    projectDetail: projectDetail,
    projectTask1: projectTask1,
    projectTask2: projectTask2,
    projectTask3: projectTask3,
    projectTask4: projectTask4,
    projectTask5: projectTask5,
    projectTask6: projectTask6,
    account: account,
    // components
    alert: alert,
    error: error,
    confirm: confirm,
    accountBox: accountBox,
    // components : googlemap
    google: google,
    backgroundMap: backgroundMap,
    projectMap: projectMap,
    projectFormMap: projectFormMap,
    projectRegisterMap: projectRegisterMap,
    assetDetailMap: assetDetailMap,
    // components : home
    projectPhoto: projectPhoto,
    projectPhotoModal: projectPhotoModal,
    // components : event
    eqModal: eqModal,
    eqListModal: eqListModal,
    // components : project
    projectDetailModal: projectDetailModal,
    selectPhotoModal: selectPhotoModal,
    examplesModal: examplesModal,
    renameModal: renameModal,
    projectStopModal: projectStopModal,
    projectDeleteModal: projectDeleteModal,
    projectModifyModal: projectModifyModal
  }
})
