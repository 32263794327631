// import Constants from '@/utils/Constants'

const state = {
	active: false,
	// 選択中のプロジェクトID
	projectId: null,
	// カテゴリのキー
	sectionKey: null,
	// モーダルのID
	modalId: null
}

const getters = {
  get: state => state
}

const actions = {
	show ({commit}, {projectId, sectionKey}) {
		commit('showed', {
			projectId: projectId,
			sectionKey: sectionKey,
			modalId: 'asset-modal-' + projectId + '-' + sectionKey
		})
	},
	hide ({commit}) {
		commit('hided')
	}
}

const mutations = {
	showed (state, {projectId, sectionKey, modalId}) {
		state.projectId = projectId
		state.sectionKey = sectionKey
		state.modalId = modalId
		state.active = true
	},
	hided (state) {
		state.active = false
		state.projectId = null
		state.sectionKey = null
		state.modalId = null
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
