import { mapState, mapGetters } from 'vuex'

export default {
  name: 'ProjectTask1',
  data () {
    return {

    }
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapState({
      projectModel: state => state.projectDetail.projectModel
    }),
    ...mapGetters({
      isModerator: 'auth/isModerator',
      isInspector: 'auth/isInspector',
      isObserver: 'auth/isObserver'
    }),
    companyName () {
      return this.projectModel.InspectorCompanyName || '-'
    },
    leaderName () {
      return this.projectModel.InspectorLeaderName || '-'
    },
    inspectorName () {
      return this.projectModel.InspectorName || '-'
    },
    division () {
      return this.projectModel.InspectorDivision || '-'
    },
    phone () {
      if (!this.projectModel.InspectorPhone1 &&
        !this.projectModel.InspectorPhone2 &&
        !this.projectModel.InspectorPhone3) return '-'
      
      return this.projectModel.InspectorPhone1 + '-' +
        this.projectModel.InspectorPhone2 + '-' +
        this.projectModel.InspectorPhone3
    }
  },
  methods: {
    init () {

    },
    /**
     * プロジェクト一覧に戻る
     */
    clickBack () {
      // 点検者はプロジェクト一覧へ
      if (this.isInspector) {
        this.$router.push({ name: 'Project' })

      // 管理者はイベント詳細へ
      } else {
        this.$router.push({
          name: 'EventDetail',
          params: {
            eventId: String(this.projectModel.EventId)
          }
        })
      }
    }
  }
}
