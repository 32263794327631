export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      User: {
        UserIdentity: 'identity id',
        Role: 8,
        TenantIdentity: 'xxxxx',
        TenantName: 'tenant name'
      },
      Projects: [
        {
          ProjectId: 1,
          AssetId: 1,
          Status: 'ステータス名',
          ProjectName: 'プロジェクト001',
          FlightPlan: ['プランA', 'プランB'],
          StartDate: '2019-08-02',
          EndDate: '2019-09-20',
          Note: 'notenote note\nnote',
          OrderDate: '2019-08-02',
          UpdatedDate: '2019-09-20'
        }
      ]
    }
  }
}
