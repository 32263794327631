import { mapState, mapActions, mapMutations } from 'vuex'
import Constants from '@/utils/Constants'
import Utility from '@/utils/Utility'

export default {
  name: 'ChildMarker',
  inject: [
    'google',
    'map'
  ],
  props: {
    model: Object
  },
  mounted () {
    this.setMarker()
  },
  data () {
    return {
      marker: null
    }
  },
  computed: {
    ...mapState({
      selectedMarkerId: state => state.backgroundMap.selectedMarkerId,
      markerZIndex: state => state.backgroundMap.markerZIndex
    }),

    // プロジェクトステータスに応じたマーカー色を返す
    getMarkerColor() {
      return status => {
        switch(status) {
          case 1:
            return Constants.GoogleMapMarkerColor.assetsColor.task1;
          case 2:
            return Constants.GoogleMapMarkerColor.assetsColor.task2;
          case 4:
            return Constants.GoogleMapMarkerColor.assetsColor.task3;
          case 8:
            return Constants.GoogleMapMarkerColor.assetsColor.task4;
          case 16:
          case 32:
            return Constants.GoogleMapMarkerColor.assetsColor.complete;
          default:
            return Constants.GoogleMapMarkerColor.assetsColor.default;
        }
      };
    },
  },
  watch: {
    // // eslint-disable-next-line
    // model: function (to) {
    //   // console.log(to)
    //   // 表示中のマーカー削除はMapLoaderで行う
    //   // this.marker.setMap(null)

    //   // マーカーの配置
    //   this.setMarker()
    // },
    selectedMarkerId: function (to) {
      // アイコンの変更
      this.changeMarker()

      // 選択されたマーカーが自身の場合
      if (to === this.model.AssetId &&
        this.model.Latitude &&
        this.model.Longitude) {
        // 地図の中心を移動
        const { LatLng } = this.google.maps
        this.map.panTo(new LatLng(this.model.Latitude, this.model.Longitude))
        this.map.panBy(-130, -40)

        // zIndexを上に
        this.setMarkerZIndex(this.markerZIndex + 1)
        // console.log(this.marker)
        // console.log(this.marker.getZIndex() + ' / ' + this.marker.MAX_ZINDEX)
        this.marker.setZIndex(this.markerZIndex)
      }

      // // 緯度経度が未設定の場合
      // if (to === this.model.AssetId &&
      //   (this.model.Latitude === null ||
      //   this.model.Longitude === null)) {
      //   this.showAlert(Constants.Alerts.BlankLatLng)
      // }
    }
  },
  methods: {
    ...mapActions({
      addMarker: 'backgroundMap/addMarker',
      selectMarker: 'backgroundMap/selectMarker',
      showAlert: 'alert/show'
    }),
    ...mapMutations({
      setMarkerZIndex: 'backgroundMap/setMarkerZIndex'
    }),
    /**
     * マーカーを配置する
     */
    setMarker () {
      const { Marker } = this.google.maps

      let valid = this.model.Latitude !== null &&
        this.model.Longitude !== null
      
      // マーカーを生成
      this.marker = new Marker({
        position: {
          lat: this.model.Latitude || 0,
          lng: this.model.Longitude || 0
        },
        map: this.map,
        icon: this.getMarker('default'),
        zIndex: this.markerZIndex,
        visible: valid
        // label: {
        //   text: String(this.model.AssetId),
        //   color: '#fff',
        //   fontFamily: 'Arial',
        //   fontSize: '11px'
        // }
      })
      // マーカーのクリックイベント
      this.marker.addListener('click', this.click);

      this.addMarker(this.marker)
    },
    /**
     * マーカーのSVGを取得する
     * default / active
     */
    getMarker (type) {
      // サイズ
      let size = type === 'active' ?
        Constants.GoogleMapMarkerSize.lg :
        Constants.GoogleMapMarkerSize.md

      // 色
      // let roadAdminKbn = this.model.RoadAdminKbn ? String(this.model.RoadAdminKbn) : '4'
      // let color = Constants.GoogleMapMarkerColor.roadAdminKbn[roadAdminKbn][1]
      
      // let color = type === 'default' ?
      //   Constants.GoogleMapMarkerColor.default :
      //   Constants.GoogleMapMarkerColor.active

      // 一番進んでいるプロジェクトタスクのビット値を取得
      const taskBit = this.model.LatestProjectStatus > 0
        ? Utility.currentProjectTaskBit(this.model.LatestProjectStatus)
        : 0;

      const color = type === 'default'
        // 最新のプロジェクトステータスの進捗でマーカー色を取得
        ? this.getMarkerColor(taskBit)
        // 選択中のマーカー色を取得
        : Constants.GoogleMapMarkerColor.assetsColor.active;
      // console.log('@@', this.model.Name, this.model.LatestProjectStatus, color)

      // マーカーを生成
      let template = Constants.GoogleMapMarker.Pin()
      let svg = template.replace('{size}', size + 'px')
        .replace('{color}', color)
        .replace('{center}', Constants.GoogleMapMarkerColor.center)
        .replace('{stroke}', Constants.GoogleMapMarkerColor.stroke)
      
      const { Size } = this.google.maps
      const { Point } = this.google.maps

      let icon = {
        url: 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(svg),
        size: new Size(size, size),
        origin: new Point(0, 0),
        anchor: new Point(size / 2, size)
      }
      return icon
    },
    /**
     * マーカーを変更する
     */
    changeMarker () {
      let type = 'default'
      if (this.selectedMarkerId === this.model.AssetId) {
        type = 'active'
      }
      this.marker.setIcon(this.getMarker(type))
    },

    /**
     * クリック時の処理
     */
    click () {
      console.log('click marker', this.model)

      this.selectMarker(this.model.AssetId)

      // // zIndexを上に
      // console.log(this.marker.MAX_ZINDEX)
      // this.marker.setZIndex(100000)

      // const { Animation } = this.google.maps
      // this.marker.setAnimation(Animation.BOUNCE)

      // const { Symbol } = this.google.maps
      // console.log(Symbol)
      // let symbol = new Symbol({
      //   scale: 1.4
      // })
      // this.marker.setIcon(symbol)

    }
  }
}