import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Vue from 'vue'

const state = {
  photoModel: [],
  count: 0
}

const getters = {
  get: state => state
}

const actions = {
  loadPhoto ({commit}, {projectId}) {
    return new Promise((resolve, reject) => {
      // プロジェクトの撮影写真一覧を読出し
      let parameters = {
        ProjectId: projectId
      }

      ApiManager.request(
        ['project'],
        ['v1ProjectPhotoListGet'],
        [parameters])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            commit('setPhotoModel', res[0].data.TakenPhotos)
            commit('setCount', res[0].data.PhotoCount)
            console.log('@photoModel', state.photoModel)
            resolve()

          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {
  setPhotoModel (state, data) {
    Vue.set(state, 'photoModel', data)
  },
  setCount (state, count) {
    Vue.set(state, 'count', count)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
