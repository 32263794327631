import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Vue from 'vue'

const state = {
  active: false,
  map: null,
  // MapVIewのモデル
  model: [],
  // 生成されたMarkerのリスト
  markerList: [],
  // 選択されたマーカーのProjectID
  selectedMarkerId: null,
  // マーカーの表示ZINDEX
  markerZIndex: 1,

  // 震源地のデータ
  hypocenter: null,

  // 各種クエリ
  statusQuery: -1,
  roadAdminQuery: [],

  // 検索中
  searching: false
}

const getters = {
  get: state => state,
  // 選択されたプロジェクト情報を返却
  getSelectedModel: state => {
    try {
      return state.model.filter(item => item.ProjectId === state.selectedMarkerId)[0]
    } catch {
      return {}
    }
  }
}

const actions = {
  /**
   * 検索
   */
  search ({commit}, parameters) {
    commit('selectedMarker', null)
    commit('clearModel')

    // 検索中フラグ
    commit('setSearching', true)

    // 検索
    return new Promise((resolve, reject) => {
      // プロジェクト一覧を読出し
      ApiManager.request(
        ['project'],
        ['v1ProjectListGet'],
        [parameters])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            commit('setModel', res[0].data.Projects)
            console.log('@projectModel:', state.model)

            resolve(res[0].data)
            commit('setSearching', false)

          } else {
            reject(res[0].data)
            commit('setSearching', false)
          }
        })
        .catch(error => {
          reject(error)
          commit('setSearching', false)
        })
    })
  },
  // mapにプロットするマーカーを追加
  addMarker ({commit}, marker) {
    commit('addMarker', marker)
    commit('setMarkerZIndex', state.markerZIndex + 1)
  },
  // mapにプロットされたマーカーを全て削除
  clearMarkers ({commit}) {
    state.markerList.forEach(marker => {
      marker.setMap(null)
    })
    commit('clearMarkers')
    commit('setHypocenter', null)
  },
  // マーカーを選択
  selectMarker ({commit}, id) {
    commit('selectedMarker', id === undefined ? null : id)
  },
  // 初期化
  reset ({commit}) {
    // マーカー
    state.markerList.forEach(marker => {
      marker.setMap(null)
    })
    commit('clearMarkers')
    // 震源地
    commit('setHypocenter', null)
    // データ
    commit('clearModel')
    commit('selectedMarker', null)
    commit('setMarkerZIndex', 1)
    // フィルター
    commit('setStatusQuery', -1)
    // 表示
    commit('setActive', false)
  }
}

const mutations = {
  setActive (state, flag) {
    state.active = flag
    if (flag) {
      this.commit('backgroundMap/setActive', false)
      this.commit('projectFormMap/setActive', false)
    }
  },
  setGoogleMap (state, map) {
    state.map = map
  },
  setModel (state, data) {
    Vue.set(state, 'model', data)
  },
  clearModel (state) {
    Vue.set(state, 'model', [])
  },
  // マーカー
  addMarker (state, marker) {
    state.markerList.push(marker)
  },
  clearMarkers (state) {
    Vue.set(state, 'markerList', [])
  },
  selectedMarker (state, id) {
    state.selectedMarkerId = id
  },
  setMarkerZIndex (state, zindex) {
    state.markerZIndex = zindex
  },
  // 震源地情報
  setHypocenter (state, data) {
    Vue.set(state, 'hypocenter', data)
  },
  // 各種クエリ
  setStatusQuery (state, str) {
    state.statusQuery = str
  },
  setRoadAdminQuery (state, list) {
    // console.log('setRoadAdminQuery', list)
    Vue.set(state, 'roadAdminQuery', list)
  },
  setSearching (state, flag) {
    state.searching = flag
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
