import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Utility from '@/utils/Utility'
import Vue from 'vue'

const state = {
  eventModel: [],
  quakeModel: []
}

const getters = {
  get: state => state
}

const actions = {
  /**
   * 初期読出し
   */
  initLoad ({commit}, {eventParameters, quakeParameters}) {
    return new Promise((resolve, reject) => {
      // イベント一覧・地震速報一覧を読出し
      ApiManager.request(
        ['event', 'util'],
        ['v1EventListGet', 'v1HypocentersListGet'],
        [eventParameters, quakeParameters])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            commit('setEventModel', res[0].data.Events)
            commit('setQuakeModel', res[1].data.Hypocenters)

            console.log('@eventModel', state.eventModel)
            console.log('@quakeModel', state.quakeModel)
            resolve()

          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })

      // ApiManager.request(
      //   ['util'],
      //   ['v1GovermentCodesListGet'],
      //   [{
      //     prefectureName: '東京都'
      //   }])
      //   .then(res => {
      //     console.log('v1GovermentCodesListGet', res[0].data.LocalGovermentCodes)
      //   })
      //   .catch(error => {
      //     console.log('v1GovermentCodesListGet', error)
      //   })
    })
  },
  /**
   * イベント一覧を読出し
   */
  loadEventList ({commit}, {moderator}) {
    return new Promise((resolve, reject) => {
      // イベント一覧を読出し
      let parameters = {}
      if (moderator !== undefined) parameters.moderator = moderator

      ApiManager.request(
        ['event'],
        ['v1EventListGet'],
        [parameters])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            commit('setEventModel', res[0].data.Events)
            console.log('@eventModel', state.eventModel)
            resolve()

          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  /**
   * 地震速報一覧一覧を読出し
   */
  loadQuakeList ({commit}, {originDtFrom, originDtTo}) {
    return new Promise((resolve, reject) => {
      // 地震速報一覧を読出し
      let parameters = {}
      if (originDtFrom !== undefined) parameters.originDtFrom = originDtFrom
      if (originDtTo !== undefined) parameters.originDtTo = originDtTo

      ApiManager.request(
        ['util'],
        ['v1HypocentersListGet'],
        [parameters])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            commit('setQuakeModel', res[0].data.Hypocenters)
            console.log('@quakeModel', state.quakeModel)
            resolve()

          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {
  setEventModel (state, data) {
    data.sort(Utility.sortByKey('CreatedAt', false))
    Vue.set(state, 'eventModel', data)
  },
  setQuakeModel (state, data) {
    Vue.set(state, 'quakeModel', data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
