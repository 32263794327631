export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      ProjectReports: [
        {
          ProjectId: 1,
          AssetId: 101,
          PromptReportName: '速報レポートファイル名',
          PromptReportObjectKey: '速報レポートS3 ObjectKey',
          PromptReportCreatedAt: '2020-03-20 19:20:29',
          PromptReportCreatorCompanyName: '点検会社',
          PromptReportCreatorName: '点検太郎',
          PhotosReportName: '写真レポートファイル名',
          PhotosReportObjectKey: '写真レポートS3 ObjectKey ',
          PhotosReportCreatedAt: '2020-03-20 19:20:29',
          PhotosReportCreatorCompanyName: '点検会社',
          PhotosReportCreatorName: '点検太郎',
          MapReportName: '地図レポートファイル名',
          MapReportObjectKey: '地図レポートS3 ObjectKey ',
          MapReportCreatedAt: '2020-03-20 19:20:29',
          MapReportCreatorCompanyName: '点検会社',
          MapReportCreatorName: '点検太郎',
          CheckReportName: '点検調査レポートファイル名',
          CheckReportObjectKey: '点検調査レポートファイルS３ObjectKey ',
          CheckReportCreatedAt: '2020-03-20 19:20:29',
          CheckReportCreatorCompanyName: '点検会社',
          CheckReportCreatorName: '点検太郎'
        }
      ]
    }
  }
}  
