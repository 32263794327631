import { mapState, mapActions } from 'vuex'
import Constants from '@/utils/Constants'

export default {
  name: 'AssetList',
  data () {
    return {
      // テーブルオプション
      tableColumns: [
        'LocationId',
        'Name',
        'RoadAdmin',
        'OfficeName',
        'Prefecture',
        'Address',
        'BridgeForm',
        'Length',
        'Spanner',
        'Width',
        'Latitude_60_degree',
        'Longitude_60_degree'
      ],
      tableData: [],
      tableOptions: {
        headings: {
          LocationId: '地域番号',
          Name: '橋梁名',
          RoadAdmin: '道路管理者',
          OfficeName: '事務所',
          Prefecture: '都道府県',
          Address: '住所',
          BridgeForm: '橋梁形式',
          Length: '橋長(m)',
          Spanner: '支間長(m)',
          Width: '幅員(m)',
          Latitude_60_degree: '北緯',
          Longitude_60_degree: '東経'
        },
        columnsClasses: {
          LocationId: '',
          Name: '',
          RoadAdmin: ''
        },
        sortable: [],
        filterable: false,
        texts: Constants.TableOptions.getTexts('お知らせ'),
        perPage: Constants.TableOptions.perPage,
        perPageValues: Constants.TableOptions.perPageValues,
        pagination: Constants.TableOptions.pagination,
        resizableColumns: false,
      }
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapState({
      model: state => state.backgroundMap.model
    })
  },
  methods: {
    ...mapActions({
      selectMarker: 'backgroundMap/selectMarker',
      hideList: 'home/hideList'
    }),
    init () {
      console.log(this.model)
      this.tableData = $.extend(true, [], this.model)
    },
    getViewData (props) {
      let key = props.column
      let value = props.row[key]

      if (key === 'Prefecture') return Constants.Prefecture[value]
      if (key === 'Latitude_60_degree')
        return value + '° ' +
          props.row.Latitude_60_minutes + '&#39;' +
          props.row.Latitude_60_seconds + '&quot;'
      if (key === 'Longitude_60_degree')
        return value + '° ' +
          props.row.Longitude_60_minutes + '&#39;' +
          props.row.Longitude_60_seconds + '&quot;'
      return value
    },
    changePage () {
      // console.log(this.$refs.table.page)
    },
    clickDetail (id) {
      this.selectMarker(id)
      this.hideList()
    }
  }
}