import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import Constants from '@/utils/Constants'
import Switcher from '@/components/Home/Switcher'
import AssetList from '@/components/Home/AssetList'
import ProjectPhoto from '@/components/Home/ProjectPhoto'

export default {
  name: 'Home',
  components: {
    Switcher,
    AssetList,
    ProjectPhoto
  },
  metaInfo () {
    this.setMeta({
      title: Constants.SiteInfo.title
      // meta: [
      //   { name: 'description', content: 'xxxxxxxxxx' },
      //   { name: 'keyword', content: 'xxxxxxxxxx' }
      // ]
    })
    return this.getMeta
  },
  created () {
    this.init()
  },
  mounted () {
    // 点検写真一覧表示を初期化
    this.hidePhotoList()

    // GoogleMapを初期化
    this.resetGooglemap()
    this.activateBackgroundMap(true)
  },
  data () {
    return {
      showLoading: true
    }
  },
  computed: {
    ...mapState({
      activeList: state => state.home.activeList,
      activePhoto: state => state.home.activePhoto
    }),
    ...mapGetters({
      getMeta: 'meta/get',
      isModerator: 'auth/isModerator',
      isInspector: 'auth/isInspector'
    }),
    showSwither () {
      return !this.activePhoto
    },
    showAssetList () {
      return this.activeList
    },
    showProjectPhoto () {
      return !this.showLoading && this.activePhoto
    }
  },
  methods: {
    ...mapActions({
      setMeta: 'meta/set',
      hidePhotoList: 'home/hidePhotoList',
      resetGooglemap: 'backgroundMap/reset'
    }),
    ...mapMutations({
      activateBackgroundMap: 'backgroundMap/setActive'
    }),
    init () {
      // if (this.inited) return

      this.showLoading = false
      
      // // データ読出し
      // this.showLoading = true
      // let parameters = {
      //   MajorStatus: 'open',
      //   ShowAll: true,
      //   Status: null
      // }
      // this.load(parameters)
      //   .then(() => {
      //     this.showLoading = false
      //     console.log(this.model)
      //   })
    }
  }
}