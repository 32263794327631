import { mapState, mapGetters, mapActions } from 'vuex'
import Loading from '@/components/Loading'
import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Utility from '@/utils/Utility'

export default {
  name: 'AccountConfirm',
  components: {
    Loading
  },
  data () {
    return {
      connecting: false
    }
  },
  computed: {
    ...mapState({
      values: state => state.account.values
    }),
    ...mapGetters({
      userIdentity: 'auth/userIdentity'
    })
  },
  methods: {
    ...mapActions({
      showError: 'error/show'
    }),
    clickBack () {
      this.$router.go(-1)
    },
    clickRegister () {
      this.connecting = true

      let parameters = {
        UserData: {
          TargetUserIdentity: this.userIdentity,
          UserRole: Utility.getRoleCodeByInt(this.values.userRole),
          Name: this.values.name,
          NameKana: this.values.kana,
          Division: this.values.division,
          Email: this.values.email,
          Phone1: this.values.phone1,
          Phone2: this.values.phone2,
          Phone3: this.values.phone3,
          Address: this.values.address,
          LiveFlg: !this.values.invalidFlag,
          Note: this.values.note,
        }
      }
      console.log('register data', parameters)

      // 登録
      ApiManager.request(
        ['user'],
        ['v1UserUpdate'],
        [parameters])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            this.$router.push({name: 'AccountComplete'})

          // API エラー
          } else if (res[0].data.Result === Constants.ApiResult.Failure) {
            this.showError(res[0].data)
            
          // システムエラー
          } else {
            this.showError(null)
          }
          this.connecting = false
          
        })
        .catch(() => {
          this.showError(null)
          this.connecting = false
        })
    }
  }
}
