import { mapState, mapActions } from 'vuex'

export default {
  name: 'Results',
  mounted () {
    $(window).on({resize: this.onResize })
    
    this.$nextTick(() => {
      this.onResize()
    })
  },
  beforeDestroy () {
    $(window).off({resize: this.onResize })
  },
  computed: {
    ...mapState({
      model: state => state.backgroundMap.model,
      serachedType: state => state.backgroundMap.serachedType,
      searchedQuery: state => state.backgroundMap.searchedQuery
    }),
    getModel () {
      return this.model
    },
    getQuery () {
      if (this.serachedType === null) return ''
      let label = ''
      if (this.serachedType === 0) label = 'キーワード'
      if (this.serachedType === 1) label = '地域番号'
      if (this.serachedType === 2) label = '道路管理者名'
      if (this.serachedType === 3) label = '市区町村'
      if (this.serachedType === 4) label = '竣工年'
      if (this.serachedType === 5) label = '施工会社'
      if (this.serachedType === 6) label = '橋梁管理者'
      if (this.serachedType === 7) label = '橋梁形式'
      if (this.serachedType === 8) label = '地震ID'
      return '「' + label + ' : ' + this.searchedQuery + '」の検索結果'
    }
  },
  methods: {
    ...mapActions({
      selectMarker: 'backgroundMap/selectMarker',
    }),
    clickItem (id) {
      this.selectMarker(id)
    },
    onResize () {
      let windowHeight = window.innerHeight ?
        window.innerHeight : $(window).height()
      let container = $('#asideHomeResult').children('.wrap');
      // let h = windowHeight - 210
      // container.height(h)
      let h = windowHeight - 210
      container.css({maxHeight: h})
    }
  }
}