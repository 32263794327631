export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      Tenants: [
        {
          TenantId: 1,
          TenantIdentity: 'tenantmd5hashxxxxxxxxxxxxxxxx1',
          TenantName: 'テナントaaa',
          Abbreviation: 'aaa',
          Note: '備考\n備考',
          CreatedDt: '2019-08-02',
          UpdatedDt: '2019-09-20'
        },
        {
          TenantId: 2,
          TenantIdentity: 'tenantmd5hashxxxxxxxxxxxxxxxx2',
          TenantName: 'テナントbbb',
          Abbreviation: 'bbb',
          Note: '備考\n備考',
          CreatedDt: '2019-08-02',
          UpdatedDt: '2019-09-20'
        },
        {
          TenantId: 3,
          TenantIdentity: 'tenantmd5hashxxxxxxxxxxxxxxxx3',
          TenantName: 'テナントccc',
          Abbreviation: 'ccc',
          Note: '備考\n備考',
          CreatedDt: '2019-08-02',
          UpdatedDt: '2019-09-20'
        }
      ]
    }
  }
}