// import ApiManager from '@/utils/ApiManager'
// import Constants from '@/utils/Constants'
// import Vue from 'vue'

const state = {
  // 検索結果の橋梁一覧
  activeList: false,
  // 選択したプロジェクトの撮影写真一覧
  activePhoto: false,
  // 選択されたプロジェクトID
  selectedProjectId: null
}

const getters = {
  get: state => state,
  active: state => state.activeList || state.activePhoto
}

const actions = {
  /**
   * 検索結果の橋梁一覧を表示
   */
  showList ({commit}) {
    commit('setShowList', true)
  },
  hideList ({commit}) {
    commit('setShowList', false)
  },
  /**
   * 選択したプロジェクトの撮影写真一覧を表示
   */
  showPhotoList ({commit}, {projectId}) {
    commit('setProjectId', projectId)
    commit('setShowPhoto', true)
  },
  hidePhotoList ({commit}) {
    commit('setShowPhoto', false)
    commit('setProjectId', null)
  }
}

const mutations = {
  setProjectId (state, id) {
    state.selectedProjectId = id
  },
  setShowList (state, flag) {
    state.activeList = flag
  },
  setShowPhoto (state, flag) {
    state.activePhoto = flag
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
