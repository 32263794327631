import { mapState, mapActions } from 'vuex'
export default {
  name: 'Confirm',
  props: {
    id: String
  },
  mounted () {
    // // モーダル表示時の処理
    // $('#' + this.id).on('show.bs.modal', () => {

    // })

    // モーダル非表示時の処理
    $('#' + this.id).on('hide.bs.modal', () => {
      this.hide()
    })
  },
  data () {
    return {
      defaultTitle: '',
      defaultBody: '',
      defaultAcceptLabel: 'はい',
      defaultDenyLabel: 'いいえ'
    }
  },
  computed: {
    ...mapState({
      active: state => state.confirm.active,
      title: state => state.confirm.title,
      body: state => state.confirm.body,
      acceptLabel: state => state.confirm.acceptLabel,
      denyLabel: state => state.confirm.denyLabel
    }),
    getTitle () {
      return this.title || this.defaultTitle
    },
    getBody () {
      return this.body || this.defaultBody
    },
    getAcceptLabel () {
      return this.acceptLabel || this.defaultAcceptLabel
    },
    getDenyLabel () {
      return this.denyLabel || this.defaultDenyLabel
    }
  },
  watch: {
    '$route' () {
      // console.log('close modal')
      $('#' + this.id).modal('hide')
    },
    active (to) {
      if (to) {
        // console.log('open modal')
        $('#' + this.id).modal('show')
      } else {
        // console.log('close modal')
        $('#' + this.id).modal('hide')
      }
    }
  },
  methods: {
    ...mapActions({
      hide: 'confirm/hide',
      accept: 'confirm/accept'
    }),
    clickClose () {
      $('#' + this.id).modal('hide')
    },
    clickAccept () {
      // this.$emit('childs-event', {
      //   accept: true
      // })
      this.accept()
      this.$nextTick(() => {
        $('#' + this.id).modal('hide')
      })
    }
  }
}