import Constants from '@/utils/Constants'

const state = {
  // バージョン
  revision: Constants.Revision,

  // グローバルナビゲーション表示をしているか判別
  showGnav: false,
  // フィルターを表示するかどうか判別
  showFilters: false,
}

const getters = {
  get: state => state,
  // release mode
  privateRelease: state => state.revision === 0,
  publicRelease: state => state.revision === 1
}

const actions = {
  // フィルター
  toggleFilters ({commit}) {
    commit('setShowFilters', !state.showFilters)
  },
}

const mutations = {
  // グロナビ
  setShowGnav (state, flag) {
    state.showGnav = flag
  },
  // フィルター
  setShowFilters (state, flag) {
    state.showFilters = flag
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
