export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      Users: [
        {
          TenantId: 1,
          TenantIdentity: '36ab6155cb5623d4565ec58f48464c62',
          TenantName: '株式会社XXXXXXXX',
          UserId: 21,
          UserIdentity: 'mb2c6a69f00ab0e34583ce9b3c58b4a1',
          LoginId: 'moderator01',
          UserRole: 4,
          Name: 'モデレータ 太郎',
          NameKana: 'モデレータ タロウ',
          Division: 'プロジェクトマネジメント部',
          Email: 'm01@aaa.com',
          Phone1: '080',
          Phone2: '1000',
          Phone3: '1111',
          Address: '連絡先住所',
          Note: '備考\n備考',
          LiveFlg: true,
          CreatedAt: '2019-08-02',
          UpdatedAt: '2019-09-20'
        },
        {
          TenantId: 1,
          TenantIdentity: '36ab6155cb5623d4565ec58f48464c62',
          TenantName: '株式会社XXXXXXXX',
          UserId: 22,
          UserIdentity: 'mb2c6a69f00ab0e34583ce9b3c58b4a2',
          LoginId: 'moderator02',
          UserRole: 4,
          Name: 'モデレータ 次郎',
          NameKana: 'モデレータ ジロウ',
          Division: 'プロジェクトマネジメント部',
          Email: 'm02@aaa.com',
          Phone1: '080',
          Phone2: '1000',
          Phone3: '2222',
          Address: '連絡先住所',
          Note: '備考\n備考',
          LiveFlg: true,
          CreatedAt: '2019-08-02',
          UpdatedAt: '2019-09-20'
        },
        {
          TenantId: 1,
          TenantIdentity: '36ab6155cb5623d4565ec58f48464c62',
          TenantName: '株式会社XXXXXXXX',
          UserId: 23,
          UserIdentity: 'mb2c6a69f00ab0e34583ce9b3c58b4a3',
          LoginId: 'moderator03',
          UserRole: 64,
          Name: '閲覧 太郎',
          NameKana: 'エツラン タロウ',
          Division: '建設部',
          Email: 'm03@aaa.com',
          Phone1: '080',
          Phone2: '1000',
          Phone3: '3333',
          Address: '連絡先住所',
          Note: '備考\n備考',
          LiveFlg: true,
          CreatedAt: '2019-08-02',
          UpdatedAt: '2019-09-20'
        },
        {
          TenantId: 1,
          TenantIdentity: '36ab6155cb5623d4565ec58f48464c62',
          TenantName: '株式会社XXXXXXXX',
          UserId: 31,
          UserIdentity: 'ib2c6a69f00ab0e34583ce9b3c58b4a1',
          LoginId: 'inspector01',
          UserRole: 16,
          Name: 'インスペクター 太郎',
          NameKana: 'インスペクター タロウ',
          Division: '建設部',
          Email: 'i01@aaa.com',
          Phone1: '080',
          Phone2: '2000',
          Phone3: '1111',
          Address: '連絡先住所',
          InspectorCompanyName: 'テンケン会社',
          InspectorCompanyNameKana: 'テンケンカイシャ',
          InspectorTeamLeader: '点検 班長',
          InspectorTeamLeaderKana: 'テンケン ハンチョウ',
          InspectorTeamNumber: '10',
          Note: '備考\n備考',
          LiveFlg: true,
          CreatedAt: '2019-08-02',
          UpdatedAt: '2019-09-20'
        },
        {
          TenantId: 1,
          TenantIdentity: '36ab6155cb5623d4565ec58f48464c62',
          TenantName: '株式会社XXXXXXXX',
          UserId: 32,
          UserIdentity: 'ib2c6a69f00ab0e34583ce9b3c58b4a2',
          LoginId: 'inspector02',
          UserRole: 16,
          Name: 'インスペクター 次郎',
          NameKana: 'インスペクター ジロウ',
          Division: '建設部',
          Email: 'i02@aaa.com',
          Phone1: '080',
          Phone2: '2000',
          Phone3: '2222',
          Address: '連絡先住所',
          InspectorCompanyName: 'テンケン会社',
          InspectorCompanyNameKana: 'テンケンカイシャ',
          InspectorTeamLeader: '点検 班長',
          InspectorTeamLeaderKana: 'テンケン ハンチョウ',
          InspectorTeamNumber: '10',
          Note: '備考\n備考',
          LiveFlg: true,
          CreatedAt: '2019-08-02',
          UpdatedAt: '2019-09-20'
        }
      ]
    }
  }
}