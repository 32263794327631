import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import Constants from '@/utils/Constants'

export default {
  name: 'ProjectIndex',
  metaInfo () {
    this.setMeta({
      title: 'MYプロジェクト一覧 | ' + Constants.SiteInfo.title
    })
    return this.getMeta
  },
  data () {
    return {
      showLoading: true
    }
  },
  mounted () {
    this.init()

    // GoogleMapを初期化
    this.resetGooglemap()
    this.activateProjectMap(true)

    this.setProjectLoaded(false)

    // プロジェクトを読み出し
    let parameters = {
      showAll: false
    }
    if (this.statusQuery !== -1) {
      parameters.status = this.statusQuery
    }
    this.searchGooglemap(parameters)
      .then(() => {
        this.setProjectLoaded(true)
      })
  },
  computed: {
    ...mapState({
      projectLoaded: state => state.eventDetail.loaded,
      statusQuery: state => state.projectMap.statusQuery
    }),
    ...mapGetters({
      getMeta: 'meta/get',
      isModerator: 'auth/isModerator'
    })
  },
  methods: {
    ...mapActions({
      setMeta: 'meta/set',
      showError: 'error/show',
      searchGooglemap: 'projectMap/search',
      resetGooglemap: 'projectMap/reset'
    }),
    ...mapMutations({
      setProjectLoaded: 'eventDetail/setLoaded',
      activateProjectMap: 'projectMap/setActive'
    }),
    init () {

    }
  }
}
