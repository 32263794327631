export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'failure',
      Errors: [
        {
          Code: '1011',
          Message: 'ユーザーIDまたはパスワードが正しくありません'
        }
      ]
    }
  }
}