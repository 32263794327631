import { mapState, mapActions, mapMutations } from 'vuex'
import GoogleMapsApiLoader from 'google-maps-api-loader'
import MapProvider from '@/components/GoogleMap/MapProvider'

export default {
  name: 'MapLoader',
  components: {
    MapProvider
  },
  props: {
    id: String,
    mapConfig: Object,
    apiKey: String
  },
  data () {
    return {
      
    }
  },
  mounted () { // point 3
    this.initGoogle()
  },
  beforeDestroy () {
    this.resetMap()
  },
  computed: {
    ...mapState({
      startedGoogle: state => state.google.started,
      initedGoogle: state => state.google.inited,
      storeGoogle: state => state.google.google,
      storeMap: state => state.assetDetailMap.map
    }),
    google: {
      get: function () {
        return this.storeGoogle
      },
      set: function (value) {
        this.setGoogle(value)
      }
    },
    map: {
      get: function () {
        return this.storeMap
      },
      set: function (value) {
        this.setGoogleMap(value)
      }
    }
  },
  methods: {
    ...mapActions({
      resetMap: 'assetDetailMap/reset'
    }),
    ...mapMutations({
      setStartedGoogle: 'google/setStarted',
      setInitedGoogle: 'google/setInited',
      setGoogle: 'google/setGoogle',
      setGoogleMap: 'assetDetailMap/setGoogleMap'
    }),
    initGoogle () {
      // GoogleAPIの読込中の場合
      if (this.startedGoogle) {
        setTimeout(this.initGoogle, 100)

      // GoogleAPIの初期化が完了している場合
      } else if (this.initedGoogle) {
        this.initGoogleMap()

      // GoogleMapを初期化
      } else {
        this.setStartedGoogle(true)
        GoogleMapsApiLoader({
          apiKey: this.apiKey
        })
          .then(google => {
            this.google = google
            this.setInitedGoogle(true)
            this.setStartedGoogle(false)
            this.initGoogleMap()
          })
      }
    },
    initGoogleMap () {
      // console.log(this.id)
      const mapContainer = this.$el.querySelector('#' + this.id) // point 1
      const { Map } = this.google.maps
      this.map = new Map(mapContainer, this.mapConfig)
    }
  }
}