export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      Projects: [
        {
          ProjectId: 2,
          EventId: 1,
          AssetId: 102,
          AssetName: '合ヶ瀬大橋',
          EventName: 'イベント001',
          EventProjectNumber: 2,
          ModeratorIdentity: 'mb2c6a69f00ab0e34583ce9b3c58b4a1',
          InspectorIdentity: 'ib2c6a69f00ab0e34583ce9b3c58b4a1',
          ProjectName: '合ヶ瀬大橋',
          ProjectStatus: 32,
          StartDatePlan: '2020-04-20',
          EndDatePlan: '2020-05-08',
          StatusUpdateAt: '2020-04-12 12:39:20',
          ProjectCreatedAt: '2020-04-02 10:21:06',
          ProjectUpdatedAt: '2020-04-12 12:39:20',
          ProjectProfilePhoto: '橋梁写真S3 KEY',
          ProjectNote: 'プロジェクト備考',
          ModeratorName: 'モデレータ 太郎',
          ModeratorNameKana: 'モデレータ タロウ',
          ModeratorDivision: 'プロジェクトマネジメント部',
          ModeratorPhone1: '080',
          ModeratorPhone2: '1000',
          ModeratorPhone3: '1111',
          ModeratorAddress: '連絡先住所',
          InspectorName: 'インスペクター 太郎',
          InspectorNameKana: 'インスペクター タロウ',
          InspectorCompanyName: 'テンケン会社',
          InspectorCompanyNameKana: 'テンケンカイシャ',
          InspectorLeaderName: '点検 班長',
          InspectorLeaderNameKana: 'テンケン ハンチョウ',
          InspectorDivision: '建設部',
          InspectorPhone1: '080',
          InspectorPhone2: '2000',
          InspectorPhone3: '1111',
          InspectorAddress: '連絡先住所',
          BridgeName: '合ヶ瀬大橋',
          RoadAdmin: '道路管理者',
          LineName: '路線名',
          BridgeForm: '3径間連続上路トラス橋',
          BridgeLength: '213.65',
          BridgeWidth: '7.5',
          OfficeName: '事業所名',
          OfficeContact: '連絡先',
          ProjectJishiInfo: []
        }
      ]
    }
  }
}
