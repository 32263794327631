import { mapState, mapGetters, mapActions } from 'vuex'
import Loading from '@/components/Loading'
import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'

export default {
  name: 'EventConfirm',
  components: {
    Loading
  },
  data () {
    return {
      connecting: false
    }
  },
  computed: {
    ...mapState({
      eventId: state => state.eventForm.targetId,
      values: state => state.eventForm.values,
      selectedQuakeModel: state => state.eventForm.selectedQuakeModel,
    }),
    ...mapGetters({
      isRegister: 'eventForm/isRegister',
      isModify: 'eventForm/isModify'
    }),
    invalidFlag () {
      return this.values.invalidFlag ? '無効' : '有効'
    },
    note () {
      return this.values.note ? this.values.note.replace(/\n/, '<br>') : ''
    }
  },
  methods: {
    ...mapActions({
      showError: 'error/show',
      selectEventId: 'projectForm/selectEventId',
      showEqModal: 'eqModal/show'
    }),
    clickBack () {
      this.$router.go(-1)
    },
    clickEq (item) {
      this.showEqModal({
        id: item.EqId,
        model: $.extend(true, {}, item)
      })
    },
    clickRegister () {
      this.connecting = true

      let method = this.isRegister ? 'v1EventPost' : 'v1EventUpdate'
      let parameters = {
        EventData: {}
      }
      if (this.values.note) {
        parameters.EventData.Note = this.values.note
      }
      if (this.selectedQuakeModel.length) {
        parameters.EventData.EqIds = []
        this.selectedQuakeModel.forEach(item => parameters.EventData.EqIds.push(item.EqId))
      }
      if (this.isRegister) {
        parameters.EventData.EventName = this.values.eventName
      }
      if (this.isModify) {
        parameters.EventData.EventId = Number(this.eventId)
        parameters.EventData.Name = this.values.eventName
        // parameters.EventData.LiveFlg = !this.values.invalidFlag
      }
      console.log('register data', parameters)

      // 登録
      ApiManager.request(
        ['event'],
        [method],
        [parameters])
        .then(res => {
          console.log('v1EventPost', res)
          if (res[0].data.Result === Constants.ApiResult.Success) {
            // イベント登録後にプロジェクト登録に遷移するため、
            // 登録したイベントIDをストアに保持
            if (this.isRegister &&
              this.selectedQuakeModel.length) {
              this.selectEventId({
                eventId: res[0].data.EventId
              })
            }

            // 完了画面
            this.$router.push({name: 'EventRegisterComplete'})

          // API エラー
          } else if (res[0].data.Result === Constants.ApiResult.Failure) {
            this.showError(res[0].data)

          // システムエラー
          } else {
            this.showError(null)
          }
          this.connecting = false
          
        })
        .catch(() => {
          this.showError(null)
          this.connecting = false
        })
    }
  }
}
