export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Results: [
        {
          Result: 'success',
          TakenPhotoId: 1
        }
      ]
    }
  }
}