import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Vue from 'vue'

const state = {
  active: true,
  map: null,
  // MapVIewのモデル
  model: [],
  // 生成されたMarkerのリスト
  markerList: [],
  // 選択されたマーカーのAssetID
  selectedMarkerId: null,
  // マーカーの表示ZINDEX
  markerZIndex: 1,

  // 震源地のデータ
  hypocenter: null,

  // 各種クエリ
  assetNameQuery: '',
  prefectureQuery: '',
  cityQuery: '',
  eqQuery: '',
  roadAdminQuery: [],

  // 登録用に選択されたAssetのリスト
  value: [],

  // 検索クエリ
  // serachedType: null,
  // searchedQuery: '',

  // 検索中
  searching: false
}

const getters = {
  get: (state) => {
    return state
  },
  // 選択されたアセット情報を返却
  getSelectedModel: state => state.model.filter(item => item.AssetId === state.selectedMarkerId)
}

const actions = {
  /**
   * 検索
   */
  search ({commit}) {
    commit('selectedMarker', null)
    commit('clearModel')

    // 検索中フラグ
    commit('setSearching', true)

    /*
     * 検索クエリ以外の入力をリセット
     */
    // let clearQueries = type => {
    //   let queries = [
    //     'assetNameQuery',
    //     'prefectureQuery',
    //     'cityQuery',
    //     'eqQuery'
    //   ]
    //   queries.forEach((item, index) => {
    //     if (type !== index) Vue.set(state, item, '')
    //   })
    // }

    // // 検索条件
    // let value = {
    //   type: type,
    //   query: ''
    // }
    // if (type === 0) value.query = state.assetNameQuery
    // if (type === 1) value.query = state.prefectureQuery
    // if (type === 2) value.query = state.cityQuery
    // if (type === 3) value.query = state.eqQuery
    // commit('setSearchedValue', value)

    return new Promise((resolve, reject) => {
      let parameters = {
        showAll: false
      }
      if (state.assetNameQuery)
        parameters.bridgeName = state.assetNameQuery
      if (state.prefectureQuery)
        parameters.prefecture = state.prefectureQuery
      if (state.cityQuery)
        parameters.city = state.cityQuery
      if (state.eqQuery)
        parameters.eqId = state.eqQuery
      if (state.roadAdminQuery.length)
        parameters.roadAdminKbns = state.roadAdminQuery
      console.log('parameters : ', parameters)

      // 検索クエリ以外の入力をリセット
      // clearQueries(type)

      // プロジェクト一覧・管理者一覧を読出し
      ApiManager.request(
        ['asset'],
        ['v1AssetListGet'],
        [parameters])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            commit('setModel', res[0].data.Assets)
            if (res[0].data.Hypocenter) {
              commit('setHypocenter', res[0].data.Hypocenter)
            }
            console.log('GoogleMap.model', state.model)
            console.log('GoogleMap.hypocenter', state.hypocenter)

            resolve()
            commit('setSearching', false)

          } else {
            reject(res[0].data)
            commit('setSearching', false)
          }
        })
        .catch(error => {
          reject(error)
          commit('setSearching', false)
        })
    })
  },
  // search ({commit}, {type}) {
  //   commit('selectedMarker', null)
  //   commit('clearModel')

  //   // 検索中フラグ
  //   commit('setSearching', true)

  //   /*
  //    * 検索クエリ以外の入力をリセット
  //    */
  //   let clearQueries = type => {
  //     let queries = [
  //       'assetNameQuery',
  //       'prefectureQuery',
  //       'cityQuery',
  //       'eqQuery'
  //     ]
  //     queries.forEach((item, index) => {
  //       if (type !== index) Vue.set(state, item, '')
  //     })
  //   }

  //   // 検索条件
  //   let value = {
  //     type: type,
  //     query: ''
  //   }
  //   if (type === 0) value.query = state.assetNameQuery
  //   if (type === 1) value.query = state.prefectureQuery
  //   if (type === 2) value.query = state.cityQuery
  //   if (type === 3) value.query = state.eqQuery
  //   commit('setSearchedValue', value)

  //   return new Promise((resolve, reject) => {
  //     let parameters = {
  //       showAll: false
  //     }
  //     if (type === 0) parameters.bridgeName = state.searchedQuery
  //     if (type === 1) parameters.prefecture = state.searchedQuery
  //     if (type === 2) parameters.city = state.searchedQuery
  //     if (type === 3) parameters.eqId = state.searchedQuery
  //     console.log('parameters : ', parameters)

  //     // 検索クエリ以外の入力をリセット
  //     clearQueries(type)

  //     // プロジェクト一覧・管理者一覧を読出し
  //     ApiManager.request(
  //       ['asset'],
  //       ['v1AssetListGet'],
  //       [parameters])
  //       .then(res => {
  //         if (res[0].data.Result === Constants.ApiResult.Success) {
  //           commit('setModel', res[0].data.Assets)
  //           if (res[0].data.Hypocenter) {
  //             commit('setHypocenter', res[0].data.Hypocenter)
  //           }
  //           console.log('GoogleMap.model', state.model)
  //           console.log('GoogleMap.hypocenter', state.hypocenter)

  //           resolve()
  //           commit('setSearching', false)

  //         } else {
  //           reject(res[0].data)
  //           commit('setSearching', false)
  //         }
  //       })
  //       .catch(error => {
  //         reject(error)
  //         commit('setSearching', false)
  //       })
  //   })
  // },
  // mapにプロットするマーカーを追加
  addMarker ({commit}, marker) {
    commit('addMarker', marker)
    commit('setMarkerZIndex', state.markerZIndex + 1)
  },
  // mapにプロットされたマーカーを全て削除
  clearMarkers ({commit}) {
    // console.log('clearMarkers', $.extend(true, [], state.markerList))
    state.markerList.forEach(marker => {
      marker.setMap(null)
    })
    commit('clearMarkers')
    commit('setHypocenter', null)
  },
  // マーカーを選択
  selectMarker ({commit}, id) {
    commit('selectedMarker', id === undefined ? null : id)
  },

  // 任意のAssetを登録用に選択
  addValue({commit}, assetIds) {
    commit('addValue', assetIds)
  },
  // 任意のAssetを登録用リストから削除
  removeValue({commit}, assetIds) {
    commit('removeValue', assetIds)
  },

  // 初期化
  reset ({commit}) {
    // マーカー
    state.markerList.forEach(marker => {
      marker.setMap(null)
    })
    commit('clearMarkers')
    // 震源地
    commit('setHypocenter', null)
    // データ
    commit('clearModel')
    commit('selectedMarker', null)
    commit('setMarkerZIndex', 1)
    // フィルター
    commit('setAssetNameQuery', '')
    commit('setPrefectureQuery', '')
    commit('setCityQuery', '')
    commit('setEqQuery', '')
    commit('setRoadAdminQuery', [])
    // 選択されたアセット
    commit('flushValue')
    // 表示
    commit('setActive', false)
  }
}

const mutations = {
  setActive (state, flag) {
    state.active = flag
    if (flag) {
      this.commit('backgroundMap/setActive', false)
      this.commit('projectMap/setActive', false)
    }
  },
  setGoogleMap (state, map) {
    state.map = map
  },
  setModel (state, data) {
    Vue.set(state, 'model', data)
  },
  clearModel (state) {
    Vue.set(state, 'model', [])
  },
  // マーカー
  addMarker (state, marker) {
    state.markerList.push(marker)
  },
  clearMarkers (state) {
    Vue.set(state, 'markerList', [])
  },
  selectedMarker (state, id) {
    state.selectedMarkerId = id
  },
  setMarkerZIndex (state, zindex) {
    state.markerZIndex = zindex
  },
  // 震源地情報
  setHypocenter (state, data) {
    Vue.set(state, 'hypocenter', data)
  },
  // 各種クエリ
  setAssetNameQuery (state, str) {
    state.assetNameQuery = str
  },
  setPrefectureQuery (state, str) {
    state.prefectureQuery = str
  },
  setCityQuery (state, str) {
    state.cityQuery = str
  },
  setEqQuery (state, str) {
    state.eqQuery = str
  },
  setRoadAdminQuery (state, list) {
    // console.log('setRoadAdminQuery', list)
    Vue.set(state, 'roadAdminQuery', list)
  },

  // 登録用に選択されたAssetのリスト
  addValue (state, assetIds) {
    assetIds.forEach(id => {
      let index = state.value.findIndex(item => id === item.AssetId)
      if (index === -1) {
        let data = state.model.filter(item => id === item.AssetId)[0]
        state.value.push(data)
      }
    })
    console.log('SelectedAsset:', state.value)
  },
  removeValue (state, assetIds) {
    console.log('removeValue', assetIds)
    let list = state.value.filter(item => assetIds.indexOf(item.AssetId) === -1)
    Vue.set(state, 'value', list)

    // state.value.forEach(item => {
    //   let index = assetIds.findIndex(id => id === item.AssetId)
    //   if (index !== -1) {
    //     state.value.splice(index, 1)
    //   }
    // })
    console.log('SelectedAsset:', state.value)
  },
  flushValue (state) {
    Vue.set(state, 'value', [])
  },

  // 検索クエリ
  setSearchedValue (state, {type, query}) {
    state.serachedType = type
    state.searchedQuery = query
  },
  setSearching (state, flag) {
    state.searching = flag
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
