import { mapState } from 'vuex'
import Utility from '@/utils/Utility'

export default {
  name: 'CommentHistory',
  props: {
    index: Number
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapState({
      commentModel: state => state.projectDetail.commentModel
    }),
    commentList () {
      let list = $.extend(true, [], this.commentModel)

      // 日付で並替え
      list.sort(Utility.sortByKey('CreatedAt', false))

      // 各項目のフォーマット
      list.forEach(item => {
        this.$set(item, 'nameResult', item.CreateUserName)
        this.$set(item, 'commentResult', Utility.decodeBraak(item.Comment))
        this.$set(item, 'dateResult', Utility.getJSTDate(item.CreatedAt, 'YYYY/MM/DD HH:mm'))
      })

      return list
    },
    showHistory () {
      return this.commentModel.length
    }
  },
  methods: {

  }
}