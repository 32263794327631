import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import Loading from '@/components/Loading'
import Constants from '@/utils/Constants'

export default {
  name: 'ProjectForm',
  components: {
    Loading
  },
  data () {
    return {
      showLoading: false,
      registerHash: Constants.Params.RegisterHash,
      // 地震ID
      showEqSuggest: false,
      focusEqSuggest: false,
      // 道路管理者区分のドロップダウン表示
      showRoadAdmin: false
    }
  },
  mounted () {
    // resize
    $(window).on({resize: this.onResize })
    this.$nextTick(() => {
      this.onResize()
    })
  },
  beforeDestroy () {
    $(window).off({resize: this.onResize })
  },
  computed: {
    ...mapState({
      inited: state => state.projectForm.inited,
      eventId: state => state.projectForm.eventId,
      eqId: state => state.projectForm.eqId,
      values: state => state.projectForm.values,
      eventModel: state => state.projectForm.eventModel,
      assetModel: state => state.projectFormMap.model,
      assetValue: state => state.projectFormMap.value,
      selectedMarkerId: state => state.projectFormMap.selectedMarkerId,
      // filters
      assetNameQuery: state => state.projectFormMap.assetNameQuery,
      prefectureQuery: state => state.projectFormMap.prefectureQuery,
      cityQuery: state => state.projectFormMap.cityQuery,
      eqQuery: state => state.projectFormMap.eqQuery,
      roadAdminQuery: state => state.projectFormMap.roadAdminQuery,
      // serachedType: state => state.projectFormMap.serachedType,
      // event detail
      // eventModel: state => state.eventDetail.values,
    }),
    ...mapGetters({
      isModerator: 'auth/isModerator',
      isInspector: 'auth/isInspector',
      isObserver: 'auth/isObserver'
    }),

    // filters
    assetName: {
      get () { return this.assetNameQuery },
      set (value) { this.setAssetNameQuery(value) }
    },
    prefecture: {
      get () { return this.prefectureQuery },
      set (value) { this.setPrefectureQuery(value) }
    },
    city: {
      get () { return this.cityQuery },
      set (value) { this.setCityQuery(value) }
    },
    eq: {
      get () { return this.eqQuery },
      set (value) { this.setEqQuery(value) }
    },
    roadAdminFilter: {
      get () { return this.roadAdminQuery },
      set (value) { this.setRoadAdminQuery(value) }
    },

    // 地震ID
    eqIdList () {
      // イベントに紐づく地震IDを返す
      console.log('eventModel:', this.eventModel)
      let list = []
      if (this.eventModel.EarthquakesInfo &&
        this.eventModel.EarthquakesInfo.length) {
        this.eventModel.EarthquakesInfo.forEach(item => {
          if (this.eq !== item.EqId) {
            list.push(item.EqId)
          }
        })
      }
      // if (this.eqId !== null)
      //   list.push(this.eqId)
      // list.push('11111000000')
      // list.push('11101010101')
      return list
    },

    // 道路管理区分
    roadAdminPeriodList () {
      let list = []
      // let list = [{
      //   value: '',
      //   label: '全ての道路管理区分'
      // }]
      Object.keys(Constants.AssetConst.RoadAdminPeriod)
        .forEach(item => list.push({
          value: item,
          label: Constants.AssetConst.RoadAdminPeriod[item]
        }))
      return list
    },
    roadAdminValue () {
      if (!this.roadAdminQuery.length)
        return '全ての道路管理区分'

      let list = this.roadAdminPeriodList.filter(item => this.roadAdminQuery.indexOf(item.value) !== -1)
      let str = ''
      list.forEach(item => {
        str += str === '' ? '' : '、'
        str += item.label
      })
      return str
    },

    disabledSearchButton () {
      return !this.assetName &&
        !this.prefecture &&
        !this.city &&
        !this.eq
    },
    disabledSelectButton () {
      // console.log('disabledSelectButton', this.assetModel)
      return this.assetModel.length === 0
    }
  },
  watch: {
    // statusFilter () {
    //   this.search()
    // }
    inited (to) {
      // イベントモデルの読み出しが完了したら地震ID初期値を反映
      if (!to) return

      // 自身を紐付けたイベント新規登録から遷移
      console.log('Project from Event | eqId : ', this.eqId)
      if (this.eqId) {
        this.eq = this.eqId

      // イベント詳細から新規作成
      } else if (this.eventModel.EarthquakesInfo &&
        this.eventModel.EarthquakesInfo.length) {
        this.eq = this.eventModel.EarthquakesInfo[0].EqId
      }
    },
    roadAdminFilter () {
      this.clickSearch()
    }
  },
  methods: {
    ...mapActions({
      showError: 'error/show',
      showAlert: 'alert/show',
      searchGooglemap: 'projectFormMap/search',
      selectMarker: 'projectFormMap/selectMarker',
      addValue: 'projectFormMap/addValue'
    }),
    ...mapMutations({
      setAssetNameQuery: 'projectFormMap/setAssetNameQuery',
      setPrefectureQuery: 'projectFormMap/setPrefectureQuery',
      setCityQuery: 'projectFormMap/setCityQuery',
      setEqQuery: 'projectFormMap/setEqQuery',
      setRoadAdminQuery: 'projectFormMap/setRoadAdminQuery'
    }),
    init () {

    },
    onResize () {
      let windowHeight = window.innerHeight ?
        window.innerHeight : $(window).height()
      let container = $('#asideProjectList').children('.wrap').children('.projects');
      let bodyH = windowHeight - 120
      let h1 = container.children('.filters').length ?
        container.children('.filters').outerHeight() : 0
      let h2 = container.find('.roadAdmin').length ?
        container.find('.roadAdmin').outerHeight() : 0
      let h3 = container.find('.footer').length ?
        container.find('.footer').outerHeight() : 0
        console.log(h1 + ' - ' + h2 + ' - ' + h3)
      let h = bodyH - h1 - h2 - h3

      container.find('.list').children('.wrap').height(h)
    },
    clickSearch () {
      if (this.disabledSearchButton) return

      // 検索
      this.searchGooglemap()
        .then(() => {
          if (!this.assetModel.length) {
            this.showAlert({
              body: '該当する橋梁が見つかりません'
            })
          }
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
        })
    },
    // clickSearch (type) {
    //   if (type === 0 && !this.assetName) return
    //   if (type === 1 && !this.prefecture) return
    //   if (type === 2 && !this.city) return
    //   if (type === 3 && !this.eq) return

    //   // 検索
    //   this.searchGooglemap({
    //     type: type
    //   })
    //     .then(() => {
    //       if (!this.assetModel.length) {
    //         this.showAlert({
    //           body: '該当する橋梁が見つかりません'
    //         })
    //       }
    //     })
    //     .catch(error => {
    //       // API エラー
    //       if (error.Result === Constants.ApiResult.Failure) {
    //         this.showError(error)
            
    //       // システムエラー
    //       } else {
    //         this.showError(null)
    //       }
    //     })
    // },
    /**
     * 地震ID
     */
    focusEqInput () {
      this.showEqSuggest = true
    },
    blurEqInput () {
      if (!this.focusEqSuggest) {
        this.showEqSuggest = false
      }
    },
    mouseoverEqSuggest () {
      this.focusEqSuggest = true
    },
    mouseoutEqSuggest () {
      this.focusEqSuggest = false
    },
    clickEqSuggest (str) {
      this.eq = str
      this.showEqSuggest = false
    },
    /**
     * 道路管理者区分
     */
    toggleRoadAdmin () {
      // console.log('roadAdminQuery :', this.roadAdminQuery)
      this.showRoadAdmin = !this.showRoadAdmin
    },
    /**
     * マーカー画像を取得
     */
    getMarker (model) {
      // サイズ
      let size = Constants.GoogleMapMarkerSize.md

      // 色
      let roadAdminKbn = model.RoadAdminKbn ? String(model.RoadAdminKbn) : '4'
      let color = Constants.GoogleMapMarkerColor.roadAdminKbn[roadAdminKbn][1]
      
      // let color = this.selectedMarkerId === model.assetId ?
      //   Constants.GoogleMapMarkerColor.active :
      //   this.assetValue.findIndex(item => model.assetId === item.AssetId) === -1 ?
      //     Constants.GoogleMapMarkerColor.default :
      //     Constants.GoogleMapMarkerColor.selected

      // マーカーを生成
      let template = Constants.GoogleMapMarker.Pin()
      let svg = template.replace('{size}', size + 'px')
        .replace('{color}', color)
        .replace('{center}', Constants.GoogleMapMarkerColor.center)
        .replace('{stroke}', Constants.GoogleMapMarkerColor.stroke)
      return 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(svg)
    },
    clickItem (id) {
      this.selectMarker(id)
    },

    /**
     * すべてのアセットを選択
     */
    selectAllAssets () {
      let list = this.assetModel.map(item => item.AssetId)
      this.addValue(list)
    }
  }
}