import { mapState, mapGetters, mapActions } from 'vuex'
import Loading from '@/components/Loading'
import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import PhotoUploader from '@/components/Project/Task3/PhotoUploader'
import CommentHistory from '@/components/Project/CommentHistory'

export default {
  name: 'ProjectTask3',
  components: {
    Loading,
    PhotoUploader,
    CommentHistory
  },
  data () {
    return {
      showLoading: true,
      // flag
      isClickedConfirm: false,
      connectingComment: false
    }
  },
  mounted () {
    console.log('--- Task3 ---')
    this.init()
  },
  computed: {
    ...mapState({
      // model
      projectModel: state => state.projectDetail.projectModel,
      assetModel: state => state.projectDetail.assetModel,
      commentModel: state => state.projectDetail.commentModel,
      photoModel: state => state.projectTask3.photoModel,
      photoCount: state => state.projectTask3.photoCount,
      reportName: state => state.projectTask3.reportName,
      reportObjectKey: state => state.projectTask3.reportObjectKey,
      // value
      photoValue: state => state.projectTask3.photoValue,
      moderatorCommentValue: state => state.projectTask3.moderatorCommentValue,
      inspectorCommentValue: state => state.projectTask3.inspectorCommentValue
    }),
    ...mapGetters({
      isModerator: 'auth/isModerator',
      isInspector: 'auth/isInspector',
      isObserver: 'auth/isObserver',
      projectCompleted: 'projectDetail/projectCompleted'
    }),
    commentLabel () {
      return this.isModerator ?
        '点検者へのコメント' : '管理者へのコメント'
    },
    
    // detail
    bridgeAdmin () {
      if (!this.assetModel) return '-'
      return this.assetModel.BridgeAdmin || '-'
    },
    lineName () {
      if (!this.assetModel) return '-'
      return this.assetModel.LineName || '-'
    },
    photoCountResult () {
      if (!this.photoCount) return '-'
      return this.photoCount + '枚'
    },

    // 実施者
    showPractitioner () {
      try {
        return !this.disabledDownloadButton
        // return this.projectModel.ProjectJishiInfo.filter(item => item.ProjectStatus === 4).length > 0
      } catch (e) {
        return false
      }
    },
    practiceModel () {
      return this.projectModel.ProjectJishiInfo.filter(item => item.ProjectStatus === 4)[0]
    },
    practiceCompany () {
      return this.practiceModel.InspectorCompanyName || ''
    },
    practiceInspector () {
      return this.practiceModel.InspectorName || ''
    },
    practiceLeader () {
      return this.practiceModel.InspectorLeaderName || ''
    },
    practiceDivision () {
      return this.practiceModel.InspectorDivision || ''
    },
    practicePhone () {
      if (!this.practiceModel.InspectorPhone1 &&
        !this.practiceModel.InspectorPhone2 &&
        !this.practiceModel.InspectorPhone3)
        return ''
      return this.practiceModel.InspectorPhone1 + '-'
        + this.practiceModel.InspectorPhone2 + '-'
        + this.practiceModel.InspectorPhone3
    },

    // form
    enablePhotoForm () {
      return this.isInspector && !this.projectCompleted
    },
    enableCommentForm () {
      return (this.isModerator || this.isInspector) &&
        !this.projectCompleted
    },

    // input
    comment: {
      get () {
        return this.isModerator ?
          this.moderatorCommentValue : this.inspectorCommentValue
      },
      set (value) {
        this.isModerator ?
          this.setModeratorComment(value) : this.setInspectorComment(value)
      }
    },

    // button
    disabledDownloadButton () {
      return this.reportObjectKey === ''
    },
    disabledCommentButton () {
      if (this.connectingComment) return true
      return this.isModerator ?
        this.moderatorCommentValue === '' :
        this.inspectorCommentValue === ''
    }
  },
  methods: {
    ...mapActions({
      showError: 'error/show',
      showAlert: 'alert/show',
      // Task3
      initLoad: 'projectTask3/initLoad',
      // コメント
      loadComments: 'projectDetail/loadComments',
      saveComment: 'projectDetail/saveComment',
      setModeratorComment: 'projectTask3/setModeratorComment',
      setInspectorComment: 'projectTask3/setInspectorComment',
      // 初期化
      reset: 'projectTask3/reset',
    }),
    init () {
      // 初期化
      this.reset()
      this.showLoading = true

      // データ読出し
      this.initLoad({
        ProjectId: this.projectModel.ProjectId
      })
        .then(() => {
          console.log('@photoModel', this.photoModel)
          console.log('@commentModel', this.commentModel)
          console.log('@photoValue', this.photoValue)
          this.showLoading = false
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
        })
    },
    /**
     * 写真報告書のダウンロード
     */
    clickDownload () {
      ApiManager.request(
        ['aws'],
        ['v1AwsPresignedUrlGet'],
        [{
          docType: 10,
          s3ObjectKey: this.reportObjectKey,
          use: 1
        }])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            let url = res[0].data.S3ObjectInfo.PreSignedUrl
            window.open(url)

          } else if (res[0].data.Result === Constants.ApiResult.Failure) {
            this.showError(res[0].data.Errors)

          } else {
            this.showError(null)
          }
        })
        .catch(() => {
          this.showError(null)
        })
    },
    /**
     * プロジェクト一覧に戻る
     */
    clickBack () {
      // 点検者はプロジェクト一覧へ
      if (this.isInspector) {
        this.$router.push({ name: 'Project' })

      // 管理者はイベント詳細へ
      } else {
        this.$router.push({
          name: 'EventDetail',
          params: {
            eventId: String(this.projectModel.EventId)
          }
        })
      }
    },

    /**
     * コメントを登録
     */
    clickComment () {
      this.connectingComment = true

      // パラメータ
      let parameters = {
        ProjectId: this.projectModel.ProjectId,
        CommentData: {
          CommentType: this.isModerator ? 2 : 1,
          Comment: this.isModerator ?
            this.moderatorCommentValue : this.inspectorCommentValue
        }
      }

      // コメントを登録
      this.saveComment(parameters)
        .then(() => {
          // this.showAlert({
          //   key: 'add-comment',
          //   body: 'コメントを登録しました'
          // })
          this.comment = ''

          // コメント一覧を読出し
          this.loadComments({
            ProjectId: this.projectModel.ProjectId
          })
            .catch(error => {
              // API エラー
              if (error.Result === Constants.ApiResult.Failure) {
                this.showError(error)
                
              // システムエラー
              } else {
                this.showError(null)
              }
            })

          this.connectingComment = false
          
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
          this.connectingComment = false
        })
    }
  }
}
