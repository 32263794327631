import { mapState, mapGetters,  mapActions } from 'vuex'
import Constants from '@/utils/Constants'
import Utility from '@/utils/Utility'
import DamageFormPhoto from './Photo'

export default {
  name: 'DamageForm',
  components: {
    DamageFormPhoto
  },
  props: {
    options: Object
  },
  data () {
    return {

    }
  },

  mounted() {
    if (this.isModify) return;
    // console.log(this.options.sectionKey, this.options.preset.TargetView, this.options.preset)

    if (this.options.preset &&
      this.options.preset.DamageDescription) {
      this.section = 'true';
      this.comment = this.options.preset.DamageDescription;
    }

    if (this.options.preset &&
      this.options.preset.ProjectPhotoNumber) {
      this.addDamagePhotoId({
        sectionKey: this.options.sectionKey,
        takenPhotoId: this.options.preset.TakenPhotoId
      })
    }
  },

  computed: {
    ...mapState({
      // photoValue: state => state.projectTask3.photoValue
    }),
    ...mapGetters({
      isModerator: 'auth/isModerator',
      isInspector: 'auth/isInspector',
      isObserver: 'auth/isObserver',
      projectCompleted: 'projectDetail/projectCompleted',
      getDamageValue: 'projectTask4/getDamageValue',
      isModify: 'projectTask4/isModify',
    }),
    showLoadCapacity () {
      return this.options.loadCapacity !== undefined
    },
    showRunnability () {
      return this.options.runnability !== undefined
    },

    // ラジオボタンのname
    existsName () {
      return this.options.sectionKey + '-' + 'exists'
    },
    sectionName () {
      return this.options.sectionKey + '-' + 'section'
    },

    // result
    existsResult () {
      if (this.exists === undefined || this.exists === null) return '-'
      return this.exists ? '点検対象' : '非対象'
    },
    sectionResult () {
      if (this.section === undefined || this.section === null) return '-'
      if (!this.exists) return '-'
      if (!this.section) return '未確認'

      if (this.damage) {
        if (Constants.ProjectConst.LoadCapacity.indexOf(this.damage) !== -1) {
          return '耐荷力 ' + this.damage
        } else if (Constants.ProjectConst.Runnability.indexOf(this.damage) !== -1) {
          return '走行性 ' + this.damage
        } else if (Constants.ProjectConst.Aging.indexOf(this.damage) !== -1) {
          return '経年劣化 ' + this.damage
        } else {
          return '-'
        }
      }
      return '-'
      // return this.loadCapacity || this.runnability || this.aging || '-'
    },
    commentResult () {
      if (this.comment === undefined || this.comment === null) return '-'
      if (!this.exists) return '-'
      return this.comment  ? Utility.decodeBraak(this.comment) : '-'
    },

    // 入力値のリスト
    damageList () {
      let list = []
      if (this.options.loadCapacity) {
        this.options.loadCapacity.items.forEach(item => {
          list.push({
            label: '耐荷力 ' + item,
            value: item
          })
        })
      }
      if (this.options.runnability) {
        this.options.runnability.items.forEach(item => {
          list.push({
            label: '走行性 ' + item,
            value: item
          })
        })
      }
      if (this.options.aging) {
        this.options.aging.items.forEach(item => {
          list.push({
            label: '経年劣化 ' + item,
            value: item
          })
        })
      }
      return list
    },
    // loadCapacityList () {
    //   return this.options.loadCapacity.items
    // },
    // runnabilityList () {
    //   return this.options.runnability.items
    // },
    // agingList () {
    //   return this.options.aging.items
    // },

    // 添付写真のリスト
    photoList () {
      let list = this.exists && this.section ?
        $.extend(true, [], this.getDamageValue(this.options.sectionKey).photoIds) :
        []
      if (this.enableForm) list.push(null)
      return list
    },

    // input
    exists: {
      get () { return this.getDamageValue(this.options.sectionKey).exists },
      set (value) {
        this.setDamageParam({
          sectionKey: this.options.sectionKey,
          key: 'exists',
          value: value === 'true'
        })
      }
    },
    section: {
      get () {
        if (!this.exists) return false
        return this.getDamageValue(this.options.sectionKey).section
      },
      set (value) {
        this.setDamageParam({
          sectionKey: this.options.sectionKey,
          key: 'section',
          value: value === 'true'
        })
      }
    },
    damage: {
      get () {
        if (!this.exists || !this.section) return ''
        return this.getDamageValue(this.options.sectionKey).loadCapacity ||
          this.getDamageValue(this.options.sectionKey).runnability ||
          this.getDamageValue(this.options.sectionKey).aging || ''
      },
      set (value) {
        let key = null
        if (Constants.ProjectConst.LoadCapacity.indexOf(value) !== -1) {
          key = 'loadCapacity'
        } else if (Constants.ProjectConst.Runnability.indexOf(value) !== -1) {
          key = 'runnability'
        } else if (Constants.ProjectConst.Aging.indexOf(value) !== -1) {
          key = 'aging'
        } else {
          return
        }

        this.setDamageParam({
          sectionKey: this.options.sectionKey,
          key: key,
          value: value
        })
      }
    },
    // loadCapacity: {
    //   get () { return this.getDamageValue(this.options.sectionKey).loadCapacity },
    //   set (value) {
    //     this.setDamageParam({
    //       sectionKey: this.options.sectionKey,
    //       key: 'loadCapacity',
    //       value: value
    //     })
    //   }
    // },
    // runnability: {
    //   get () { return this.getDamageValue(this.options.sectionKey).runnability },
    //   set (value) {
    //     this.setDamageParam({
    //       sectionKey: this.options.sectionKey,
    //       key: 'runnability',
    //       value: value
    //     })
    //   }
    // },
    // aging: {
    //   get () { return this.getDamageValue(this.options.sectionKey).aging },
    //   set (value) {
    //     this.setDamageParam({
    //       sectionKey: this.options.sectionKey,
    //       key: 'aging',
    //       value: value
    //     })
    //   }
    // },
    comment: {
      get () {
        if (!this.exists || !this.section) return ''
        return this.getDamageValue(this.options.sectionKey).comment
      },
      set (value) {
        this.setDamageParam({
          sectionKey: this.options.sectionKey,
          key: 'comment',
          value: value
        })
      }
    },

    // form
    enableForm () {
      return (this.isModerator || this.isInspector) &&
        !this.projectCompleted
    },
    showRequire () {
      return this.enableForm
    },

    // flag for disabled input
    disabledForm () {
      return !this.exists || !this.section
    },
    disabledDamage () {
      return this.disabledForm
    },
    // disabledLoadCapacity () {
    //   return this.disabledForm || !this.options.loadCapacity.enable
    // },
    // disabledRunnability () {
    //   return this.disabledForm || !this.options.runnability.enable
    // },
    // disabledAging () {
    //   return this.disabledForm || !this.options.aging.enable
    // },
    
    // validate
    existsIsValid () {
      return true
    },
    sectionIsValid () {
      return true
    },
    commentIsValid () {
      return true
    },
    photoIsValid () {
      return true
    }
  },
  watch: {
    // exists: function (to) {
    //   if (to) return
    //   this.section = false
    // },
    disabledForm: function (to) {
      if (!to) return
      // if (this.showLoadCapacity) this.loadCapacity = ''
      // if (this.showRunnability) this.runnability = ''
      this.loadCapacity = ''
      this.runnability = ''
      this.aging = ''
      this.comment = ''
    }
  },
  methods: {
    ...mapActions({
      setDamageParam: 'projectTask4/setDamageParam',
      addDamagePhotoId: 'projectTask4/addDamagePhotoId',
      // showAlert: 'alert/show'
    })
  }
}