import Vue from 'vue'
import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'

const state = {
  // model
  reportModel: {},
  // value
  moderatorCommentValue: '',
  inspectorCommentValue: ''
}

const getters = {
  get: state => state
}

const actions = {
  /**
   * 初期読み出し
   */
  initLoad ({commit}, parameters) {
    return new Promise((resolve, reject) => {
      // レポート一覧を読出し
      ApiManager.request(
        ['project'],
        ['v1ProjectReportListGet'],
        [parameters])
        .then(res => {
          // レポート一覧
          if (res[0].data.Result === Constants.ApiResult.Success) {
            // model
            commit('setReportModel', res[0].data.ProjectReports[0])

            // コメント一覧を取得
            this.dispatch('projectDetail/loadComments', {
              ProjectId: parameters.ProjectId
            })
              .then(res => {
                resolve(res)
              })
              .catch(error => {
                reject(error)
              })

          } else {
            reject(res[0].data)
          }

        })
        .catch(error => {
          reject(error)
        })
    })
  },
  /**
   * レポート一覧を読出し
   */
  loadProjectReport ({commit}, parameters) {
    return new Promise((resolve, reject) => {
      // プロジェクト一覧を読出し
      ApiManager.request(
        ['project'],
        ['v1ProjectReportListGet'],
        [parameters])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            // model
            commit('setReportModel', res[0].data.ProjectReports[0])
            resolve()

          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  /**
   * 管理者からのコメントを設定する
   */
  setModeratorComment ({commit}, comment) {
    commit('setValue', {
      key: 'moderatorCommentValue',
      value: comment
    })
  },
  /**
   * 点検者からのコメントを設定する
   */
  setInspectorComment ({commit}, comment) {
    commit('setValue', {
      key: 'inspectorCommentValue',
      value: comment
    })
  },

  /**
   * 初期化
   */
  reset ({commit}) {
    commit('reset')
  }
}

const mutations = {
  setReportModel (state, data) {
    Object.keys(data).forEach(item => {
      Vue.set(state.reportModel, item, data[item])
    })
    console.log(state.reportModel)
    // Vue.set(state, 'reportModel', data)
  },
  setValue (state, {key, value}) {
    Vue.set(state, key, value)
  },

  // 初期化
  reset (state) {
    Vue.set(state, 'reportModel', [])
    state.moderatorCommentValue = ''
    state.inspectorCommentValue = ''
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
