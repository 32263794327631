import { mapMutations } from 'vuex'
// import Constants from '@/utils/Constants'
export default {
  name: 'ReminderComplete',
  data () {
    return {
      // redirectTimer: null
    }
  },
  mounted () {
    this.resetForm()
    // this.redirectTimer = setTimeout(() => {
    //   this.$router.push({name: 'Reminder'})
    // }, Constants.Params.CompletedRedirectDelay)
  },
  beforeDestroy () {
    // if (this.redirectTimer) {
    //   clearTimeout(this.redirectTimer)
    //   this.redirectTimer = null
    // }
  },
  computed: {
    description () {
      return 'メールアドレスを送信しました。'
    }
  },
  methods: {
    ...mapMutations({
      resetForm: 'reminder/reset'
    })
  }
}
