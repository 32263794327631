import { mapState, mapGetters, mapActions } from 'vuex'
import Constants from '@/utils/Constants'
import DamageForm from '../DamageForm'

export default {
  name: 'Runnability',
  components: {
    DamageForm
  },
  data () {
    return {
      categoryList: [
        {
          sectionKey: 'Expantion',
          title: '伸縮装置',
          runnability: {
            enable: true,
            items: Constants.ProjectConst.Runnability
          },
          aging: {
            enable: true,
            items: Constants.ProjectConst.Aging
          }
        },
        {
          sectionKey: 'Banking',
          title: '取付盛土（沈下）',
          runnability: {
            enable: true,
            items: Constants.ProjectConst.Runnability
          },
          aging: {
            enable: false,
            items: []
          }
        },
        {
          sectionKey: 'Fence',
          title: '車両用防護柵',
          runnability: {
            enable: true,
            items: Constants.ProjectConst.Runnability
          },
          aging: {
            enable: true,
            items: Constants.ProjectConst.Aging
          }
        },
        {
          sectionKey: 'Road',
          title: '部位路面',
          runnability: {
            enable: true,
            items: Constants.ProjectConst.Runnability
          },
          aging: {
            enable: true,
            items: Constants.ProjectConst.Aging
          }
        },
        {
          sectionKey: 'Sign',
          title: '標識付属物',
          runnability: {
            enable: true,
            items: Constants.ProjectConst.Runnability
          },
          aging: {
            enable: true,
            items: Constants.ProjectConst.Aging
          }
        }
      ]
    }
  },

  beforeMount() {
    if (this.isModify) return;

    this.photoModel.forEach(item => console.log('@', item.TargetView, item));
    // 新規登録の場合、プリセットデータを参照する
    this.categoryList
      .map(item => {
        const model = this.presetData(item.title);
        // console.log(item.title, model);
        return Object.assign(item, {
          preset: model,
        });
      });
  },

  computed: {
    ...mapState({
      photoModel: state => state.projectTask4.photoModel
    }),
    ...mapGetters({
      isModify: 'projectTask4/isModify',
      presetData: 'projectTask4/presetData',
      // showAlert: 'alert/show'
    }),

    activePreset() {
      return !this.isModify;
    }
  },
  methods: {
    ...mapActions({
      // setPhotoValueParam: 'projectTask3/setPhotoValueParam',
      // showAlert: 'alert/show'
    })
  }
}