export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      User: {
        UserIdentity: 'identity id',
        Role: 8,
        TenantIdentity: 'xxxxx',
        TenantName: 'tenant name'
      },
      Bridges: [
        {
          BridgeId: 1,
          BridgeName: '橋梁001',
          CreatedDt: '2019-08-02',
          UpdatedDt: '2019-09-20'
        },
        {
          BridgeId: 2,
          BridgeName: '橋梁002',
          CreatedDt: '2019-10-05',
          UpdatedDt: '2019-11-22'
        },
        {
          BridgeId: 3,
          BridgeName: '橋梁003',
          CreatedDt: '2019-12-12',
          UpdatedDt: '2020-02-20'
        }
      ]
    }
  }
}