import { mapState, mapActions } from 'vuex'
// import Constants from '@/utils/Constants'

export default {
  name: 'ProjectStopModal',
  mounted () {
    // // モーダル表示時の処理
    // $('#' + this.id).on('show.bs.modal', () => {

    // })

    // モーダル非表示時の処理
    $('#' + this.id).on('hide.bs.modal', () => {
      this.hide()
    })
    
    $('#' + this.id).modal('show')
  },
  data () {
    return {
      reason: ''
    }
  },
  computed: {
    ...mapState({
      active: state => state.projectStopModal.active,
      id: state => state.projectStopModal.id,
      model: state => state.projectStopModal.model
    }),
    disabledStopButton () {
      return false
    }
  },
  methods: {
    ...mapActions({
      hide: 'projectStopModal/hide'
    }),
    clickClose () {
      $('#' + this.id).modal('hide')
    },
    clickAccept () {
      //TODO API連携処理
      $('#' + this.id).modal('hide')
    }
  }
}