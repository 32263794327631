import Constants from '@/utils/Constants'

const state = {
  title: '',
  meta: []
}

const getters = {
  get: (state) => {
    return state
  }
}

const actions = {
  set ({commit}, metaData) {
    commit('set', metaData)
  }
}

const mutations = {
  set (state, metaData) {
    state['title'] = metaData['title']
    let array = [].concat(Constants.Meta)
    if (metaData['meta']) array = array.concat(metaData['meta'])
    state['meta'] = array
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
