<template>
  <div id="app">
    <div
      class="appWrapper"
      :class="{nn: !showAside, wideContent: activeList}">
      <BackgroundMap id="backgroundGoogleMap" />
      <ProjectMap id="projectGoogleMap" />
      <ProjectFormMap id="projectFormGoogleMap" />
      <div
        class="mapCover"
        v-if="showMapCover" />
      <Aside v-if="showAside" />
      <!-- router view -->
      <div
        class="contentWrap"
        v-if="showContent">
        <router-view/>
      </div>
      <Loading
        v-if="showMapLoader"
        customClass="mapLoading" />
      <Header v-if="showHeader" />
      <AccountBox />
    </div>

    <!-- dialog -->
    <Error id="errorDialog" />
    <Alert id="alertDialog" />
    <Confirm id="confirmDialog" />
    
    <!-- modal -->
    <EqModal v-if="showEqModal" />
    <EqListModal v-if="showEqListModal" />
    <ProjectPhotoModal v-if="showProjectPhotoModal" />
    <ProjectDetailModal v-if="showProjectDetailModal" />
    <SelectPhotoModal v-if="showSelectPhotoModal" />
    <ExamplesModal v-if="showExamplesModal" />
    <RenameModal v-if="showRenameModal" />
    <ProjectStopModal v-if="showProjectStopModal" />
    <ProjectDeleteModal v-if="showProjectDeleteModal" />
    <ProjectModifyModal v-if="showProjectModifyModal" />
  </div>
</template>

<style>
.mapCover {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(0,0,0,0.75);
}
.mapLoading {
  position: fixed;
  z-index: 2;
}
</style>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Loading from '@/components/Loading'
import Header from '@/components/Header'
import AccountBox from '@/components/AccountBox'
import Aside from '@/components/Aside'
// googlemap
import BackgroundMap from '@/components/GoogleMap/BackgroundMap'
import ProjectMap from '@/components/GoogleMap/ProjectMap'
import ProjectFormMap from '@/components/GoogleMap/ProjectFormMap'
// dialog
import Error from '@/components/Dialog/Error'
import Alert from '@/components/Dialog/Alert'
import Confirm from '@/components/Dialog/Confirm'
// modal
import EqModal from '@/components/Event/EqModal'
import EqListModal from '@/components/Event/EqListModal'
import ProjectPhotoModal from '@/components/Home/ProjectPhotoModal'
import ProjectDetailModal from '@/components/Project/ProjectDetailModal'
import SelectPhotoModal from '@/components/Project/Task4/SelectPhotoModal'
import ExamplesModal from '@/components/Project/ExamplesModal'
import RenameModal from '@/components/Project/Task5/RenameModal'
import ProjectStopModal from '@/components/Project/ProjectStopModal'
import ProjectDeleteModal from '@/components/Project/ProjectDeleteModal'
import ProjectModifyModal from '@/components/Project/ProjectModifyModal'

export default {
  name: 'App',
  components: {
    Loading,
    Header,
    AccountBox,
    Aside,
    BackgroundMap,
    ProjectMap,
    ProjectFormMap,
    Alert,
    Error,
    Confirm,
    EqModal,
    EqListModal,
    ProjectPhotoModal,
    ProjectDetailModal,
    SelectPhotoModal,
    ExamplesModal,
    RenameModal,
    ProjectStopModal,
    ProjectDeleteModal,
    ProjectModifyModal
  },
  data () {
    return {
      enable: false,
      pathArray: null
    }
  },
  mounted () {
    this.enable = false

    this.$nextTick(() => {
      if (this.$route.meta.requiresAuth) {
        // ローカルストレージに前回保存したユーザー情報があれば
        if (window.sessionStorage.user) {
          this.enable = true
        } else {
          this.signOut().then(() => {
            this.$router.push({name: 'Signin'})
            this.enable = true
          })
        }
      } else {
        this.enable = true
      }
    })

    // // ローカルストレージに前回保存したユーザー情報があれば
    // if (window.sessionStorage.user) {
    //   this.enable = true
    // } else {
    //   this.$router.push({name: 'Signin'})
    //   this.enable = true
    // }

    // // ローカルストレージに前回保存したユーザー情報があれば
    // if (window.sessionStorage.user) {
    //   // ブラウザ起動時にローカルストレージのトークンをリフレッシュ
    //   this.refreshToken()
    //     .then(res => {
    //       console.log('refreshToken.SUCCESS', res)
    //       window.sessionStorage.tokenRefreshed = 'true'
    //       this.enable = true
    //     })
    //     .catch(error => {
    //       console.log('refreshToken.ERROR', error)
    //       this.signOut().then(() => {
    //         this.$router.push({name: 'Signin'})
    //         this.enable = true
    //       })
    //     })
    // } else {
    //   // this.$router.push({name: 'Signin'})
    //   this.enable = true
    // }

    // pathArrayを生成
    this.setPathArray()

    // admin lteのスタイルを削除
    $('html').removeAttr('style')
    $('body').removeAttr('style')
  },
  computed: {
    ...mapState({
      authorized: state => state.auth.authorized,
      requireChangePassword: state => state.auth.requireChangePassword,
      activeList: state => state.home.activeList,
      // modal
      eqModalId: state => state.eqModal.id,
      eqListModalId: state => state.eqListModal.id,
      projectPhotoModalId: state => state.projectPhotoModal.id,
      projectDetailModalId: state => state.projectDetailModal.id,
      selectPhotoModalId: state => state.selectPhotoModal.modalId,
      examplesModalId: state => state.examplesModal.id,
      renameModalId: state => state.renameModal.id,
      projectStopModalId: state => state.projectStopModal.id,
      projectDeleteModalId: state => state.projectDeleteModal.id,
      projectModifyModalId: state => state.projectModifyModal.id,
      // googlemap
      backgroundMapActive: state => state.backgroundMap.active,
      searchingBackgroundMap: state => state.backgroundMap.searching,
      projectMapActive: state => state.projectMap.active,
      searchingProjectMap: state => state.projectMap.searching
    }),
    ...mapGetters({
      homeIsActive: 'home/active'
    }),
    showAside () {
      return this.authorized &&
        !this.requireChangePassword
    },
    showHeader () {
      return !this.pathArray || (
        this.pathArray[0] !== 'signin' &&
        this.pathArray[0] !== 'register' &&
        this.pathArray[0] !== 'reminder')
    },
    showContent () {
      return this.enable
      // return this.enable && this.$route.name !== 'Home'
    },

    // googlemap
    showMapCover () {
      if (this.$route.name === 'EventDetail') return false
      if (this.$route.name === 'Project') return false
      if (this.$route.name === 'ProjectRegister') return false
      // if (this.$route.name === 'ProjectRegisterAttribute') return false
      // if (this.$route.name === 'ProjectRegisterConfirm') return false
      // if (this.$route.name === 'ProjectRegisterComplete') return false
      return this.$route.name !== 'Home' ||
        this.homeIsActive
    },
    showMapLoader () {
      return this.searchingBackgroundMap ||
        this.searchingProjectMap
    },

    // modal
    showEqModal () {
      return this.eqModalId !== null
    },
    showEqListModal () {
      return this.eqListModalId !== null
    },
    showProjectPhotoModal () {
      return this.projectPhotoModalId !== null
    },
    showProjectDetailModal () {
      return this.projectDetailModalId !== null
    },
    showSelectPhotoModal () {
      return this.selectPhotoModalId !== null
    },
    showExamplesModal () {
      return this.examplesModalId !== null
    },
    showRenameModal () {
      return this.renameModalId !== null
    },
    showProjectStopModal () {
      return this.projectStopModalId !== null
    },
    showProjectDeleteModal () {
      return this.projectDeleteModalId !== null
    },
    showProjectModifyModal () {
      return this.projectModifyModalId !== null
    }
  },
  watch: {
    // eslint-disable-next-line
    '$route' (to, from) {
      // pathArray
      this.setPathArray()
    }
  },
  methods: {
    ...mapActions({
      refreshToken: 'auth/refreshToken',
      signOut: 'auth/signOut'
    }),
    setPathArray () {
      this.pathArray = this.$route.path.split('/')
      if(this.pathArray[0] === '') this.pathArray.shift()
      if(this.pathArray[this.pathArray.length - 1] === '') this.pathArray.pop()
    }
  }
}
</script>
