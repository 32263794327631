import { mapState, mapActions, mapMutations } from 'vuex'
import Constants from '@/utils/Constants'
import Utility from '@/utils/Utility'

export default {
  name: 'ChildMarker',
  inject: [
    'google',
    'map'
  ],
  props: {
    model: Object
  },
  mounted () {
    this.setMarker()
  },
  data () {
    return {
      marker: null
    }
  },
  computed: {
    ...mapState({
      selectedMarkerId: state => state.projectMap.selectedMarkerId,
      markerZIndex: state => state.projectMap.markerZIndex
    })
  },
  watch: {
    selectedMarkerId: function (to) {
      // アイコンの変更
      this.changeMarker()

      // 選択されたマーカーが自身の場合
      if (to === this.model.ProjectId &&
        this.model.Latitude &&
        this.model.Longitude) {
        // 地図の中心を移動
        const { LatLng } = this.google.maps
        this.map.panTo(new LatLng(this.model.Latitude, this.model.Longitude))
        this.map.panBy(-130, -40)

        // zIndexを上に
        this.setMarkerZIndex(this.markerZIndex + 1)
        this.marker.setZIndex(this.markerZIndex)
      }
    }
  },
  methods: {
    ...mapActions({
      addMarker: 'projectMap/addMarker',
      selectMarker: 'projectMap/selectMarker',
      showAlert: 'alert/show'
    }),
    ...mapMutations({
      setMarkerZIndex: 'projectMap/setMarkerZIndex'
    }),
    /**
     * マーカーを配置する
     */
    setMarker () {
      const { Marker } = this.google.maps

      let valid = this.model.Latitude !== null &&
        this.model.Longitude !== null
      
      // マーカーを生成
      this.marker = new Marker({
        position: {
          lat: this.model.Latitude || 0,
          lng: this.model.Longitude || 0
        },
        map: this.map,
        icon: this.getMarker('default'),
        zIndex: this.markerZIndex,
        visible: valid
      })
      // マーカーのクリックイベント
      this.marker.addListener('click', this.click);

      this.addMarker(this.marker)
    },
    /**
     * マーカーのSVGを取得する
     * default / active
     */
    getMarker (type) {
      // サイズ
      let size = type === 'active' ?
        Constants.GoogleMapMarkerSize.lg :
        Constants.GoogleMapMarkerSize.md

      // 色
      let roadAdminKbn = this.model.RoadAdminKbn ? String(this.model.RoadAdminKbn) : '4'
      let statusLabel = Utility.currentProjectTaskLabel(this.model.ProjectStatus)
      let currentStatusId = Constants.Tasks.filter(item => item.label === statusLabel)[0].id

      let color = currentStatusId === 1 ?
        Constants.GoogleMapMarkerColor.roadAdminKbn[roadAdminKbn][0] :
        currentStatusId === 6 ?
          Constants.GoogleMapMarkerColor.roadAdminKbn[roadAdminKbn][2] :
          Constants.GoogleMapMarkerColor.roadAdminKbn[roadAdminKbn][1]

      // let color = type === 'default' ?
      //   Constants.GoogleMapMarkerColor.default :
      //   Constants.GoogleMapMarkerColor.active

      // マーカーを生成
      let template = Constants.GoogleMapMarker.Pin()
      let svg = template.replace('{size}', size + 'px')
        .replace('{color}', color)
        .replace('{center}', Constants.GoogleMapMarkerColor.center)
        .replace('{stroke}', Constants.GoogleMapMarkerColor.stroke)
      
      const { Size } = this.google.maps
      const { Point } = this.google.maps

      let icon = {
        url: 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(svg),
        size: new Size(size, size),
        origin: new Point(0, 0),
        anchor: new Point(size / 2, size)
      }
      return icon
    },
    /**
     * マーカーを変更する
     */
    changeMarker () {
      let type = 'default'
      if (this.selectedMarkerId === this.model.ProjectId) {
        type = 'active'
      }
      this.marker.setIcon(this.getMarker(type))
    },

    /**
     * クリック時の処理
     */
    click () {
      // console.log('click marker', this.model)

      this.selectMarker(this.model.ProjectId)
    }
  }
}