export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      Events: [
        {
          EventId: 2,
          EventName: 'イベント002',
          ModeratorName: 'モデレータ 太郎',
          LiveFlg: true,
          EventStatus: '100',
          EventStatusUpdated: '2019-11-25 18:28:54',
          CreatedAt: '2019-10-05 06:40:02',
          UpdatedAt: '2019-11-22 12:05:02',
          ProjectCount: '2',
          EarthquakesInfo: []
        }
      ]
    }
  }
}