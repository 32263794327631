import { mapState } from 'vuex'
import Constants from '@/utils/Constants'
import MapLoader from '@/components/GoogleMap/ProjectRegisterMap/MapLoader'
import ChildMarker from '@/components/GoogleMap/ProjectRegisterMap/ChildMarker'
import HypoCenter from '@/components/GoogleMap/ProjectRegisterMap/HypoCenter'

export default {
  name: 'ProjectRegisterMap',
  components: {
    MapLoader,
    ChildMarker,
    HypoCenter
  },
  props: {
    id: String
  },
  data () {
    return {
      mapConfig: {
        zoom: 10,
        maxZoom: 20,
        center: Constants.GoogleMapCenter,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        clickableIcons: false,
        // styles
        // ref. https://lab.syncer.jp/Web/API/Google_Maps/JavaScript/MapTypeStyle/
        styles: [
          {
            featureType: 'poi', // 全てのスポット
            // featureType: 'poi.attraction',       // 観光名所
            // featureType: 'poi.business',         // 企業、店舗
            // featureType: 'poi.government',       // 政府機関
            // featureType: 'poi.medical',          // 病院、薬局、警察、医師などの緊急サービス
            // featureType: 'poi.park',             // 公園
            // featureType: 'poi.place_of_worship', // 宗教施設
            // featureType: 'poi.school',           // 学校
            // featureType: 'poi.sports_complex',   // スポーツ施設
            stylers: [
              {
                visibility: 'off'
              }
            ]
          }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      storeMap: state => state.projectRegisterMap.map,
      model: state => state.projectRegisterMap.model,
      hypocenter: state => state.projectRegisterMap.hypocenter
    }),
    apiKey () {
      return Constants.GoogleMapApiKey
    },
    markerList () {
      // Map再描画時にmapが存在するか確認してからmodelを返却する
      if (this.storeMap === null) return []

      console.log(this.model)
      return this.model
    },
    showHypocCenter () {
      // Map再描画時にmapが存在するか確認してからmodelを返却する
      if (this.storeMap === null) return false

      return this.hypocenter !== null
    }
  },
  methods: {

  }
}