import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Vue from 'vue'

const state = {
	active: false,
	id: null,
  // model
  companyModel: [],
  inspectorModel: [],
}

const getters = {
  get: state => state,
  getInspectorName: state => userIdentity => {
    let data = state.inspectorModel.filter(item => item.UserIdentity === userIdentity)
    return data.length ? data[0].Name : ''
  }
}

const actions = {
	show ({commit}, {id}) {
		commit('showed', {
			id: id
		})
	},
	hide ({commit}) {
		commit('hided')
	},
  /**
   * 初期読出し
   */
  initLoad ({commit}) {
    return new Promise((resolve, reject) => {
      // 点検会社一覧を読出し
      ApiManager.request(
        ['user'],
        ['v1InspectorCompanyListGet'],
        [{}])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            commit('setCompanyModel', res[0].data.Users)
            console.log('@companyModel', state.companyModel)
            resolve()

          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  /**
   * 点検会社名から点検車を取得
   */
  loadInspectorList ({commit}, {company}) {
    return new Promise((resolve, reject) => {
      // 点検会社一覧を読出し
      ApiManager.request(
        ['user'],
        ['v1InspectorListGet'],
        [{
          showAll: false,
          company: company
        }])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            commit('setInspectorModel', res[0].data.Users)
            console.log('@inspectorModel', state.inspectorModel)
            resolve()

          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}

const mutations = {
	showed (state, {id}) {
		state.id = id
		state.active = true
	},
	hided (state) {
		state.active = false
		state.id = null
	},
  // model
  setCompanyModel (state, data) {
    Vue.set(state, 'companyModel', data)
  },
  setInspectorModel (state, data) {
    Vue.set(state, 'inspectorModel', data)
  },

  // reset
  reset (state) {
    Vue.set(state, 'companyModel', [])
    Vue.set(state, 'inspectorModel', [])
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
