import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Vue from 'vue'

const state = {
  inited: false,
  eventId: null,
  values: {},
  // model
  eventModel: {},
  companyModel: [],
  inspectorModel: [],
  // assetIds
  assetIds: [],
  // 選択された地震ID
  eqId: null
}

const getters = {
  get: state => state,
  getInspectorName: state => userIdentity => {
    let data = state.inspectorModel.filter(item => item.UserIdentity === userIdentity)
    return data.length ? data[0].Name : ''
  }
}

const actions = {
  /**
   * イベントIDを選択
   */
  selectEventId ({commit}, {eventId}) {
    console.log('eventId : ', eventId)
    commit('setEventId', eventId || null)
  },
  /**
   * 地震IDを選択
   */
  selectEqId ({commit}, {eqId}) {
    console.log('eqId : ', eqId)
    commit('setEqId', eqId || null)
  },
  /**
   * 初期読出し
   */
  initLoad ({commit}) {
    return new Promise((resolve, reject) => {
      // 点検会社一覧を読出し
      ApiManager.request(
        ['user', 'event'],
        ['v1InspectorCompanyListGet', 'v1EventDetailGet'],
        [{}, {eventIds: [state.eventId]}])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            commit('setCompanyModel', res[0].data.Users)
            commit('setEventModel', res[1].data.Events[0])
            commit('inited')
            console.log('@companyModel', state.companyModel)
            console.log('@eventModel', state.eventModel)
            resolve()

          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  /**
   * 点検会社名から点検車を取得
   */
  loadInspectorList ({commit}, {company}) {
    return new Promise((resolve, reject) => {
      // 点検会社一覧を読出し
      ApiManager.request(
        ['user'],
        ['v1InspectorListGet'],
        [{
          showAll: false,
          company: company
        }])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            commit('setInspectorModel', res[0].data.Users)
            console.log('@inspectorModel', state.inspectorModel)
            resolve()

          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  /**
   * 橋梁を選択
   */
  setAsset ({commit}, {id, name, roadAdmin}) {
    commit('setAsset', {
      id: id,
      name: name,
      roadAdmin: roadAdmin
    })
  },
}

const mutations = {
  inited (state) {
    state.inited = true
  },
  setEventId (state, eventId) {
    state.eventId = eventId
  },
  setValues (state, values) {
    Vue.set(state, 'values', values)
  },
  // model
  setEventModel (state, data) {
    Vue.set(state, 'eventModel', data)
  },
  setCompanyModel (state, data) {
    Vue.set(state, 'companyModel', data)
  },
  setInspectorModel (state, data) {
    Vue.set(state, 'inspectorModel', data)
  },
  setAssetIds (state, assetIds) {
    Vue.set(state, 'assetIds', assetIds)
  },
  // eq
  setEqId (state, id) {
    state.eqId = id
  },

  // reset
  reset (state) {
    Vue.set(state, 'values', {})
    state.eqId = null
    state.inited = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
