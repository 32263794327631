import Constants from '@/utils/Constants'

const state = {
  active: false,
  title: 'エラー',
  body: '',
  label: '閉じる',
  // 特殊処理フラグ
  expiredToken: false
}

const getters = {
  get: state => state
}

const actions = {
  show ({commit}, error) {
    // console.log('routeName:', this.state.route.name)
    console.log('error', error)
    let parameters = {}
    // システムエラーの場合
    if (error === null) {
      parameters.title = Constants.Errors.SystemError.title
      parameters.body = Constants.Errors.SystemError.body

    // APIのレスポンスエラーの場合
    } else {
      let message = []
      error.Errors.forEach(item => {
        if (item.Code !== undefined) {
          message.push(item.Message + '（' + item.Code + '）')
        } else {
          message.push(item.Message)
        }
      })
      // error.Errors.forEach(item => message.push(item.Message + '（' + item.Code + '）'))
      // parameters.title = 'エラー'
      parameters.body = message.join('<br>')

      // トークンの有効期限が切れた場合
      if (error.Errors[0].Code === '4001') {
        commit('setExpiredToken', true)
      }
    }
    commit('showed', parameters)
  },
  // show ({commit}, {title, body, label}) {
  //  let parameters = {}
  //  if (title) parameters.title = title
  //  if (body) parameters.body = body
  //  if (label) parameters.label = label
  //  commit('showed', parameters)
  // },
  hide ({commit}) {
    commit('hided')
  }
}

const mutations = {
  showed (state, {title, body, label}) {
    if (title) state.title = title
    if (body) state.body = body
    if (label) state.label = label
    state.active = true
  },
  hided (state) {
    state.active = false
    state.title = ''
    state.body = ''
    state.label = ''
    state.isExpiredToken = false
  },
  setExpiredToken (state, flag) {
    state.expiredToken = flag
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
