export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      Users: [
        {
          TenantId: 1,
          TenantIdentity: '36ab6155cb5623d4565ec58f48464c62',
          TenantName: '株式会社XXXXXXXX',
          UserId: 31,
          UserIdentity: 'ib2c6a69f00ab0e34583ce9b3c58b4a1',
          LoginId: 'inspector01',
          UserRole: 16,
          Name: 'インスペクター 太郎',
          NameKana: 'インスペクター タロウ',
          Division: '建設部',
          Email: 'i01@aaa.com',
          Phone1: '080',
          Phone2: '2000',
          Phone3: '1111',
          Address: '連絡先住所',
          InspectorCompanyName: 'テンケン会社',
          InspectorCompanyNameKana: 'テンケンカイシャ',
          InspectorTeamLeader: '点検 班長',
          InspectorTeamLeaderKana: 'テンケン ハンチョウ',
          InspectorTeamNumber: '10',
          Note: '備考\n備考',
          LiveFlg: true,
          CreatedAt: '2019-08-02',
          UpdatedAt: '2019-09-20'
        }
      ]
    }
  }
}