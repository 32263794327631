import { mapState, mapActions } from 'vuex'
// import moment from 'moment'
import Constants from '@/utils/Constants'
import Utility from '@/utils/Utility'

export default {
  name: 'EqModal',
  mounted () {
    // // モーダル表示時の処理
    // $('#' + this.id).on('show.bs.modal', () => {

    // })

    // モーダル非表示時の処理
    $('#' + this.id).on('hide.bs.modal', () => {
      this.hide()
    })
    
    $('#' + this.id).modal('show')

    this.quakeTableData = $.extend(true, [], this.model)
  },
  data () {
    return {
      // 地震速報テーブル
      quakeTableColumns: [
        'EqId',
        'OriginTime',
        'HypoAreaName',
        'MaxInt',
        'Magnitude',
        'Depth',
        'Latitude',
      ],
      quakeTableData: [],
      quakeTableOptions: {
        headings: {
          EqId: '地震ID',
          OriginTime: '発生時刻',
          HypoAreaName: '震源地',
          MaxInt: '震度',
          Magnitude: 'マグニチュード',
          Depth: '深さ',
          Latitude: '緯度／経度',
        },
        columnsClasses: {
          EqId: 'wp-10',
          OriginTime: 'wp-18',
          HypoAreaName: '',
          MaxInt: 'wp-6',
          Magnitude: '',
          Depth: '',
          Latitude: '',
        },
        sortable: [],
        filterable: false,
        texts: Constants.TableOptions.getTexts('地震情報'),
        perPage: Constants.TableOptions.perPage,
        perPageValues: Constants.TableOptions.perPageValues,
        pagination: {
          show: false
        },
        resizableColumns: false,
      }
    }
  },
  computed: {
    ...mapState({
      active: state => state.eqListModal.active,
      id: state => state.eqListModal.id,
      eventName: state => state.eqListModal.eventName,
      model: state => state.eqListModal.model
    }),
    getTitle () {
      return this.eventName + 'の地震情報'
    },

    originTime () {
      // return moment(new Date(this.model.OriginTime)).format('YYYY/MM/DD HH:mmごろ')
      return Utility.getJSTDate(this.model.OriginTime, 'YYYY/MM/DD HH:mmごろ')
    },
    hypoAreaName () {
      return this.model.HypoAreaName || '-'
    },
    level () {
      return this.model.MaxInt || '-'
    },
    magnitude () {
      return this.model.Magnitude || '-'
    },
    depth () {
      if (this.model.Depth === undefined) return '-'
      return -1 * (this.model.Depth / 1000) + 'km'
    },
    latlng () {
      if (this.model.Latitude === undefined ||
        this.model.Longitude === undefined) return ''
      return '北緯' + this.model.Latitude + '度／東経' +
        this.model.Longitude + '度'
    }
  },
  methods: {
    ...mapActions({
      hide: 'eqListModal/hide'
    }),
    clickClose () {
      $('#' + this.id).modal('hide')
    },

    getViewData (props) {
      let key = props.column
      let value = props.row[key]
      
      switch (key) {
        case 'OriginTime':
          return Utility.getJSTDate(value, 'YYYY/MM/DD HH:mmごろ')
        case 'HypoAreaName':
          return value || '-'
        case 'MaxInt':
          return value || '-'
        case 'Magnitude':
          return value || '-'
        case 'Depth':
          if (value === undefined) return '-'
          return -1 * (value / 1000) + 'km'
        case 'Latitude':
          if (value === undefined ||
            props.row.Longitude === undefined) return ''
          return '北緯' + value + '度／東経' +
            props.row.Longitude + '度'
      }
      return value
    },
    changePage () {
      // console.log(this.$refs.table.page)
    },
  }
}