import { mapGetters, mapActions, mapMutations } from 'vuex'
import Constants from '@/utils/Constants'
export default {
  name: 'ReminderPassword',
  metaInfo () {
    this.setMeta({
      title: '新しいパスワードの登録 | ' + Constants.SiteInfo.title
    })
    return this.getMeta
  },
  mounted () {
    this.init()
  },
  props: {
    // token: String
  },
  computed: {
    ...mapGetters({
      getMeta: 'meta/get'
    })
  },
  methods: {
    ...mapActions({
      setMeta: 'meta/set'
    }),
    ...mapMutations({
      setToken: 'reminderPassword/setToken',
      setEmail: 'reminderPassword/setEmail'
    }),
    init () {
      // console.log('token: ', window.location.search)
      try {
        let queries = window.location.search.replace('?', '').split('&')
        let token = queries[0].replace('PasswordResetToken=', '')
        let email = decodeURIComponent(queries[1].replace('Email=', ''))
        console.log(token)
        console.log(email)
        this.setToken(token)
        this.setEmail(email)
      } catch {
        console.log('invalid')
      }
    }
  }
}
