import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import Loading from '@/components/Loading'
import Constants from '@/utils/Constants'
import PhotoUploaderItem from './Item'

export default {
  name: 'PhotoUploader',
  components: {
    Loading,
    PhotoUploaderItem
  },
  props: {
    // 編集可能な場合、true
    // 写真のダウンロードのみの場合、false
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      connecting: false
    }
  },
  computed: {
    ...mapState({
      projectId: state => state.projectDetail.projectId,
      photoModel: state => state.projectTask3.photoModel,
      photoValue: state => state.projectTask3.photoValue,
      // confirm
      accepted: state => state.confirm.accept
    }),
    itemList () {
      console.log('@ photoValue', this.photoValue)
      // 写真のダウンロードのみの場合、写真追加用のレコードを削除して返す
      if (!this.editable) {
        return this.photoValue.filter(item => item.File !== null);
      }

      return this.photoValue
    },

    // propsでeditableがfalseの場合（task完了など）、そのまま全てfalseを返す
    // propsでeditableがtrueの場合、プリセット項目は新規登録時のみtrueを返す
    adjustedEditable() {
      return item => {
        if (!this.editable) return false;

        return item.Type === 1
          ? this.photoModel.length === 0
          : true;
      }
    },

    // button
    disabledSaveButton () {
      if (this.connecting) return true
      if (this.photoValue.length < 2) return true

      // 登録写真があり、かつ新規写真が登録されている場合
      if (this.photoModel.length < this.photoValue.length - 1) {
        return false

      // 登録写真があり、新規写真の登録がない場合
      } else if (this.photoModel.length) {
        let len = this.photoModel.filter((item, index) => {
          if (this.photoValue[index].File !== undefined ||
            this.photoValue[index].TargetView !== item.TargetView ||
            this.photoValue[index].DamageDescription !== item.DamageDescription ||
            this.photoValue[index].BridgeProfileFlg !== item.BridgeProfileFlg
            ) {
            return true
          }
        }).length
        return len === 0

      // 新規写真のみが登録されている場合
      } else {
        return false
      }
    }
  },
  watch: {
    accepted: function (to) {
      if (to) {
        this.saveTask()
      }
    }
  },
  methods: {
    ...mapActions({
      showError: 'error/show',
      showAlert: 'alert/show',
      showConfirm: 'confirm/show',
      // プロジェクトステータス
      changeProjectStatus: 'projectDetail/changeProjectStatus',
      // 現場写真
      loadPhoto: 'projectTask3/loadPhoto',
      savePhoto: 'projectTask3/savePhoto',
      copyPhotoModel: 'projectTask3/copyPhotoModel'
    }),
    ...mapMutations({
      addPhotoValue: 'projectTask3/addPhotoValue'
    }),
    /**
     * 現場写真登録を登録
     */
    clickSave () {
      this.showConfirm({
        key: 'save',
        body: 'Task3を登録しますか？',
        acceptLabel: '登録',
        denyLabel: 'キャンセル'
      })
    },
    saveTask () {
      // console.log('-----')
      // console.log('photoModel : ', this.photoModel)
      console.log('photoValue : ', this.photoValue)
      this.connecting = true

      let parameters = []
      this.photoValue.forEach((d, index) => {
        let item = $.extend(true, {}, d);

        // 新規登録項目の場合、true
        const isRegister = item.TakenPhotoId === undefined;
        // プリセット項目可判別
        const isPreset = item.Type === 1
        // デスクトップから写真が選択されているか判別
        let selectedPhoto = item.File !== undefined && item.File !== null

        // プリセット項目
        if (isPreset) {
          // 登録済みの項目が変更されているか判別
          let changedValues = item.TakenPhotoId &&
            (item.DamageDescription !== this.photoModel[index].DamageDescription ||
            item.BridgeProfileFlg !== this.photoModel[index].BridgeProfileFlg ||
            item.ProjectPhotoNumber !== this.photoModel[index].ProjectPhotoNumber)

          // 新規登録
          if (isRegister) {
            // 写真登録がない場合、ProjectPhotoNumber、File、BridgeProfileFlgを削除
            if (item.File === null) {
              delete item.ProjectPhotoNumber;
              delete item.File;
              delete item.BridgeProfileFlg;
            }

          // 編集
          } else {
            // // 写真が削除された場合、ProjectPhotoNumberを削除
            // if (this.photoModel[index].FileObjectKey !== null &&
            //   item.FileObjectKey === null) {

            //   // 新たな写真登録がある場合
            //   if (item.File !== null) {
            //     item.ProjectPhotoNumber = index + 1;
            //   } else {
            //     delete item.ProjectPhotoNumber;
            //     delete item.BridgeProfileFlg;
            //   }
            // }

            // 写真の登録がなく新しい写真もない場合、ProjectPhotoNumberを削除
            if (this.photoModel[index].FileObjectKey === null &&
              !item.File) {
              delete item.ProjectPhotoNumber;
            }
          }

          if (isRegister || selectedPhoto || changedValues) {
            // Fileが登録されていればレポート作成フラグをtrue
            const regist = item.ProjectPhotoNumber && item.File !== undefined;
            const modify = item.FileObjectKey ? true : false;
            const flag = regist || modify;
            // console.log(flag, item)

            // console.log(this.photoModel[index])
            // console.log(this.photoValue[index])

            let obj = $.extend(true, {
              CreateReportFlg: flag,
            }, item)
            parameters.push(obj)
          }

        // オプション項目
        } else {
          // 登録済みの項目が変更されているか判別
          let changedValues = item.TakenPhotoId &&
            (item.TargetView !== this.photoModel[index].TargetView ||
            item.DamageDescription !== this.photoModel[index].DamageDescription ||
            item.BridgeProfileFlg !== this.photoModel[index].BridgeProfileFlg)

          if (selectedPhoto || changedValues) {
            // レポート作成フラグを常時trueに設定
            let obj = $.extend(true, {
              CreateReportFlg: true,
            }, item)
            parameters.push(obj)
          }
        }
      })
      console.log('parameters : ', parameters)

      // ファイルの追加／変更を行う
      this.savePhoto(parameters)
        .then(() => {

          // プロジェクトステータスを変更
          this.changeProjectStatus({
            code: 'task3'
          })
            .then(() => {
              this.showAlert({
                body: '写真を登録しました'
              })

              // 写真一覧の再読み込み
              this.loadPhoto({
                ProjectId: this.projectId
              })
                .then(() => {
                  // 読み出したmodelをvalueに反映
                  this.copyPhotoModel()
                  this.connecting = false
                })
                .catch(error => {
                  // API エラー
                  if (error.Result === Constants.ApiResult.Failure) {
                    this.showError(error)
                    
                  // システムエラー
                  } else {
                    this.showError(null)
                  }
                  this.connecting = false
                })
              
            })
            .catch(error => {
              // API エラー
              if (error.Result === Constants.ApiResult.Failure) {
                this.showError(error)
                
              // システムエラー
              } else {
                this.showError(null)
              }
              this.connecting = false
            })

        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
          this.connecting = false
        })
    },
    startLoading() {
      this.connecting = true
    },
    finishLoading() {
      this.connecting = false
    }
  }
}