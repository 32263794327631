import { mapState, mapActions } from 'vuex'
import Constants from '@/utils/Constants'

export default {
  name: 'HypoCenter',
  inject: [
    'google',
    'map'
  ],
  mounted () {
    this.setMarker()
  },
  data () {
    return {
      marker: null
    }
  },
  computed: {
    ...mapState({
      hypocenter: state => state.backgroundMap.hypocenter
    }),
    getMarker () {
      let size = Constants.GoogleMapMarkerSize.md
      let template = Constants.GoogleMapMarker.Hypo()
      let svg = template.replace('{color}', Constants.GoogleMapMarkerColor.hypo)

      const { Size } = this.google.maps
      const { Point } = this.google.maps

      let icon = {
        url: 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(svg),
        size: new Size(size, size),
        origin: new Point(0, 0),
        anchor: new Point(size / 2, size / 2)
      }
      return icon
    }
  },
  methods: {
    ...mapActions({
      addMarker: 'backgroundMap/addMarker'
    }),
    /**
     * マーカーを配置する
     */
    setMarker () {
      if (this.hypocenter === null) return
      
      const { Marker } = this.google.maps
      
      // マーカーを生成
      this.marker = new Marker({
        position: {
          lat: this.hypocenter.Latitude,
          lng: this.hypocenter.Longitude
        },
        map: this.map,
        icon: this.getMarker,
        // zIndex: this.markerZIndex
      })

      this.addMarker(this.marker)
    }
  }
}