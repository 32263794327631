import Vue from 'vue'
import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Utility from '@/utils/Utility'

const state = {
  // model
  presetItemModel: [],
  photoModel: [],
  photoCount: 0,
  reportName: '',
  reportObjectKey: '',
  reportCreatedAt: '',
  // value
  photoValue: [],
  moderatorCommentValue: '',
  inspectorCommentValue: ''
}

const getters = {
  get: state => state,
  isAddValue: state => {
    const data = state.photoValue
      .filter(item => item.Type === 0);
    if (!data.length) return true

    // 末尾に写真が登録されていたらtrueを返す
    let target = data[data.length - 1]
    return target.File !== null
  },
  getNewProjectPhotoNumber: state => {
    let numberList = state.photoValue
      .map(item => item.ProjectPhotoNumber)
    if (!numberList.length) return 1
    let max = Math.max(...numberList)
    return max + 1
  }
}

const actions = {
  /**
   * 初期読み出し
   */
  initLoad ({commit}, parameters) {
    return new Promise((resolve, reject) => {
      // TODO プリセットデータを取得（疎通確認）

      // 撮影写真一覧／コメント一覧を読出し
      ApiManager.request(
        ['project', 'project'],
        ['v1ProjectPhotoPresetItemsGet', 'v1ProjectPhotoListGet'],
        [{}, parameters])
        // ['project'],
        // ['v1ProjectPhotoListGet'],
        // [parameters])
        .then(res => {
          // 撮影写真一覧
          if (res[0].data.Result === Constants.ApiResult.Success) {
            // model
            commit('setPresetItemModel', res[0].data.PresetItems)
            commit('setPhotoModel', res[1].data.TakenPhotos)

            // // TODO 削除
            // commit('setPresetItemModel', [
            //   { itemName: '基礎', itemValue: '異常なし' },
            //   { itemName: '橋台', itemValue: '異常なし' },
            //   { itemName: '橋脚', itemValue: '異常なし' },
            //   { itemName: '主構造（上部工）', itemValue: '異常なし' },
            //   { itemName: '支承部', itemValue: '異常なし' },
            //   { itemName: '床版（下面）', itemValue: '異常なし' },
            //   { itemName: '伸縮装置', itemValue: '異常なし' },
            //   { itemName: '取付盛土（沈下）', itemValue: '異常なし' },
            //   { itemName: '車両用防護柵', itemValue: '異常なし' },
            //   { itemName: '部位路面', itemValue: '異常なし' },
            //   { itemName: '標識付属物', itemValue: '異常なし' },
            //   { itemName: '支承サイドブロック', itemValue: '異常なし' },
            //   { itemName: '変位制限構造', itemValue: '異常なし' },
            //   { itemName: 'ダンパー', itemValue: '異常なし' },
            //   { itemName: '落橋防止装置', itemValue: '異常なし' },
            //   { itemName: 'その他', itemValue: '異常なし' },
            //   { itemName: '下部工（コメント）', itemValue: '異常なし' },
            // ]);

            commit('setValue', {
              key: 'photoCount',
              value: res[1].data.PhotoCount || 0
            })
            commit('setValue', {
              key: 'reportName',
              value: res[1].data.PhotoReportName || ''
            })
            commit('setValue', {
              key: 'reportObjectKey',
              value: res[1].data.PhotoReportObjectKey || ''
            })
            commit('setValue', {
              key: 'reportCreatedAt',
              value: res[1].data.PhotoReportCreatedAt || ''
            })

            // value
            // commit('setPhotoValue', $.extend(true, [], state.photoModel))
            this.dispatch('projectTask3/copyPhotoModel')

            // コメント一覧を取得
            this.dispatch('projectDetail/loadComments', {
              ProjectId: parameters.ProjectId
            })
              .then(res => {
                resolve(res)
              })
              .catch(error => {
                reject(error)
              })

          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  /**
   * 現場写真を読出し
   */
  loadPhoto ({commit}, parameters) {
    return new Promise((resolve, reject) => {
      ApiManager.request(
        ['project'],
        ['v1ProjectPhotoListGet'],
        [parameters])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            // model
            commit('setPhotoModel', res[0].data.TakenPhotos)

            commit('setValue', {
              key: 'photoCount',
              value: res[0].data.PhotoCount || 0
            })
            commit('setValue', {
              key: 'reportName',
              value: res[0].data.PhotoReportName || ''
            })
            commit('setValue', {
              key: 'reportObjectKey',
              value: res[0].data.PhotoReportObjectKey || ''
            })
            commit('setValue', {
              key: 'reportCreatedAt',
              value: res[0].data.PhotoReportCreatedAt || ''
            })

            // this.dispatch('projectTask3/copyPhotoModel')
            resolve()

          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  /**
   * 現場写真を登録／編集
   */
  // eslint-disable-next-line
  savePhoto ({commit}, parameterList) {
    // console.log('parameterList', parameterList)
    return new Promise((resolve, reject) => {
      // 10ファイル単位で呼び出し
      let limit = 10
      let count = 0
      let results = []

      // 再帰アップロード
      let upload = () => {
        // console.log('upload ----------- ' + count)
        let types = []
        let methods = []
        let parameters = []

        // パラメータを生成
        parameterList.forEach((item, index) => {
          if (limit * count <= index &&
            index < limit * (count + 1)) {
            types.push('project')

            // 変更
            if (item.TakenPhotoId) {
              methods.push('v1ProjectPhotoUpdate')
              parameters.push({
                ProjectId: this.state.projectDetail.projectModel.ProjectId,
                TakenPhotoId: item.TakenPhotoId,
                TakenPhotoData: item
              })

            // 追加
            } else {
              methods.push('v1ProjectPhotoPost')
              parameters.push({
                ProjectId: this.state.projectDetail.projectModel.ProjectId,
                TakenPhotoData: item
              })
            }
          }
        })

        // API
        ApiManager.request(
          types,
          methods,
          parameters)
          .then(res => {
            results = results.concat(res)
            // console.log(results)
            if (parameterList.length <= ++count * limit) {
              if (results[0].data.Result === Constants.ApiResult.Success) {
                resolve(results)

              } else {
                reject(results[0].data)
              }

            } else {
              upload()
            }
            
          })
          .catch(error => {
            reject(error)
          })
      }
      upload()
    })
  },

  /**
   * 指定した写真（オプション項目）を削除
   */
  // eslint-disable-next-line
  deletePhoto ({commit}, parameters) {
    return new Promise((resolve, reject) => {
      // プロジェクト一覧を読出し
      ApiManager.request(
        ['project'],
        ['v1ProjectPhotoDelete'],
        [parameters])
        .then(res => {
          // console.log(res)
          let errorList = res[0].data.Results.filter(item => item.Result === Constants.ApiResult.Failure)

          if (!errorList.length) {
            resolve()

          } else {
            let error = {
              Result: Constants.ApiResult.Failure,
              Errors: [{
                Message: errorList.length + '枚の写真を削除できませんでした。'
              }]
            }
            reject(error)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  /**
   * 指定した写真（プリセット項目）を削除
   */
  // eslint-disable-next-line
  deletePresetPhoto ({commit}, parameters) {
    return new Promise((resolve, reject) => {
      // プロジェクト一覧を読出し
      ApiManager.request(
        ['project'],
        ['v1ProjectPhotoUpdate'],
        [parameters])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            resolve()

          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  /**
   * 現場写真のmodelをvalueに上書きコピー
   * 初期読出し、削除時に実行
   */
  copyPhotoModel ({commit}) {
    const data = state.photoModel.length === 0
      ? state.presetItemModel
          .map((item, index) => {
            return {
              Type: 1,
              BridgeProfileFlg: false,
              DamageDescription: item.itemValue,
              File: null,
              TargetView: item.itemName,
              ProjectPhotoNumber: index + 1
            };
          })
      : state.photoModel
          .map((item, index) => {
            return item.Type === 1
              ? $.extend(true, item, {
                ProjectPhotoNumber: index + 1
              })
              : item
          });
      // : state.photoModel;
    console.log('@@ data', data)
    commit('setPhotoValue', $.extend(true, [], data))

    // commit('setPhotoValue', $.extend(true, [], state.photoModel))

    this.dispatch('projectTask3/adjustValue')
    console.log('copyPhotoModel : ', state.photoValue)
  },

  copySinglePhotoModel({ commit }, params) {
    console.log(params)
    if (params.Type === 1) {
      const model = state.photoModel
        .find(item => item.TakenPhotoId === params.TakenPhotoId);
      if (!model) return;

      const data = state.photoValue
        .map((item, index) => {
          return item.TakenPhotoId === params.TakenPhotoId
            ? $.extend(true, model, {
                ProjectPhotoNumber: index + 1
              })
            : item;
        })

      commit('setPhotoValue', data);
    } else {
      const data = state.photoValue
        .filter((item, index) => {
          return item.TakenPhotoId !== params.TakenPhotoId;
        })

      commit('setPhotoValue', data);
    }
  },
  
  /**
   * 入力値を変更
   */
  setPhotoValueParam ({commit}, {index, key, value}) {
    // console.log(getters.isAddValue)
    commit('setPhotoValueParam', {
      index: index,
      key: key,
      value: value
    })

    this.dispatch('projectTask3/adjustValue')
  },
  /**
   * 現場写真登録の項目の増減を行う
   */
  adjustValue ({getters, commit}) {
    // console.log('adjustValue.before', $.extend(true, [], state.photoValue))

    // アップロードする写真を削除したときにラインを削除（プリセットを除く）
    let list = state.photoValue
      .filter(item => item.Type === 1 || item.File !== null)

    commit('setPhotoValue', list)

    // 末尾に写真が登録されていたらラインを生成
    if (getters.isAddValue) {
      commit('addPhotoValue', getters.getNewProjectPhotoNumber)
    }
  },
  /**
   * 指定の現場写真項目をプロフィール写真に登録する
   */
  setProfileIndex ({commit}, targetIndex) {
    state.photoValue.forEach((item, index) => {
      commit('setPhotoValueParam', {
        index: index,
        key: 'BridgeProfileFlg',
        value: targetIndex === index
      })
    })
  },

  /**
   * 管理者からのコメントを設定する
   */
  setModeratorComment ({commit}, comment) {
    commit('setValue', {
      key: 'moderatorCommentValue',
      value: comment
    })
  },
  /**
   * 点検者からのコメントを設定する
   */
  setInspectorComment ({commit}, comment) {
    commit('setValue', {
      key: 'inspectorCommentValue',
      value: comment
    })
  },

  /**
   * 初期化
   */
  reset ({commit}) {
    commit('reset')
  }
}

const mutations = {
  setPresetItemModel (state, data) {
    Vue.set(state, 'presetItemModel', data)
  },
  setPhotoModel (state, data) {
    const list = []

    // プリセット項目は、プリセット項目の順番に並べ替え
    state.presetItemModel
      .forEach(preset => {
        const index = data
          .findIndex(item => item.TargetView === preset.itemName &&
            item.Type === 1);
        if (index !== -1) {
          list.push(data[index]);
        }
      });

    // オプション項目を追加
    data
      .filter(item => item.Type !== 1)
      .sort(Utility.sortByKey('ProjectPhotoNumber', true))
      .forEach(item => list.push(item));

    // list.forEach(item => console.log(item.TargetView))
    Vue.set(state, 'photoModel', list)

    // // ProjectPhotoNumberでソート
    // data.sort(Utility.sortByKey('ProjectPhotoNumber', true))
    
    // Vue.set(state, 'photoModel', data)
  },
  setValue (state, {key, value}) {
    Vue.set(state, key, value)
  },

  // 現場写真
  setPhotoValue (state, data) {
    Vue.set(state, 'photoValue', data)
  },
  addPhotoValue (state, photoNumber) {
    state.photoValue.push({
      Type: 0,
      TargetView: '',
      DamageDescription: '',
      File: null,
      BridgeProfileFlg: false,
      ProjectPhotoNumber: photoNumber
    })
  },
  setPhotoValueParam (state, {index, key, value}) {
    // console.log('setPhotoValueParam[' + index + '].' + key + ' = ' + value)
    Vue.set(state.photoValue[index], key, value)
  },

  // 初期化
  reset (state) {
    Vue.set(state, 'photoModel', [])
    state.photoCount = 0
    state.reportName = ''
    state.reportObjectKey = ''
    state.reportCreatedAt = ''
    Vue.set(state, 'photoValue', [])
    state.moderatorCommentValue = ''
    state.inspectorCommentValue = ''
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
