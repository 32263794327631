export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      Events: [
        {
          EventId: 3,
          EventName: 'イベント003',
          ModeratorName: 'モデレータ 太郎',
          LiveFlg: true,
          EventStatus: '900',
          EventStatusUpdated: '2020-02-24 23:12:02',
          CreatedAt: '2019-12-12 22:12:02',
          UpdatedAt: '2020-02-20 23:55:02',
          ProjectCount: '7',
          EarthquakesInfo: []
        }
      ]
    }
  }
}