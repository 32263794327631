import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Vue from 'vue'

const state = {
  targetId: null,
  values: {}
}

const getters = {
  get: state => state
}

const actions = {
  /**
   * 初期読出し
   */
  initLoad ({commit}, {targetId}) {
    commit('setTargetId', targetId)

    return new Promise((resolve, reject) => {
      // お知らせ詳細を読出し
      ApiManager.request(
        ['notice'],
        ['v1NoticeDetailGet'],
        [{
          noticeId: state.targetId
        }])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            commit('initLoaded', res[0].data.Notices[0])
            console.log('@noticeValue', state.values)
            resolve()
            
          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {
  initLoaded (state, data) {
    Vue.set(state, 'values', {
      subject: data.NoticeSubject,
      body: data.NoticeText,
      invalidFlag: !data.LiveFlg,
      note: data.Note,
      startDate: data.OpenTermStartDt,
      endDate: data.OpenTermEndDt,
      createdDt: data.CreatedDt,
      updatedDt: data.UpdatedDt
    })
  },
  setTargetId (state, targetId) {
    state.targetId = targetId
  },
  reset (state) {
    state.targetId = null
    Vue.set(state, 'values', {})
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
