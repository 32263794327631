import { mapState, mapActions } from 'vuex'
export default {
  name: 'Switcher',
  data () {
    return {

    }
  },
  computed: {
    ...mapState({
      model: state => state.backgroundMap.model,
      showGnav: state => state.config.showGnav,
      activeList: state => state.home.activeList
    }),
    disabledList () {
      return this.showGnav ||
        !this.model.length
    }
  },
  methods: {
    ...mapActions({
      showList: 'home/showList',
      hideList: 'home/hideList'
    }),
    clickMap () {
      this.hideList()
    },
    clickList () {
      if (this.disabledList) return

      this.showList()
    }
  }
}