export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      ProjectId: 1,
      PhotoCount: 3,
      Comments: [
        {
          CommentId: 4,
          CommentType: 1,
          Comment: 'コメントテスト\nコメント',
          CreateUserName: '点検者 太郎',
          CreateUserNameKana: 'テンケンシャ タロウ',
          CreatedAt: '2020-03-20 19:20:29'
        },
        {
          CommentId: 3,
          CommentType: 2,
          Comment: 'モデレータからのコメント',
          CreateUserName: 'モデレータ 太郎',
          CreateUserNameKana: 'モデレータ タロウ',
          CreatedAt: '2020-03-19 10:10:05'
        },
        {
          CommentId: 1,
          CommentType: 1,
          Comment: '点検者からのコメント',
          CreateUserName: '点検者 太郎',
          CreateUserNameKana: 'テンケンシャ タロウ',
          CreatedAt: '2020-03-19 09:06:59'
        }
      ]
    }
  }
}