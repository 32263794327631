import { mapState, mapMutations } from 'vuex'
import Validator from '@/utils/Validator'
export default {
  name: 'PasswordInput',
  data () {
    return {
      // 入力値
      currentPassword: '',
      password: '',
      passwordConfirm: '',
      isClickedConfirm: false
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapState({
      values: state => state.register.values
    }),
    // validate
    currentPasswordIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.currentPassword)
    },
    passwordIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.password)
    },
    passwordConfirmIsValid () {
      if (!this.isClickedConfirm) return true
      return this.password === this.passwordConfirm
    },
    formIsValid () {
      // return true
      return this.currentPasswordIsValid &&
         this.passwordIsValid &&
        this.passwordConfirmIsValid
    },
    disableConfirmButton () {
      return !Validator.validText(this.currentPassword) ||
        !Validator.validText(this.password) ||
        this.password !== this.passwordConfirm
    }
  },
  methods: {
    ...mapMutations({
      setValues: 'register/setValues'
    }),
    init () {
      // 確認画面から戻った時、編集に宣した時に値を挿入
      // if (this.values) this.isClickedConfirm = true
      if (this.values.currentPassword) this.currentPassword = this.values.currentPassword
      if (this.values.password) this.password = this.values.password
      if (this.values.password) this.passwordConfirm = this.values.password
    },
    clickConfirm () {
      this.isClickedConfirm = true
      // console.log(this.invalidFlag)

      // validate
      if (this.formIsValid) {
        let data = {
          currentPassword: this.currentPassword,
          password: this.password
        }
        this.setValues(data)
        this.$router.push({name: 'PasswordConfirm'})
      }
    }
  }
}
