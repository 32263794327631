export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      EventReportName: '調査・点検橋梁一覧表報告書 rev1.0',
      EventReportObjectKey: 'S3 Object Key',
      EventReportCreatedAt: '2020-02-20 23:55:02'
    }
  }
}
