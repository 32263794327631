import StatusForm from '../StatusForm'

export default {
  name: 'Others',
  components: {
    StatusForm
  },
  data () {
    return {
      sectionKey: 'Others'
    }
  }
}