import { mapActions } from 'vuex'
import Constants from '@/utils/Constants'

export default {
  name: 'ChildMarker',
  inject: [
    'google',
    'map'
  ],
  props: {
    model: Object
  },
  mounted () {
    this.setMarker()
  },
  data () {
    return {
      marker: null
    }
  },
  computed: {
    defaultMarker () {
      let size = Constants.GoogleMapMarkerSize.md
      let template = Constants.GoogleMapMarker.Pin()
      let svg = template.replace('{size}', size + 'px')
        .replace('{color}', Constants.GoogleMapMarkerColor.default)
        .replace('{center}', Constants.GoogleMapMarkerColor.center)
        .replace('{stroke}', Constants.GoogleMapMarkerColor.stroke)
      
      const { Size } = this.google.maps
      const { Point } = this.google.maps

      let icon = {
        url: 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(svg),
        size: new Size(size, size),
        origin: new Point(0, 0),
        anchor: new Point(size / 2, size)
      }
      return icon
    }
  },
  methods: {
    ...mapActions({
      addMarker: 'assetDetailMap/addMarker'
    }),
    /**
     * マーカーを配置する
     */
    setMarker () {
      const { Marker } = this.google.maps
      
      // マーカーを生成
      this.marker = new Marker({
        position: {
          lat: this.model.Latitude,
          lng: this.model.Longitude
        },
        map: this.map,
        icon: this.defaultMarker
      })

      this.addMarker(this.marker)
    },
  }
}