import { mapState, mapMutations } from 'vuex'
import Constants from '@/utils/Constants'

export default {
  name: 'EventComplete',
  data () {
    return {
      redirectTimer: null
    }
  },
  mounted () {
    this.resetForm()
    this.redirectTimer = setTimeout(() => {
      this.$router.push({
        name: 'EventDetail',
        params: {
          eventId: String(this.eventId)
        }
      })

    }, Constants.Params.CompletedRedirectDelay)
  },
  beforeDestroy () {
    if (this.redirectTimer) {
      clearTimeout(this.redirectTimer)
      this.redirectTimer = null
    }
  },
  computed: {
    ...mapState({
      eventId: state => state.projectForm.eventId
    })
  },
  methods: {
    ...mapMutations({
      resetForm: 'projectForm/reset'
    })
  }
}
