// import Constants from '@/utils/Constants'

const state = {
	active: false,
	id: null
}

const getters = {
  get: state => state
}

const actions = {
	show ({commit}, {id}) {
		commit('showed', {
			id: id
		})
	},
	hide ({commit}) {
		commit('hided')
	}
}

const mutations = {
	showed (state, {id}) {
		state.id = id
		state.active = true
	},
	hided (state) {
		state.active = false
		state.id = null
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
