import moment from 'moment-timezone'
import Constants from '@/utils/Constants'

export default {
  // *********************
  // UserRole
  // *********************
  /**
   * ユーザーロール：整数からビット値に変換
   */
  roleToBit (userRole) {
    let bit = ('000000' + userRole.toString(2)).slice(-7).split('')
    return bit.reverse().join('')
    // return ('00000' + userRole.toString(2)).slice(-6)
  },
  /**
   * ユーザーロール：ビット値から整数に変換
   */
  roleToInt (userRole) {
    let n = 0
    if (typeof userRole !== 'string' || userRole.length !== 7) return n
    if (userRole.charAt(0) === '1') n += 64
    if (userRole.charAt(1) === '1') n += 32
    if (userRole.charAt(2) === '1') n += 16
    if (userRole.charAt(3) === '1') n += 8
    if (userRole.charAt(4) === '1') n += 4
    if (userRole.charAt(5) === '1') n += 2
    if (userRole.charAt(6) === '1') n += 1
    return n
  },
  /**
   * ユーザーロール：整数からロールコードを取得
   */
  getRoleCodeByInt (userRole) {
    let bit = this.roleToBit(userRole)
    let bitArray = []
    for(let i = 0, l = bit.length; i < l; i++) {
      if (bit.charAt(i) === '1') {
        bitArray.push(Constants.UserRole[i].code)
      }
    }
    return bitArray.join('、')
  },
  /**
   * ユーザーロール：整数からロールラベルを取得
   */
  getRoleLabelByInt (userRole) {
    let bit = this.roleToBit(userRole)
    let bitArray = []
    for(let i = 0, l = bit.length; i < l; i++) {
      if (bit.charAt(i) === '1') {
        bitArray.push(Constants.UserRole[i].label)
      }
    }
    return bitArray.join('、')
  },

  // *********************
  // ProjectStatus
  // *********************
  /**
   * プロジェクトステータス：整数からビット値に変換
   */
  projectStatusToBit (status) {
    let bit = ('00000' + status.toString(2)).slice(-6).split('')
    return bit.join('')
    // return bit.reverse().join('')
  },
  /**
   * プロジェクトステータス：ビット値から整数に変換
   */
  projectStatusToInt (status) {
    let n = 0
    if (typeof status !== 'string' || status.length !== 6) return n
    if (status.charAt(0) === '1') n += 32
    if (status.charAt(1) === '1') n += 16
    if (status.charAt(2) === '1') n += 8
    if (status.charAt(3) === '1') n += 4
    if (status.charAt(4) === '1') n += 2
    if (status.charAt(5) === '1') n += 1
    return n
  },
  /**
   * プロジェクトのタスクが完了しているか判別
   */
  getEnableToCompleteProject (status) {
    let bit = this.projectStatusToBit(status)
    return bit[2] === '1' &&
      bit[3] === '1' &&
      bit[4] === '1' &&
      bit[5] === '1'
  },
  /**
   * プロジェクトのタスクが完了しているか判別
   */
  projectCompleted (status) {
    let bit = this.projectStatusToBit(status)
    return bit[0] === '1'
  },
  /**
   * 一番進んでいるプロジェクトタスクのビット値を取得
   */
  currentProjectTaskBit (status) {
    let bit = this.projectStatusToBit(status).split('').reverse()
    let currentIndex = 0
    bit.forEach((item, index) => item === '1' ?
      currentIndex = index : null)
    return Constants.Tasks[currentIndex].bit
  },
  /**
   * 一番進んでいるプロジェクトタスク名を取得
   */
  currentProjectTaskLabel (status) {
    let bit = this.projectStatusToBit(status).split('').reverse()
    let currentIndex = 0
    bit.forEach((item, index) => item === '1' ?
      currentIndex = index : null)
    return Constants.Tasks[currentIndex].label
  },

  // *********************
  // Identifier
  // *********************
  /**
   * イベントIDを0埋めして返す
   */
  getEventIdZeroPadding (value) {
    return ('00000' + value).slice(-5)
  },
  /**
   * プロジェクトIDを0埋めして返す
   */
  getProjectIdZeroPadding (value) {
    return ('00000' + value).slice(-5)
  },

  // *********************
  // Date
  // *********************
  /**
   * GMTをJSTに変換して指定のフォーマットで返す
   * @param  format  YYYY/MM/DD HH:mm:ss
   */
  getJSTDate (value, format) {
    // return moment(value).tz('Asia/Tokyo').format(format)
    return moment(value).add(9, 'hours').format(format)
    // return moment(value).add(9, 'h').format(format)
  },
  getDate (value, format) {
    return moment(value).format(format)
  },
  getDateObjectByYYYYMMDD (date) {
    let str = date.substr(0, 4) + '-' +
      date.substr(4, 2) + '-' +
      date.substr(6, 2)
    return new Date(str)
  },

  // *********************
  // Object
  // *********************
  /**
   * keyでソート
   */
  sortByKey (key, ascending) {
    return (a, b) => {
      if (a[key] < b[key]) return ascending ? -1 : 1
      if (a[key] > b[key]) return ascending ? 1 : -1
      return 0
    }
  },

  // *********************
  // HTML
  // *********************
  /**
   * HTMLデコード
   */
  decodeHTML (str) {
    str = str.replace(/&lt;/g, '<')
    str = str.replace(/&gt;/g, '>')
    str = str.replace(/&amp;/g, '&')
    str = str.replace(/&apos;/g, '\'')
    str = str.replace(/&quot;/g, '"')
    return str
  },
  /**
   * 改行コードを変換
   */
  decodeBraak (str) {
    return str.replace(/\n/g, '<br>')
  }
}